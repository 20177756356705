import { LANGUAGES, REGIONS } from 'state/intl/types';

export const regionSupportedLanguages = {
  [REGIONS.US]: new Set([LANGUAGES.en]),
  [REGIONS.GB]: new Set([LANGUAGES.en]),
  [REGIONS.CA]: new Set([LANGUAGES.fr, LANGUAGES.en]),
  [REGIONS.CH]: new Set([LANGUAGES.en, LANGUAGES.de, LANGUAGES.it, LANGUAGES.fr]),
  [REGIONS.ZA]: new Set([LANGUAGES.en]),
  [REGIONS.DE]: new Set([LANGUAGES.de]),
  [REGIONS.NZ]: new Set([LANGUAGES.en]),
  [REGIONS.AQ]: new Set([LANGUAGES.en]),
};
