import { isNil } from 'lodash-es';

import { IHistoricalOrder } from '@rbi-ctg/menu';
import { useGetUserOrderQuery } from 'generated/rbi-graphql';
import { useAuthContext } from 'state/auth';

export const useOrderDetails = (rbiOrderId?: string | null) => {
  const { isAuthenticated } = useAuthContext();
  const { data, error, loading, refetch } = useGetUserOrderQuery({
    variables: {
      id: rbiOrderId!,
    },
    skip: isNil(rbiOrderId) || !isAuthenticated(),
  });

  const order = (data?.order as unknown) as IHistoricalOrder;

  return { error, loading, order, refetch };
};
