import React, { useState } from 'react';

import { Icon } from '@rbilabs/components-library';
import ReactDOM from 'react-dom';
import { useIntl } from 'react-intl';

import useOnClickOutside from 'hooks/use-on-click-outside';
import { useAuthContext } from 'state/auth';
import { CustomEventNames, EventTypes, useMParticleContext } from 'state/mParticle';
import { useReversedUIContext } from 'state/reversed-ui';

import ListOfLinks from '../dropdown/list-of-links';

import { IconContainer, SideNavContainer, StyledAccount } from './side-navigation.styled';
import { IActionBtn, ISideNav, ISideNavWithClickOutsideListener } from './types';

const SideNavWithClickOutsideListener = ({
  entries,
  visible,
  onDismiss,
}: ISideNavWithClickOutsideListener) => {
  const mParticle = useMParticleContext();
  const OutsideClickWrapper = useOnClickOutside(onDismiss);
  const { isAuthenticated } = useAuthContext();

  const trackStaticPage = (attr: any) => () => {
    mParticle.logEvent(CustomEventNames.BUTTON_CLICK_STATIC_PAGES, EventTypes.Navigation, attr);
  };

  return (
    <OutsideClickWrapper>
      <SideNavContainer
        data-testid="side-navigation-container"
        visible={visible}
        aria-expanded={visible}
      >
        {isAuthenticated() && <StyledAccount />}
        {entries && (
          <ListOfLinks
            navigate={() => undefined}
            title={''}
            entries={entries}
            trackStaticPage={trackStaticPage}
          />
        )}
      </SideNavContainer>
    </OutsideClickWrapper>
  );
};

const ActionBtn = ({ visible, iconColor, onClick }: IActionBtn) => {
  const { formatMessage } = useIntl();

  return (
    <IconContainer
      aria-label={formatMessage({ id: 'toggleMenu' })}
      data-testid="side-nav-action-btn"
      onClick={onClick}
    >
      <Icon icon={visible ? 'close' : 'menu'} color={iconColor} width="1.5rem" aria-hidden />
    </IconContainer>
  );
};

const SideNav = ({ entries }: ISideNav) => {
  const [visible, setVisible] = useState<boolean>(false);
  const { reversedUI } = useReversedUIContext();
  const iconColor = reversedUI ? 'icon-reversed' : 'icon-header-contrast';

  const revealSideNav = () => {
    setVisible(!visible);
  };

  return (
    <>
      <ActionBtn visible={visible} onClick={revealSideNav} iconColor={iconColor} />
      {ReactDOM.createPortal(
        <SideNavWithClickOutsideListener
          entries={entries}
          visible={visible}
          onDismiss={() => {
            setVisible(false);
          }}
        />,
        document.body
      )}
    </>
  );
};

export default SideNav;
