import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { useUserGeoQuery } from 'generated/rbi-graphql';
import { useLocale } from 'state/intl';
import { inferHasShownLanguageSelector as userSelectedLocale } from 'state/intl/hook';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { ENABLE_AUTO_SHOW_REGION_SELECTOR } from 'utils/constants';
import { routes } from 'utils/routing';

import { fetchUserLocale } from './fetch-user-locale';

export const useSetUserLocale = () => {
  const { pathname } = useLocation();
  const isLocalizationDisabled = useFlag(LaunchDarklyFlag.DISABLE_LOCALIZATION);
  const enableAutoLocale = !!useFlag(LaunchDarklyFlag.ENABLE_AUTO_LOCALE);
  const enableAutoShowRegionSelector = ENABLE_AUTO_SHOW_REGION_SELECTOR;
  const isDeployPreview = pathname.includes('preview-');
  const {
    clearRegionSpecificStorage,
    region: currentRegion,
    locale: currentLocale,
    setShowLanguageSelectorModal,
    setHasShownLocaleSelector,
    inferLanguage,
    determiningLocaleComplete,
    setDeterminingLocaleComplete,
  } = useLocale();

  const blacklistedLangModalRoute = [routes.confirmJwt, routes.store].some(route =>
    pathname.includes(route)
  );

  const shouldFetchLocale =
    !isLocalizationDisabled &&
    !userSelectedLocale() &&
    !blacklistedLangModalRoute &&
    !determiningLocaleComplete;

  const { data } = useUserGeoQuery({
    skip: !shouldFetchLocale || !enableAutoLocale,
  });

  useEffect(() => {
    if (data) {
      fetchUserLocale({
        currentRegion,
        currentLocale,
        clearRegionSpecificStorage,
        enableAutoShowRegionSelector,
        setShowLanguageSelectorModal,
        setHasShownLocaleSelector,
        isDeployPreview,
        inferLanguage,
        setDeterminingLocaleComplete,
        data,
      });
    }
  }, [
    clearRegionSpecificStorage,
    currentLocale,
    currentRegion,
    data,
    enableAutoShowRegionSelector,
    inferLanguage,
    isDeployPreview,
    setDeterminingLocaleComplete,
    setHasShownLocaleSelector,
    setShowLanguageSelectorModal,
  ]);
};
