import { useMemo } from 'react';

import { useOrderContext } from 'state/order';
import { useUIContext } from 'state/ui';

export const useCartButtonText = () => {
  const { formatCurrencyForLocale } = useUIContext();
  const { calculateCartTotal } = useOrderContext();

  const cartButtonText = useMemo(() => formatCurrencyForLocale(calculateCartTotal()), [
    calculateCartTotal,
    formatCurrencyForLocale,
  ]);

  return { cartButtonText };
};
