/**
 * AUTOGENERATED - DO NOT EDIT DIRECTLY
 *
 * Please see "scripts/regionalization/README.md" for how to modify
 */

// eslint-disable-next-line prettier/prettier
import {
  ca,
  cs,
  de,
  enCA,
  enGB,
  enNZ,
  enUS,
  es,
  fr,
  frCA,
  frCH,
  ko,
  nl,
  pl,
  pt,
  ro,
} from 'date-fns/locale';

import { CURRENCY_CODE_FORMAT, LANGUAGES, LOCALES, MONEY_FORMAT, REGIONS } from './types';

export const LOCALE_DATA = {
  [LOCALES.ca_es]: {
    code: LOCALES.ca_es,
    region: REGIONS.ES,
    language: LANGUAGES.ca,
    moneyFormat: MONEY_FORMAT.ca_es,
    currencyCodeFormat: CURRENCY_CODE_FORMAT.ca_es,
    dateFormat: ca,
  },
  [LOCALES.cs_cz]: {
    code: LOCALES.cs_cz,
    region: REGIONS.CZ,
    language: LANGUAGES.cs,
    moneyFormat: MONEY_FORMAT.cs_cz,
    currencyCodeFormat: CURRENCY_CODE_FORMAT.cs_cz,
    dateFormat: cs,
  },
  [LOCALES.de_ch]: {
    code: LOCALES.de_ch,
    region: REGIONS.CH,
    language: LANGUAGES.de,
    moneyFormat: MONEY_FORMAT.de_ch,
    currencyCodeFormat: CURRENCY_CODE_FORMAT.de_ch,
    dateFormat: frCH,
  },
  [LOCALES.de_de]: {
    code: LOCALES.de_de,
    region: REGIONS.DE,
    language: LANGUAGES.de,
    moneyFormat: MONEY_FORMAT.de_de,
    currencyCodeFormat: CURRENCY_CODE_FORMAT.de_de,
    dateFormat: de,
  },
  [LOCALES.de_at]: {
    code: LOCALES.de_at,
    region: REGIONS.AT,
    language: LANGUAGES.de,
    moneyFormat: MONEY_FORMAT.de_at,
    currencyCodeFormat: CURRENCY_CODE_FORMAT.de_at,
    dateFormat: de,
  },
  [LOCALES.en_ca]: {
    code: LOCALES.en_ca,
    region: REGIONS.CA,
    language: LANGUAGES.en,
    moneyFormat: MONEY_FORMAT.en_ca,
    currencyCodeFormat: CURRENCY_CODE_FORMAT.en_ca,
    dateFormat: enCA,
  },
  [LOCALES.en_ch]: {
    code: LOCALES.en_ch,
    region: REGIONS.CH,
    language: LANGUAGES.en,
    moneyFormat: MONEY_FORMAT.en_ch,
    currencyCodeFormat: CURRENCY_CODE_FORMAT.en_ch,
    dateFormat: frCH,
  },
  [LOCALES.en_de]: {
    code: LOCALES.en_de,
    region: REGIONS.DE,
    language: LANGUAGES.en,
    moneyFormat: MONEY_FORMAT.en_de,
    currencyCodeFormat: CURRENCY_CODE_FORMAT.en_de,
    dateFormat: de,
  },
  [LOCALES.en_gb]: {
    code: LOCALES.en_gb,
    region: REGIONS.GB,
    language: LANGUAGES.en,
    moneyFormat: MONEY_FORMAT.en_gb,
    currencyCodeFormat: CURRENCY_CODE_FORMAT.en_gb,
    dateFormat: enGB,
  },
  [LOCALES.en_nz]: {
    code: LOCALES.en_nz,
    region: REGIONS.NZ,
    language: LANGUAGES.en,
    moneyFormat: MONEY_FORMAT.en_nz,
    currencyCodeFormat: CURRENCY_CODE_FORMAT.en_nz,
    dateFormat: enNZ,
  },
  [LOCALES.en_us]: {
    code: LOCALES.en_us,
    region: REGIONS.US,
    language: LANGUAGES.en,
    moneyFormat: MONEY_FORMAT.en_us,
    currencyCodeFormat: CURRENCY_CODE_FORMAT.en_us,
    dateFormat: enUS,
  },
  [LOCALES.en_za]: {
    code: LOCALES.en_za,
    region: REGIONS.ZA,
    language: LANGUAGES.en,
    moneyFormat: MONEY_FORMAT.en_za,
    currencyCodeFormat: CURRENCY_CODE_FORMAT.en_za,
    dateFormat: enGB,
  },
  [LOCALES.en_nl]: {
    code: LOCALES.en_nl,
    region: REGIONS.NL,
    language: LANGUAGES.en,
    moneyFormat: MONEY_FORMAT.en_nl,
    currencyCodeFormat: CURRENCY_CODE_FORMAT.en_nl,
    dateFormat: nl,
  },
  [LOCALES.en_aq]: {
    code: LOCALES.en_aq,
    region: REGIONS.AQ,
    language: LANGUAGES.en,
    moneyFormat: MONEY_FORMAT.en_aq,
    currencyCodeFormat: CURRENCY_CODE_FORMAT.en_aq,
    dateFormat: enUS,
  },
  [LOCALES.en_kr]: {
    code: LOCALES.en_kr,
    region: REGIONS.KR,
    language: LANGUAGES.en,
    moneyFormat: MONEY_FORMAT.ko_kr,
    currencyCodeFormat: CURRENCY_CODE_FORMAT.ko_kr,
    dateFormat: ko,
  },
  [LOCALES.en_es]: {
    code: LOCALES.en_es,
    region: REGIONS.ES,
    language: LANGUAGES.en,
    moneyFormat: MONEY_FORMAT.en_es,
    currencyCodeFormat: CURRENCY_CODE_FORMAT.en_es,
    dateFormat: es,
  },
  [LOCALES.en_at]: {
    code: LOCALES.en_at,
    region: REGIONS.AT,
    language: LANGUAGES.en,
    moneyFormat: MONEY_FORMAT.en_at,
    currencyCodeFormat: CURRENCY_CODE_FORMAT.en_at,
    dateFormat: de,
  },
  [LOCALES.en_cz]: {
    code: LOCALES.en_cz,
    region: REGIONS.CZ,
    language: LANGUAGES.en,
    moneyFormat: MONEY_FORMAT.en_cz,
    currencyCodeFormat: CURRENCY_CODE_FORMAT.en_cz,
    dateFormat: cs,
  },
  [LOCALES.en_pl]: {
    code: LOCALES.en_pl,
    region: REGIONS.PL,
    language: LANGUAGES.en,
    moneyFormat: MONEY_FORMAT.en_pl,
    currencyCodeFormat: CURRENCY_CODE_FORMAT.en_pl,
    dateFormat: pl,
  },
  [LOCALES.en_ro]: {
    code: LOCALES.en_ro,
    region: REGIONS.RO,
    language: LANGUAGES.en,
    moneyFormat: MONEY_FORMAT.en_ro,
    currencyCodeFormat: CURRENCY_CODE_FORMAT.en_ro,
    dateFormat: ro,
  },
  [LOCALES.es_es]: {
    code: LOCALES.es_es,
    region: REGIONS.ES,
    language: LANGUAGES.es,
    moneyFormat: MONEY_FORMAT.es_es,
    currencyCodeFormat: CURRENCY_CODE_FORMAT.es_es,
    dateFormat: es,
  },
  [LOCALES.fr_ca]: {
    code: LOCALES.fr_ca,
    region: REGIONS.CA,
    language: LANGUAGES.fr,
    moneyFormat: MONEY_FORMAT.fr_ca,
    currencyCodeFormat: CURRENCY_CODE_FORMAT.fr_ca,
    dateFormat: frCA,
  },
  [LOCALES.fr_ch]: {
    code: LOCALES.fr_ch,
    region: REGIONS.CH,
    language: LANGUAGES.fr,
    moneyFormat: MONEY_FORMAT.fr_ch,
    currencyCodeFormat: CURRENCY_CODE_FORMAT.fr_ch,
    dateFormat: frCH,
  },
  [LOCALES.fr_fr]: {
    code: LOCALES.fr_fr,
    region: REGIONS.FR,
    language: LANGUAGES.fr,
    moneyFormat: MONEY_FORMAT.fr_fr,
    currencyCodeFormat: CURRENCY_CODE_FORMAT.fr_fr,
    dateFormat: fr,
  },
  [LOCALES.it_ch]: {
    code: LOCALES.it_ch,
    region: REGIONS.CH,
    language: LANGUAGES.it,
    moneyFormat: MONEY_FORMAT.it_ch,
    currencyCodeFormat: CURRENCY_CODE_FORMAT.it_ch,
    dateFormat: frCH,
  },
  [LOCALES.ko_kr]: {
    code: LOCALES.ko_kr,
    region: REGIONS.KR,
    language: LANGUAGES.ko,
    moneyFormat: MONEY_FORMAT.ko_kr,
    currencyCodeFormat: CURRENCY_CODE_FORMAT.ko_kr,
    dateFormat: ko,
  },
  [LOCALES.nl_nl]: {
    code: LOCALES.nl_nl,
    region: REGIONS.NL,
    language: LANGUAGES.nl,
    moneyFormat: MONEY_FORMAT.nl_nl,
    currencyCodeFormat: CURRENCY_CODE_FORMAT.nl_nl,
    dateFormat: nl,
  },
  [LOCALES.pl_pl]: {
    code: LOCALES.pl_pl,
    region: REGIONS.PL,
    language: LANGUAGES.pl,
    moneyFormat: MONEY_FORMAT.pl_pl,
    currencyCodeFormat: CURRENCY_CODE_FORMAT.pl_pl,
    dateFormat: pl,
  },
  [LOCALES.pt_pt]: {
    code: LOCALES.pt_pt,
    region: REGIONS.PT,
    language: LANGUAGES.pt,
    moneyFormat: MONEY_FORMAT.pt_pt,
    currencyCodeFormat: CURRENCY_CODE_FORMAT.pt_pt,
    dateFormat: pt,
  },
  [LOCALES.en_pt]: {
    code: LOCALES.en_pt,
    region: REGIONS.PT,
    language: LANGUAGES.en,
    moneyFormat: MONEY_FORMAT.en_pt,
    currencyCodeFormat: CURRENCY_CODE_FORMAT.en_pt,
    dateFormat: pt,
  },
  [LOCALES.ro_ro]: {
    code: LOCALES.ro_ro,
    region: REGIONS.RO,
    language: LANGUAGES.ro,
    moneyFormat: MONEY_FORMAT.ro_ro,
    currencyCodeFormat: CURRENCY_CODE_FORMAT.ro_ro,
    dateFormat: ro,
  },
  [LOCALES.gl_es]: {
    code: LOCALES.gl_es,
    region: REGIONS.ES,
    language: LANGUAGES.gl,
    moneyFormat: MONEY_FORMAT.gl_es,
    currencyCodeFormat: CURRENCY_CODE_FORMAT.gl_es,
    dateFormat: es,
  },
  [LOCALES.eu_es]: {
    code: LOCALES.eu_es,
    region: REGIONS.ES,
    language: LANGUAGES.eu,
    moneyFormat: MONEY_FORMAT.eu_es,
    currencyCodeFormat: CURRENCY_CODE_FORMAT.eu_es,
    dateFormat: es,
  },
};
