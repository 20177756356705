import {
  LazyQueryHookOptions,
  QueryHookOptions,
  useLazyQuery,
  useQuery,
} from '@apollo/react-hooks';
import { DocumentNode } from 'graphql';
import { merge } from 'lodash-es';

import useEffectOnUpdates from 'hooks/use-effect-on-updates';
import { useLocale } from 'state/intl';
import { useNetworkContext } from 'state/network';

const useSanityGqlEndpoint = (isV2: boolean = false) => {
  const networkCtx = useNetworkContext();
  return isV2 ? networkCtx.sanityEndpoints.graphqlV2 : networkCtx.sanityEndpoints.graphql;
};

export const useSanityQuery = <TData = any, TVariables = Record<string, any>>(
  query: DocumentNode,
  options: QueryHookOptions<TData, TVariables> = {},
  sanityOptions: { isV2: boolean } = { isV2: false }
) => {
  const uri = useSanityGqlEndpoint(sanityOptions.isV2);
  const { language, region } = useLocale();

  const queryOptions = merge(options, { context: { uri } });

  const queryResult = useQuery<TData, TVariables>(query, queryOptions);

  const { refetch } = queryResult;

  useEffectOnUpdates(() => {
    refetch();
  }, [language, region]);

  return queryResult;
};

export const useLazySanityQuery = <TData = any, TVariables = Record<string, any>>(
  query: DocumentNode,
  options: LazyQueryHookOptions<TData, TVariables> = {},
  sanityOptions: { isV2: boolean } = { isV2: false }
) => {
  const uri = useSanityGqlEndpoint(sanityOptions.isV2);

  const queryOptions = merge(options, { context: { uri } });

  return useLazyQuery<TData, TVariables>(query, queryOptions);
};

// for apollo codegen compatibility
export * from '@apollo/react-hooks';
export { useSanityQuery as useQuery, useLazySanityQuery as useLazyQuery };
