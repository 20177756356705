import gql from 'graphql-tag';

export default gql`
  fragment MenuImageFragment on Image {
    hotspot {
      x
      y
      height
      width
    }
    crop {
      top
      bottom
      left
      right
    }
    asset {
      metadata {
        lqip
        palette {
          dominant {
            background
            foreground
          }
        }
      }
      _id
    }
  }
`;
