import styled from 'styled-components';

export const HorizontalLinksContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0.5rem 0;

  ${Styles.desktop`
    justify-content: flex-start;
  `}

  > * {
    ${Styles.mobile`
      color: ${Styles.color.grey.one};
    `}
    padding-right: 0.5rem;
  }

  > * + * {
    border-left: 1px solid;
    padding-left: 0.6rem;
  }
`;
