import { AllowedEvent } from '@rbilabs/mparticle-client';

import { IBackendCartEntries, ICartEntry, IServerOrder, ServiceMode } from '@rbi-ctg/menu';
import { IStaticPageRoute } from 'remote/queries/static-page';
import { StoreProxy } from 'state/store/hooks/types';
import { OTPAuthDeliveryMethod } from 'utils/otp';

import { CustomEventNames, EventTypes, ProductActionTypes, SignInPhases } from './constants';

export interface ILogEvent {
  (
    eventName: CustomEventNames | string,
    eventType: EventTypes,
    attributes?: object,
    customFlags?: object
  ): void;
}

export interface ILogRBIEvent {
  (event: AllowedEvent): void;
}

export interface ISignInEventOptions {
  phase: SignInPhases;
  success: boolean;
  message?: string;
  otpMethod?: OTPAuthDeliveryMethod;
}

export interface ISignUpEventOptions {
  success: boolean;
  message?: string;
  otpMethod?: OTPAuthDeliveryMethod;
}

export interface IAutoSignInEventOptions {
  phase: SignInPhases;
  success: boolean;
  message?: string;
}

type AppflowEvent = 'started' | 'downloaded' | 'extracted' | 'applied';

export interface IAppflowUpdateEventOptions {
  isBlockingUpdate?: boolean;
  phase: AppflowEvent;
  success: boolean;
  updateVersion?: string;
  totalTimeMs?: number;
}

export interface IMParticleCtx {
  init: () => void;
  login: any;
  logout: any;
  deviceId: string;
  sessionId: string;
  signInEvent: (options: ISignInEventOptions) => void;
  signOutEvent: (success: boolean, message?: string) => void;
  signUpEvent: (options: ISignUpEventOptions) => void;
  autoSignInEvent: (options: IAutoSignInEventOptions) => void;
  updateUniversalAttributes: (universalAttributes: Partial<IMParticleUniversalAttributes>) => void;
  updateStaticRoutes: (newStaticRoutes: IStaticPageRoute[]) => void;
  updateUserAttributes: any;
  updateUserLocationPermissionStatus: () => void;
  updateUserIdentities: (
    userIdentity?: {
      email?: string;
      customerid?: string;
      ccToken?: string;
    },
    additional?: {
      callback?: () => void;
      tryAgain?: boolean;
    }
  ) => void;

  //pageView data
  logPageView: any;
  logCommercePageView: (
    menuData: { id: string; name: string; menuType: string },
    attrs?: {}
  ) => void;

  //eCommerce events
  addToCart(
    cartEntry: ICartEntry,
    serviceMode: ServiceMode,
    previousCartEntries: ICartEntry[],
    selectionAttrs?: IAddToCartSelectionAttributes
  ): void;
  updateItemInCart(
    cartEntry: ICartEntry,
    originalCartEntry: ICartEntry,
    serviceMode: ServiceMode
  ): void;
  removeFromCart(cartEntry: ICartEntry): void;
  logOrderLatencyEvent(
    order: IServerOrder | undefined,
    eventName: 'commit' | 'price',
    duration: number
  ): void;
  logPurchase(
    cartEntries: ICartEntry[],
    store: StoreProxy,
    serviceMode: ServiceMode,
    serverOrder: IServerOrder,
    attrs?: {
      currencyCode: string;
      fireOrderInMinutes: number;
    }
  ): void;
  logCustomerInitiatedRefund(
    event: CustomEventNames,
    items?: IBackendCartEntries[],
    amount?: number,
    reason?: string
  ): void;

  //error tracking
  logError: (
    error: { name: string; message?: string; stack?: string },
    attrs?: { [key: string]: string | number | boolean }
  ) => void;

  //custom events
  logEvent: ILogEvent;
  logRBIEvent: ILogRBIEvent;
  logNavigationClick: (
    eventName: CustomEventNames,
    attributes?: object,
    customFlags?: object
  ) => void;
  logAddPaymentMethodClick: () => void;
  selectServiceMode: (mode: ServiceMode) => void;
  appflowUpdateEvent: (options: IAppflowUpdateEventOptions) => void;
  logOfferActivatedEvent: (sanityId: string, offerName: string, tokenId?: string | null) => void;
  logCheckoutEvent(serviceMode: ServiceMode, cartEntries: ICartEntry[]): void;
  logUpsellAddedEvent: (item: ICartEntry, itemPosition?: number) => void;
  logUpsellRemovedEvent: (item: ICartEntry) => void;
  logNavBarClickEvent: (text: string, componentKey?: string) => void;
  marketingTileClickEvent: (title: string, position: number, cardID: string) => void;
}

// booleans need to be stringified for mParticle
export interface IMParticleAttributes {
  $FirstName?: string;
  $LastName?: string;
  $Zip?: string;
  $Age?: number;
  $Gender?: string;
  $City?: string;
  $State?: string;
  $Mobile?: string;
  'Date of Birth'?: string;
  'Email Opt In'?: string;
  favoriteStores?: string;
  'Join Date'?: string;
  language?: string;
  Locale?: string;
  'Legacy User'?: string;
  'Location Services'?: string;
  marketingEmail?: string;
  marketingPush?: string;
  mediaServicesPreferences?: string;
  orderStatus?: string;
  paybackUserId?: string;
  'RBI Cognito ID'?: string;
  rewardsEmail?: string;
  rewardsPush?: string;
  Timezone?: string;
  'Type Preference'?: string;
  'Snack Preference'?: string;
  'Size Preference'?: string;
  'Time Preference'?: string;
  'UTM Source'?: string;
  'UTM Medium'?: string;
  'UTM Campaign'?: string;
  'UTM Term'?: string;
  'UTM Content'?: string;
  'IOS Location Permissions'?: string;
  'Android Location Permissions'?: string;
}

export interface IMParticleCart {
  /**
   * Adds a cart product to the user cart
   * @method add
   * @param {Object} product the product
   * @param {Boolean} [logEvent] a boolean to log adding of the cart object. If blank, no logging occurs.
   */
  add: (product: IMParticleProduct, logEvent: boolean) => void;
  /**
   * Clears all products from the user cart
   * @method clear
   */
  clear: () => void;
  /**
   * Removes a cart product from the current user cart
   * @method remove
   * @param {Object} product the product
   * @param {Boolean} [logEvent] a boolean to log adding of the cart object. If blank, no logging occurs.
   */
  remove: (product: IMParticleProduct, logEvent: boolean) => void;
  /**
   * Returns all cart products
   * @method getCartProducts
   * @return {Array} array of cart products
   */
  getCartProducts: () => IMParticleProduct[];
}

interface ILoggedOutMParticleUserIdentity {}

export interface IMParticleUserIdentity {
  email: string;
  customerid: string;
  other3?: string;
}

export interface IMParticleUser {
  /**
   * Get user identities for current user
   * @method getUserIdentities
   * @return {Object} an object with userIdentities as its key
   */
  getUserIdentities: () => {
    userIdentities: IMParticleUserIdentity;
  };
  // todo finish this interface
  getAllUserAttributes: () => IMParticleAttributes;
  setUserAttributes: (attributes: IMParticleAttributes) => void;
  isLoggedIn: () => boolean;
  getMPID?: () => string;
  /**
   * Returns the cart object for the current user
   * @method getCart
   * @return a cart object
   */
  getCart: () => IMParticleCart;
}

export enum HTTPCode {
  SUCCESS = 200,
  NO_HTTP_COVERAGE = -1,
  ACTIVE_IDENTITY_REQUEST = -2,
  ACTIVE_SESSION = -3,
  VALIDATION_ISSUE = -4,
  NATIVE_IDENTITY_REQUEST = -5,
  LOGGING_DISABLED_OR_MISSING_API_KEY = -6,
  TOO_MANY_REQUESTS = 42,
}

export interface IMParticleIdentityModifyCallback {
  (args: { httpCode: HTTPCode; body: string | null }): void;
}
export interface IMParticleCallback {
  (args: {
    httpCode: HTTPCode;
    body: string;
    getUser: () => IMParticleUser;
    getPreviousUser: () => IMParticleUser;
  }): void;
}

export interface IMparticleIdentityApiData {
  userIdentities: IMParticleUserIdentity | ILoggedOutMParticleUserIdentity;
}
interface IMParticleIdentityApiCallback {
  (identityApiData: IMparticleIdentityApiData, callback: IMParticleCallback): void;
}

interface IMParticleIdentityApiModifyCallback {
  (identityApiData: IMparticleIdentityApiData, callback: IMParticleIdentityModifyCallback): void;
}
export interface IMParticleProduct {
  Name: string;
  Sku: string;
  Price: number;
  Quantity: number;
  Brand: string;
  Variant: string;
  Category: string;
  Position: number;
  CouponCode: string;
  TotalAmount: number;
  Attributes: { [key: string]: string | number | boolean };
  SubProducts: IMParticleProduct[];
}

export interface IMParticle {
  eCommerce: {
    /**
     * Creates a product
     * @for mParticle.eCommerce
     * @method createProduct
     * @param {String} name product name
     * @param {String} sku product sku
     * @param {Number} price product price
     * @param {Number} [quantity] product quantity. If blank, defaults to 1.
     * @param {String} [variant] product variant
     * @param {String} [category] product category
     * @param {String} [brand] product brand
     * @param {Number} [position] product position
     * @param {String} [coupon] product coupon
     * @param {Object} [attributes] product attributes
     */
    createProduct: (
      name: string,
      sku: string,
      price: number,
      quantity?: number,
      variant?: string,
      category?: string,
      brand?: string,
      position?: number,
      coupon?: string,
      attributes?: { [key: string]: string | number | boolean }
    ) => null | IMParticleProduct;
    logProductAction: (
      type: number,
      product: IMParticleProduct[] | IMParticleProduct,
      attrs?: object | null,
      flags?: object | null,
      transactionalAttrs?: object
    ) => void;
    logPurchase: any;
  };
  sessionManager: {
    initialize: () => void;
    getSession: () => string;
  };
  Identity: {
    /**
     * Initiate a login request to the mParticle server
     * @method login
     * @param {Object} identityApiData The identityApiData object as indicated [here](https://github.com/mParticle/mparticle-sdk-javascript/blob/master-v2/README.md#1-customize-the-sdk)
     * @param {Function} [callback] A callback function that is called when the login request completes
     */
    login: IMParticleIdentityApiCallback;
    logout: IMParticleIdentityApiCallback;
    getCurrentUser: () => IMParticleUser;
    modify: IMParticleIdentityApiModifyCallback;
    identify: IMParticleIdentityApiCallback;
  };
  /**
   * Used to log custom errors
   *
   * @method logError
   * @param {String or Object} error The name of the error (string), or an object formed as follows {name: 'exampleName', message: 'exampleMessage', stack: 'exampleStack'}
   * @param {Object} [attrs] Custom attrs to be passed along with the error event; values must be string, number, or boolean
   */
  logError: (
    error: string | { name: string; message?: string; stack?: string },
    attrs?: { [key: string]: string | number | boolean }
  ) => void;
  // logError: (...args: any) => void;
  logPageView: (...args: any) => void;
  logCustomPageView: (path: string, attrs?: any) => void;
  logCommercePageView: (
    menuData: { id: string; name: string; menuType: string },
    attrs?: any
  ) => void;
  logEvent: (
    name: string,
    eventTypes: EventTypes,
    attributes?: object,
    customFlags?: object
  ) => void;
  EventType: {
    Navigation: EventTypes.Navigation;
    Location: EventTypes.Location;
    Search: EventTypes.Search;
    Transaction: EventTypes.Transaction;
    UserContent: EventTypes.UserContent;
    UserPreference: EventTypes.UserPreference;
    Social: EventTypes.Social;
    Other: EventTypes.Other;
  };
  getDeviceId: () => string;
  ProductActionType: {
    AddToCart: number;
    RemoveFromCart: number;
    Refund: number;
    Purchase: number;
    Checkout: number;
    ViewDetail: number;
  };
  setOptOut: (state: boolean) => void;
}

export interface IMParticlePurchaseEventAttributes {
  'Pickup Mode': string;
  'Service Mode': string;
  branch_service_mode: string;
  customer_event_alias: string;
  'CC Token': string | null;
  'Coupon ID': string;
  'Coupon Applied': string;
  Currency: string;
  'Tax Amount': number;
  'Total Amount': number;
  'Value Threshold 20 Met'?: boolean;
  'Value Threshold 15 Met'?: boolean;
  'Value Threshold 10 Met'?: boolean;
  'Value Threshold 5 Met'?: boolean;
  'Timed Fire Minutes': number;
  'Transaction Order Number ID': string;
  'Transaction POS': string;
  'Transaction RBI Cloud Order ID': string;
  'Restaurant ID': string | null;
  'Restaurant Name': string | null;
  'Restaurant Number': string | null;
  'Restaurant Address': string | null;
  'Restaurant City': string | null;
  'Restaurant State/Province Name': string | null;
  'Restaurant Postal Code': string | null;
  'Restaurant Country': string | null;
  'Restaurant Latitude': number | null;
  'Restaurant Longitude': number | null;
  'Restaurant Status': string | null;
  'Restaurant Drink Station Type': string | null;
  'Restaurant Drive Thru Lane Type': string | null;
  'Restaurant Franchise Group Id': number | null;
  'Restaurant Franchise Group Name': string | null;
  'Restaurant Front Counter Closed': boolean | null;
  'Restaurant Has Breakfast': boolean | null;
  'Restaurant Has Burgers For Breakfast': boolean | null;
  'Restaurant Has Curbside': boolean | null;
  'Restaurant Has Front Counter Closed': boolean | null;
  'Restaurant Has Catering': boolean | null;
  'Restaurant Has Dine In': boolean | null;
  'Restaurant Has Drive Thru': boolean | null;
  'Restaurant Has Table Service': boolean | null;
  'Restaurant Has Home Delivery': boolean | null;
  'Restaurant Has Mobile Ordering': boolean | null;
  'Restaurant Has Parking': boolean | null;
  'Restaurant Has Playground': boolean | null;
  'Restaurant Has Take Out': boolean | null;
  'Restaurant Has Wifi': boolean | null;
  'Restaurant Number Drive Thru Windows': number;
  'Restaurant Parking Type': string | null;
  'Restaurant Playground Type': string | null;
  'Restaurant POS': string | null;
  'Restaurant POS Version': string | null;
  'Is Kiosk': boolean;
  'Card Type': string;
  'Payment Type': string;
  'Has Upsell': boolean;
  'Upsell Total': number;
  'Recommender Provider': string;
  Chef: string | null;
  'Device Time': string;
  'Source Page': string;
  deliveryDiscountAmount?: number;
  deliveryFeeAmount?: number;
  deliveryGeographicalFeeAmount?: number;
  deliverySmallCartFeeAmount?: number;
  deliveryServiceFeeAmount?: number;
  deliverySurchargeFeeAmount?: number;
  totalDeliveryOrderFeeAmount?: number;
  baseDeliveryFeeAmount?: number;
  quotedFeeAmount?: number;
  'Cart Data': string;
  Rewards: string | null;
  'Is Loyalty': boolean;
  roundUpAmount: number;
  'Currency Code': string;
}

export interface IMParticleUniversalAttributes {
  'Service Mode': string;
  'Pickup Mode': string;
  'Source Page': string;
  browserType: string | null;
  browserVersion: string | null;
  isMobileWeb: string | null;
  isSmallScreen: boolean;
  isLoyaltyUser: boolean;
  currentBuild: string;
  chefUpsellItemCount: number;
  enableCheckoutUpsellItems2: boolean;
  chefRecommendationEngine2: boolean;
  enableStoreConfirmationModal: boolean;
  enableServiceModeCartSelection: boolean;
  enableOffersRefresh: boolean;
  enableFeatureHomePage: boolean;
  enableHomePageRecentItems: boolean;
  enableRecentItemsAddToCart: boolean;
  enableRecentlyOrderedItems: boolean;
  enableUserSavedDeliveryAddressPhone: boolean;
  enableCognitoSignupInBE: boolean;
  enableRecentItemsWithModifiers: boolean;
}

export interface IMParticleSublevelItem {
  id: string;
  quantity: number;
}

export abstract class IMParticleIdentityAdapter {
  static modify: IMParticleIdentityApiModifyCallback;
}

export interface IMParticleAdapter {
  createProduct: (
    name: string,
    sku: string,
    price: number,
    quantity: number
  ) => IMParticleProduct | null;
  logEvent: (
    name: string,
    eventTypes: EventTypes,
    attributes?: object,
    customFlags?: object
  ) => void;
  logProductAction: (
    type: ProductActionTypes,
    products: IMParticleProduct[],
    attrs?: object | null,
    flags?: object | null,
    transactionalAttrs?: object
  ) => void;

  getDeviceId: () => string | void;

  getSession: () => Promise<{ sessionId: string }>;
  setOptOut: (optOut: boolean) => void;

  Identity: IMParticleIdentityAdapter;
}

// Track the modification of item, combo slot and picker aspect.
// These values are initially false and is set to true if the user
// changes these values away from the initial state.
export interface IAddToCartSelectionAttributes {
  pickerAspectSelection: boolean;
  comboSlotSelection: boolean;
  itemModified: boolean;
}
