import { useCallback, useEffect, useState } from 'react';

import { useWindowDimensions } from 'react-native';

import mediaQueries from 'styles/constants/media-queries';

export enum MediaQuery {
  Mobile = 'mobile',
  MobileTiny = 'mobileTiny',
  MobileSmall = 'mobileSmall',
  MobileLandscape = 'mobileLandscape',
  Desktop = 'desktop',
  DesktopLarge = 'desktopLarge',
  MobileFullscreen = 'mobileFullscreen',
}

const useMediaQuery = (mq: string): boolean => {
  const { height, width } = useWindowDimensions();
  const matchMedia = useCallback(() => {
    if (typeof mediaQueries[mq] === 'function') {
      const query = mediaQueries[mq]``.join('').replace(/(@media screen and |\{\})/g, '');
      return window.matchMedia(query)?.matches;
    }
    return window.matchMedia(mq)?.matches;
  }, [mq]);
  const [matches, setMatches] = useState(matchMedia());
  useEffect(() => {
    setMatches(matchMedia());
  }, [matchMedia, height, width]);
  return matches;
};

export default useMediaQuery;
