import * as React from 'react';

import { Icon } from '@rbilabs/components-library';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { useMParticleContext } from 'state/mParticle';
import { isExternalLink } from 'utils/is-external-link';

import theme from './theme';

const LinkStyles = css`
  border-bottom: 1px solid ${Styles.color.grey.four};
  width: 100%;
  font-family: ${Styles.fontFamily.body};
  text-decoration: none;
  color: ${theme.arrowLinkLabelColor};
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledLink = styled(Link)`
  ${LinkStyles}
`;
export const StyledAnchor = styled.a`
  ${LinkStyles}
`;

export const ArrowLink: React.FC<React.ComponentProps<typeof StyledLink | typeof StyledAnchor>> = ({
  children,
  eventName,
  onClick,
  ...props
}) => {
  const mParticle = useMParticleContext();

  const handleClick = (e: React.MouseEvent) => {
    if (eventName) {
      mParticle.logNavigationClick(eventName);
    }

    if (onClick) {
      onClick(e);
    }

    return true;
  };

  return isExternalLink(props.to) ? (
    <StyledAnchor href={props.to} target="_blank" onClick={handleClick}>
      {children}
      <Icon icon="next" color="icon-default" width="16px" aria-hidden />
    </StyledAnchor>
  ) : (
    <StyledLink {...props} onClick={handleClick}>
      {children}
      <Icon icon="next" color="icon-default" width="16px" aria-hidden />
    </StyledLink>
  );
};
