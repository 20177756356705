import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';

import delve from 'dlv';
import Measure, { MeasuredComponentProps } from 'react-measure';
import styled from 'styled-components';

import { ISanityImage } from '@rbi-ctg/menu';
import { IImageFragment } from 'generated/sanity-graphql';
import useVisibilitySensor from 'hooks/use-visibility-sensor';
import { parseAssetID } from 'remote/build-image-url';
import { useUIContext } from 'state/ui';
import { generateSrc, generateSrcSet, needsLegacyImgSupport } from 'utils/image';

interface IUpdatesWillCauseFetch {
  sizes: string;
  src: string;
  srcSet: string;
}

// For Applitools automated test screenshots to be stable, we need to ensure
// that images are always loaded for our tests. This lets automation skip
// the lazy loading for out of screen images.
const skipLazyLoadingForTests = window.location.hash === '#skip-image-lazy-loading';

const Boundary = styled.div`
  border-radius: inherit;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
`;

const Image = styled.img<{ isLoaded: boolean; objectFitContain?: boolean }>`
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: ${p => (p.objectFitContain ? 'contain' : 'cover')};
  object-position: center;
  opacity: ${p => (p.isLoaded ? 1 : 0)};
  transition: opacity 200ms ease-out;
`;

const LQImage = styled(Image)`
  opacity: 0.2;
`;

const AspectRatioBox = styled.div<{ aspectRatio: number }>`
  padding-bottom: ${p => `${p.aspectRatio * 100}%`};
`;

const AspectRatioBoxInside = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const LowQualityImage = ({ image, alt }: { alt: string; image: ISanityImage | IImageFragment }) => {
  const src = delve(image, 'asset.metadata.lqip');
  if (!src) {
    return null;
  }
  return <LQImage alt={alt} src={src} isLoaded data-testid="lq-image" />;
};

export interface IPictureProps {
  alt: string;
  children?: ReactNode;
  className?: string;
  image?: ISanityImage | IImageFragment | null;
  lazy?: boolean;
  lockedAspectRatio?: boolean;
  lqOff?: boolean;
  objectFitContain?: boolean;
  placeholderAspectRatio?: number;
  quality?: number;
  draggable?: boolean;

  // A number multiplier to be used when requesting the image.
  // Useful when you will need to zoom in on the image or the image will be artificial cropped.
  //
  // A value of 1 will keep the resolution the same.
  // Values less then 1 will decrease the resolution.
  // And values greater then one will increase resolution
  resolutionMultiplier?: number;

  onImageLoaded?: () => void;
}

const isSVG = (format: string) => format === 'svg';

const Picture = ({
  alt,
  children,
  className,
  image,
  lazy = true,
  lockedAspectRatio = false,
  lqOff,
  objectFitContain = false,
  placeholderAspectRatio,
  quality = 40,
  draggable = true,
  resolutionMultiplier,
  onImageLoaded,
}: IPictureProps) => {
  const prevImage = useRef(image);
  const [isLoaded, setIsLoaded] = useState(false);
  const [size, setSize] = useState({ width: 0, height: 0 });
  const [imageSrc, setImageSrc] = useState<string>('');
  const [imgFetchingAttributes, setImgFetchingAttributes] = useState<IUpdatesWillCauseFetch | null>(
    null
  );
  const { isVisible, visibilityRef } = useVisibilitySensor({ triggerOnce: true });
  const { buildImageUrl } = useUIContext();

  useEffect(() => {
    if (!image || (imgFetchingAttributes && image === prevImage.current)) {
      return;
    }
    prevImage.current = image;

    if (size.width) {
      const { format } = parseAssetID(image?.asset?._id);
      if (isSVG(format)) {
        const src = buildImageUrl(image);
        setImageSrc(src);
      }
      setImgFetchingAttributes({
        sizes: `${(size.width / window.innerWidth) * 100}vw`,
        src: needsLegacyImgSupport()
          ? generateSrc({
              buildImageUrl,
              image,
              format: 'png',
              device: { width: 1280, dpr: 1 },
            })
          : '',
        srcSet: generateSrcSet({
          buildImageUrl,
          image,
          quality,
          resolutionMultiplier,
          auto: 'format',
        }),
      });
    }
  }, [
    image,
    size.width,
    imgFetchingAttributes,
    quality,
    resolutionMultiplier,
    buildImageUrl,
    size,
  ]);

  const renderContent = useCallback(
    ({ measureRef }: MeasuredComponentProps) => {
      if (!image || !image.asset || !image.asset._id) {
        return null;
      }
      const { dimensions, format } = parseAssetID(image.asset._id);

      const placeholderHeightWidthRatio = placeholderAspectRatio
        ? 1 / placeholderAspectRatio // aspectRatio is width/height, must convert to height/width
        : size.height && size.width
        ? size.height / size.width // size and height are non-zero
        : dimensions.height / dimensions.width; // default to image's natural aspect ratio

      const isShowingImage = isVisible || !lazy || skipLazyLoadingForTests;
      let content;
      if (isShowingImage && (imgFetchingAttributes || imageSrc)) {
        if (isSVG(format) && imageSrc) {
          content = (
            <Image
              data-testid="picture-img-svg"
              alt={alt}
              src={imageSrc}
              onLoad={() => {
                setIsLoaded(true);
                onImageLoaded?.();
              }}
              isLoaded={isLoaded}
              objectFitContain={objectFitContain}
              draggable={draggable}
            />
          );
        } else if (imgFetchingAttributes) {
          content = (
            <picture>
              <source
                data-testid="picture-source"
                sizes={imgFetchingAttributes.sizes}
                srcSet={imgFetchingAttributes.srcSet}
              />
              <Image
                data-testid="picture-img"
                alt={alt}
                src={imgFetchingAttributes.src}
                onLoad={() => {
                  setIsLoaded(true);
                  onImageLoaded?.();
                }}
                isLoaded={isLoaded}
                objectFitContain={objectFitContain}
                draggable={draggable}
              />
            </picture>
          );
        }
      }
      let childContent = <AspectRatioBox aspectRatio={placeholderHeightWidthRatio} />;
      if (children) {
        // Default behavior is to let the child content dictate the size of the component
        childContent = <Boundary>{children}</Boundary>;
        // if lockedAspectRatio is passed, then aspect ratio to be set to the
        // `placeholderHeightWidthRatio` or the aspect ratio of the image
        if (lockedAspectRatio) {
          childContent = (
            <AspectRatioBox aspectRatio={placeholderHeightWidthRatio}>
              <AspectRatioBoxInside>{children}</AspectRatioBoxInside>
            </AspectRatioBox>
          );
        }
      }
      return (
        <Boundary className={className} ref={measureRef} data-testid="picture-boundary">
          {!isLoaded && !lqOff && <LowQualityImage image={image} alt="" />}
          {content}
          {childContent}
        </Boundary>
      );
    },
    [
      image,
      placeholderAspectRatio,
      size.height,
      size.width,
      isVisible,
      lazy,
      imgFetchingAttributes,
      imageSrc,
      children,
      className,
      isLoaded,
      lqOff,
      alt,
      objectFitContain,
      draggable,
      onImageLoaded,
      lockedAspectRatio,
    ]
  );

  if (!image?.asset) {
    return <>{children}</>;
  }

  return (
    <Measure
      innerRef={visibilityRef as any}
      onResize={contentRect => {
        if (isLoaded) {
          return;
        }

        const newSize = {
          width: Math.ceil(delve(contentRect, 'entry.width', 0)),
          height: Math.ceil(delve(contentRect, 'entry.height', 0)),
        };

        if (newSize.width !== size.width || newSize.height !== size.height) {
          setSize(newSize);
        }
      }}
    >
      {renderContent}
    </Measure>
  );
};

export default Picture;
