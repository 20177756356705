import { useCallback, useEffect } from 'react';

import { useServiceModeStatus } from 'hooks/use-service-mode-status';
import { getRestaurantsByStoreNumber } from 'remote/api/restaurants';
import { useLocationContext } from 'state/location';
import { useLoggerContext } from 'state/logger/context';
import { useNetworkContext } from 'state/network';
import LocalStorage, { StorageKeys } from 'utils/local-storage';
import { mergeRestaurantData, useGetRestaurantFn } from 'utils/restaurant';
import { routes } from 'utils/routing';

import { IStoreContext, StoreProxy } from './types';
import { usePosDataQuery } from './use-pos-data-query';
import useRefreshStore from './use-refresh-store';

export const resetLastTimeStoreUpdated: IStoreContext['resetLastTimeStoreUpdated'] = currentTime =>
  LocalStorage.setItem(StorageKeys.LAST_TIME_STORE_UPDATED, currentTime || Date.now());

export const useStoreUtils = ({
  resetStore,
  store,
}: {
  resetStore: IStoreContext['resetStore'];
  store: StoreProxy;
}) => {
  const { location, navigate } = useLocationContext();
  const { connection, sanityEndpoints } = useNetworkContext();
  const getRestaurant = useGetRestaurantFn();
  const { decorateLogger, logger } = useLoggerContext();

  const { data, refetch: refetchPosData } = usePosDataQuery({
    storeNumber: store.number,
    restaurantPosDataId: store.restaurantPosData?._id ?? '',
  });

  const prices = data?.posData ?? null;

  const storeEmail = store.email?.[0] ?? '';

  const { serviceModeStatus, isRestaurantAvailable } = useServiceModeStatus(store);

  const onConfirmLocateRestaurants = useCallback(() => {
    resetStore();

    if (location.pathname !== routes.storeLocator) {
      navigate(routes.storeLocator);
    }
  }, [location.pathname, navigate, resetStore]);

  const fetchStore = useCallback(
    async (storeId: string) => {
      const rbiRestaurant = await getRestaurant(storeId);

      if (!rbiRestaurant) {
        return;
      }

      const isRestaurantPosAvailable = rbiRestaurant?.available;

      if (!isRestaurantPosAvailable) {
        onConfirmLocateRestaurants();
        return;
      }

      const [sanityStore] = await getRestaurantsByStoreNumber(sanityEndpoints.groq, {
        connection,
        storeNumber: storeId,
      });

      return mergeRestaurantData({ rbiRestaurant, sanityStore });
    },
    [connection, onConfirmLocateRestaurants, sanityEndpoints.groq] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useRefreshStore({
    getRestaurantPosData: () => {
      refetchPosData();
    },
    store,
  });

  useEffect(() => {
    const storeId = store.number ?? 'No store selected';

    decorateLogger({
      storeId,
    });

    if (!store._id) {
      return;
    }

    if (!store.restaurantPosData) {
      return logger.error({ message: 'No POS data for selected store', storeId: store._id });
    }
  }, [store]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    email: storeEmail,
    fetchStore,
    isRestaurantAvailable,
    prices,
    resetLastTimeStoreUpdated,
    serviceModeStatus,
  };
};
