import { useCallback, useEffect, useState } from 'react';

import { isEmpty } from 'lodash-es';

import { ICartEntry } from '@rbi-ctg/menu';
import { usePrevious } from 'hooks/use-previous';
import { actions, selectors, useAppDispatch, useAppSelector } from 'state/global-state';
import { updateAppliedRewardsInStorage } from 'state/global-state/models/loyalty/rewards/rewards.utils';
import { IUseLoyaltyRewards, UseLoyaltyRewards } from 'state/loyalty/hooks/types';
import { getParentIdFromUrl } from 'utils/cart';

export const useLoyaltyRewards: UseLoyaltyRewards = (loyaltyUser): IUseLoyaltyRewards => {
  const [shouldApplyStorageRewards, setShouldApplyStorageRewards] = useState(false);
  const dispatch = useAppDispatch();
  const availableLoyaltyRewardsMap = useAppSelector(
    selectors.loyalty.selectAvailableLoyaltyRewardsMap
  );

  const loyaltyUserId = loyaltyUser?.id;
  const previousLoyaltyUserId = usePrevious(loyaltyUserId);

  useEffect(() => {
    // loyalty user id will populate upon logging in / initial page load
    if (!previousLoyaltyUserId && loyaltyUserId) {
      setShouldApplyStorageRewards(true);
    }
    // occurs when a user logs out
    else if (previousLoyaltyUserId && !loyaltyUserId) {
      updateAppliedRewardsInStorage({});
    }
  }, [previousLoyaltyUserId, loyaltyUserId]);

  useEffect(() => {
    if (!isEmpty(availableLoyaltyRewardsMap) && shouldApplyStorageRewards) {
      dispatch(actions.loyalty.rehydrateAppliedReward());
      setShouldApplyStorageRewards(false);
    }
  }, [availableLoyaltyRewardsMap, dispatch, shouldApplyStorageRewards]);

  const getAvailableRewardFromCartEntry = useCallback(
    (cartEntry: ICartEntry) => {
      if (!cartEntry) {
        return;
      }

      const availableReward = availableLoyaltyRewardsMap[cartEntry._id];

      if (availableReward) {
        return availableReward;
      }

      // there may not always be a 1-1 correlation with the index of `availableLoyaltyRewardsMap` and `cartEntry._id`
      // this is the case with pickers because the cartEntry._id is the item that the picker resolved to
      // since the reward map holds the parent picker, we have to parse the parent picker id from `cartEntry.url`
      // example url - /menu/picker-$pickerId?cartId=$cartId&_id=$itemId
      const parentId = getParentIdFromUrl(cartEntry.url);
      return availableLoyaltyRewardsMap[parentId];
    },
    [availableLoyaltyRewardsMap]
  );

  return {
    getAvailableRewardFromCartEntry,
  };
};
