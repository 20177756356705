import React, { useEffect, useState } from 'react';

import { useIntl } from 'react-intl';

import { ActionButtonVariants } from 'components/action-button';
import { ModalSize } from 'components/modal';
import useMediaQuery from 'hooks/use-media-query';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { CustomEventNames, EventTypes, useMParticleContext } from 'state/mParticle';
import LocalStorage, { StorageKeys } from 'utils/local-storage';
import { promptReview } from 'utils/review/prompt-review';

import RatingStars from './rating-stars';
import {
  ButtonContainer,
  Description,
  DismissButton,
  ModalContent,
  ModalHead,
  ModalInner,
  StyledModal,
  StyledStars,
} from './styled';
import { RatingType } from './types';

const ModalRating: React.FC = () => {
  const { formatMessage } = useIntl();
  const isDesktop = useMediaQuery('desktop');
  const [showModal, setShowModal] = useState(true);
  const AFTER_RATE_DELAY = 3000;
  const AMOUNT_STARS = 5;

  const { logEvent } = useMParticleContext();

  const onCloseModal = () => {
    setShowModal(false);
    sendLogEvent(RatingType.Closed);
  };

  const onNotNowModal = () => {
    setShowModal(false);
    sendLogEvent(RatingType.NotRated);
  };

  const onRate = (stars: number) => {
    if (stars === AMOUNT_STARS) {
      if (!isDesktop) {
        promptReview();
      }
      closeModalOnRate();
    } else {
      setTimeout(() => {
        closeModalOnRate();
      }, AFTER_RATE_DELAY);
    }
    sendLogEvent(RatingType.Rated, stars);
  };

  const closeModalOnRate = () => {
    setShowModal(false);
    LocalStorage.setItem(StorageKeys.APP_RATING_SHOWN, true);
  };

  const sendLogEvent = (rating: RatingType, stars = 0) => {
    logEvent(CustomEventNames.APP_RATING, EventTypes.Other, {
      Action: rating,
      Stars: stars,
    });
  };

  return (
    (showModal && (
      <StyledModal
        size={ModalSize.SMALL}
        data-testid="modal-app-rating"
        onDismiss={onCloseModal}
        mParticleEventData={{
          modalAppearanceEventMessage: 'Modal App Rating',
        }}
      >
        <ModalInner data-testid="modal-rating">
          <ModalHead>
            <h3>{formatMessage({ id: 'enjoyUsingOurApp' })}</h3>
          </ModalHead>
          <ModalContent>
            <Description>
              <StyledStars data-testid="rating-stars">
                <RatingStars onRate={onRate} />
              </StyledStars>
            </Description>
            <ButtonContainer>
              <DismissButton
                data-testid="notnow-button"
                variant={ActionButtonVariants.TEXT_ONLY}
                onClick={onNotNowModal}
              >
                {formatMessage({ id: 'notNow' })}
              </DismissButton>
            </ButtonContainer>
          </ModalContent>
        </ModalInner>
      </StyledModal>
    )) || <></>
  );
};

const ModalRatingWrapper: React.FC = () => {
  const [showModalDelayed, setShowModalDelayed] = useState(false);

  const enableModalRating = useFlag(LaunchDarklyFlag.ENABLE_APP_RATING_ON_ORDER_COMPLETED);
  const appRatingShown = LocalStorage.getItem(StorageKeys.APP_RATING_SHOWN);
  const SHOW_UP_DELAY = 3000;

  useEffect(() => {
    setTimeout(() => {
      setShowModalDelayed(true);
    }, SHOW_UP_DELAY);
  }, []);

  return showModalDelayed && enableModalRating && !appRatingShown ? <ModalRating /> : null;
};

export default ModalRatingWrapper;
