import { useCallback, useMemo } from 'react';

import { Card, IAppboy } from 'appboy-web-sdk';

import useEffectOnce from 'hooks/use-effect-once';
import useReadyQueue from 'hooks/use-ready-queue';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { isRecentCookieTimestamp } from 'utils/cookies';
import logger from 'utils/logger';

type ToggleContentCards = <E extends Element>(p: E) => void;

export interface IBrazeContext {
  initBraze: () => void;
  getCachedContentCards(): Promise<Card[]>;
  setUserId(userId: string | null): void;
  toggleContentCards: ToggleContentCards;
}

const useBraze = () => {
  const { enqueueIfNotDrained, drainQueue } = useReadyQueue();
  const enableCookieBanner = useFlag(LaunchDarklyFlag.ENABLE_COOKIE_BANNER);
  const enableHtmlInAppMessagesInBrazeSdkConfig = useFlag(
    LaunchDarklyFlag.ENABLE_HTML_IN_APP_MESSAGES_IN_BRAZE_SDK
  );
  const hasAcceptedCookies = isRecentCookieTimestamp();

  const toggleContentCards = useCallback<ToggleContentCards>(
    enqueueIfNotDrained(parentNode => {
      window.rbiAppboy.display.toggleContentCards(parentNode);
    }),
    [enqueueIfNotDrained]
  );

  const setUserId = useCallback(
    enqueueIfNotDrained((userId: string | null) => {
      window.rbiAppboy.changeUser(userId);
      return window.rbiAppboy.requestContentCardsRefresh();
    }),
    [enqueueIfNotDrained]
  );

  // Making this async to be compatible with the react native module
  const getCachedContentCards = useCallback(async () => {
    if (!window.rbiAppboy) {
      return [];
    }
    return window.rbiAppboy.getCachedContentCards()?.cards ?? [];
  }, []);

  const initBraze = useCallback(() => {
    import('../init')
      .then(({ default: init }) => {
        init(drainQueue, { enableHtmlInAppMessagesInBrazeSdkConfig });
      })
      .catch(error => {
        logger.error({ error, message: 'Braze: import error' });
      });
  }, [drainQueue, enableHtmlInAppMessagesInBrazeSdkConfig]);

  useEffectOnce(() => {
    if (!enableCookieBanner || hasAcceptedCookies) {
      initBraze();
    }

    return () => {
      // appboy requests are batched and flushed.
      // if the app is unmounting we should immediately
      // send anything in the queue
      if (window.rbiAppboy) {
        window.rbiAppboy.requestImmediateDataFlush();
      }
    };
  });

  const ctxValue: IBrazeContext = useMemo(
    () => ({
      initBraze,
      getCachedContentCards,
      setUserId,
      toggleContentCards,
    }),
    [initBraze, getCachedContentCards, setUserId, toggleContentCards]
  );

  return ctxValue;
};

export default useBraze;

declare global {
  interface Window {
    appboy: IAppboy;
    rbiAppboy: IAppboy;
  }
}
