/**
 * AUTOGENERATED - DO NOT EDIT DIRECTLY
 *
 * Please see "scripts/regionalization/README.md" for how to modify
 */

import { StorageKeys } from 'utils/local-storage';

import { DOMAINS, LANGUAGES, REGIONS } from './types';

export const LOCALE_SELECTED_QUERY_PARAM = 'locale-selected';

export const regionSpecificLocalStorageKeys = [
  StorageKeys.STORE,
  StorageKeys.AUTH_REDIRECT,
  StorageKeys.ORDER,
  StorageKeys.SERVICE_MODE,
];

export const READABLE_LANGUAGES = {
  [LANGUAGES.ca]: {
    [LANGUAGES.ca]: 'Català',
    [LANGUAGES.cs]: 'Čeština, Český Jazyk',
    [LANGUAGES.de]: 'Deutsch',
    [LANGUAGES.en]: 'English',
    [LANGUAGES.es]: 'Español',
    [LANGUAGES.eu]: 'Euskara',
    [LANGUAGES.gl]: 'Galego',
    [LANGUAGES.fr]: 'Français',
    [LANGUAGES.it]: 'Italiano',
    [LANGUAGES.ko]: '한국어 (韓國語), 조선어 (朝鮮語)',
    [LANGUAGES.nl]: 'Nederlands, Vlaams',
    [LANGUAGES.pl]: 'Język Polski, Polszczyzna',
    [LANGUAGES.pt]: 'Portuguès',
    [LANGUAGES.ro]: 'Limba Română',
  },
  [LANGUAGES.cs]: {
    [LANGUAGES.ca]: 'Català',
    [LANGUAGES.cs]: 'Čeština, Český Jazyk',
    [LANGUAGES.de]: 'Deutsch',
    [LANGUAGES.en]: 'English',
    [LANGUAGES.es]: 'Español',
    [LANGUAGES.eu]: 'Euskara',
    [LANGUAGES.gl]: 'Galego',
    [LANGUAGES.fr]: 'Français',
    [LANGUAGES.it]: 'Italiano',
    [LANGUAGES.ko]: '한국어 (韓國語), 조선어 (朝鮮語)',
    [LANGUAGES.nl]: 'Nederlands, Vlaams',
    [LANGUAGES.pl]: 'Język Polski, Polszczyzna',
    [LANGUAGES.pt]: 'Portugues',
    [LANGUAGES.ro]: 'Limba Română',
  },
  [LANGUAGES.de]: {
    [LANGUAGES.ca]: 'Català',
    [LANGUAGES.cs]: 'Čeština, Český Jazyk',
    [LANGUAGES.de]: 'Deutsch',
    [LANGUAGES.en]: 'English',
    [LANGUAGES.es]: 'Español',
    [LANGUAGES.eu]: 'Euskara',
    [LANGUAGES.gl]: 'Galego',
    [LANGUAGES.fr]: 'Français',
    [LANGUAGES.it]: 'Italiano',
    [LANGUAGES.ko]: '한국어 (韓國語), 조선어 (朝鮮語)',
    [LANGUAGES.nl]: 'Nederlands, Vlaams',
    [LANGUAGES.pl]: 'Język Polski, Polszczyzna',
    [LANGUAGES.pt]: 'Portugués',
    [LANGUAGES.ro]: 'Limba Română',
  },
  [LANGUAGES.en]: {
    [LANGUAGES.ca]: 'Català',
    [LANGUAGES.cs]: 'Čeština, Český Jazyk',
    [LANGUAGES.de]: 'Deutsch',
    [LANGUAGES.en]: 'English',
    [LANGUAGES.es]: 'Español',
    [LANGUAGES.eu]: 'Euskara',
    [LANGUAGES.gl]: 'Galego',
    [LANGUAGES.fr]: 'Français',
    [LANGUAGES.it]: 'Italiano',
    [LANGUAGES.ko]: '한국어 (韓國語), 조선어 (朝鮮語)',
    [LANGUAGES.nl]: 'Nederlands, Vlaams',
    [LANGUAGES.pl]: 'Język Polski, Polszczyzna',
    [LANGUAGES.pt]: 'Português',
    [LANGUAGES.ro]: 'Limba Română',
  },
  [LANGUAGES.es]: {
    [LANGUAGES.ca]: 'Català',
    [LANGUAGES.cs]: 'Čeština, Český Jazyk',
    [LANGUAGES.de]: 'Deutsch',
    [LANGUAGES.en]: 'English',
    [LANGUAGES.es]: 'Español',
    [LANGUAGES.eu]: 'Euskara',
    [LANGUAGES.gl]: 'Galego',
    [LANGUAGES.fr]: 'Français',
    [LANGUAGES.it]: 'Italiano',
    [LANGUAGES.ko]: '한국어 (韓國語), 조선어 (朝鮮語)',
    [LANGUAGES.nl]: 'Nederlands, Vlaams',
    [LANGUAGES.pl]: 'Język Polski, Polszczyzna',
    [LANGUAGES.pt]: 'Portugues',
    [LANGUAGES.ro]: 'Limba Română',
  },
  [LANGUAGES.eu]: {
    [LANGUAGES.ca]: 'Català',
    [LANGUAGES.cs]: 'Čeština, Český Jazyk',
    [LANGUAGES.de]: 'Deutsch',
    [LANGUAGES.en]: 'English',
    [LANGUAGES.es]: 'Español',
    [LANGUAGES.eu]: 'Euskara',
    [LANGUAGES.gl]: 'Galego',
    [LANGUAGES.fr]: 'Français',
    [LANGUAGES.it]: 'Italiano',
    [LANGUAGES.ko]: '한국어 (韓國語), 조선어 (朝鮮語)',
    [LANGUAGES.nl]: 'Nederlands, Vlaams',
    [LANGUAGES.pl]: 'Język Polski, Polszczyzna',
    [LANGUAGES.pt]: 'Portugues',
    [LANGUAGES.ro]: 'Limba Română',
  },
  [LANGUAGES.fr]: {
    [LANGUAGES.ca]: 'Català',
    [LANGUAGES.cs]: 'Čeština, Český Jazyk',
    [LANGUAGES.de]: 'Deutsch',
    [LANGUAGES.en]: 'English',
    [LANGUAGES.es]: 'Español',
    [LANGUAGES.eu]: 'Euskara',
    [LANGUAGES.gl]: 'Galego',
    [LANGUAGES.fr]: 'Français',
    [LANGUAGES.it]: 'Italiano',
    [LANGUAGES.ko]: '한국어 (韓國語), 조선어 (朝鮮語)',
    [LANGUAGES.nl]: 'Nederlands, Vlaams',
    [LANGUAGES.pl]: 'Język Polski, Polszczyzna',
    [LANGUAGES.pt]: 'Portugais',
    [LANGUAGES.ro]: 'Limba Română',
  },
  [LANGUAGES.gl]: {
    [LANGUAGES.ca]: 'Català',
    [LANGUAGES.cs]: 'Čeština, Český Jazyk',
    [LANGUAGES.de]: 'Deutsch',
    [LANGUAGES.en]: 'English',
    [LANGUAGES.es]: 'Español',
    [LANGUAGES.eu]: 'Euskara',
    [LANGUAGES.gl]: 'Galego',
    [LANGUAGES.fr]: 'Français',
    [LANGUAGES.it]: 'Italiano',
    [LANGUAGES.ko]: '한국어 (韓國語), 조선어 (朝鮮語)',
    [LANGUAGES.nl]: 'Nederlands, Vlaams',
    [LANGUAGES.pl]: 'Język Polski, Polszczyzna',
    [LANGUAGES.pt]: 'Portugues',
    [LANGUAGES.ro]: 'Limba Română',
  },
  [LANGUAGES.it]: {
    [LANGUAGES.ca]: 'Català',
    [LANGUAGES.cs]: 'Čeština, Český Jazyk',
    [LANGUAGES.de]: 'Deutsch',
    [LANGUAGES.en]: 'English',
    [LANGUAGES.es]: 'Español',
    [LANGUAGES.eu]: 'Euskara',
    [LANGUAGES.gl]: 'Galego',
    [LANGUAGES.fr]: 'Français',
    [LANGUAGES.it]: 'Italiano',
    [LANGUAGES.ko]: '한국어 (韓國語), 조선어 (朝鮮語)',
    [LANGUAGES.nl]: 'Nederlands, Vlaams',
    [LANGUAGES.pl]: 'Język Polski, Polszczyzna',
    [LANGUAGES.pt]: 'Portugues',
    [LANGUAGES.ro]: 'Limba Română',
  },
  [LANGUAGES.ko]: {
    [LANGUAGES.ca]: 'Català',
    [LANGUAGES.cs]: 'Čeština, Český Jazyk',
    [LANGUAGES.de]: 'Deutsch',
    [LANGUAGES.en]: 'English',
    [LANGUAGES.es]: 'Español',
    [LANGUAGES.eu]: 'Euskara',
    [LANGUAGES.gl]: 'Galego',
    [LANGUAGES.fr]: 'Français',
    [LANGUAGES.it]: 'Italiano',
    [LANGUAGES.ko]: '한국어 (韓國語), 조선어 (朝鮮語)',
    [LANGUAGES.nl]: 'Nederlands, Vlaams',
    [LANGUAGES.pl]: 'Język Polski, Polszczyzna',
    [LANGUAGES.pt]: 'Portoghese',
    [LANGUAGES.ro]: 'Limba Română',
  },
  [LANGUAGES.nl]: {
    [LANGUAGES.ca]: 'Català',
    [LANGUAGES.cs]: 'Čeština, Český Jazyk',
    [LANGUAGES.de]: 'Deutsch',
    [LANGUAGES.en]: 'English',
    [LANGUAGES.es]: 'Español',
    [LANGUAGES.eu]: 'Euskara',
    [LANGUAGES.gl]: 'Galego',
    [LANGUAGES.fr]: 'Français',
    [LANGUAGES.it]: 'Italiano',
    [LANGUAGES.ko]: '한국어 (韓國語), 조선어 (朝鮮語)',
    [LANGUAGES.nl]: 'Nederlands, Vlaams',
    [LANGUAGES.pl]: 'Język Polski, Polszczyzna',
    [LANGUAGES.pt]: 'Portugees',
    [LANGUAGES.ro]: 'Limba Română',
  },
  [LANGUAGES.pl]: {
    [LANGUAGES.ca]: 'Català',
    [LANGUAGES.cs]: 'Čeština, Český Jazyk',
    [LANGUAGES.de]: 'Deutsch',
    [LANGUAGES.en]: 'English',
    [LANGUAGES.es]: 'Español',
    [LANGUAGES.eu]: 'Euskara',
    [LANGUAGES.gl]: 'Galego',
    [LANGUAGES.fr]: 'Français',
    [LANGUAGES.it]: 'Italiano',
    [LANGUAGES.ko]: '한국어 (韓國語), 조선어 (朝鮮語)',
    [LANGUAGES.nl]: 'Nederlands, Vlaams',
    [LANGUAGES.pl]: 'Język Polski, Polszczyzna',
    [LANGUAGES.pt]: '포르투갈 인',
    [LANGUAGES.ro]: 'Limba Română',
  },
  [LANGUAGES.pt]: {
    [LANGUAGES.ca]: 'Català',
    [LANGUAGES.cs]: 'Čeština, Český Jazyk',
    [LANGUAGES.de]: 'Deutsch',
    [LANGUAGES.en]: 'English',
    [LANGUAGES.es]: 'Español',
    [LANGUAGES.eu]: 'Euskara',
    [LANGUAGES.gl]: 'Galego',
    [LANGUAGES.fr]: 'Français',
    [LANGUAGES.it]: 'Italiano',
    [LANGUAGES.ko]: '한국어 (韓國語), 조선어 (朝鮮語)',
    [LANGUAGES.nl]: 'Nederlands, Vlaams',
    [LANGUAGES.pl]: 'Język Polski, Polszczyzna',
    [LANGUAGES.pt]: 'Português',
    [LANGUAGES.ro]: 'Limba Română',
  },
  [LANGUAGES.ro]: {
    [LANGUAGES.ca]: 'Català',
    [LANGUAGES.cs]: 'Čeština, Český Jazyk',
    [LANGUAGES.de]: 'Deutsch',
    [LANGUAGES.en]: 'English',
    [LANGUAGES.es]: 'Español',
    [LANGUAGES.eu]: 'Euskara',
    [LANGUAGES.gl]: 'Galego',
    [LANGUAGES.fr]: 'Français',
    [LANGUAGES.it]: 'Italiano',
    [LANGUAGES.ko]: '한국어 (韓國語), 조선어 (朝鮮語)',
    [LANGUAGES.nl]: 'Nederlands, Vlaams',
    [LANGUAGES.pl]: 'Język Polski, Polszczyzna',
    [LANGUAGES.pt]: 'Portugues',
    [LANGUAGES.ro]: 'Limba Română',
  },
};

export const REGION_DOMAIN_VALUES = {
  AQ: DOMAINS.io,
  AT: DOMAINS.at,
  CA: DOMAINS.ca,
  CH: DOMAINS.ch,
  CZ: DOMAINS.cz,
  DE: DOMAINS.de,
  ES: DOMAINS.es,
  FR: DOMAINS.fr,
  GB: DOMAINS.uk,
  KR: DOMAINS.kr,
  NL: DOMAINS.nl,
  NZ: DOMAINS.nz,
  PL: DOMAINS.pl,
  PT: DOMAINS.pt,
  RO: DOMAINS.ro,
  US: DOMAINS.com,
  ZA: DOMAINS.za,
};

/* @TODO: let's just use formatMessage here instead of redefining for every language */
export const READABLE_REGIONS = {
  [LANGUAGES.en]: {
    [REGIONS.CA]: 'Canada',
    [REGIONS.GB]: 'Great Britain',
    [REGIONS.US]: 'USA',
    [REGIONS.ZA]: 'South Africa',
    [REGIONS.CH]: 'Switzerland',
    [REGIONS.DE]: 'Germany',
    [REGIONS.NZ]: 'New Zealand',
    [REGIONS.PT]: 'Portugal',
    [REGIONS.KR]: 'Korea',
    [REGIONS.ES]: 'Spain',
  },
  [LANGUAGES.fr]: {
    [REGIONS.ZA]: 'Afrique du Sud',
    [REGIONS.CA]: 'Canada',
    [REGIONS.US]: 'États Unis',
    [REGIONS.GB]: 'Grande Bretagne',
    [REGIONS.CH]: 'Switzerland',
    [REGIONS.DE]: 'Allemagne',
    [REGIONS.NZ]: 'Nouvelle-Zélande',
    [REGIONS.PT]: 'le Portugal',
    [REGIONS.KR]: 'Korea',
    [REGIONS.ES]: 'Spain',
  },
  [LANGUAGES.de]: {
    [REGIONS.CA]: 'Kanada',
    [REGIONS.GB]: 'Großbritannien',
    [REGIONS.US]: 'Vereinigte Staaten von Amerika',
    [REGIONS.ZA]: 'Südafrika',
    [REGIONS.CH]: 'Schweiz',
    [REGIONS.DE]: 'Deutschland',
    [REGIONS.NZ]: 'Neuseeland',
    [REGIONS.PT]: 'Portugal',
    [REGIONS.KR]: 'Korea',
    [REGIONS.ES]: 'Spain',
  },
  [LANGUAGES.it]: {
    [REGIONS.CA]: 'Canada',
    [REGIONS.GB]: 'Gran Bretagna',
    [REGIONS.US]: 'Stati Uniti',
    [REGIONS.ZA]: 'Sud Africa',
    [REGIONS.CH]: 'Svizzera',
    [REGIONS.DE]: 'Germania',
    [REGIONS.NZ]: 'Nuova Zelanda',
    [REGIONS.KR]: 'Korea',
    [REGIONS.PT]: 'Portogallo',
    [REGIONS.ES]: 'Spain',
  },
  [LANGUAGES.pt]: {
    [REGIONS.CA]: 'Canada',
    [REGIONS.GB]: 'Gran Bretagna',
    [REGIONS.US]: 'Stati Uniti',
    [REGIONS.ZA]: 'Sud Africa',
    [REGIONS.CH]: 'Svizzera',
    [REGIONS.DE]: 'Germania',
    [REGIONS.NZ]: 'Nuova Zelanda',
    [REGIONS.PT]: 'Portugal',
    [REGIONS.KR]: 'Korea',
    [REGIONS.ES]: 'Spain',
  },
  [LANGUAGES.ko]: {
    [REGIONS.CA]: 'Canada',
    [REGIONS.GB]: 'Great Britain',
    [REGIONS.US]: 'USA',
    [REGIONS.ZA]: 'South Africa',
    [REGIONS.CH]: 'Switzerland',
    [REGIONS.DE]: 'Germany',
    [REGIONS.NZ]: 'New Zealand',
    [REGIONS.PT]: 'Portugal',
    [REGIONS.KR]: 'Korea',
    [REGIONS.ES]: 'Spain',
  },
  [LANGUAGES.cs]: {
    [REGIONS.CA]: 'Canada',
    [REGIONS.GB]: 'Great Britain',
    [REGIONS.US]: 'USA',
    [REGIONS.ZA]: 'South Africa',
    [REGIONS.CH]: 'Switzerland',
    [REGIONS.DE]: 'Germany',
    [REGIONS.NZ]: 'New Zealand',
    [REGIONS.PT]: 'Portugal',
    [REGIONS.KR]: 'Korea',
    [REGIONS.ES]: 'Spain',
  },
  [LANGUAGES.pl]: {
    [REGIONS.CA]: 'Canada',
    [REGIONS.GB]: 'Great Britain',
    [REGIONS.US]: 'USA',
    [REGIONS.ZA]: 'South Africa',
    [REGIONS.CH]: 'Switzerland',
    [REGIONS.DE]: 'Germany',
    [REGIONS.NZ]: 'New Zealand',
    [REGIONS.PT]: 'Portugal',
    [REGIONS.KR]: 'Korea',
    [REGIONS.ES]: 'Spain',
  },
  [LANGUAGES.ro]: {
    [REGIONS.CA]: 'Canada',
    [REGIONS.GB]: 'Great Britain',
    [REGIONS.US]: 'USA',
    [REGIONS.ZA]: 'South Africa',
    [REGIONS.CH]: 'Switzerland',
    [REGIONS.DE]: 'Germany',
    [REGIONS.NZ]: 'New Zealand',
    [REGIONS.PT]: 'Portugal',
    [REGIONS.KR]: 'Korea',
    [REGIONS.ES]: 'Spain',
  },
  [LANGUAGES.ca]: {
    [REGIONS.CA]: 'Canada',
    [REGIONS.GB]: 'Gran Bretagna',
    [REGIONS.US]: 'Stati Uniti',
    [REGIONS.ZA]: 'Sud Africa',
    [REGIONS.CH]: 'Svizzera',
    [REGIONS.DE]: 'Germania',
    [REGIONS.NZ]: 'Nuova Zelanda',
    [REGIONS.KR]: 'Korea',
    [REGIONS.PT]: 'Portogallo',
    [REGIONS.ES]: 'Spain',
  },
  [LANGUAGES.gl]: {
    [REGIONS.CA]: 'Canada',
    [REGIONS.GB]: 'Gran Bretagna',
    [REGIONS.US]: 'Stati Uniti',
    [REGIONS.ZA]: 'Sud Africa',
    [REGIONS.CH]: 'Svizzera',
    [REGIONS.DE]: 'Germania',
    [REGIONS.NZ]: 'Nuova Zelanda',
    [REGIONS.KR]: 'Korea',
    [REGIONS.PT]: 'Portogallo',
    [REGIONS.ES]: 'Spain',
  },
  [LANGUAGES.eu]: {
    [REGIONS.CA]: 'Canada',
    [REGIONS.GB]: 'Gran Bretagna',
    [REGIONS.US]: 'Stati Uniti',
    [REGIONS.ZA]: 'Sud Africa',
    [REGIONS.CH]: 'Svizzera',
    [REGIONS.DE]: 'Germania',
    [REGIONS.NZ]: 'Nuova Zelanda',
    [REGIONS.KR]: 'Korea',
    [REGIONS.PT]: 'Portogallo',
    [REGIONS.ES]: 'Spain',
  },
};
