import { useCallback } from 'react';

import { useApolloClient } from '@apollo/react-hooks';
import { ApolloQueryResult } from 'apollo-client';
import {
  addDays,
  differenceInMinutes,
  format,
  getHours,
  isAfter,
  isBefore,
  isValid,
  isWithinInterval,
  parse,
  subHours,
  subMinutes,
} from 'date-fns';
import { pipe } from 'lodash/fp';
import { IntlShape } from 'react-intl';

import { ILocation, SupportedRegions } from '@rbi-ctg/frontend';
import { IStore, IStoreHoursOfOperation } from '@rbi-ctg/store';
import { GraphQLErrorMessages } from 'enums/graphql';
import {
  DeliveryQuoteDocument,
  DeliveryStatus,
  GetRestaurantDocument,
  IDeliveryQuoteQuery,
  IGetRestaurantQuery,
  IOperatingHours,
  IRestaurant,
  IRestaurantNode,
} from 'generated/rbi-graphql';
import { StoreStatus } from 'remote/api/restaurants';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { CustomEventNames, EventTypes, useMParticleContext } from 'state/mParticle';
import { ServiceMode } from 'state/order';
import { StoreProxy } from 'state/store/hooks';
import { StatusType, dataDogLogger } from 'utils/datadog';
import { convertMilesToFeet, convertMilesToKilometers, convertMilesToMeters } from 'utils/distance';
import { GraphQLErrorCodes, parseGraphQLErrorCodes } from 'utils/errors';
import { Geography } from 'utils/geography';
import { IPlaceAddress } from 'utils/geolocation';
import logger from 'utils/logger';

import { getConfigValue } from '../environment';

import {
  ALT_PARSE_FORMAT,
  LOCALE_TIME_PARSE_FORMAT,
  MIDNIGHT,
  OrderingStatus,
  PARSE_FORMAT,
  TWELVE_HOUR_TIME_PARSE_FORMAT,
} from './constants';

export { getUSState } from './get-us-state';
export { default as remapStore } from './remap-store';

export enum SanityTypes {
  ADDRESS = 'address',
  HOURS_OF_OPERATION = 'hoursOfOperation',
  POS = 'pos',
}

export enum Days {
  sun,
  mon,
  tue,
  wed,
  thr,
  fri,
  sat,
}

export enum HeartbeatOverrideStatus {
  Online = 'online',
  Offline = 'offline',
  Auto = 'auto',
}

export type IRbiRestaurant = IRestaurant | IGetRestaurantQuery['restaurant'];

type IAvailabilityProps = object & {
  available?: boolean;
  isAvailable?: boolean;
};

type ICheckAvailability = (rbiRestaurant?: IAvailabilityProps) => boolean;

type IGetRestaurantFn = (storeId: string) => Promise<IRbiRestaurant | undefined>;

type QueryResult = ApolloQueryResult<{ restaurant: IRbiRestaurant } | null>;

interface IMergeRestaurantData {
  rbiRestaurant: IRbiRestaurant;
  sanityStore: IStore;
}

const isAvailable = (rbiRestaurant?: IAvailabilityProps): boolean => {
  if (!rbiRestaurant) {
    return true;
  }

  // if `available` is null | undefined, it means the query for RbiRestaurant failed
  // in this case we default to true
  if ('isAvailable' in rbiRestaurant) {
    return rbiRestaurant.isAvailable ?? true;
  } else {
    return rbiRestaurant.available ?? true;
  }
};

/**
 * Utility hook for determining restaurant availability
 *
 * @returns {ICheckAvailability}
 * * Function that determines availability based on the result of the
 * * GetRestaurant query and the ENABLE_ORDERING LD flag
 * * If the query throws an errors, or the flag is off, then we default to `true`
 * * otherwise we return the `restaurant.available` boolean
 */
export const useGetRestaurantAvailabilityFn = (): ICheckAvailability => {
  const enableOrdering = useFlag(LaunchDarklyFlag.ENABLE_ORDERING);

  return useCallback(
    rbiRestaurant => {
      // if LD is offline for any reason, we default to true
      return enableOrdering ? isAvailable(rbiRestaurant) : true;
    },
    [enableOrdering]
  );
};

/**
 * Utility hook that provides a function to query an rbiRestaurant by id
 *
 * @returns {IGetRestaurantFn}
 * Function that retrieves an RbiRestaurant with the most up to date data
 * It will pass the restaurant through `ICheckAvailability` before returning
 */
export const useGetRestaurantFn = (): IGetRestaurantFn => {
  const client = useApolloClient();
  const checkAvailability = useGetRestaurantAvailabilityFn();

  const getRestaurantFn = useCallback<IGetRestaurantFn>(
    async (storeId: string) => {
      const { data, errors }: QueryResult = await client.query({
        // we always want the most up to date data when calculating heartbeat freshness
        fetchPolicy: 'network-only',
        variables: { storeId },
        query: GetRestaurantDocument,
      });

      if (!data || errors?.length) {
        const message = `Failed to query 'GetRestaurant' with 'storeId: ${storeId}'`;

        if (errors?.length) {
          logger.error({
            errors,
            message,
          });
        } else {
          logger.error(message);
        }

        return;
      }

      const { restaurant } = data;

      return {
        ...restaurant,
        available: checkAvailability(restaurant),
      };
    },
    [checkAvailability, client]
  );

  return getRestaurantFn;
};

/**
 * Merges the data from the GetRestaurant query and sanity query
 *
 * @param {IMergeRestaurantData} data
 * * rbiRestaurant - The restaurant returend from GetRestaurant
 * * sanityStore - the sanity document queried from groq
 *
 * @returns {IStore} merged store document
 *
 *
 * *NOTE* at the moment only the operating hours come from GetRestaurant
 */
export const mergeRestaurantData = ({
  rbiRestaurant,
  sanityStore,
}: IMergeRestaurantData): IStore & { available: boolean } => {
  return {
    ...sanityStore,
    available: rbiRestaurant.available,
    curbsideHours:
      (rbiRestaurant.curbsideHours as IStoreHoursOfOperation) || sanityStore.curbsideHours,
    deliveryHours:
      (rbiRestaurant.deliveryHours as IStoreHoursOfOperation) || sanityStore.deliveryHours,
    diningRoomHours:
      (rbiRestaurant.diningRoomHours as IStoreHoursOfOperation) || sanityStore.diningRoomHours,
    driveThruHours:
      (rbiRestaurant.driveThruHours as IStoreHoursOfOperation) || sanityStore.driveThruHours,
  };
};

export function nextDay(now: Date) {
  return Days[(now.getDay() + 1) % 7];
}

export function previousDay(now: Date) {
  return Days[(now.getDay() - 1) % 7];
}

function isLocation(point: Partial<ILocation>): point is ILocation {
  return Boolean(point?.lat && point?.lng);
}

/*
 * Determines what day is the next open day and returns the open hour
 */
export function nextOpenDay(
  hours: IStoreHoursOfOperation | IOperatingHours,
  now = new Date(Date.now())
): string {
  return hours[nextDay(now).concat('Open')];
}

/*
 * Determines what day is the next close day and returns the close hour
 */
export function nextCloseDay(
  hours: IStoreHoursOfOperation | IOperatingHours,
  now = new Date(Date.now())
): string {
  return hours[nextDay(now).concat('Close')];
}

/**
 * Parses a time string from mdm
 *
 * time strings can come in three flavors, so we have to try to parse all three
 * * yyyy-MM-dd HH:mm:ss.SSSSSSS
 * * yyyy-MM-dd HH:mm:ss
 * * HH:mm:ss
 *
 */
export function parseMdmTimeString(timeString: string): Date {
  let formattedTime = parse(timeString, PARSE_FORMAT, new Date(Date.now()));

  if (!isValid(formattedTime)) {
    formattedTime = parse(timeString, ALT_PARSE_FORMAT, new Date(Date.now()));
  }

  if (!isValid(formattedTime)) {
    formattedTime = parse(timeString, LOCALE_TIME_PARSE_FORMAT, new Date(Date.now()));
  }

  return formattedTime;
}

/**
 * Displays the given time string in a human readable format, either in standard or
 * military time format based on the market configuration.
 * @param {string} timeString Time string.
 * @param {boolean | undefined} ignoreMdmTimeParse If true, will not parse the time string as mdm.
 * @returns {string} Human readable time string.
 */
export function readableHour(timeString: string, ignoreMdmTimeParse?: boolean): string {
  try {
    const timeFormat = getConfigValue('timeFormat') || TWELVE_HOUR_TIME_PARSE_FORMAT;

    if (ignoreMdmTimeParse) {
      return format(new Date(timeString), timeFormat);
    }

    return format(parseMdmTimeString(timeString), timeFormat);
  } catch (error) {
    return 'hours not available';
  }
}

export const readableTimeInterval = (
  fallbackMessage: string,
  open24HoursMessage?: string,
  dateFormat?: string
) => (fromTime: string, toTime: string) => {
  try {
    const timeFormat = dateFormat || getConfigValue('timeFormat') || TWELVE_HOUR_TIME_PARSE_FORMAT;

    const readableFromHour = format(parseMdmTimeString(fromTime), timeFormat);
    const readableToHour = format(parseMdmTimeString(toTime), timeFormat);

    // we cannot simply check for equality because we only want to show
    // that a store is open 24 hours if the open/close times are both midnight
    // and not just any 24 hour interval.
    const is24HourInterval = readableFromHour === MIDNIGHT && readableToHour === MIDNIGHT;

    if (is24HourInterval && open24HoursMessage) {
      return open24HoursMessage;
    }

    return `${readableFromHour} - ${readableToHour}`;
  } catch (e) {
    return fallbackMessage;
  }
};

function getTodaysHoursOfOperation(
  hours: IStoreHoursOfOperation | IOperatingHours,
  now: Date = new Date(Date.now())
): [string, string] {
  if (!hours) {
    return ['', ''];
  }
  const dayName = Days[now.getDay()];
  const openHours: string = hours[dayName + 'Open'];
  const closeHours: string = hours[dayName + 'Close'];
  return [openHours, closeHours];
}

export function readableCloseHourToday(hours?: IStoreHoursOfOperation | IOperatingHours | null) {
  if (!hours) {
    return 'N/A';
  }

  const [, closeHours] = getTodaysHoursOfOperation(hours);

  if (!closeHours) {
    return 'N/A';
  }

  return readableHour(closeHours);
}

export function readableImperialDistance(
  miles: number,
  milesLocale: string,
  feetLocale: string
): string {
  if (miles >= 10) {
    return `${miles.toFixed(0)} ${milesLocale}`;
  } else if (miles >= 0.1) {
    return `${miles.toFixed(1)} ${milesLocale}`;
  } else {
    return `${convertMilesToFeet(miles).toFixed(0)} ${feetLocale}`;
  }
}

export function readableMetricDistance(
  miles: number,
  kilometersLocale: string,
  metersLocale: string
): string {
  const kilometers = convertMilesToKilometers(miles);
  if (kilometers >= 10) {
    return `${kilometers.toFixed(0)} ${kilometersLocale}`;
  } else if (kilometers >= 0.1) {
    return `${kilometers.toFixed(1)} ${kilometersLocale}`;
  } else {
    return `${convertMilesToMeters(miles).toFixed(0)} ${metersLocale}`;
  }
}

export function readableDistanceFromStore(
  miles: number,
  region: SupportedRegions,
  formatMessage: IntlShape['formatMessage']
): string {
  if (['US', 'GB'].includes(region)) {
    return readableImperialDistance(
      miles,
      formatMessage({ id: 'miles' }),
      formatMessage({ id: 'feet' })
    );
  } else {
    return readableMetricDistance(
      miles,
      formatMessage({ id: 'kilometers' }),
      formatMessage({ id: 'meters' })
    );
  }
}

/*
 * Composable version of Date.getTime()
 */
const dateToTime = (date: Date) => date.getTime();

/*
 * Given a time and a reference date, return a Date that is set to the reference
 * date's month, day, year, and the hours, minutes, seconds from time.
 */
const getDateForTime = (time: number, referenceDate: Date = new Date(Date.now())): Date => {
  const date = new Date(time);
  date.setFullYear(referenceDate.getFullYear());
  date.setMonth(referenceDate.getMonth());
  date.setDate(referenceDate.getDate());

  return date;
};

/*
 * Given a time string of the format "yyyy-MM-dd HH:mm:ss",
 * return today's date with hours, minutes, and seconds from the time string.
 */
export const getDateForHours = pipe(parseMdmTimeString, dateToTime, getDateForTime);

export function isHoursOfOperationValid(
  hours: IStoreHoursOfOperation | IOperatingHours,
  now: Date = new Date(Date.now())
) {
  if (!hours) {
    return false;
  }

  const [openHours, closeHours] = getTodaysHoursOfOperation(hours, now);

  return !!(closeHours && openHours);
}

/*
 * Hours is a strange shape of data. Things like
 * sunOpen: string,
 * sunClose: string,
 * monOpen: string,
 * monClose: string,
 * etc
 */
export function isRestaurantOpen(
  hours?: IStoreHoursOfOperation | IOperatingHours | null,
  now: Date = new Date(Date.now())
) {
  if (!hours) {
    return false;
  }

  const [openHours, closeHours] = getTodaysHoursOfOperation(hours, now);

  // if the hours are the same, the store is open 24hrs
  if (openHours && closeHours && openHours === closeHours) {
    return true;
  }

  // We send null for opening / closing when we want to close a restaurant
  // But we need to check if the restaurant closes past midnight to and ensure
  // current time is past yesterday closing hour when it happens.
  // Ex:
  // todayOpen: null
  // todayClose: null
  // currentTime: 1:00am
  // yesterdayCloseTime: 3:00am
  if (!closeHours || !openHours) {
    const yesterday = new Date(now.getTime());
    yesterday.setDate(yesterday.getDate() - 1);
    const [yesterdayOpenHours, yesterdayCloseHours] = getTodaysHoursOfOperation(hours, yesterday);

    if (!yesterdayOpenHours || !yesterdayCloseHours) {
      return false;
    }

    // getDateForHours always uses date now so there's no need to fix the day
    const yesterdayOpen = getDateForHours(yesterdayOpenHours);
    const yesterdayClose = getDateForHours(yesterdayCloseHours);

    // Check if the restaurant closes past midnight and if now is before close
    if (isBefore(yesterdayClose, yesterdayOpen) && isBefore(now, yesterdayClose)) {
      return true;
    }

    // if we got here it means that the restaurant is closed today
    return false;
  }

  let open = getDateForHours(openHours);
  let close = getDateForHours(closeHours);

  // If Closing is past midnight, some date manipulation is needed
  // before the times can be compared
  // e.g.
  // open:  06:00
  // close: 01:00
  if (isBefore(close, open)) {
    // Current Time is past midnight
    // e.g.
    // open: 06:00
    // currentTime: 01:00
    if (isBefore(now, open)) {
      const yesterdayDayName = previousDay(now);
      const yesterdayOpenHours = hours[`${yesterdayDayName}Open`];
      if (!yesterdayOpenHours) {
        return false;
      }

      open = getDateForHours(yesterdayOpenHours);
      // Past midnight, the open hours for the previous day are the relevant hours
      // Set Open to the day before for comparison
      open.setDate(open.getDate() - 1);
    } else {
      // Current Time is NOT past midnight
      // e.g.
      // open: 06:00
      // currentTime: 23:00
      // Fix close date day for comparison
      close.setDate(close.getDate() + 1);
    }
  }
  return isAfter(now, open) && isBefore(now, close);
}
export function checkServiceModeUnavailable(serviceMode: ServiceMode, store: StoreProxy) {
  let serviceModeUnavailable = false;
  switch (serviceMode) {
    case ServiceMode.DELIVERY:
      serviceModeUnavailable = !isRestaurantOpen(store.deliveryHours);
      break;
    case ServiceMode.CURBSIDE:
      serviceModeUnavailable = !isRestaurantOpen(store.curbsideHours);
      break;
    case ServiceMode.DRIVE_THRU:
      serviceModeUnavailable = !isRestaurantOpen(store.driveThruHours);
      break;
    default:
      serviceModeUnavailable = !isRestaurantOpen(store.diningRoomHours);
  }
  return serviceModeUnavailable;
}
export function checkIfWithinOneHourOfCloseToday(
  hours: IStoreHoursOfOperation | IOperatingHours | null,
  now: Date = new Date(Date.now())
) {
  if (!hours || !isRestaurantOpen(hours, now)) {
    return false;
  }
  const [, closeHours] = getTodaysHoursOfOperation(hours, now);
  const close = getDateForHours(closeHours);

  return isWithinInterval(now, { start: subHours(close, 1), end: close });
}

// Check given time is within 20 minutes of closing
// and ensuring there is a gap closure of the next opening day
export function isOpenIfNotWithinMinutesOfCloseTodayAndNot24hours(
  hours: IStoreHoursOfOperation | IOperatingHours,
  now: Date = new Date(Date.now()),
  minutes = 20
) {
  if (!isRestaurantOpen(hours, now)) {
    return false;
  }
  const [, closeHours] = getTodaysHoursOfOperation(hours, now);
  const close = getDateForHours(closeHours);

  if (!isWithinInterval(now, { start: subMinutes(close, minutes), end: close })) {
    return true;
  }

  // 24 hour store check
  // Subtract one minute due from 00 to 59
  const FOR_24_HOURS_IN_MINUTES_BETWEEN_MONTHS = 60 * 24 - 1;
  const tomorrowDayName = nextDay(now);
  const tomorrowOpenHours = hours[`${tomorrowDayName}Open`];

  const open = getDateForHours(tomorrowOpenHours);

  // The comparison of today close and tmrw open hours
  return differenceInMinutes(close, open) === FOR_24_HOURS_IN_MINUTES_BETWEEN_MONTHS;
}

export function checkIfDateIsWithinCloseTimeAndMinutes(
  hours: IStoreHoursOfOperation | IOperatingHours,
  now: Date = new Date(Date.now()),
  minutes = 60
) {
  if (!isRestaurantOpen(hours, now)) {
    return false;
  }
  const [, closeHours] = getTodaysHoursOfOperation(hours, now);
  let close = getDateForHours(closeHours);

  if (getHours(close) === 0) {
    close = addDays(close, 1);
  }

  return isWithinInterval(now, { start: subMinutes(close, minutes), end: close });
}

export function milesBetweenCoordinates(point1: ILocation, point2: Partial<ILocation>): number {
  if (!isLocation(point1) || !isLocation(point2)) {
    return 0;
  }

  return Geography.coordinateDistance(
    { latitude: point1.lat, longitude: point1.lng },
    { latitude: point2.lat, longitude: point2.lng },
    true
  );
}

/**
 * Determine whether delivery is available for the given store
 *
 * @param store Store
 * @param isAlphaBetaStoreOrderingEnabled Legacy mechanism for enabling mobile ordering
 * @param enableDeliveryCheckoutOutsideOpeningHours If true, consider a restaurant open for delivery regardless of HOO
 */
const getIsDeliveryAvailable = (
  store: IStore,
  isAlphaBetaStoreOrderingEnabled: boolean,
  enableDeliveryCheckoutOutsideOpeningHours: boolean
) => {
  const isOrderingAvailable = isMobileOrderingAvailable(store, isAlphaBetaStoreOrderingEnabled);
  const storeHasPosData = !!store.restaurantPosData;

  const isDeliveryOpen =
    store.hasDelivery &&
    store.deliveryHours &&
    (isRestaurantOpen(store.deliveryHours) || enableDeliveryCheckoutOutsideOpeningHours);

  return isOrderingAvailable && isDeliveryOpen && storeHasPosData;
};

export const useDeliveryQuoteSucceeds = () => {
  const { query } = useApolloClient();
  const { logEvent } = useMParticleContext();
  return useCallback(
    async (store: IStore, phone: string, deliveryAddress?: IPlaceAddress | null) => {
      const dropoff = {
        addressLine1: deliveryAddress?.addressLine1 ?? '',
        addressLine2: deliveryAddress?.addressLine2,
        city: deliveryAddress?.city ?? '',
        country: deliveryAddress?.country ?? '',
        phoneNumber: phone ?? '',
        state: deliveryAddress?.state ?? '',
        zip: deliveryAddress?.zip ?? '',
      };
      const pickup = {
        addressLine1: store.physicalAddress.address1,
        addressLine2: store.physicalAddress.address2,
        city: store.physicalAddress.city,
        country: store.physicalAddress.country,
        phoneNumber: store.phoneNumber.replace(/-/g, ''),
        state: store.physicalAddress.stateProvince,
        zip: store.physicalAddress.postalCode,
      };
      try {
        const { data } = await query<IDeliveryQuoteQuery>({
          query: DeliveryQuoteDocument,
          variables: { dropoff, pickup },
        });

        dataDogLogger({
          message: data.deliveryQuote.status,
          status: StatusType.info,
        });
        logEvent(CustomEventNames.FIRST_DELIVERY_QUOTE, EventTypes.Other, {
          status: data.deliveryQuote.status,
          'Restaurant Id': store._id,
          'Restaurant Name': store.name,
        });

        return {
          status: data.deliveryQuote.status,
          error: '',
          deliverySurchargeFeeCents: data.deliveryQuote?.deliverySurchargeFeeCents || 0,
        };
      } catch (err) {
        logger.error(err);
        const { errorCode, message } = parseGraphQLErrorCodes(err)[0];
        if (
          errorCode === GraphQLErrorCodes.BAD_USER_INPUT &&
          message === GraphQLErrorMessages.INVALID_PHONE_NUMBER
        ) {
          return {
            status: DeliveryStatus.QUOTE_ERROR,
            error: GraphQLErrorMessages.INVALID_PHONE_NUMBER,
          };
        }
      }
      return { status: DeliveryStatus.QUOTE_ERROR, error: '' };
    },
    [logEvent, query]
  );
};

export const useGetAvailableRestaurantWithDetails = () => {
  const deliveryQuoteSucceeds = useDeliveryQuoteSucceeds();
  const isAlphaBetaStoreOrderingEnabled = useFlag(
    LaunchDarklyFlag.ENABLE_ALPHA_BETA_STORE_ORDERING
  );
  const enableDeliveryCheckoutOutsideOpeningHours = useFlag(
    LaunchDarklyFlag.ENABLE_DELIVERY_CHECKOUT_OUTSIDE_OPENING_HOURS
  );

  return useCallback(
    // TODO: This should always be an IRestaurantNode[]
    // If none of the options trigger below, default status to NO_DELIVERY and nearestStore to null

    async (stores: IStore[], phone: string, deliveryAddress?: IPlaceAddress | null) => {
      let nearestStore = null;
      let storeStatus = StoreStatus.NO_DELIVERY;
      let nextEarliestOpen = Infinity;
      let storesWithInvalidHOO = [];
      let deliveryQuote: DeliveryStatus | null = null;
      let deliveryQuoteError = null;
      let deliveryQuoteSurchargeFeeCents = 0;

      if (!stores.length) {
        return {
          storeStatus,
          store: null,
          storesWithInvalidHOO: [],
          deliveryQuoteError,
          deliveryQuoteSurchargeFeeCents,
        };
      }
      for (let store of stores) {
        // If store doesn't have delivery, continue
        const isPosOnline = ((store as unknown) as IRestaurantNode).isAvailable;
        if (!isPosOnline || !store.hasDelivery || !store.deliveryHours) {
          const isHOOValid = isHoursOfOperationValid(store.deliveryHours);
          if (!isHOOValid) {
            // Log stores which don't have delivery hours set
            storesWithInvalidHOO.push(store);
          }
          continue;
        }
        // If store with delivery is available, break out of loop and set store
        const isDeliveryAvailable = getIsDeliveryAvailable(
          store,
          isAlphaBetaStoreOrderingEnabled,
          enableDeliveryCheckoutOutsideOpeningHours
        );

        if (isDeliveryAvailable) {
          /**
           * Same reason for disabling as above
           */
          // eslint-disable-next-line no-await-in-loop
          const { status, error, deliverySurchargeFeeCents } = await deliveryQuoteSucceeds(
            store,
            phone,
            deliveryAddress
          );
          deliveryQuote = status;
          deliveryQuoteError = error;
          deliveryQuoteSurchargeFeeCents = deliverySurchargeFeeCents || 0;

          if (deliveryQuote === DeliveryStatus.QUOTE_SUCCESSFUL) {
            nearestStore = store;
            storeStatus = StoreStatus.OPEN;
            break;
          }
        } else {
          const isHOOValid = isHoursOfOperationValid(store.deliveryHours);
          if (!isHOOValid) {
            // Log stores which don't have delivery hours set
            storesWithInvalidHOO.push(store);
            continue;
          }
        }

        // Otherwise if any store has delivery but is not available, set store status to CLOSED and find earliest open
        storeStatus = StoreStatus.CLOSED;

        const now = new Date(Date.now());
        const [todayOpenHours] = getTodaysHoursOfOperation(store.deliveryHours);
        const todayOpenDateTime = getDateForHours(todayOpenHours);

        // NOTE: This assumes tomorrow has open hours and could be improved by
        // checking future dates and ensuring we find one with open hours.
        const getTomorrowOpenDateTime = pipe(
          nextOpenDay,
          parseMdmTimeString,
          dateToTime,
          (time: number) => getDateForTime(time, addDays(now, 1))
        );
        // NOTE: This assumes we have proved the store is closed in earlier code
        const isStoreOpenToday = isBefore(now, todayOpenDateTime);

        // if store closed for today get the open time for the next day
        const nextOpenDateTime = isStoreOpenToday
          ? todayOpenDateTime
          : getTomorrowOpenDateTime(store.deliveryHours);

        // If earlier open time found in radius, set new time
        nextEarliestOpen = Math.min(nextOpenDateTime.getTime(), nextEarliestOpen);
      }

      return {
        storeStatus,
        store: nearestStore,
        storesWithInvalidHOO,
        deliveryQuote,
        deliveryQuoteError,
        deliveryQuoteSurchargeFeeCents,
        // If stores are closed, send nextEarliestOpen
        ...(!nearestStore && { nextEarliestOpen: new Date(nextEarliestOpen) }),
      };
    },
    [
      deliveryQuoteSucceeds,
      enableDeliveryCheckoutOutsideOpeningHours,
      isAlphaBetaStoreOrderingEnabled,
    ]
  );
};

/*
 * isMobileOrderingAvailable compares the app environment with the
 * restaurant environment returned from Sanity
 */

export function isMobileOrderingAvailable(
  restaurant: StoreProxy | IRestaurantNode,
  isAlphaBetaStoreOrderingEnabled: boolean
): boolean {
  const { mobileOrderingStatus } = restaurant;

  if (!restaurant?.hasMobileOrdering || !mobileOrderingStatus) {
    return false;
  }

  if (
    [OrderingStatus.ALPHA, OrderingStatus.BETA].includes(mobileOrderingStatus) &&
    !isAlphaBetaStoreOrderingEnabled
  ) {
    return false;
  }

  return getConfigValue('restaurants').validMobileOrderingEnvs.includes(mobileOrderingStatus);
}

/**
 * wrap `isMobileOrderingAvailable` in a hook so that it can encapsulate checking the LD flag
 */
export function useIsMobileOrderingAvailable(restaurant: StoreProxy | IRestaurantNode): boolean {
  const isAlphaBetaStoreOrderingEnabled = useFlag(
    LaunchDarklyFlag.ENABLE_ALPHA_BETA_STORE_ORDERING
  );

  return isMobileOrderingAvailable(restaurant, isAlphaBetaStoreOrderingEnabled);
}
