import React from 'react';

import { useIntl } from 'react-intl';
import styled from 'styled-components';

import ActionButton, { ActionButtonVariants } from 'components/action-button';
import Dialog from 'components/dialog';

export interface ICurbsideConfirmationModalProps {
  onKeepOrder: () => void;
  onCancelOrder: () => void;
  onDismiss: () => void;
}

const ModalContainer = styled.div`
  text-align: center;
`;
const Title = styled.h2``;
const Text = styled.p`
  margin-bottom: 2rem;
`;
const ActionsContiner = styled.div``;
const StyledButton = styled(ActionButton)`
  margin-bottom: 0.75rem;
`;

const CurbsideConfirmationModal: React.FC<ICurbsideConfirmationModalProps> = ({
  onKeepOrder,
  onCancelOrder,
  onDismiss,
}) => {
  const { formatMessage } = useIntl();
  return (
    <Dialog
      data-testid="curbside-confirmation-modal"
      showCloseButton={true}
      onDismiss={onDismiss}
      modalAppearanceEventMessage="Curbside Confirmation Modal"
      bodyComponent={
        <ModalContainer>
          <Title>{formatMessage({ id: 'cancelCurbsideOrderTitle' })}</Title>
          <Text>{formatMessage({ id: 'cancelCurbsideOrderText' })}</Text>
        </ModalContainer>
      }
      actions={
        <ActionsContiner>
          <StyledButton
            allowFullScreen
            fullWidth
            onClick={onKeepOrder}
            data-testid="keep-curbside-order"
          >
            {formatMessage({ id: 'keepCurbsideOrder' })}
          </StyledButton>
          <StyledButton
            variant={ActionButtonVariants.OUTLINE}
            allowFullScreen
            fullWidth
            onClick={onCancelOrder}
            data-testid="cancel-curbside-order"
          >
            {formatMessage({ id: 'cancelCurbsideOrder' })}
          </StyledButton>
        </ActionsContiner>
      }
    />
  );
};

export default CurbsideConfirmationModal;
