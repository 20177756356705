import React, { useCallback, useRef } from 'react';

import { DialogContent, DialogOverlay } from '@reach/dialog';
import { noop } from 'lodash-es';
import styled, { ThemeProvider } from 'styled-components';

import CloseButtonComponent from 'components/close-button';
import usePreventBodyScroll from 'hooks/use-prevent-body-scroll';
import { CustomEventNames, EventTypes, useMParticleContext } from 'state/mParticle';
import { theme as componentLibraryTheme } from 'styles/configure-theme';

import theme from './theme';
import { IModalProps } from './types';
import { findInnerHeaderText } from './utils';

export enum ModalSize {
  FULLSCREEN = 'FULLSCREEN',
  REGULAR = 'REGULAR',
  SMALL = 'SMALL',
  LANDSCAPE = 'LANDSCAPE',
  AUTO = 'AUTO',
}

export const modalSizeStyles = {
  borderRadius: {
    [ModalSize.FULLSCREEN]: '0',
    [ModalSize.REGULAR]: Styles.borderRadius,
    [ModalSize.SMALL]: Styles.borderRadius,
    [ModalSize.LANDSCAPE]: Styles.borderRadius,
    [ModalSize.AUTO]: Styles.borderRadius,
  },
  height: {
    [ModalSize.FULLSCREEN]: '100%',
    [ModalSize.REGULAR]: 'calc(100% - 10rem)',
    [ModalSize.SMALL]: 'calc(100% - 15rem)',
    [ModalSize.LANDSCAPE]: 'calc(100% - 10rem)',
    [ModalSize.AUTO]: 'auto',
  },
  width: {
    [ModalSize.FULLSCREEN]: '100%',
    [ModalSize.REGULAR]: 'calc(100% - 10rem)',
    [ModalSize.SMALL]: 'calc(60% - 15rem)',
    [ModalSize.LANDSCAPE]: '400px',
    [ModalSize.AUTO]: 'auto',
  },
  maxHeight: {
    [ModalSize.FULLSCREEN]: 'auto',
    [ModalSize.REGULAR]: '100%',
    [ModalSize.SMALL]: '100%',
    [ModalSize.LANDSCAPE]: 'auto',
    [ModalSize.AUTO]: '100%',
  },
  maxWidth: {
    [ModalSize.FULLSCREEN]: 'auto',
    [ModalSize.REGULAR]: '860px',
    [ModalSize.SMALL]: '480px',
    [ModalSize.LANDSCAPE]: '400px',
    [ModalSize.AUTO]: '100%',
  },
  minHeight: {
    [ModalSize.FULLSCREEN]: 'auto',
    [ModalSize.REGULAR]: '860px',
    [ModalSize.SMALL]: '480px',
    [ModalSize.LANDSCAPE]: '860px',
    [ModalSize.AUTO]: 'auto',
  },
  minWidth: {
    [ModalSize.FULLSCREEN]: 'auto',
    [ModalSize.REGULAR]: '540px',
    [ModalSize.SMALL]: '400px',
    [ModalSize.LANDSCAPE]: '400px',
    [ModalSize.AUTO]: 'auto',
  },
};

const ModalContainer = styled(DialogOverlay)<IModalProps>`
  background: rgba(0, 0, 0, 0.7);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  /* TODO: refactor z-indexes */
  z-index: ${Styles.zIndex.max - 200};

  [data-reach-dialog-content] {
    background-color: ${p => p.$backgroundColor || theme.modalContentBackground};
    background-image: ${p => (p.$backgroundImage ? `url(${p.$backgroundImage})` : '')};
    display: flex;
    flex-direction: column;
    height: 100%;
    left: 0;
    overflow-y: auto;
    position: absolute;
    top: 0;
    width: 100%;

    //webkit hack to ensure content renders with -webkit-overflow-scrolling: touch above;
    > * {
      -webkit-transform: translateZ(0px);
    }

    ${Styles.desktop`
      border-radius: ${(p: IModalProps) => modalSizeStyles.borderRadius[p.size!]};
      flex-direction: row;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      height: ${(p: IModalProps) => modalSizeStyles.height[p.size!]};
      width: ${(p: IModalProps) => modalSizeStyles.width[p.size!]};
      max-width: ${(p: IModalProps) => modalSizeStyles.maxWidth[p.size!]};
      max-height: ${(p: IModalProps) => modalSizeStyles.maxHeight[p.size!]};
      height: ${({ height }: IModalProps) => height};
      background-color: ${(p: any) => p.$backgroundColor || theme.modalContentBackground};
    `}
  }
`;

const ModalContent = styled(DialogContent)`
  ${Styles.mobile`
    padding: env(safe-area-inset-top) env(safe-area-inset-left) env(safe-area-inset-bottom) env(safe-area-inset-right);
  `}
`;

const StyledCloseButtonDefault = styled(CloseButtonComponent)`
  left: 1.25rem;
  left: calc(1.25rem + env(safe-area-inset-left));
  position: fixed;
  top: calc(1rem + env(safe-area-inset-top));
  box-shadow: ${theme.closeButtonBoxShadow};
  z-index: ${Styles.zIndex.below};

  ${Styles.mobileFullscreen`
    top: calc(1.25rem + env(safe-area-inset-top));
  `}
`;

const Modal = ({
  allowsDismiss = true,
  className,
  children,
  onDismiss = noop,
  size = ModalSize.REGULAR,
  closeButton: CloseButton = StyledCloseButtonDefault,
  mParticleEventData: inEventData,
  height,
  skipLoggingHeaderAndMessage = false,
  backgroundColor,
  backgroundImage,
  ...props
}: IModalProps) => {
  const { logEvent = noop } = useMParticleContext();
  const mParticleEventData = useRef(inEventData);
  usePreventBodyScroll();

  const contentRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (!node) {
        return;
      }

      const { modalHeader, modalMessage, modalAppearanceEventMessage } = mParticleEventData.current;
      const header = modalHeader || findInnerHeaderText(node) || '';
      const message = modalMessage || node.innerText;
      logEvent(CustomEventNames.MODAL_APPEARANCE, EventTypes.Other, {
        Message: modalAppearanceEventMessage,
        ModalHeader: skipLoggingHeaderAndMessage ? '' : header,
        ModalMessage: skipLoggingHeaderAndMessage ? '' : message,
      });
    },
    [logEvent, skipLoggingHeaderAndMessage]
  );

  return (
    <ThemeProvider theme={componentLibraryTheme}>
      <ModalContainer
        isOpen
        $backgroundColor={backgroundColor}
        $backgroundImage={backgroundImage}
        onDismiss={onDismiss}
        size={size}
        ref={contentRef}
        height={height}
      >
        <ModalContent
          className={className}
          role="dialog"
          aria-modal={true}
          aria-labelledby="modal-heading"
          {...props}
        >
          {allowsDismiss && (
            <CloseButton aria-hidden="true" onClick={onDismiss} data-testid="modal-close-button" />
          )}
          {children}
        </ModalContent>
      </ModalContainer>
    </ThemeProvider>
  );
};

export { default as ModalContent } from './modal-content';
export { default as ModalHeading } from './modal-heading';
export default Modal;
