import { IBrandRoutes } from './types';
import { LazyRoute, lazyWithFallback } from './util';

const Trending: LazyRoute = lazyWithFallback(() => import('components/trending-page'));
const WllLoyaltyVendor: LazyRoute = lazyWithFallback(() => import('pages/wll-loyalty-vendor'));
const PreferencesModal: LazyRoute = lazyWithFallback(() =>
  import('components/preferences-modal-bkde')
);
const IframeCampaign: LazyRoute = lazyWithFallback(() =>
  import('components/iframe-campaign/iframe-campaign')
);
export const brandRoutes: IBrandRoutes = {
  'routes.trending': Trending,
  'routes.wllLoyaltyVendor': WllLoyaltyVendor,
  'routes.userPreferencesModal': PreferencesModal,
};

export const fullPageBrandRoutes: IBrandRoutes = {
  'routes.campaign': IframeCampaign,
};

export default brandRoutes;
