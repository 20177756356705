import { createContext, useContext } from 'react';

import { noop } from 'lodash-es';

import { ILoggerOptions, defaultLogger } from 'utils/logger';

export type Logger = typeof defaultLogger;

export interface ILoggerContext {
  decorateLogger: <N extends object>(params: N, decorators?: ILoggerOptions) => void;
  logger: Logger;
}

export const LoggerContext = createContext<ILoggerContext>({
  decorateLogger: noop,
  logger: defaultLogger,
} as ILoggerContext);

export const useLoggerContext = () => useContext(LoggerContext);
