import React, { FC } from 'react';

import { omit } from 'lodash-es';

export function filterProps<P extends Record<string, any>, O extends Record<string, any>>(
  Component: FC<P>,
  ...keys: Array<keyof O>
): FC<P & O> {
  return (props: P & O) => <Component {...(omit(props, keys) as P)} />;
}

export default filterProps;
