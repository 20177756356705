import React, { FC } from 'react';

import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import Picture from 'components/picture';
import { selectors, useAppSelector } from 'state/global-state';

import { useLoyaltyLinkCardUI } from '../loyalty-link-card/use-loyalty-link-card-ui';

import {
  CardContainer,
  CardNumber,
  Container,
  PictureContainer,
  Title,
} from './loyalty-card-tile.styled';

const LoyaltyCardTile: FC = () => {
  const { data, loading: fetchingUIData } = useLoyaltyLinkCardUI();
  const { formatMessage } = useIntl();
  const cardData = useAppSelector(selectors.loyalty.select12DigitSwipeCard);
  if (!cardData || fetchingUIData) {
    return null;
  }
  return (
    <Container data-testid="loyalty-card-tile">
      <CardContainer>
        <Title>{data?.linkCardSectionLinkedState?.title?.locale || ''}</Title>
        <CardNumber>{cardData?.identifier}</CardNumber>
        <Link to={formatMessage({ id: 'routes.linkPhysicalCard' })}>
          {formatMessage({ id: 'viewDetails' })}
        </Link>
      </CardContainer>
      <PictureContainer>
        <Picture
          alt={data?.linkCardSectionLinkedState?.imageDescription?.locale || ''}
          image={data?.linkCardSectionLinkedState?.image?.locale}
        />
      </PictureContainer>
    </Container>
  );
};

export default LoyaltyCardTile;
