import { ApolloLink } from 'apollo-link';
import { print } from 'graphql';
import gql from 'graphql-tag';

import { PROD_DEFAULT_LANGUAGE, PROD_SUPPORTED_LANGUAGES } from 'state/intl/hook';
import { PROD_DEFAULT_REGION } from 'state/intl/supported-regions';

/**
 * @deprecated
 */
const DEPRECATED_LANGUAGE_INTERPOLATION_REGEX = /_LANG_/g;

/**
 * @deprecated use new localization interpolation link
 */
export const DEPRECATED_withLocalizedQueries = (
  replacementPattern: string | RegExp = DEPRECATED_LANGUAGE_INTERPOLATION_REGEX,
  defaultLanguage: PROD_SUPPORTED_LANGUAGES = PROD_DEFAULT_LANGUAGE[PROD_DEFAULT_REGION]
) =>
  new ApolloLink((operation, next) => {
    const context = operation.getContext();
    const { language = defaultLanguage } = context;

    if (operation.query) {
      const interpolated = gql(print(operation.query).replace(replacementPattern, language));

      operation.query = interpolated;

      return next(operation);
    }

    return next(operation);
  });

const LANGUAGE_INTERPOLATION_REGEX = /locale(Raw)?: en(Raw)?/g;

export const withLocalizedQueries = (
  replacementPattern: string | RegExp = LANGUAGE_INTERPOLATION_REGEX,
  defaultLanguage: PROD_SUPPORTED_LANGUAGES = PROD_DEFAULT_LANGUAGE[PROD_DEFAULT_REGION]
) =>
  new ApolloLink((operation, next) => {
    const context = operation.getContext();
    const { language = defaultLanguage } = context;

    if (operation.query) {
      const interpolated = gql(
        print(operation.query).replace(replacementPattern, `locale$1: ${language}$2`)
      );

      operation.query = interpolated;

      return next(operation);
    }

    return next(operation);
  });
