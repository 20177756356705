import LogRocketHelper from 'utils/logrocket';

import { withHeaders } from './with-headers';

const LOGROCKET_HEADER = 'x-lr-session-url';

export const withLogrocketHeaders = withHeaders(() => {
  const headers = {};
  const logrocketInstance = LogRocketHelper.getInstance().logrocket;

  if (logrocketInstance) {
    headers[LOGROCKET_HEADER] = logrocketInstance.sessionURL;
  }

  return headers;
});
