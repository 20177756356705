import React from 'react';

import { IHorizontalLinkListWidgetFragment, ILinkWidgetFragment } from 'generated/sanity-graphql';

import { Links } from './links';
import { HorizontalExternalLink, HorizontalInternalLink, HorizontalLinksContainer } from './styled';

type IHorizontalLinkListProps = IHorizontalLinkListWidgetFragment;

export const HorizontalLinkList: React.FC<IHorizontalLinkListProps> = props => {
  return (
    <HorizontalLinksContainer data-testid="horizontal-links">
      <Links
        links={props.linkWidgets as ILinkWidgetFragment[]}
        InternalLink={HorizontalInternalLink}
        ExternalLink={HorizontalExternalLink}
      />
    </HorizontalLinksContainer>
  );
};
