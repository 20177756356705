/**
 * AUTOGENERATED - DO NOT EDIT DIRECTLY
 *
 * Please see "scripts/regionalization/README.md" for how to modify
 */

export const importMap = {
  cs: () => import('state/translations/cs.json'),
  de: () => import('state/translations/de.json'),
  en: () => import('state/translations/en.json'),
  es: () => import('state/translations/es.json'),
  fr: () => import('state/translations/fr.json'),
  it: () => import('state/translations/it.json'),
  ko: () => import('state/translations/ko.json'),
  nl: () => import('state/translations/nl.json'),
  pl: () => import('state/translations/pl.json'),
  pt: () => import('state/translations/pt.json'),
  ro: () => import('state/translations/ro.json'),
  de_AT: () => import('state/translations/de_AT.json'),
  de_CH: () => import('state/translations/de_CH.json'),
  de_DE: () => import('state/translations/de_DE.json'),
  en_AQ: () => import('state/translations/en_AQ.json'),
  en_AT: () => import('state/translations/en_AT.json'),
  en_CA: () => import('state/translations/en_CA.json'),
  en_CH: () => import('state/translations/en_CH.json'),
  en_CZ: () => import('state/translations/en_CZ.json'),
  en_ES: () => import('state/translations/en_ES.json'),
  en_GB: () => import('state/translations/en_GB.json'),
  en_PL: () => import('state/translations/en_PL.json'),
  en_RO: () => import('state/translations/en_RO.json'),
  en_US: () => import('state/translations/en.json'),
  en_ZA: () => import('state/translations/en_ZA.json'),
  es_ES: () => import('state/translations/es_ES.json'),
  fr_CA: () => import('state/translations/fr_CA.json'),
  fr_CH: () => import('state/translations/fr_CH.json'),
  it_CH: () => import('state/translations/it_CH.json'),
  nl_NL: () => import('state/translations/nl_NL.json'),
  pt_PT: () => import('state/translations/pt_PT.json'),
  ca_ES: () => import('state/translations/ca_ES.json'),
  en_PT: () => import('state/translations/en_PT.json'),
  gl_ES: () => import('state/translations/gl_ES.json'),
  eu_ES: () => import('state/translations/eu_ES.json'),
};
