/**
 * AUTOGENERATED - DO NOT EDIT DIRECTLY
 *
 * Please see "scripts/regionalization/README.md" for how to modify
 */

import { LANGUAGES, REGIONS } from './types';

export enum PROD_SUPPORTED_LANGUAGES {
  ca = LANGUAGES.ca,
  cs = LANGUAGES.cs,
  de = LANGUAGES.de,
  en = LANGUAGES.en,
  es = LANGUAGES.es,
  eu = LANGUAGES.eu,
  fr = LANGUAGES.fr,
  gl = LANGUAGES.gl,
  it = LANGUAGES.it,
  nl = LANGUAGES.nl,
  pl = LANGUAGES.pl,
  pt = LANGUAGES.pt,
  ro = LANGUAGES.ro,
}

export const PROD_DEFAULT_LANGUAGE = {
  [REGIONS.CA]: PROD_SUPPORTED_LANGUAGES.en,
  [REGIONS.CH]: PROD_SUPPORTED_LANGUAGES.en,
  [REGIONS.DE]: PROD_SUPPORTED_LANGUAGES.de,
  [REGIONS.GB]: PROD_SUPPORTED_LANGUAGES.en,
  [REGIONS.NZ]: PROD_SUPPORTED_LANGUAGES.en,
  [REGIONS.US]: PROD_SUPPORTED_LANGUAGES.en,
  [REGIONS.ZA]: PROD_SUPPORTED_LANGUAGES.en,
  [REGIONS.NL]: PROD_SUPPORTED_LANGUAGES.nl,
  [REGIONS.AQ]: PROD_SUPPORTED_LANGUAGES.en,
  [REGIONS.PT]: PROD_SUPPORTED_LANGUAGES.pt,
  [REGIONS.AT]: PROD_SUPPORTED_LANGUAGES.de,
  [REGIONS.CZ]: PROD_SUPPORTED_LANGUAGES.cs,
  [REGIONS.PL]: PROD_SUPPORTED_LANGUAGES.pl,
  [REGIONS.RO]: PROD_SUPPORTED_LANGUAGES.ro,
  [REGIONS.ES]: PROD_SUPPORTED_LANGUAGES.es,
};
