import { REGION_DOMAIN_VALUES } from 'state/intl/hook';
import LocalStorage from 'utils/cognito/storage';
import { isNative } from 'utils/environment';
import { StorageKeys } from 'utils/local-storage';

import { determineValidLang, isNewLocaleSameAsCurrent, isSupportedRegion } from './helpers';
import { IFetchUserLocale } from './types';

export const fetchUserLocale = async ({
  currentRegion,
  currentLocale,
  clearRegionSpecificStorage,
  enableAutoShowRegionSelector,
  setShowLanguageSelectorModal,
  setHasShownLocaleSelector,
  isDeployPreview,
  inferLanguage,
  setDeterminingLocaleComplete,
  data,
}: IFetchUserLocale) => {
  const userLanguage = await inferLanguage();
  const {
    userGeo: { country: detectedRegion },
  } = data;
  // Prevent auto-locale if region not supported & prompt user with locale selector if enableAutoShowRegionSelector
  if (!detectedRegion || !isSupportedRegion(detectedRegion)) {
    if (enableAutoShowRegionSelector) {
      setShowLanguageSelectorModal(true);
    }
    setDeterminingLocaleComplete();
    return;
  }
  if (isNewLocaleSameAsCurrent(currentLocale, detectedRegion, userLanguage)) {
    setDeterminingLocaleComplete();
    return;
  }
  const [currentTLD, detectedTLD] = [
    REGION_DOMAIN_VALUES[currentRegion],
    REGION_DOMAIN_VALUES[detectedRegion],
  ];
  // Only change URL if not native or deploy preview;
  const url =
    isNative || isDeployPreview
      ? window.location.origin
      : window.location.origin.replace(currentTLD, detectedTLD);
  // Determine if browser language is valid for TLD, if not use first supported language
  const validLang = determineValidLang(detectedRegion, userLanguage);
  const strParams = new URLSearchParams(`lang=${validLang}`);
  const notFullUrlChange = url === window.location.origin;
  if (notFullUrlChange) {
    strParams.append('region', detectedRegion);
    clearRegionSpecificStorage();
  }
  setHasShownLocaleSelector();
  window.location.href = `${url}?${strParams}`;
  LocalStorage.setItem(StorageKeys.DETERMINING_LOCALE_COMPLETE, true);
};
