import React from 'react';

import { useFeatureNavigation } from 'hooks/use-feature-navigation';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';

import { DynamicLeftNav } from './dynamic-left-nav';
import { StaticLeftNav } from './static-left-nav';

export const LeftNavContainer = () => {
  const { featureNavigationDesktop, featureNavigationLoading } = useFeatureNavigation();
  const sanityNavigationEnabled = useFlag(LaunchDarklyFlag.ENABLE_SANITY_WEB_NAVIGATION);

  if (featureNavigationLoading) {
    return null;
  }

  const dynamicNavAvailable = !!featureNavigationDesktop.length && sanityNavigationEnabled;

  return dynamicNavAvailable ? (
    <DynamicLeftNav navLinks={featureNavigationDesktop} />
  ) : (
    <StaticLeftNav />
  );
};
