import React, { useCallback } from 'react';

import { VisuallyHidden } from '@rbilabs/components-library';
import { isNil } from 'lodash-es';
import { useIntl } from 'react-intl';

import { ServiceMode } from '@rbi-ctg/menu';
import Dropdown from 'components/app-header/dropdown';
import { LeftNav } from 'components/app-header/left-nav';
import { RightNav } from 'components/app-header/right-nav';
import StyleLoadingIndicator from 'components/app-header/styled-loading-indicator';
import { AppSubheader } from 'components/app-subheader';
import CartPreview from 'components/cart-preview';
import { DeliveryBannerContainer } from 'components/delivery-banner';
import Logo from 'components/logo';
import { TopServiceMode } from 'components/top-service-mode';
import { useFeatureNavigation } from 'hooks/use-feature-navigation';
import { useAccessibility } from 'state/accessibility';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useLoadingIndicator } from 'state/loading';
import { CustomEventNames, useMParticleContext } from 'state/mParticle';
import { useReversedUIContext } from 'state/reversed-ui';
import { useStoreContext } from 'state/store';
import { routes } from 'utils/routing';

import { ArrayNode, SingleNode } from '../dropdown/list-of-links';
import { SideNav } from '../side-navigation';
import NavContainer from '../styled/nav-container';

import { BRAND_LOGO_NAME_ID, MEDIA_QUERY_HEADER } from './constants';
import {
  Center,
  DesktopHeaderContainer,
  FlexContainer,
  LeftNavWrapper,
  RightNavContainer,
  StyledLink,
} from './desktop-header.styled';
import { DesktopHamburgerButton } from './hamburger-button';
import LogoContainer from './styled/logo-container';

// The types for react native do not support `dataSet` yet, but
// it is the required way to pass data-* attributes to the node.
// And we are using this mechanism to ensure these nodes are rendered
// during the pre-render, but only visible for the correct screen size.
const onlyRenderInDesktop = () =>
  ({
    dataSet: { mediaquery: MEDIA_QUERY_HEADER },
  } as any);

interface IDesktopHeaderProps {
  serviceMode: ServiceMode;
  userAddress: string;
  cateringPickupDateTime: string;
}

const DesktopHeader = ({
  serviceMode,
  userAddress,
  cateringPickupDateTime,
}: IDesktopHeaderProps) => {
  const { formatMessage } = useIntl();
  const enableOrdering = useFlag(LaunchDarklyFlag.ENABLE_ORDERING);
  const enableBottomServiceMode = useFlag(LaunchDarklyFlag.ENABLE_BOTTOM_SERVICE_MODE);
  const enableTopServiceMode =
    useFlag(LaunchDarklyFlag.ENABLE_TOP_SERVICE_MODE) && !enableBottomServiceMode;
  const enableDesktopSideNavigation = useFlag(LaunchDarklyFlag.ENABLE_DESKTOP_SIDE_NAVIGATION);
  const mParticle = useMParticleContext();
  const { isStoreOpenAndAvailable, noStoreSelected } = useStoreContext();
  const LoadingIndicator = useLoadingIndicator();
  const { featureAboutMenuEntriesDesktop: aboutMenuEntries } = useFeatureNavigation();
  const { isFullPageModalActive } = useAccessibility();
  const { reversedUI } = useReversedUIContext();

  // onReFocus Sets the focus of the clicked element to itself
  //
  // This is necessary due to the way that Reach Router changes the focus.
  // After the initial page load, reach router will move the focus to the outer most
  // component that is wrapped by the router. This component is outside of router,
  // so when clicking in the nav the focus is being pulled into the page.
  // For accessibility reasons, this is not the desired behavior.
  const onReFocus = useCallback(e => {
    e.persist();
    let target = e.target;
    window.setTimeout(() => {
      if (target && target.focus) {
        target.focus();
      }
    }, 200);
  }, []);

  const onLogoClick = useCallback(
    e => {
      mParticle.logNavigationClick(CustomEventNames.BUTTON_CLICK_LOGO);
      onReFocus(e);
    },
    [mParticle, onReFocus]
  );

  const onMyAccountClick = useCallback(
    e => {
      mParticle.logNavigationClick(CustomEventNames.BUTTON_CLICK_MY_ACCOUNT);
      onReFocus(e);
    },
    [mParticle, onReFocus]
  );

  // enable cart if a selected store is open and and available or no store is selected
  const enableCart =
    (isNil(enableOrdering) || enableOrdering) && (isStoreOpenAndAvailable || noStoreSelected);

  return (
    <DesktopHeaderContainer {...onlyRenderInDesktop()} aria-hidden={isFullPageModalActive}>
      <NavContainer reversed={reversedUI} aria-label={formatMessage({ id: 'primaryNavLinks' })}>
        <FlexContainer>
          <LeftNavWrapper>
            {enableDesktopSideNavigation ? (
              <SideNav
                entries={((aboutMenuEntries?.pages as unknown) as [SingleNode | ArrayNode]) ?? []}
              />
            ) : (
              <DesktopHamburgerButton />
            )}
            <LeftNav />
            {aboutMenuEntries && !enableDesktopSideNavigation && (
              <Dropdown
                title={aboutMenuEntries?.name?.locale ?? ''}
                entries={aboutMenuEntries?.pages ?? []}
              />
            )}
          </LeftNavWrapper>
          <Center>
            <StyledLink to={routes.base} onClick={onLogoClick}>
              <VisuallyHidden>{formatMessage({ id: BRAND_LOGO_NAME_ID })}</VisuallyHidden>
              <LogoContainer aria-hidden="true">
                <Logo aria-hidden="true" tabIndex={-1} />
              </LogoContainer>
            </StyledLink>
          </Center>
          <RightNavContainer>
            {enableTopServiceMode && <TopServiceMode />}
            <RightNav onMyAccountClick={onMyAccountClick} onReFocus={onReFocus} />
            {enableCart && <CartPreview isVisible={false} />}
          </RightNavContainer>
        </FlexContainer>
      </NavContainer>
      <StyleLoadingIndicator
        as={LoadingIndicator}
        hasStoreSelection={isStoreOpenAndAvailable && !enableBottomServiceMode}
      />
      {!enableBottomServiceMode && !enableTopServiceMode && <DeliveryBannerContainer />}
      <AppSubheader
        serviceMode={serviceMode}
        userAddress={userAddress}
        cateringPickupDateTime={cateringPickupDateTime}
      />
    </DesktopHeaderContainer>
  );
};

export default DesktopHeader;
