import React, { FC, useCallback } from 'react';

import { Icon, RotateIcon, VisuallyHidden } from '@rbilabs/components-library';
import { useIntl } from 'react-intl';

import useOnClickOutside from 'hooks/use-on-click-outside';
import { ENABLE_LOYALTY_2_0_SEE_MORE_OPTIONS } from 'utils/constants';
import { isButtonLikeKeypress } from 'utils/event';

import {
  CategoryDropdownContainer,
  OptionsContainer,
  SeeMoreOptionsCTA,
  Title,
  TitleContainer,
} from './category-dropdown.styled';
import { CategoryDropdownProps } from './types';

export const CategoryDropdown: FC<CategoryDropdownProps> = ({
  children,
  focusOptionsWhenNotVisible = false,
  id,
  label,
  onClickSeeMoreActions,
  optionsVisible,
  selectedOptionName,
  title,
  toggleOptionsVisible,
  handleOutsideClick,
  bold = false,
  ...props
}) => {
  const OutsideClickWrapper = useOnClickOutside(() => {
    if (handleOutsideClick) {
      handleOutsideClick();
    }
  });

  const { formatMessage } = useIntl();

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (isButtonLikeKeypress(e)) {
        e.preventDefault();
        toggleOptionsVisible();
      }
    },
    [toggleOptionsVisible]
  );

  return (
    <OutsideClickWrapper>
      <CategoryDropdownContainer optionsVisible={optionsVisible}>
        <TitleContainer>
          <Title
            aria-controls={`${id}-content`}
            aria-expanded={optionsVisible}
            onClick={toggleOptionsVisible}
            onKeyDown={onKeyDown}
            tabIndex={0}
            bold={bold}
          >
            <VisuallyHidden>
              {formatMessage(
                { id: 'rewardCategory' },
                {
                  categoryName: label,
                  selectedOptionText: selectedOptionName
                    ? formatMessage(
                        { id: 'containsSelectedOption' },
                        {
                          rewardTier: selectedOptionName,
                        }
                      )
                    : '',
                }
              )}
            </VisuallyHidden>
            <span aria-hidden>{title}</span>
            <RotateIcon active={optionsVisible}>
              <Icon icon="select" color={'icon-default'} width="24px" aria-hidden />
            </RotateIcon>
          </Title>
        </TitleContainer>
        <OptionsContainer
          {...props}
          id={`${id}-content`}
          focusOptionsWhenNotVisible={focusOptionsWhenNotVisible}
          optionsVisible={optionsVisible}
        >
          {children}
          {ENABLE_LOYALTY_2_0_SEE_MORE_OPTIONS && onClickSeeMoreActions && optionsVisible && (
            <SeeMoreOptionsCTA onClick={onClickSeeMoreActions}>
              {formatMessage({ id: 'seeMoreOptions' })}
            </SeeMoreOptionsCTA>
          )}
        </OptionsContainer>
      </CategoryDropdownContainer>
    </OutsideClickWrapper>
  );
};
