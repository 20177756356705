import * as React from 'react';

import { toast as toastify } from 'react-toastify';

import { ToastView } from 'components/toast/toast.view';
import { IToastOptions, ToastType } from 'components/toast/types';
import { DEFAULT_HIDE_DURATION as autoClose } from 'utils/toast/constants';

const createToast = (type: ToastType) => (message: string, { closeIcon }: IToastOptions = {}) =>
  toastify(props => <ToastView type={type} message={message} closeIcon={closeIcon} {...props} />, {
    type,
    autoClose,
  });

export const toast = {
  default: createToast('default'),
  info: createToast('info'),
  success: createToast('success'),
  warning: createToast('warning'),
  error: createToast('error'),
};
