// This file is generated via scripts/build-static-config.js
// Do not modify directly as changes will be overwritten in CI

export const CONFIGURATION = {
  country: 'us',
  brand: 'bk',
  env: 'prod',
  graphqlEnv: 'prod',
  graphqlGatewayEnv: '',
  sanityEnv: 'prod',
  platform: 'web',
  commitRef: 'e212ae281c8f7005d81a3e8d716a46e29b377ccf',
  releaseTimestamp: '1680546540',
  sanityDataset: '',
  sanityProjectId: 'czqk28jt',
  appFlowBuildId: '',
  adyen: {
    clientKey: 'live_L6BRV3FK4FFZVN2XFXYC6WCMRALGGK6O',
    environment: 'live-us',
    urlPrefix: 'https://checkoutshopper-live.adyen.com',
  },
  apiKeys: {
    amplitude: '9173edc6287c7687c01a9d1819b5baae',
    branch: 'key_live_pjC6MwRAi7CX8J1F6MMbKcdoEElw8ztg',
    braze: 'e1f5b376-c0ff-46b4-9042-01ffac3ec488',
    bringg: '',
    googleMaps: 'AIzaSyATcsWnJUNiEC1the-_wjs-M_qy8YtA7rc',
    googleAnalytics: '',
    tomtom: '',
    launchDarkly: '639b5fee9bf9a7117a1ee9cb',
    launchDarklyMobileKey: 'mob-f76d0955-0705-48be-b27c-2fe77e6a061b',
    logRocket: 'mj7uvx/ctg-prod',
    mParticle: 'us1-46ab09cd603b594b98131f1b555a2721',
    radar: '',
  },
  apple: {
    merchantId: 'merchant.com.bk.native.tillster.prod',
    migrationMerchantId: '',
    paymentsNetworks: ['AmEx','MasterCard','Visa','Discover'],
    prepaidMerchantId: 'merchant.com.bk.native.tillster.prod',
    migrationPrepaidMerchantId: '',
  },
  aws: {
    gqlApiUrl: 'https://use1-prod-bk.rbictg.com/graphql',
    gqlGatewayApiUrl: 'https://use1-prod-bk-gateway.rbictg.com/graphql',
    region: 'us-east-1',
    userPoolClientId: '1ca8tuisf8vnaffhfck2258idt',
    userPoolId: 'us-east-1_BCVUfvjft',
  },
  bringg: {
    region: '6',
  },
  datadog: {
    logs: {
      clientToken: 'pubf86c7fbda9db5464a9cf21d924cd74db',
      dataCenter: '',
    },
    rum: {
      applicationId: 'cf8d1c33-af85-481b-b4b7-ce00a7ec999d',
      clientToken: 'pubf86c7fbda9db5464a9cf21d924cd74db',
      dataCenter: '',
    },
  },
  forter: {
    siteId: '89ea0743f614',
  },
  google: {
    paymentsEnvironment: 'prod',
    paymentsGateway: 'firstdata',
    paymentsGatewayMerchantId: {
      ca: '',
      us: '456031998994',
    },
    migrationPaymentsGateway: '',
    migrationPaymentsGatewayMerchantId: {
      ca: '',
      us: '',
    },
    paymentsNetworks: ['AMEX','MASTERCARD','VISA','DISCOVER','JCB'],
    prepaidPaymentsGateway: 'firstdata',
    prepaidPaymentsGatewayMerchantId: {
      ca: '',
      us: '456031998994',
    },
    migrationPrepaidPaymentsGateway: 'firstdata',
    migrationPrepaidPaymentsGatewayMerchantId: {
      ca: '',
      us: '',
    }
  },
  googleAdManager: {
    campaignId: '',
  },
  googleTagManager: {
    containerId: '',
  },
  oneTrust: {
    cookieCompliance: {
      consentCategoryBranch: 'C0004',
      consentCategoryBraze: 'C0002',
      consentCategoryMParticle: 'C0001',
      globalConsentState: 'Opt-Out',
      scriptUrl: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
      scriptId: '1aad1bfb-17b6-4023-aa99-6f60a365e4b5',
    },
    deleteAccountUrl: '',
  },
  pypestream: {
    scriptUrl: 'https://web.pypestream.com/api-launcher.js',
    appId: '671e8279-ccd4-4908-ab01-e4e953961334',
  },
  restaurants: {
    displayEnv: 'prod',
    validMobileOrderingEnvs: ['alpha','beta','live'],
  },
  urls: {
    braze: 'https://customer.iad-03.braze.com/api/v3',
    firstData: 'https://api.payeezy.com',
    firstDataTh: '',
    webBaseUrl: '',
    thDigital: '',
  },
  tokenEx: {
    publicKey: '',
  },
  vrPayment: {
    braintreeTokenizationKey: '',
  },
  orbital: {
    baseUrl: '',
    subscriberId: '',
    pieFormat: '',
    mode: '',
  },
  timeFormat: 'hh:mm aaaa',
  dateFormat: '',
  appStoreDeeplink: '',
};