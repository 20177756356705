import { datadogRum } from '@datadog/browser-rum';

import useEffectOnce from 'hooks/use-effect-once';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import {
  appVersionCode,
  brand,
  env,
  getConfigValue,
  isLocalDev,
  platform,
} from 'utils/environment';

const FALLBACK_SAMPLING_RATE = isLocalDev ? 100 : 10;

const useDatadogRum = () => {
  const sampleRate = useFlag(LaunchDarklyFlag.DATADOG_RUM_SAMPLE_RATE);

  useEffectOnce(() => {
    const awsConfig = getConfigValue('aws');
    const dataDogConfig = getConfigValue('datadog');
    const applicationId = dataDogConfig.rum.applicationId;
    const clientToken = dataDogConfig.rum.clientToken;
    const datacenter = dataDogConfig.rum.dataCenter || 'us';
    const allowedTracingOrigins = [awsConfig.gqlApiUrl, awsConfig.gqlGatewayApiUrl];

    datadogRum.init({
      applicationId,
      clientToken,
      datacenter,
      service: `${brand()}_${platform()}`,
      env: env(),
      version: appVersionCode(),
      sampleRate: sampleRate ?? FALLBACK_SAMPLING_RATE,
      trackInteractions: true,
      defaultPrivacyLevel: 'mask-user-input',
      allowedTracingOrigins,
    });
  });
};

export default useDatadogRum;
