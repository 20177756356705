import React, { ComponentType } from 'react';

import { isExternalLink } from 'utils/is-external-link';

import { IUniversalLinkProps } from './types';

const UniversalLink = <InternalProps extends object, ExternalProps extends object>({
  to,
  children,
  internal,
  external,
  ...rest
}: IUniversalLinkProps<InternalProps, ExternalProps>) => {
  const isExternal = isExternalLink(to);

  const propToUse = isExternal ? external : internal;

  const Link: ComponentType = propToUse.component;
  const linkProps = {
    ...propToUse.props,
    ...(isExternal ? { href: to } : { to }),
  };
  return (
    <Link {...linkProps} {...rest}>
      {children}
    </Link>
  );
};

export default UniversalLink;
