export class ThreeDSMethodError extends Error {
  constructor() {
    super('Method flow activated');
  }
}

export class ThreeDSChallengeError extends Error {
  constructor() {
    super('Challenge flow activated');
  }
}
