import gql from 'graphql-tag';

export default gql`
  fragment VendorConfigFragment on VendorConfig {
    pluType
    parentSanityId
    pullUpLevels
    constantPlu
    quantityBasedPlu {
      quantity
      plu
      qualifier
    }
    multiConstantPlus {
      quantity
      plu
      qualifier
    }
    parentChildPlu {
      plu
      childPlu
    }
    sizeBasedPlu {
      comboPlu
      comboSize
    }
  }
`;
