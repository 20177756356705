import React, { useContext } from 'react';

import { Box } from '@rbilabs/components-library';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { ActionLink } from 'components/action-button';
import { AuthContext } from 'state/auth';
import { primitive } from 'styles/constants/primitives';
import { routes } from 'utils/routing';

import theme from './theme';

export const verticalCenteringStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  /* 57px: height of fixed header, 3rem: vertical container padding */
  min-height: calc(100vh - 57px - 3rem);

  ${Styles.mobile`
    display: block;
    margin-top: 0;
    margin-bottom: 0;
  `}
`;

export const MapContainer = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  ${verticalCenteringStyles};
`;

export const OrderCardScroller = styled.div`
  position: absolute;
  width: 100%;
  overflow-x: auto;
  bottom: 0;
  left: 0;

  ${Styles.desktop`
    bottom: unset;
    left: 0;
    top: 8rem;
    width: 100%;
  `}
`;

const OrderIssuesContainer = styled.div`
  color: ${Styles.color.black};
  font-size: 0.875rem;
  text-align: center;
  padding: 1rem;
  max-width: 311px;
  border-top: solid 1px ${Styles.color.grey.four};
  margin: 1.5rem auto 0;
`;

const OrderIssuesHeading = styled.h3`
  font-size: 1rem;
  margin: 0 0 0.625rem 0;
  font-family: ${Styles.fontFamily.base};
  font-weight: 600;
`;

const OrderIssuesText = styled.p`
  margin: 0;
  font-size: 0.75rem;
`;

const StyledLink = styled(Link)`
  color: ${Styles.color.primary};
  text-decoration: none;
  text-decoration: underline;
  :hover {
    color: ${Styles.color.primaryHover};
  }
`;

export const OrderIssues: React.FC = ({ children }) => {
  const { formatMessage } = useIntl();

  return (
    <OrderIssuesContainer>
      <OrderIssuesHeading>{formatMessage({ id: 'needHelp' })}</OrderIssuesHeading>
      <OrderIssuesText>
        {formatMessage({ id: 'issuesDuringExperience' })}&nbsp;
        <StyledLink to={routes.support}>{formatMessage({ id: 'visitSupport' })}</StyledLink>
      </OrderIssuesText>
      {children}
    </OrderIssuesContainer>
  );
};

export const CTA: React.FC = () => {
  const { isAuthenticated } = useContext(AuthContext);
  const { formatMessage } = useIntl();

  return isAuthenticated() ? (
    <Box margin="0 auto">
      <ActionLink to={routes.base}>{formatMessage({ id: 'home' })}</ActionLink>
    </Box>
  ) : (
    <Box margin="0 auto">
      <ActionLink to={routes.account}>{formatMessage({ id: 'signUp' })}</ActionLink>
    </Box>
  );
};

export const OrderCardScrollContainer = styled.div`
  padding: 1.25rem;
  display: inline-flex;
  overflow-x: visible;

  ${Styles.desktop`
    width: 100%;
    display: flex;
    justify-content: center;
  `}
`;

export const OrderCard = styled.div`
  background: ${Styles.color.white};
  border-radius: ${Styles.borderRadius};
  box-shadow: ${Styles.boxShadow};
  padding: 2.25rem 2.25rem 1.438rem;
  width: calc(100vw - 3rem);
  margin-right: 1rem;

  ${Styles.desktop`
    width: 80%;
    max-width: 500px;
  `}
`;

export const SignupCard = styled(OrderCard)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  margin-right: 4rem;

  ${Styles.desktop`
    display: none;
  `}
`;

export const HeroHeading = styled.h2`
  color: ${Styles.color.black};
  font-family: ${Styles.fontFamily.base};
  line-height: 1;
  margin: 3.125rem auto 2.375rem;
  text-align: center;
  text-transform: ${theme.heroHeadingTextTransform};
  width: 310px;

  > small {
    text-transform: lowercase;
    vertical-align: top;
  }
`;

export const LogoContainer = styled.div`
  margin: auto;
  text-align: center;
  width: 8rem;

  svg {
    fill: ${Styles.color.primary};
    width: 4.5rem;
  }

  ${Styles.desktop`
    svg {
      width: 8rem;
    }
  `}
  ${Styles.mobileTiny`
    margin-top: 7.5rem;
  `}
`;

export const NarrowSection = styled.div`
  max-width: ${Styles.layout.smallerSectionMaxWidth};
  margin: auto;
  padding: 0 1.5rem;
  ${Styles.desktop`
    padding: 0 2.75rem;
  `}
`;

export const QRContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2em;
  margin-bottom: 1em;
`;

export const QRWrapper = styled.div`
  padding: 0.75em;
  background-color: ${primitive.$white};
  & > canvas {
    display: block;
  }
`;

export const PickupPinWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  width: fit-content;
  padding: 1rem;
  margin: 2rem auto;
  border-radius: 0.5rem;
  background-color: ${primitive.$white};
`;

export const PickupPinCode = styled.span`
  font-size: 2.25rem;
  line-height: 1;
  font-family: ${Styles.fontFamily.base};
`;

export const PickupPinMessage = styled.span`
  font-size: 1rem;
  line-height: 1;
`;
