import styled from 'styled-components';

import ActionButton from 'components/action-button';

export const ActionButtonStyled = styled(ActionButton)<{ reversed: boolean }>`
  color: ${p => p.theme.token('text-reversed')} !important;
  border-color: ${p =>
    p.reversed ? p.theme.token('icon-reversed') : p.theme.token('icon-default')} !important;
  background: ${p => p.theme.token('background-hero-reversed')} !important;
  svg {
    path {
      fill: ${p => p.theme.token('icon-reversed')};
    }
  }
`;
