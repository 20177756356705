import { useCallback, useEffect, useMemo, useState } from 'react';

import { useAuthContext } from 'state/auth';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import {
  OrderLocatorTab,
  OrderLocatorTabVariations,
  defaultOrderLocatorTabVariation,
} from 'state/launchdarkly/variations';
import { CustomEventNames, useMParticleContext } from 'state/mParticle';
import { ServiceMode, useOrderContext } from 'state/order';

import { IStoreLocatorTabs, IStoreLocatorTabsProps, LocatorTabIds } from '../types';

const deliveryTabEnabled = (selectedServiceMode: ServiceMode, enableCateringDelivery: boolean) => {
  if (
    selectedServiceMode === ServiceMode.CATERING_DELIVERY ||
    (selectedServiceMode === ServiceMode.CATERING_PICKUP && !enableCateringDelivery)
  ) {
    return false;
  }
  return true;
};

const filterTabsForServiceMode = (
  tabs: OrderLocatorTab[],
  selectedServiceMode: ServiceMode,
  enableCateringDelivery: boolean
) => {
  return tabs.filter(tab => {
    if (
      tab === OrderLocatorTab.DELIVERY &&
      !deliveryTabEnabled(selectedServiceMode, enableCateringDelivery)
    ) {
      return false;
    }
    return true;
  });
};
/**
 * @name orderTabsForUnauthenticatedUser
 * @description we always want to show the NEARBY tab first
 *  for unauthed users while still preserving the custom order
 *  and visibility based on LD.
 */
const orderTabsForUnauthenticatedUser = (ldTabsOrder: OrderLocatorTab[]) => [
  OrderLocatorTab.NEARBY,
  ...ldTabsOrder.filter(tab => tab !== OrderLocatorTab.NEARBY),
];

export default function useStoreLocatorTabs({
  errorNearby,
  errorFavs,
  errorRecent,
}: IStoreLocatorTabsProps): IStoreLocatorTabs {
  const { isAuthenticated } = useAuthContext();
  const { logNavigationClick } = useMParticleContext();
  const { serviceMode } = useOrderContext();
  const enableCateringDelivery = useFlag(LaunchDarklyFlag.ENABLE_CATERING_DELIVERY);
  const locatorTabsOrder =
    useFlag<OrderLocatorTabVariations>(LaunchDarklyFlag.ORDER_LOCATOR_TABS) ||
    defaultOrderLocatorTabVariation;
  const [currentTabIndex, setTabIndex] = useState(0);
  // Track whether or not the current tab is in an error state
  const [currentTabHasError, setCurrentTabHasError] = useState(false);

  // TODO: potentially also track tab empty state status here?

  const userIsAuthenticated = isAuthenticated();
  const ldTabData: OrderLocatorTab[] = useMemo(() => Object.values(locatorTabsOrder), [
    locatorTabsOrder,
  ]);

  const locatorTabsEnabledAndOrdered = useMemo(
    () =>
      filterTabsForServiceMode(
        userIsAuthenticated ? ldTabData : orderTabsForUnauthenticatedUser(ldTabData),
        serviceMode,
        enableCateringDelivery
      ),
    [enableCateringDelivery, ldTabData, serviceMode, userIsAuthenticated]
  );

  /**
   * @name locatorTabIds
   *  maps all possible tabs with their index in the final order
   *  any non-visible tabs have a value of -1, but are necessary
   *  to have in the map for type safety and ease of use by consumers
   */
  const locatorTabIds = Object.keys(OrderLocatorTab).reduce((acc, cur) => {
    const tabIdx = locatorTabsEnabledAndOrdered.indexOf(OrderLocatorTab[cur]);
    acc[OrderLocatorTab[cur]] = tabIdx;
    return acc;
  }, {} as LocatorTabIds);

  const currentTab = locatorTabsEnabledAndOrdered[currentTabIndex];

  const handleTabChange = useCallback(
    (newValue: number) => {
      const newTabName = locatorTabsEnabledAndOrdered[newValue];
      logNavigationClick(CustomEventNames.BUTTON_CLICK_STORE_LOCATOR_TAB, {
        Tab: newTabName,
      });

      setTabIndex(newValue);
    },
    [locatorTabsEnabledAndOrdered, logNavigationClick]
  );

  // update whether or not the current tab has an error
  // check any time the tab itself or any of the tab error states changes
  useEffect(() => {
    let tabHasErr = false;
    switch (currentTab) {
      case OrderLocatorTab.NEARBY:
        if (errorNearby) {
          tabHasErr = true;
        }
        break;
      case OrderLocatorTab.FAVORITE:
        if (errorFavs) {
          tabHasErr = true;
        }
        break;
      case OrderLocatorTab.RECENT:
        if (errorRecent) {
          tabHasErr = true;
        }
        break;
      default:
    }
    // TODO: should we only update if value has actually changed?
    setCurrentTabHasError(tabHasErr);
  }, [currentTab, errorFavs, errorNearby, errorRecent]);

  return {
    currentTab,
    currentTabHasError,
    currentTabIndex,
    handleTabChange,
    locatorTabIds,
    locatorTabs: locatorTabsEnabledAndOrdered,
  };
}
