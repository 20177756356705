import { Button, OutlineButton, TextOnlyButton } from '@rbilabs/components-library';
import styled from 'styled-components';

import Dialog from 'components/dialog';
import Picture from 'components/picture';

export const StyledHeader = styled.h1`
  margin: 0 auto;
  font-family: ${Styles.fontFamily.alternative};
  font-size: 1.65rem;
  font-weight: ${Styles.fontWeight.heavy};
`;

export const StyledBody = styled.p`
  margin: 0 auto 1rem auto;
  font-family: ${Styles.fontFamily.body};
  font-size: 1rem;
  font-weight: ${Styles.fontWeight.light};
  width: 98%;
`;

export const StyledDialog = styled(Dialog)`
  padding-top: 1.5rem;
  text-align: center;
`;

export const StyledPicture = styled(Picture)`
  max-width: 85%;
  margin: auto;

  img {
    object-fit: contain;
  }
`;

export const StyledButtonsContainer = styled.div``;

export const StyledButton = styled(Button)`
  width: 100%;
  margin-top: 1rem;
`;

export const StyledOutlineButton = styled(OutlineButton)`
  width: 100%;
  margin-top: 1rem;
`;

export const StyledTextOnlyButton = styled(TextOnlyButton)`
  width: 100%;
  margin: 1rem auto;
  text-transform: capitalize;
`;
