import React, { ChangeEvent, useCallback, useEffect } from 'react';

import { LocaleSelect } from '@rbilabs/components-library';
import { useIntl } from 'react-intl';

import { useLocale } from 'state/intl';
import { LOCALE_SELECTED_QUERY_PARAM } from 'state/intl/hook';
import { REGIONS } from 'state/intl/types';
import { NEXT_URL_QUERY_PARAM } from 'state/location/constants';
import { useMParticleContext } from 'state/mParticle';
import { isNative } from 'utils/environment';

export const LanguageSelectorV2 = ({
  redirectToCurrentLocation = false,
  localeOptions,
}: {
  redirectToCurrentLocation?: boolean;
  localeOptions: { value: string; label: string }[];
}) => {
  const { formatMessage } = useIntl();
  const { updateUserAttributes } = useMParticleContext();

  const {
    clearRegionSpecificStorage,
    language,
    locale,
    region,
    regionDomainValues,
    setHasShownLocaleSelector,
  } = useLocale();

  const domainChangeByRegion = (url: string, regionString?: REGIONS) => {
    if (regionString === REGIONS.CA && !window.location.hostname.includes('popeyeschicken')) {
      return url.replace('popeyes', 'popeyeschicken');
    }
    if (regionString === REGIONS.US && window.location.hostname.includes('popeyes.com')) {
      return url.replace('popeyeschicken', 'popeyes');
    }
    return url;
  };

  /** update the attributes for this user */
  useEffect(() => {
    updateUserAttributes({ language });
  }, [updateUserAttributes, language]);

  const setLanguage = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const newLang = event.target.value.substring(0, 2);
      const newRegion = event.target.value.substring(3, 5);
      const isDeployPreview = window.location.href.includes('preview-');
      const encodedCurrentUrl = encodeURIComponent(window.location.pathname);

      let strParams = `?lang=${newLang}&${LOCALE_SELECTED_QUERY_PARAM}=1`;
      if (redirectToCurrentLocation) {
        strParams += `&${NEXT_URL_QUERY_PARAM}=${encodedCurrentUrl}`;
      }

      const params = new URLSearchParams(strParams);

      let url = window.location.origin.replace(
        regionDomainValues[region],
        regionDomainValues[newRegion]
      );

      url = domainChangeByRegion(url, region as REGIONS);

      // Don't change the URL on native
      // Changing the url will cause Android to open the web browser
      if (isNative || isDeployPreview) {
        url = window.location.origin;
      }

      // Add the region query param and clear localstorage
      // if we are not going to do a full url change
      if (url === window.location.origin) {
        params.append('region', region);

        clearRegionSpecificStorage();
      }

      setHasShownLocaleSelector();
      window.location.href = `${url}?${params}`;
    },
    [
      clearRegionSpecificStorage,
      redirectToCurrentLocation,
      region,
      regionDomainValues,
      setHasShownLocaleSelector,
    ]
  );

  /** If there is at most one valid locale, do not render the localeSelector */
  if (localeOptions.length <= 1) {
    return null;
  }

  return (
    <LocaleSelect
      data-testid={'locale-selector'}
      reversed={true}
      value={locale}
      options={localeOptions}
      onChange={setLanguage}
      aria-label={formatMessage({ id: 'languageAndRegionSelector' })}
    />
  );
};
