import React, { Suspense, useEffect, useState } from 'react';

import { isNil } from 'lodash-es';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { View, useWindowDimensions } from 'react-native';
import { Navigate, Route, Routes, useMatch } from 'react-router-dom';
import { ThemeProvider as WebThemeProvider } from 'styled-components';
import styled, { ThemeProvider as RNThemeProvider } from 'styled-components/native';
import { isFalse } from 'utils';

import AppHeader from 'components/app-header';
import AppflowUpdateProgress from 'components/appflow-update-progress';
import { AutoSelectRestaurant } from 'components/auto-select-restaurant';
import { BottomServiceMode } from 'components/bottom-service-mode';
import ErrorNetwork from 'components/error-network';
import { useFeatureHomePage } from 'components/features/home-page/use-feature-home-page';
import Footer from 'components/footer';
import { FooterV2 } from 'components/footer-V2';
import LanguageSelectorModal from 'components/language-selector-modal';
import { regionFilter } from 'components/language-selector/regionFilter';
import LayoutBottomContainer from 'components/layout/layout-bottom-container';
import LayoutFabButton from 'components/layout/layout-fab-button';
import LoadingAnimation from 'components/loading-animation';
import MobileNavigation from 'components/mobile-web-navigation-bar';
import ModalBeeper from 'components/modal-beeper';
import ModalLockerPickup from 'components/modal-lockers-pickup';
import { PypestreamInterface } from 'components/pypestream';
import SetLoading from 'components/set-loading';
import { ToastContainer } from 'components/toast';
import { TopServiceModeNotification } from 'components/top-service-mode-notification';
import WelcomeMessage from 'components/welcome-message';
import {
  useFeatureAccountFormQuery,
  useFeatureAccountQuery,
  useFeatureMenuQuery,
} from 'generated/sanity-graphql';
import useAuthRedirects from 'hooks/auth/use-auth-redirects';
import useLoyaltyRedirect from 'hooks/loyalty/use-loyalty-redirect';
import { useResetStoreResetCartResetServiceModeTimeout } from 'hooks/reset-store-timeout';
import useDatadogRum from 'hooks/rum/use-datadog-rum';
import { useAppflow } from 'hooks/use-appflow';
import useDialogModal from 'hooks/use-dialog-modal';
import useEffectOnce from 'hooks/use-effect-once';
import { useFeatureNavigation } from 'hooks/use-feature-navigation';
import { useForter } from 'hooks/use-forter';
import { useGeoData } from 'hooks/use-geo-data';
import useGoogleAnalytics from 'hooks/use-google-analytics';
import { useIosAtt } from 'hooks/use-ios-att';
import { useLogrocketTargetedRecording } from 'hooks/use-logrocket-targeted-recording';
import useRegionRedirect from 'hooks/use-region-redirect';
import { useSetUserLocale } from 'hooks/use-set-user-locale';
import { useTrendingData } from 'hooks/use-trending-data';
import LanguageSelectorPage from 'pages/language-selector';
import LoyaltyNotAvailableAtDeliveryMode from 'pages/loyalty/loyalty-not-available-at-delivery-mode';
import LoyaltyNotAvailableAtThisRestaurant from 'pages/loyalty/loyalty-not-available-at-this-restaurant';
import LoyaltyOptInModal from 'pages/loyalty/loyalty-opt-in-modal';
import { useFeaturesContext } from 'state/features';
import { useLocale } from 'state/intl';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useLocationContext } from 'state/location';
import { NEXT_URL_QUERY_PARAM } from 'state/location/constants';
import { useLogger } from 'state/logger';
import { useLoyaltyContext } from 'state/loyalty';
import { useIsLoyaltyEnabled } from 'state/loyalty/hooks/use-is-loyalty-enabled';
import { useNetworkContext } from 'state/network';
import { useOnboardingContext } from 'state/onboarding';
import { OnboardingContent } from 'state/onboarding/onboarding-content';
import { useOrderContext } from 'state/order';
import { useScrollContext } from 'state/scroll';
import { usePageManagerContext } from 'state/static-page-manager';
import { useStaticPageRoutes } from 'state/static-page-manager/hooks/use-static-page-routes';
import { theme } from 'styles/configure-theme';
import { fullBrandName } from 'utils/environment';
import { getIosAppTrackingStatus } from 'utils/get-ios-app-tracking-status';
import { Status } from 'utils/get-ios-app-tracking-status/types';
import { getAllLocalizedVersionsOfRoute, routes } from 'utils/routing';

import { brandRoutes, fullPageBrandRoutes } from './brand-routes';
import { IS_TH } from './constants';
import { DefaultCookieBannerContainer, OneTrustCookieBanner } from './cookie-banner-container';
import ForceUpdateDialog from './force-update-dialog';
import { ForceAppUpdateVariations } from './types';
import useLogPageView from './use-log-page-view';
import useRobotMetaTags from './use-robot-meta-tags';
import useScrollTop from './use-scroll-top';
import { LazyRoute, lazyWithFallback } from './util';

const ThemeProvider = (props: any) => {
  return (
    <WebThemeProvider theme={props.theme}>
      <RNThemeProvider theme={props.theme}>{props.children}</RNThemeProvider>
    </WebThemeProvider>
  );
};

const Cart: LazyRoute = lazyWithFallback(() => import('pages/cart'));
const MainMenu: LazyRoute = lazyWithFallback(() => import('pages/main-menu'));
const MenuContent: LazyRoute = lazyWithFallback(() => import('pages/menu-content'));
const Home: LazyRoute = lazyWithFallback(() => import('pages/home'));
const Account: LazyRoute = lazyWithFallback(() => import('pages/account'));
const CommPreferences: LazyRoute = lazyWithFallback(() => import('pages/account/comm-preferences'));
const AccountInfo: LazyRoute = lazyWithFallback(() => import('pages/account/account-info'));
const AccountDelete: LazyRoute = lazyWithFallback(() => import('pages/account/account-delete'));
const AccountRequestInfo: LazyRoute = lazyWithFallback(() =>
  import('pages/account/account-request-info')
);
const PaymentMethods: LazyRoute = lazyWithFallback(() => import('pages/account/payment-methods'));
const PaybackVendor: LazyRoute = lazyWithFallback(() =>
  import('pages/account/payback-vendor/payback-vendor')
);
const Diagnostics: LazyRoute = lazyWithFallback(() => import('pages/diagnostics'));
const AccountOrders: LazyRoute = lazyWithFallback(() => import('pages/account/account-orders'));
const Offers: LazyRoute = lazyWithFallback(() => import('pages/offers'));
const LoyaltyOffers: LazyRoute = lazyWithFallback(() => import('pages/loyalty/loyalty-offers'));
const LoyaltyRewards: LazyRoute = lazyWithFallback(() => import('pages/loyalty/loyalty-rewards'));
const LoyaltyDashboard: LazyRoute = lazyWithFallback(() =>
  import('pages/loyalty/loyalty-dashboard')
);
const LoyaltySurprise: LazyRoute = lazyWithFallback(() => import('pages/loyalty/loyalty-surprise'));
const LoyaltyInRestaurantRedemption: LazyRoute = lazyWithFallback(() =>
  import('pages/loyalty/loyalty-in-restaurant-redemption')
);
const LoyaltyClaimPoints: LazyRoute = lazyWithFallback(() =>
  import('pages/loyalty/loyalty-claim-points')
);
const LoyaltyLinkCard: LazyRoute = lazyWithFallback(() =>
  import('pages/loyalty/loyalty-link-card')
);
const SignupHome: LazyRoute = lazyWithFallback(() => import('pages/authentication/sign-up-home'));
const Quiz: LazyRoute = lazyWithFallback(() => import('components/quiz'));
const OnboardingFlaggedContainer: LazyRoute = lazyWithFallback(() =>
  import('pages/account/tims-rewards/onboarding-flagged-container')
);

const AuthChallengeScreenJwt: LazyRoute = lazyWithFallback(() =>
  import('pages/authentication/auth-email-jwt')
);
const ConfirmJwt: LazyRoute = lazyWithFallback(() => import('pages/authentication/confirm-jwt'));
const SignOut: LazyRoute = lazyWithFallback(() => import('pages/authentication/sign-out'));
const StoreLocator: LazyRoute = lazyWithFallback(() => import('pages/store-locator'));
const OrderConfirmation: LazyRoute = lazyWithFallback(() =>
  import('pages/order-confirmation/order-confirmation')
);
const StoreOrderConfirmation: LazyRoute = lazyWithFallback(() =>
  import('pages/order-confirmation/store/store-order-confirmation')
);
const About: LazyRoute = lazyWithFallback(() => import('pages/about'));
const StaticPage: LazyRoute = lazyWithFallback(() => import('pages/static'));
const NotFound: LazyRoute = lazyWithFallback(() => import('pages/not-found'));
const Pypestream: LazyRoute = lazyWithFallback(() => import('pages/pypestream'));
const SupportPage: LazyRoute = lazyWithFallback(() => import('pages/support'));
const SupportV2CategoriesPage: LazyRoute = lazyWithFallback(() =>
  import('pages/support-v2/support-v2-categories-page')
);
const SupportV2CategoryPage: LazyRoute = lazyWithFallback(() =>
  import('pages/support-v2/support-v2-category-page')
);
const SupportV2FormPage: LazyRoute = lazyWithFallback(() =>
  import('pages/support-v2/support-v2-form-page')
);
const SupportV2OrdersListPage: LazyRoute = lazyWithFallback(() =>
  import('pages/support-v2/support-v2-orders-list-page')
);
const ReportOrderIssuePage: LazyRoute = lazyWithFallback(() => import('pages/report-order-issue'));
const ReportOrderNeverArrivedPage: LazyRoute = lazyWithFallback(() =>
  import('pages/report-order-never-arrived')
);
const ReportOrderItemsMissingPage: LazyRoute = lazyWithFallback(() =>
  import('pages/report-order-items-missing')
);
const OfferActivatePage: LazyRoute = lazyWithFallback(() => import('pages/offer-activate'));
const SendGiftPage: LazyRoute = lazyWithFallback(() => import('pages/send-gift'));
const AcceptGift = lazyWithFallback(() => import('pages/accept-gift'));
const History = lazyWithFallback(() => import('pages/account/history'));
const FavoriteEditorPage = lazyWithFallback(() => import('pages/favorite-editor'));
const LoadingPage = lazyWithFallback(() => import('pages/loading'));
const QRSnap: LazyRoute = lazyWithFallback(() =>
  import('pages/loyalty/loyalty-in-restaurant-redemption/qr-snap/qr-snap')
);

const AppContainer = styled.View`
  height: 100%;
  width: 100%;
  flex: 1;
`;

const LayoutContainerScrollView = styled.ScrollView`
  background: ${Styles.color.background};
  flex: 1;
  width: 100%;
  &:focus {
    top: 0.2rem;
    left: 0.2rem;
    opacity: 1;
  }
`;

const ContentContainer = styled.View`
  background: ${Styles.color.background};
  flex-direction: column;
  flex: 1;
`;

const ContentLoadingContainer = styled.View`
  width: 100%;
  flex: 1;
  align-items: center;
  justify-content: center;
  background: ${Styles.color.background};
`;

const RouterWrapperContainer = styled.View<{ $windowHeight: number }>`
  min-height: ${(p: { $windowHeight: number }) => p.$windowHeight}px;
  background: ${Styles.color.background};
  flex-grow: 1;
`;

const RouterWrapper: React.FC = ({ children }) => {
  const dimensions = useWindowDimensions();

  return (
    <RouterWrapperContainer $windowHeight={dimensions.height}>{children}</RouterWrapperContainer>
  );
};

const ContentRouting = () => {
  useGoogleAnalytics();
  const { clearServerOrder } = useOrderContext(); // @todo type order context

  const { formatMessage } = useIntl();
  const loyaltyEnabled = useIsLoyaltyEnabled();
  const { claimPointsEnabled, loyaltySurpriseOffersEnabled } = useLoyaltyContext();

  const enableCatering = useFlag(LaunchDarklyFlag.ENABLE_CATERING);
  const enableOrderSupport = useFlag(LaunchDarklyFlag.ENABLE_ORDER_SUPPORT);
  const enableAccountDeletePage = useFlag(LaunchDarklyFlag.ENABLE_ACCOUNT_DELETE_PAGE);

  const enableFooterV2 = useFlag(LaunchDarklyFlag.TEMP_ENABLE_FOOTER_COMPONENT_UPDATE);

  const enableRequestMyInformation = useFlag(LaunchDarklyFlag.ENABLE_REQUEST_MY_INFORMATION);
  const enableCommPrefFlag = useFlag(LaunchDarklyFlag.ENABLE_COMMUNICATION_PREFERENCES);
  const enableCommunicationPreferences = !isFalse(enableCommPrefFlag);

  const enableInAppQuizGame = useFlag(LaunchDarklyFlag.ENABLE_IN_APP_QUIZ_GAME);

  const enableOfferGifting = useFlag(LaunchDarklyFlag.ENABLE_OFFER_GIFTING);

  const enableGuestAndMultiStepSupportForm = useFlag(
    LaunchDarklyFlag.ENABLE_GUEST_AND_MULTI_STEP_SUPPORT_PAGE
  );

  const enablePypestream = useFlag(LaunchDarklyFlag.ENABLE_PYPESTREAM);

  const enablePayback = useFlag(LaunchDarklyFlag.ENABLE_PAYBACK);

  // Show the offer page if it's not TH or if it's enabled for TH via LD
  const enableThOffersPage = useFlag(LaunchDarklyFlag.ENABLE_TH_OFFERS_PAGE);
  const showOffersPage = !IS_TH || enableThOffersPage;

  const enableSignUpFlag = useFlag(LaunchDarklyFlag.ENABLE_SIGN_UP);
  const enableSignUp = isNil(enableSignUpFlag) || enableSignUpFlag;

  const forceAuthOnHomepageFlag = useFlag(LaunchDarklyFlag.FORCE_HOMEPAGE_AUTH);
  const isBaseRoute = useMatch(routes.base);

  const enableRewardPageStandAlone = useFlag(LaunchDarklyFlag.ENABLE_REWARD_PAGE_STAND_ALONE);

  const forceAuthOnHomepage = forceAuthOnHomepageFlag && enableSignUp && isBaseRoute;

  const enableRewardsStaticPage = useFlag(LaunchDarklyFlag.ENABLE_REWARDS_STATIC_PAGE);
  const enableOffersStaticPage = useFlag(LaunchDarklyFlag.ENABLE_OFFERS_STATIC_PAGE);

  useAuthRedirects({ skip: !forceAuthOnHomepage });

  useEffect(() => {
    // reset the server order when a user navigate into the menu no matter what
    clearServerOrder();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {/* Base Routes */}
      <ContentContainer>
        <RouterWrapper>
          <Routes>
            <Route path={'*'} element={<StaticPage />} />
            <Route path={routes.notFound404} element={<NotFound />} />
            <Route path={routes.confirmJwt} element={<ConfirmJwt />} />
            {loyaltyEnabled && (
              <Route
                path={`${formatMessage({ id: 'routes.loyaltyDashboard' })}`}
                element={<LoyaltyDashboard />}
              />
            )}
            {loyaltySurpriseOffersEnabled && (
              <Route
                path={`${formatMessage({ id: 'routes.loyaltyHome' })}/${formatMessage({
                  id: 'surprise',
                })}`}
                element={<LoyaltySurprise />}
              />
            )}
            <Route
              path={formatMessage({ id: 'routes.redemptionInRestaurant' })}
              element={<LoyaltyInRestaurantRedemption />}
            />
            <Route
              path={`${formatMessage({ id: 'routes.redemptionInRestaurant' })}${formatMessage({
                id: 'routes.qrSnap',
              })}`}
              element={<QRSnap />}
            />
            <Route
              path={formatMessage({ id: 'routes.redeem-is-not-available-at-this-restaurant' })}
              element={<LoyaltyNotAvailableAtThisRestaurant />}
            />
            <Route
              path={formatMessage({ id: 'routes.redeem-is-not-available-at-delivery-mode' })}
              element={<LoyaltyNotAvailableAtDeliveryMode />}
            />
            <Route
              path={formatMessage({ id: 'routes.linkPhysicalCard' })}
              element={<LoyaltyLinkCard />}
            />
            {claimPointsEnabled && (
              <Route
                path={`${formatMessage({ id: 'routes.claimPointsBase' })}/*`}
                element={<LoyaltyClaimPoints />}
              />
            )}
            {enableSignUp && (
              <>
                <Route path={formatMessage({ id: 'routes.signUp' })} element={<SignupHome />} />
                {getAllLocalizedVersionsOfRoute('signUp').map((path, index) => (
                  <Route path={path} key={`sign-up-${index}`} element={<SignupHome />} />
                ))}
                <Route path={formatMessage({ id: 'routes.signIn' })} element={<SignupHome />} />
                {getAllLocalizedVersionsOfRoute('signIn').map((path, index) => (
                  <Route path={path} key={`sign-in-${index}`} element={<SignupHome />} />
                ))}
                <Route path={routes.confirmOtp} element={<SignupHome />} />
                <Route path={formatMessage({ id: 'routes.account' })} element={<Account />} />
                {getAllLocalizedVersionsOfRoute('account').map((path, index) => (
                  <Route path={path} key={`account-${index}`} element={<Account />} />
                ))}
              </>
            )}
            <Route path={routes.authChallengeJwt} element={<AuthChallengeScreenJwt />} />
            <Route path={routes.signOut} element={<SignOut />} />
            <Route path={routes.menu} element={<MainMenu />} />
            <Route path={routes.browseMenu} element={<MainMenu />} />

            {/*
                MenuContent handles the following routes:

                /menu/favorites
                /menu/recent-items
                /menu/section-:id
                /menu/picker-:id
                /menu/combo-:id
                /menu/item-:id
                /menu/* (Item Not Found)

              */}
            <Route path={`${routes.menu}/:id`} element={<MenuContent />} />
            <Route path={`${routes.browseMenu}/:id`} element={<MenuContent />} />

            <Route path={routes.base} element={<Home />} />
            {!enableOffersStaticPage && showOffersPage && (
              <Route path={`${routes.offers}/*`} element={<Offers />} />
            )}
            {enableRewardPageStandAlone && (
              <Route
                path={formatMessage({ id: 'routes.loyaltyHome' })}
                element={<LoyaltyDashboard />}
              />
            )}

            {!enableRewardsStaticPage && (
              <>
                <Route
                  path={formatMessage({ id: 'routes.loyaltyOfferList' })}
                  element={<LoyaltyOffers />}
                />
                <Route
                  path={`${formatMessage({ id: 'routes.loyaltyOfferList' })}/*`}
                  element={<LoyaltyOffers />}
                />
                <Route
                  path={formatMessage({ id: 'routes.loyaltyRewardList' })}
                  element={<LoyaltyRewards />}
                />
                <Route
                  path={`${formatMessage({ id: 'routes.loyaltyRewardList' })}/*`}
                  element={<LoyaltyRewards />}
                />
              </>
            )}

            <Route path={routes.info} element={<AccountInfo />} />
            <Route
              path={`${formatMessage({ id: 'routes.rewardsHistory' })}`}
              element={<History />}
            />
            {enableCommunicationPreferences && (
              <Route path={routes.communication} element={<CommPreferences />} />
            )}
            <Route path={`${routes.orders}/*`} element={<AccountOrders />} />
            {enableAccountDeletePage && (
              <Route
                path={formatMessage({ id: 'routes.accountDelete' })}
                element={<AccountDelete />}
              />
            )}
            {enableRequestMyInformation && (
              <Route
                path={formatMessage({ id: 'routes.accountRequestInfo' })}
                element={<AccountRequestInfo />}
              />
            )}
            {enableInAppQuizGame && <Route path={routes.quiz} element={<Quiz />} />}
            {enableInAppQuizGame && (
              <Route path={formatMessage({ id: 'routes.quiz' })} element={<Quiz />} />
            )}
            <Route path={`${routes.payment}/*`} element={<PaymentMethods />} />
            {enablePayback && <Route path={`${routes.payback}/*`} element={<PaybackVendor />} />}
            <Route
              path={`${formatMessage({ id: 'routes.rewardsOnboarding' })}/:stepIndex`}
              element={<OnboardingFlaggedContainer />}
            />
            <Route
              path={`${formatMessage({ id: 'routes.rewardsOnboarding' })}`}
              element={<OnboardingFlaggedContainer />}
            />
            <Route path={routes.about} element={<About />} />
            <Route path={`${routes.diagnostics}/*`} element={<Diagnostics />} />
            {enableOrderSupport && (
              <Route
                path={`${routes.reportOrderIssue}/:orderId`}
                element={<ReportOrderIssuePage />}
              />
            )}
            {enableOrderSupport && (
              <Route
                path={`${routes.missingOrder}/:orderId`}
                element={<ReportOrderNeverArrivedPage />}
              />
            )}
            {enableOrderSupport && (
              <Route
                path={`${routes.missingItems}/:orderId`}
                element={<ReportOrderItemsMissingPage />}
              />
            )}
            {enableOfferGifting && (
              <>
                <Route path={formatMessage({ id: 'routes.sendGift' })} element={<SendGiftPage />} />
                <Route path={`${routes.acceptGift}/:token`} element={<AcceptGift />} />
              </>
            )}
            <Route
              path={`${routes.offerActivate}/:tokenId/:offerId`}
              element={<OfferActivatePage />}
            />
            {enablePypestream ? (
              // show pypestream and redirect all other support links to pypestream
              <>
                <Route path={routes.support} element={<Pypestream />} />
                <Route
                  path={`${routes.support}/*`}
                  element={<Navigate replace to={routes.support} />}
                />
              </>
            ) : enableGuestAndMultiStepSupportForm ? (
              // general support requests
              <>
                {/* // general support requests */}
                <Route path={routes.supportForm} element={<SupportV2FormPage />} />
                <Route path={routes.supportOrders} element={<SupportV2OrdersListPage />} />
                <Route path={routes.supportCategories} element={<SupportV2CategoriesPage />} />
                <Route path={routes.supportCategory} element={<SupportV2CategoryPage />} />
                <Route path={routes.support} element={<SupportV2CategoriesPage />} />
              </>
            ) : (
              <Route path={routes.support} element={<SupportPage />} />
            )}

            <Route path={routes.favoriteEditor} element={<FavoriteEditorPage />} />

            {/* We only want brand-specific routes to be available for those brands */}
            {/* `brandRoutes` can be thought of a Map of routes (string) to Components (React component) */}
            {Object.entries(brandRoutes).map(([path, Component]) =>
              // if a route isn't catering we want it. if it is catering, we leave it up to the LD flag
              path !== 'routes.catering' || enableCatering ? (
                <Route
                  path={formatMessage({ id: path })}
                  element={<Component key={path} />}
                  key={path}
                />
              ) : null
            )}
          </Routes>
        </RouterWrapper>
        {enableFooterV2 ? <FooterV2 /> : <Footer />}
      </ContentContainer>
    </>
  );
};

const titleTemplate = `%s - ${fullBrandName()}`;

const Layout = () => {
  const {
    location: { pathname },
    navigate,
  } = useLocationContext();
  const {
    featureAccountId = '',
    featureIdsLoading,
    featureAccountFormId = '',
    featureMenuId = '',
  } = useFeaturesContext();
  const { hasNetworkError } = useNetworkContext();
  const { routesLoading } = usePageManagerContext();
  const { showOnboardingContent, firstAppLoadOnboarding } = useOnboardingContext();
  const { scrollRef, scrollTo } = useScrollContext();
  const [IOSTrackingStatus, setIOSTrackingStatus] = useState<null | string>(null);
  const { loading: isFeatureAccountLoading } = useFeatureAccountQuery({
    variables: { featureAccountId },
    skip: !featureAccountId || pathname !== routes.communication || pathname !== routes.orders,
  });
  const { featureNavigationLoading: isFeatureNavigationLoading } = useFeatureNavigation();
  const { loading: isTrendingContentLoading } = useTrendingData();
  const { loading: isMainMenuContentLoading } = useFeatureMenuQuery({
    variables: { featureMenuId },
    skip: !featureMenuId || pathname !== routes.menu,
  });

  const { loading: isSignUpContentLoading } = useFeatureAccountFormQuery({
    variables: { featureAccountFormId },
    skip: !featureAccountFormId || pathname !== routes.signUp || pathname !== routes.signIn,
    fetchPolicy: 'cache-and-network',
  });
  const topServiceModeFlag = useFlag(LaunchDarklyFlag.ENABLE_TOP_SERVICE_MODE);
  const bottomServiceModeFlag = useFlag(LaunchDarklyFlag.ENABLE_BOTTOM_SERVICE_MODE);
  const enableForcedNativeAppUpdate =
    useFlag(LaunchDarklyFlag.ENABLE_FORCED_NATIVE_APP_UPDATE) ?? ForceAppUpdateVariations.NONE;
  const enablePypestream = useFlag(LaunchDarklyFlag.ENABLE_PYPESTREAM);
  const enableTopServiceMode = topServiceModeFlag && !bottomServiceModeFlag;
  const enableBottomServiceMode = bottomServiceModeFlag && !topServiceModeFlag;
  const displayForceUpdateDialog = ForceAppUpdateVariations.NONE !== enableForcedNativeAppUpdate;
  const [ForceUpdateModal] = useDialogModal({
    Component: ForceUpdateDialog,
    init: displayForceUpdateDialog,
  });

  useEffect(() => {
    scrollTo({ y: 0, animated: false });
  }, [scrollTo, pathname]);

  async function trackingStatus() {
    const iosstatus = await getIosAppTrackingStatus();
    setIOSTrackingStatus(iosstatus);
  }
  useEffect(() => {
    trackingStatus();
  }, []);

  /* TODO: figure out how to get the loading status
   *       without double-triggering the Sanity call
   * CONTEXT:
   *  this code still fires off the Sanity query even
   *    though it is already fired from useFeatureHomePage hook
   *  setting skip: true means that loading always comes back false
   *  not passing anything means we still hit Sanity, but it returns
   *    an error that id is required
   *  💭 maybe moving feature home page data to context so that we
   *    have the data and loading state available multiple places?
   */
  const { featureHomePageLoading: isFeatureHomePageLoading } = useFeatureHomePage();
  const { loading: isStaticRoutesLoading } = useStaticPageRoutes();

  useDatadogRum();
  useForter();
  useLogPageView();
  useScrollTop();
  useSetUserLocale();
  useGeoData();
  const {
    setShowLanguageSelectorModal,
    setHasShownLocaleSelector,
    showLanguageSelectorModal,
    language,
  } = useLocale();

  const { formatMessage } = useIntl();
  const logger = useLogger();

  // NOTE: This should be a noop for all brands EXCEPT TH. We only need this code
  // for a short time in order to redirect US users stuck on the TH CA native experience.
  // SEE: https://rbictg.atlassian.net/browse/SHOP-2103
  useLoyaltyRedirect();

  // NOTE: This should be a noop for all brands EXCEPT PLK. We need to redirect
  // PLK users that are based in India to popeyes.in
  useRegionRedirect();

  // Prompts user for location services at start of session and selects nearest store
  const enableAutoSelectRestaurant = useFlag(LaunchDarklyFlag.ENABLE_AUTO_SELECT_RESTAURANT);
  const enableCookieBanner =
    useFlag(LaunchDarklyFlag.ENABLE_COOKIE_BANNER) && IOSTrackingStatus !== Status.DENIED;
  const enableOneTrustCookieBanner =
    useFlag(LaunchDarklyFlag.ENABLE_ONE_TRUST_COOKIE_CONSENT_BANNER) &&
    IOSTrackingStatus !== Status.DENIED;
  const geoLocationAvailable = navigator.geolocation;

  const forceBlockingUpdate = new URL(window.location.href).searchParams.get('forceBlockingUpdate');

  const {
    error: appflowError,
    extractingUpdate,
    needsBlockingUpdate,
    permissionToUpdate,
    progress,
  } = useAppflow({
    logger,
    forceBlockingUpdate: !!forceBlockingUpdate,
  });

  const { requestIosAttPermissions } = useIosAtt();
  useLogrocketTargetedRecording();

  const handleModalDismiss = () => {
    setHasShownLocaleSelector();
    setShowLanguageSelectorModal(false);
  };

  let content;

  // setup bot noindex nofollow meta tags to required routes
  // See hook and CBA-243 for more details
  const robotMetaTags = useRobotMetaTags(pathname);

  useEffectOnce(() => {
    const nextLocationQueryStringParam = new URL(window.location.href).searchParams.get(
      NEXT_URL_QUERY_PARAM
    );

    if (nextLocationQueryStringParam) {
      const decodedNexRoute = decodeURIComponent(nextLocationQueryStringParam);

      // Check for relative path but do not accept schemaless urls to avoid open redirect vulnerability
      if (/^\/[^/]/.test(decodedNexRoute)) {
        // React router will not let us call `navigate` on initial render
        setTimeout(() => {
          navigate(decodedNexRoute, { replace: true });
        }, 1);
      }
    }
  });

  useResetStoreResetCartResetServiceModeTimeout();

  useEffect(requestIosAttPermissions, []);

  if (needsBlockingUpdate && !hasNetworkError) {
    return (
      <ThemeProvider theme={theme}>
        <AppflowUpdateProgress
          extractingUpdate={extractingUpdate}
          hasError={!!appflowError}
          progress={progress}
        />
      </ThemeProvider>
    );
  }

  // Temporary solution, the goal is to make object for each pathname
  const getLoadingAriaLabel = (path: string) => {
    if (path === routes.menu) {
      return formatMessage({ id: 'loadingRestaurantMenu' });
    }
    return formatMessage({ id: 'loading' });
  };

  const blockingLoadingStates = (path: string) => {
    // The ultimate goal here is to preload what the critical pages will use,
    // without holding up the user waiting for content that isn't needed on the page.
    // As we extend this pattern, more routes should be added to this switch statement.
    // This should become the only "full page spinner" in the app, so we know it when we see it.
    switch (path) {
      case routes.base:
        return [featureIdsLoading, isFeatureHomePageLoading];
      case routes.offers:
        return [featureIdsLoading];
      case routes.about:
        return [featureIdsLoading, isFeatureNavigationLoading];
      case routes.orders:
      case routes.communication:
        return [featureIdsLoading, isFeatureAccountLoading];
      case routes.menu:
        return [featureIdsLoading, isMainMenuContentLoading];
      case routes.signUp:
        return [featureIdsLoading, isSignUpContentLoading];
      case routes.trending:
        return [featureIdsLoading, isTrendingContentLoading];
      case routes.storeOrderConfirmation:
        return [featureIdsLoading, routesLoading];
      default:
        return [routesLoading, isStaticRoutesLoading];
    }
  };

  if (blockingLoadingStates(pathname).some(queryIsLoading => !!queryIsLoading)) {
    content = (
      <ContentLoadingContainer aria-label={getLoadingAriaLabel(pathname)}>
        <LoadingAnimation fillColor={Styles.color.primary} />
      </ContentLoadingContainer>
    );
  } else {
    content = (
      <LayoutContainerScrollView testID="main-layout" ref={scrollRef} scrollToOverflowEnabled>
        <main id="main">
          <Suspense fallback={<SetLoading />}>
            <Routes>
              {/* Full page routes */}
              <Route
                path={`${routes.cart}/*`}
                element={<Cart permissionToUpdate={permissionToUpdate} />}
              />
              <Route path={routes.storeOrderConfirmation} element={<StoreOrderConfirmation />} />
              <Route
                path={`${routes.orderConfirmation}/:rbiOrderId`}
                element={<OrderConfirmation />}
              />
              <Route path={`${routes.storeLocator}/*`} element={<StoreLocator />} />
              <Route path={`${routes.languageSelector}`} element={<LanguageSelectorPage />} />
              <Route
                path={`${formatMessage({ id: 'routes.storeLocator' })}/*`}
                element={<StoreLocator />}
              />
              <Route path={routes.loading} element={<LoadingPage />} />
              {Object.entries(fullPageBrandRoutes).map(([path, Component]) => (
                <Route
                  path={formatMessage({ id: path })}
                  element={<Component key={path} />}
                  key={path}
                />
              ))}
              {/* Regular routes (with normal nav, header, footer, etc) */}
              <Route path="*" element={<ContentRouting />} />
            </Routes>
          </Suspense>
        </main>
      </LayoutContainerScrollView>
    );
  }

  // Loading animation shown when waiting to determine updated needed
  // prevents content flashing on mobile apps
  if (needsBlockingUpdate === null) {
    return (
      <ThemeProvider theme={theme}>
        <Helmet titleTemplate={titleTemplate} defaultTitle={fullBrandName()}>
          <html lang={language} />
        </Helmet>
        <ContentLoadingContainer aria-label={getLoadingAriaLabel(pathname)}>
          <LoadingAnimation fillColor={Styles.color.primary} />
        </ContentLoadingContainer>
      </ThemeProvider>
    );
  }

  const getCookieBanner = () => {
    if (enableCookieBanner) {
      return <DefaultCookieBannerContainer />;
    } else if (enableOneTrustCookieBanner) {
      return <OneTrustCookieBanner />;
    } else {
      return null;
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Helmet titleTemplate={titleTemplate} defaultTitle={fullBrandName()}>
        <html lang={language} />
      </Helmet>
      {showOnboardingContent && (
        <OnboardingContent featureOnboardingScreens={firstAppLoadOnboarding} />
      )}
      {robotMetaTags}

      <AppContainer>
        <AppHeader />
        {content}
        {enablePypestream && <PypestreamInterface />}
        <LoyaltyOptInModal />
        {showLanguageSelectorModal && (
          <LanguageSelectorModal regionFilter={regionFilter} onModalDismiss={handleModalDismiss} />
        )}
        {enableAutoSelectRestaurant && geoLocationAvailable && <AutoSelectRestaurant />}
        <WelcomeMessage />
        <ModalBeeper />
        <ModalLockerPickup />

        <View>
          <LayoutFabButton />
          <ToastContainer />
          {getCookieBanner()}
          {enableTopServiceMode && <TopServiceModeNotification />}

          <LayoutBottomContainer>
            {enableBottomServiceMode && <BottomServiceMode />}
            <MobileNavigation />
          </LayoutBottomContainer>
        </View>
        {displayForceUpdateDialog && (
          <ForceUpdateModal forceUpdatePriority={enableForcedNativeAppUpdate} />
        )}
      </AppContainer>
      <ErrorNetwork />
    </ThemeProvider>
  );
};

export default Layout;
