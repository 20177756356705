import React, { ReactNode, useCallback, useContext, useEffect, useRef, useState } from 'react';

import { SupportedLanguages, SupportedRegions } from '@rbi-ctg/frontend';
import { IStore } from '@rbi-ctg/store';
import { CartPaymentCardType, IUserGeoFragment } from 'generated/rbi-graphql';
import useReadyQueue from 'hooks/use-ready-queue';
import { loadRegion } from 'state/intl/load-region';
import { useLogger } from 'state/logger';
import { ServiceMode } from 'state/service-mode';
import { getName } from 'utils/attributes';
import { isProduction, sanityDataset } from 'utils/environment';
import LaunchDarklyHelper, {
  BooleanFlags,
  EnumFlagTypes,
  EnumFlags,
  FlagType,
  LDFlagSet,
  LDUser,
  LaunchDarklyFlag,
  LaunchDarklyFlagsObject,
  NumericFlags,
  StringFlags,
  UserAttributeUpdates,
  VariationFlags,
} from 'utils/launchdarkly';
import LogRocketHelper from 'utils/logrocket';
import noop from 'utils/noop';

import { getFeatureFlagOverridesFromQueryParameters } from './feature-flag-overrides';

export { LaunchDarklyFlag };

const LD_LOGIN_TIMEOUT_MS = 2000;

interface ILogin {
  cognitoId?: string;
  details: { name?: string; email: string };
}

export interface ILaunchDarklyCtx {
  attemptGetUpdatedLdFlag<F extends LaunchDarklyFlag>(
    flagKey: F,
    attributes: LDUser
  ): Promise<FlagType<F> | undefined>;
  attemptGetUpdatedLdFlag<F extends LaunchDarklyFlag>(
    flagKey: F,
    attributes: LDUser,
    defaultValue: FlagType<F>
  ): Promise<FlagType<F>>;
  flags: LaunchDarklyFlagsObject;
  flagOverrides: { [id: string]: boolean };
  ldUser: LDUser | null;
  /**
   * this allows us to determine whether we are receiving the correct value for
   * user-targeted flags over just the default set in LD
   */
  ldUserIsAuthenticated: boolean;
  login: (u: ILogin) => void;
  logout: () => void;
  updateCheckoutSelections: (paymentType?: CartPaymentCardType) => void;
  updateFulfillmentOperations: (operation: string) => void;
  updateUser: (u: UserAttributeUpdates) => void;
  updateUserAttributes: (u: UserAttributeUpdates) => void;
  updateUserLiveLocation: (userGeoData?: IUserGeoFragment) => void;
  updateUserLocale: (locale: { region: SupportedRegions; language: SupportedLanguages }) => void;
  updateUserDeviceId: (deviceId: string) => void;
  updateUserStore: (store: IStore | null) => void;
  updateServiceMode: (serviceMode: ServiceMode | null) => void;
}

export const LDContext = React.createContext<ILaunchDarklyCtx>({
  attemptGetUpdatedLdFlag: () => Promise.resolve(false as any),
  flags: {},
  flagOverrides: {},
  updateUserDeviceId: noop,
  ldUser: null,
  ldUserIsAuthenticated: false,
  login: noop,
  logout: noop,
  updateCheckoutSelections: noop,
  updateFulfillmentOperations: noop,
  updateServiceMode: noop,
  updateUser: noop,
  updateUserAttributes: noop,
  updateUserLiveLocation: noop,
  updateUserLocale: noop,
  updateUserStore: noop,
});

export const useLDContext = () => useContext(LDContext);

export function useFlag<T>(flag: VariationFlags): T | null | undefined;
export function useFlag<F extends EnumFlags>(flag: F): EnumFlagTypes<F>;
export function useFlag(flag: NumericFlags): FlagType<NumericFlags>;
export function useFlag(flag: StringFlags): FlagType<StringFlags>;
export function useFlag(flag: BooleanFlags): FlagType<BooleanFlags>;
export function useFlag(flag: LaunchDarklyFlag): FlagType<LaunchDarklyFlag> {
  const { flags, flagOverrides } = useLDContext();

  if (!isProduction && flagOverrides[flag] !== undefined) {
    return flagOverrides[flag];
  }

  // @todo update code to handle missing flags or update function to accept a fallback
  return flags && flags[flag]!;
}

export function useGatewayFlags() {
  const { flags } = useLDContext();

  const gatewayFlags = Object.entries(flags)
    .filter(([k]) => k.startsWith('enable-gateway-'))
    .sort((lhs, rhs) => lhs[0].localeCompare(rhs[0]))
    .map(([k, v]) => `${k}=${v}`)
    .join('&');

  return gatewayFlags;
}

// Launch Darkly enables setting up prerequisites for Flag values
// i.e When one flag is on, it can control the value of a different flag
// However, this can not be done for other flag values
// e.g if a flag is off, change the value of a different flag
// This function handles such cases
// Use this function only if LD can not handle the flag dependency needed
const checkForDependencies = (flags: LaunchDarklyFlagsObject) => {
  let cloned = { ...flags };
  // Add dependencies here:
  const dependencies = [
    {
      flag: LaunchDarklyFlag.ENABLE_STORE_SELECTION_2_0,
      check: () =>
        !flags?.[LaunchDarklyFlag.ENABLE_STATIC_MENU] ||
        flags?.[LaunchDarklyFlag.ENABLE_STORE_SELECTION_2_0],
    },
  ];
  dependencies.forEach(({ flag, check }) => {
    cloned = { ...cloned, [flag]: check() };
  });
  return cloned;
};

export function LDProvider(props: { children: ReactNode; ldFlags: LaunchDarklyFlagsObject }) {
  const [ldUser, setLdUser] = useState<LDUser | null>(null);
  const [flags, setFlags] = useState<LaunchDarklyFlagsObject>(checkForDependencies(props.ldFlags));
  const flagOverrides = useRef(getFeatureFlagOverridesFromQueryParameters());
  const { drainQueue, enqueueIfNotDrained } = useReadyQueue();
  const logger = useLogger();

  // this allows us to determine whether we are receiving the correct value for
  // user-targeted flags over just the default set in LD
  const ldUserIsAuthenticated = Boolean(ldUser && !ldUser.anonymous && ldUser.email);

  useEffect(() => {
    const ldClient = LaunchDarklyHelper.getInstance();

    if (!ldClient.launchDarkly) {
      // Launch Darkly client failed to initialize and we have rendered the app without it so do nothing
      return;
    }
    drainQueue();

    // runs if a change to flag happens while the user runs the app
    // WEB ONLY: runs if a user logs in
    // RN?: Not sure if this above WEB ONLY comment applies...
    const unsubscribeListener = ldClient.addChangeListener(newFlags => {
      const lrInstance = LogRocketHelper.getInstance().logrocket;
      const logRocketFlag = newFlags[LaunchDarklyFlag.ENABLE_LOGROCKET];
      const checkedFlags = checkForDependencies(newFlags);
      setFlags(state => ({
        ...state,
        ...checkedFlags,
      }));

      // If the logrocket flag is true and we haven't already initialized logrocket
      if (logRocketFlag && !lrInstance) {
        LogRocketHelper.init();
      }
    });

    return unsubscribeListener;
  }, [drainQueue]);

  const updateLDUser = async (changes: UserAttributeUpdates | null) => {
    const ldInstance = LaunchDarklyHelper.getInstance();
    let updatedFlags: LDFlagSet | undefined;
    try {
      const updatedUser = changes
        ? await ldInstance.updateCurrentUser(changes)
        : await ldInstance.clearCurrentUser();

      setLdUser(updatedUser?.userAttributes);

      updatedFlags = updatedUser?.newFlags;
    } catch (err) {
      // NOTE: This was crashing cypress tests because we block LD network requests
      logger.error(err);
    }

    // MOBILE ONLY:
    // We have to update the flags manually because ldClient's change event
    // watcher doesn't fire on login.
    if (updatedFlags) {
      const checkedFlags = checkForDependencies(updatedFlags);
      setFlags(state => ({
        ...state,
        ...checkedFlags,
      }));
    }

    return { updatedFlags };
  };

  const updateUser = enqueueIfNotDrained((changes: UserAttributeUpdates) => updateLDUser(changes));

  const updateUserAttributes = enqueueIfNotDrained(
    (user: { key: string; name: string; email: string }): void => {
      return updateUser({
        ...getName({ name: user.name }, { firstName: '', lastName: '' }),
        key: user.key,
        name: user.name,
        email: user.email,
      });
    }
  );

  const updateCheckoutSelections = useCallback(
    enqueueIfNotDrained((paymentType: CartPaymentCardType | undefined): void => {
      return updateUser({
        custom: {
          paymentType: paymentType ?? '',
        },
      });
    }),
    [enqueueIfNotDrained]
  );

  const updateFulfillmentOperations = useCallback(
    enqueueIfNotDrained((operation: string): void => {
      return updateUser({
        custom: {
          operation,
        },
      });
    }),
    [enqueueIfNotDrained]
  );

  const login = enqueueIfNotDrained(({ cognitoId, details: { name = '', email } }: ILogin) => {
    return updateUser({
      ...getName({ name }, { firstName: '', lastName: '' }),
      key: cognitoId,
      name,
      email,
      anonymous: false,
      custom: {
        sanityDataset: `${sanityDataset}_${loadRegion().toLowerCase()}`,
      },
    });
  });

  const logout = enqueueIfNotDrained(() => updateLDUser(null));

  const updateUserDeviceId = enqueueIfNotDrained(deviceId => {
    return updateUser({ custom: { device_id: deviceId } });
  });

  const updateUserLocale = enqueueIfNotDrained(
    (locale: { region: SupportedRegions; language: SupportedLanguages }) => {
      return updateUser({
        custom: {
          language: locale.language,
          sanityDataset: `${sanityDataset}_${loadRegion().toLowerCase()}`,
        },
        country: locale.region,
      });
    }
  );

  const updateUserStore = useCallback(
    enqueueIfNotDrained((store: IStore | null) => {
      return updateUser({
        custom: {
          storeNumber: store?.number ?? '',
          storeCity: store?.physicalAddress?.city ?? '',
          storeCountry: store?.physicalAddress?.country ?? '',
          storePostalCode: store?.physicalAddress?.postalCode ?? '',
          storeStateProvince: store?.physicalAddress?.stateProvince ?? '',
          storeStateProvinceShort: store?.physicalAddress?.stateProvinceShort ?? '',
          storeFranchiseGroupName: store?.franchiseGroupName ?? '',
          storePosVendor: store?.pos?.vendor ?? '',
          storePosVersion: store?.pos?.version ?? '',
          storeVatNumber: store?.vatNumber ?? '',
        },
      });
    }),
    [enqueueIfNotDrained]
  );

  const updateServiceMode = useCallback(
    enqueueIfNotDrained((serviceMode: ServiceMode | null) => {
      return updateUser({
        custom: {
          serviceMode: serviceMode ?? '',
        },
      });
    }),
    [enqueueIfNotDrained]
  );

  const updateUserLiveLocation = useCallback(
    enqueueIfNotDrained((userGeoData?: IUserGeoFragment) => {
      return updateUser({
        custom: {
          liveLocationCity: userGeoData?.city ?? '',
          liveLocationState: userGeoData?.state ?? '',
          liveLocationCountry: userGeoData?.country ?? '',
        },
      });
    }),
    [updateUser]
  );

  /**
   * Attempts to get the LD user's flag value
   *
   * If the request takes longer than one second, we resolve
   * to the value already set in state. This allows us to wait on
   * LD's identify request.
   *
   */
  async function attemptGetUpdatedLdFlag<F extends LaunchDarklyFlag>(
    flagKey: F,
    attributes: LDUser,
    defaultFlagValue: FlagType<F>
  ): Promise<FlagType<F>>;
  async function attemptGetUpdatedLdFlag<F extends LaunchDarklyFlag>(
    flagKey: F,
    attributes: LDUser
  ): Promise<FlagType<F> | undefined>;
  async function attemptGetUpdatedLdFlag<F extends LaunchDarklyFlag>(
    flagKey: F,
    attributes: LDUser,
    defaultFlagValue?: FlagType<F>
  ): Promise<FlagType<F> | undefined> {
    return new Promise<FlagType<F> | undefined>(res => {
      const resolveWithFlagValue = (flagValue?: LaunchDarklyFlagsObject[F]) =>
        res((flagValue ?? defaultFlagValue) as FlagType<F>);

      if (!LaunchDarklyHelper.getInstance().launchDarkly) {
        return resolveWithFlagValue(flags[flagKey]);
      }
      let timedOut = false;
      let finished = false;

      setTimeout(() => {
        if (finished) {
          return;
        }
        timedOut = true;
        return resolveWithFlagValue(flags[flagKey]);
      }, LD_LOGIN_TIMEOUT_MS);

      updateLDUser(attributes).then(({ updatedFlags }) => {
        if (timedOut || !updatedFlags) {
          return;
        }
        finished = true;
        return resolveWithFlagValue(updatedFlags[flagKey]);
      });
    }).catch(() => {
      return (flags[flagKey] ?? defaultFlagValue) as FlagType<F>;
    });
  }

  return (
    <LDContext.Provider
      value={{
        attemptGetUpdatedLdFlag,
        flags,
        flagOverrides: flagOverrides.current,
        ldUser,
        ldUserIsAuthenticated,
        login,
        logout,
        updateCheckoutSelections,
        updateFulfillmentOperations,
        updateServiceMode,
        updateUser,
        updateUserAttributes,
        updateUserLiveLocation,
        updateUserLocale,
        updateUserDeviceId,
        updateUserStore,
      }}
    >
      {props.children}
    </LDContext.Provider>
  );
}

export default LDContext.Consumer;
