import { useCallback, useState } from 'react';

import { IOffersFragment, LoyaltyOfferType } from 'generated/graphql-gateway';
import { useApolloClient } from 'state/graphql/hooks/use-sanity-query';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useLogger } from 'state/logger';
import { useSanityGqlEndpoint } from 'utils/network';

import {
  IPersonalizedData,
  fetchPersonalizedOfferData as fetchData,
} from './utils/personalized-offers';

export const usePersonalizedOffers: () => [
  (offers: readonly IOffersFragment[]) => Promise<IPersonalizedData | null>,
  { loading: boolean }
] = () => {
  const [loading, setLoading] = useState(false);

  const client = useApolloClient();
  const uri = useSanityGqlEndpoint();
  const logger = useLogger();
  const personalizedOffersTemplateEnabled = Boolean(
    useFlag(LaunchDarklyFlag.ENABLE_PERSONALIZED_OFFERS_TEMPLATE)
  );

  const fetchPersonalizedOfferData = useCallback(
    async (offers: IOffersFragment[]) => {
      let data = null;

      if (!personalizedOffersTemplateEnabled) {
        return data;
      }

      const configOffersIds: string[] = [];
      const templatesIds: string[] = [];

      offers.forEach(({ type, configId, templateId }) => {
        if (type === LoyaltyOfferType.PERSONALIZED && configId) {
          configOffersIds.push(configId);
          if (templateId) {
            templatesIds.push(templateId);
          }
        }
      });

      if (!configOffersIds.length && !templatesIds.length) {
        return data;
      }

      try {
        setLoading(true);
        data = await fetchData({ client, uri, templatesIds, configOffersIds });
      } catch (e) {
        logger.error({ error: e, message: 'Error fetching personalized offers data' });
      } finally {
        setLoading(false);
      }

      return data;
    },
    [setLoading, client, uri, personalizedOffersTemplateEnabled, logger]
  );

  return [fetchPersonalizedOfferData, { loading }];
};
