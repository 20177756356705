import styled, { css } from 'styled-components';

import { IValueProps } from './types';

const Value = styled.div<IValueProps>`
  min-width: 22px;
  text-align: center;
  font-size: 0.9375rem;
  align-self: center;
  ${Styles.desktop`
    font-size: 1.125rem;
  `}
  ${p =>
    p.width &&
    css`
      width: ${p.width};
    `}
`;

export default Value;
