import * as React from 'react';
import { useEffect } from 'react';

import { Box } from '@rbilabs/components-library';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import ActionButton, { ActionButtonSizes, ActionButtonVariants } from 'components/action-button';
import { AutoGeolocationModal } from 'components/geolocation-modal';
import { ConfirmRestaurant, ICloseIconProps } from 'components/toast/types';
import { useAutoSelectRestaurant } from 'hooks/use-auto-select-restaurant';
import useRoutes from 'hooks/use-routes';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useStoreContext } from 'state/store';
import { routes } from 'utils/routing';
import { toast } from 'utils/toast';

const createCloseIcon = (confirmRestaurant: ConfirmRestaurant) => ({
  closeToast,
}: ICloseIconProps) => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { getLocalizedRouteForPath } = useRoutes();
  const storeLocatorRoute = getLocalizedRouteForPath('store-locator');

  const handleChangeStore = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    navigate(storeLocatorRoute);
    confirmRestaurant(false);
    if (closeToast instanceof Function) {
      closeToast(e);
    }
  };

  return (
    <Box margin="0 1rem 0 0">
      <ActionButton
        variant={ActionButtonVariants.TEXT_ONLY}
        color="white"
        size={ActionButtonSizes.SMALL}
        onClick={handleChangeStore}
      >
        {formatMessage({ id: 'change' })}
      </ActionButton>
    </Box>
  );
};

const isInvalidRoute = (pathname: string) => {
  return (
    pathname.includes(routes.storeLocator) ||
    new Set([routes.confirmOtp, routes.signIn]).has(pathname)
  );
};

const AutoSelectRestaurant = () => {
  // The useAutoSelectRestaurant hook is housed here so we can conditionally call it with an LD flag at parent level
  const {
    confirmRestaurant,
    restaurantToConfirm,
    isManualRestaurantSelection,
  } = useAutoSelectRestaurant();
  const { noStoreSelected } = useStoreContext();
  const { formatMessage } = useIntl();
  const { pathname, state } = useLocation();
  const enableDeliveryOnly = useFlag(LaunchDarklyFlag.ENABLE_DELIVERY_ONLY);

  const invalidRoute = isInvalidRoute(pathname);
  const storeAddress = restaurantToConfirm?.physicalAddress?.address1;

  const hasSignedOut = (state as any)?.isSignOut;

  useEffect(() => {
    if (
      storeAddress &&
      noStoreSelected &&
      !isManualRestaurantSelection &&
      !invalidRoute &&
      !hasSignedOut &&
      !enableDeliveryOnly // This prevent to auto-select a restaurant when the flag is turned on
    ) {
      toast.info(formatMessage({ id: 'yourClosestStoreX' }, { storeAddress }), {
        closeIcon: createCloseIcon(confirmRestaurant),
      });
      confirmRestaurant(true);
    }
  }, [
    confirmRestaurant,
    enableDeliveryOnly,
    formatMessage,
    hasSignedOut,
    invalidRoute,
    isManualRestaurantSelection,
    noStoreSelected,
    storeAddress,
  ]);

  const showModal = !invalidRoute && !restaurantToConfirm && !hasSignedOut;

  return showModal ? <AutoGeolocationModal /> : null;
};

export default AutoSelectRestaurant;
