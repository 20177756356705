import { setContext } from 'apollo-link-context';
import { merge } from 'lodash-es';

export const withHeaders = (
  headers:
    | { [key: string]: string }
    | ((previousContext: Record<string, any>) => { [key: string]: string })
) =>
  setContext((_, previousContext) =>
    merge(
      { headers: typeof headers === 'function' ? headers(previousContext) : headers },
      previousContext
    )
  );
