import gql from 'graphql-tag';

import { Nullable } from '@rbi-ctg/frontend';
import { ISanityTextNode } from '@rbi-ctg/menu';
import { MenuObjectTypes } from 'enums/menu';
import {
  IConditionAllergen as IAutoGenConditionAllergen,
  IConditionItemOneOf as IAutoGenConditionItemOneOf,
  IConditionNutrition as IAutoGenConditionNutrition,
  IConditionParentCategory as IAutoGenConditionParentCategory,
} from 'generated/sanity-graphql';

import { FilterGroupFragment } from './filter-group';
import NutritionExplorerMenuFragment, { INutritionExplorerMenu } from './nutrition-explorer-menu';
import TastySwapsFragment, { ISanityTastySwaps } from './tasty-swaps';

export interface ICategoryWhitelistItem {
  _id: string;
  _type: MenuObjectTypes;
}

export type ICategoryWhitelist = ICategoryWhitelistItem[];

export default gql`
  fragment NutritionExplorerWidgetFragment on NutritionExplorerWidget {
    _key
    _type
    menu {
      ...NutritionExplorerMenuFragment
    }
    viewMenuButtonText {
      locale: _LANG_
    }
    moreOptionsButtonText {
      locale: _LANG_
    }
    madLibFilterGroup {
      ...FilterGroupFragment
    }
    modalFilterGroups {
      ...FilterGroupFragment
    }
    tastySwaps {
      ...TastySwapsFragment
    }
    categoryWhitelist {
      ... on Section {
        _id
        _type
      }
      ... on Picker {
        _id
        _type
      }
    }
  }
  ${NutritionExplorerMenuFragment}
  ${FilterGroupFragment}
  ${TastySwapsFragment}
`;

export enum NutritionIdentifier {
  Calories = 'calories',
  Fat = 'fat',
  SaturatedFat = 'saturatedFat',
  TransFat = 'transFat',
  Cholesterol = 'cholesterol',
  Sodium = 'sodium',
  Carbohydrates = 'carbohydrates',
  Fiber = 'fiber',
  Sugar = 'sugar',
  Proteins = 'proteins',
  Salt = 'salt',
  Weight = 'weight',
}

/**
 * Note: These are the values from Sanity that we want to expose
 * in the nutrition explorer. These do not constitute all possible
 * allergens.
 */
export enum AllergenIdentifier {
  Milk = 'milk',
  Soy = 'soy',
  Peanut = 'peanut',
  Gluten = 'gluten',
  Eggs = 'eggs',
  TreeNuts = 'treeNuts',
  Fish = 'fish',
  Wheat = 'wheat',
}

export enum ComparisonOperator {
  LessThan = 'lessThan',
  GreaterThan = 'greaterThan',
  EqualTo = 'equalTo',
}

export enum ConditionType {
  Allergen = 'conditionAllergen',
  Nutrition = 'conditionNutrition',
  ParentCategory = 'conditionParentCategory',
  ItemOneOf = 'conditionItemOneOf',
}

export type IConditionAllergen = IAutoGenConditionAllergen & {
  _type: ConditionType.Allergen;
};

export type IConditionNutrition = IAutoGenConditionNutrition & {
  _type: ConditionType.Nutrition;
};

export type IConditionParentCategory = IAutoGenConditionParentCategory & {
  _type: ConditionType.ParentCategory;
};

export type IConditionItemOneOf = IAutoGenConditionItemOneOf & {
  _type: ConditionType.ItemOneOf;
};

export type ICondition =
  | IConditionAllergen
  | IConditionNutrition
  | IConditionParentCategory
  | IConditionItemOneOf;

export interface ISanityFilter {
  _type: string;
  description: ISanityTextNode;
  conditions: ICondition[];
}

export interface ISanityFilterGroup {
  _type: string;
  description: ISanityTextNode;
  filters: ISanityFilter[];
}

// @todo - use auto gen types instead
export interface INutritionExplorerWidget {
  menu: INutritionExplorerMenu;
  categoryWhitelist: Nullable<ICategoryWhitelist>;
  tastySwaps: Nullable<ISanityTastySwaps>;
  viewMenuButtonText: Nullable<ISanityTextNode>;
  moreOptionsButtonText: Nullable<ISanityTextNode>;
  madLibFilterGroup: Nullable<ISanityFilterGroup>;
  modalFilterGroups: Nullable<ISanityFilterGroup[]>;
}
