import { setContext } from 'apollo-link-context';
import { merge } from 'lodash-es';

import { getCurrentSession } from 'remote/auth';

import { AUTHORIZED_KEY } from './auth-required-directive';

export const withAuthToken = setContext((_, previousContext) =>
  getCurrentSession().then(session => {
    if (session) {
      return merge(
        {
          headers: {
            authorization: `Bearer ${session.getIdToken().getJwtToken()}`,
          },
          [AUTHORIZED_KEY]: true,
        },
        previousContext
      );
    }

    return previousContext;
  })
);
