import styled from 'styled-components';

export const WordmarkSocialIconsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.125rem 0;
  width: 100%;

  ${Styles.desktop`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `}

  :first-child {
    padding-top: 0.75rem;
  }

  :last-child {
    padding-bottom: 0;
  }
`;
