import React, { FC, KeyboardEvent, MouseEvent, useCallback } from 'react';

import { useIntl } from 'react-intl';
import styled from 'styled-components';

import ActionButton, { ActionButtonVariants } from 'components/action-button';
import Dialog from 'components/dialog';
import useUserVerification from 'hooks/use-user-verification';
import { toast } from 'utils/toast';

const BodyContainer = styled.div`
  margin: 2rem auto 1rem auto;
  text-align: center;
  max-width: 20rem;
`;

const ActionsContainer = styled.div`
  margin: 1rem;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
`;

const CancelButton = styled(ActionButton)`
  margin-top: 1rem;
`;

interface IUserVerificationModalProps {
  onDismiss: (event?: MouseEvent | KeyboardEvent) => void;
}

const UserVerificationModal: FC<IUserVerificationModalProps> = ({ onDismiss }) => {
  const { formatMessage } = useIntl();
  const { sendVerificationEmail, loading } = useUserVerification();
  const onVerify = useCallback(
    async event => {
      event.preventDefault();
      await sendVerificationEmail();
      toast.success(formatMessage({ id: 'emailVerificationSent' }));
      onDismiss();
    },
    [formatMessage, onDismiss, sendVerificationEmail]
  );
  return (
    <Dialog
      onDismiss={onDismiss}
      modalAppearanceEventMessage="User Verification Modal"
      data-testid="user-verification-modal"
      heading={formatMessage({
        id: 'verifyYourEmail',
      })}
      bodyComponent={
        <BodyContainer>
          <div>
            {formatMessage({
              id: 'verifyYourEmailInstructions',
            })}
          </div>
        </BodyContainer>
      }
      actions={
        <ActionsContainer>
          <ActionButton
            isLoading={loading}
            fullWidth
            onClick={onVerify}
            data-testid="user-verification-modal-verify-button"
          >
            {formatMessage({
              id: 'verifyYourEmail',
            })}
          </ActionButton>
          <CancelButton
            disabled={loading}
            variant={ActionButtonVariants.OUTLINE}
            fullWidth
            onClick={onDismiss}
            data-testid="user-verification-modal-cancel-button"
          >
            {formatMessage({
              id: 'cancel',
            })}
          </CancelButton>
        </ActionsContainer>
      }
    />
  );
};

export default UserVerificationModal;
