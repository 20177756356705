import { ILoyaltyContext as ILoyaltyContextInterface } from './loyalty-context';
import { IThLoyaltyContext as IThLoyaltyContextInterface } from './th-loyalty-context';

export type IThLoyaltyContext = IThLoyaltyContextInterface;
export type ILoyaltyContext = ILoyaltyContextInterface;

export { LoyaltyContext, LoyaltyProvider, useLoyaltyContext } from './loyalty-context';

// To be deprecated and merged in LoyaltyContext once loyalty API migration is complete
export { ThLoyaltyContext, useThLoyaltyContext, ThLoyaltyProvider } from './th-loyalty-context';
