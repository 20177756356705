import React, { useState } from 'react';

import { useIntl } from 'react-intl';
import styled from 'styled-components';

import LanguageIcon from 'components/icons/language';
import LanguageSelectorModal from 'components/language-selector-modal';
import { regionFilter } from 'components/language-selector/regionFilter';
import TextButton from 'components/text-button';
import { useLocale } from 'state/intl';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { formatActiveLanguageForRegion } from 'utils/language/format-active-language-for-region';

export interface OptionalColorProps {
  color?: string;
}

const StyledTextButton = styled(TextButton)<OptionalColorProps>`
  display: flex;
  color: ${p => p.color};
  text-decoration: none;
  text-transform: uppercase;
  padding: 0;
  font-size: 0.9375rem;
  align-items: center;

  ${Styles.desktop`
    margin-left: auto;
    margin-right: 0.8rem;
  `}
`;

const StyledLanguageIcon = styled(LanguageIcon)`
  color: white;
  width: 1.2rem;
  margin-top: 0.1rem;
  margin-right: 0.1rem;
`;

const LanguageSelector: React.FC<OptionalColorProps> = ({ color = Styles.color.white }) => {
  const { formatMessage } = useIntl();
  const { language, region } = useLocale();
  const isLocalizationDisabled = useFlag(LaunchDarklyFlag.DISABLE_LOCALIZATION);
  const [showLanguageSelectorModal, setShowLanguageSelectorModal] = useState(false);

  const onModalDismiss = () => {
    setShowLanguageSelectorModal(false);
  };

  const onModalShow = () => {
    setShowLanguageSelectorModal(true);
  };

  return isLocalizationDisabled ? null : (
    <>
      <StyledTextButton
        aria-label={formatMessage({ id: 'languageAndRegionSelector' })}
        data-testid="language-selector-button"
        onClick={onModalShow}
        color={color}
      >
        <StyledLanguageIcon fill={color} aria-hidden="true" />
        <span lang={`${language}-${region}`} data-testid="active-language">
          {formatActiveLanguageForRegion(language, region)}
        </span>
      </StyledTextButton>
      {showLanguageSelectorModal && (
        <LanguageSelectorModal regionFilter={regionFilter} onModalDismiss={onModalDismiss} />
      )}
    </>
  );
};

export default LanguageSelector;
