import { useCallback, useEffect, useState } from 'react';

import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { omit, pick } from 'lodash-es';
import { useIntl } from 'react-intl';

import { IUserFormData } from 'components/user-info-form/types';
import {
  GetMeDocument,
  ICommunicationPreference,
  IDeliveryAddress,
  IFavoriteStore,
  IUserDetailsFragment,
  useGetMeQuery,
  useUpdateMeMutation,
} from 'generated/rbi-graphql';
import useEffectOnUpdates from 'hooks/use-effect-on-updates';
import useEffectOnce from 'hooks/use-effect-once';
import { ModalCb } from 'hooks/use-error-modal';
import { usePrevious } from 'hooks/use-previous';
import { checkForUnexpectedSignOut, getCurrentSession } from 'remote/auth';
import { updateUserAttributes as cognitoUpdateUserAttributes } from 'remote/auth/cognito';
import { LaunchDarklyFlag, useFlag, useLDContext } from 'state/launchdarkly';
import { useLoggerContext } from 'state/logger/context';
import { CustomEventNames, EventTypes, useMParticleContext } from 'state/mParticle';
import { useNetworkContext } from 'state/network';
import LocalStorage from 'utils/cognito/storage';
import { StorageKeys } from 'utils/local-storage';
import { toast } from 'utils/toast';

import { CommunicationPreferences, FavoriteStores } from '..';

import { UserDetails } from './types';
import { useThirdPartyAuthentication } from './use-third-party-authentication';

export * from './types';

export const NUM_RECENT_PURCHASED_ITEMS = 10;

export interface IUseCurrentUser {
  openErrorDialog: ModalCb;
  hasSignedIn: boolean;
  userSession: CognitoUserSession | null;
}

const configUserDetails = (details: IUserDetailsFragment) => ({
  dob: details.dob || '',
  email: details.email || '',
  emailVerified: details.emailVerified as boolean,
  name: details.name || '',
  phoneNumber: details.phoneNumber || '',
  promotionalEmails: details.promotionalEmails as boolean,
  isoCountryCode: details.isoCountryCode || '',
  zipcode: details.zipcode || '',
  defaultReloadAmt: details.defaultReloadAmt as number,
  defaultAccountIdentifier: details.defaultAccountIdentifier || '',
  defaultFdAccountId: details.defaultFdAccountId || '',
  defaultPaymentAccountId: details.defaultPaymentAccountId || '',
  defaultScanAndPayAccountIdentifier: details.defaultScanAndPayAccountIdentifier || '',
  autoReloadEnabled: details.autoReloadEnabled as boolean,
  autoReloadThreshold: details.autoReloadThreshold as number,
  loyaltyTier: details.loyaltyTier,
  communicationPreferences: (details.communicationPreferences as CommunicationPreferences) || null,
  favoriteStores: (details.favoriteStores as FavoriteStores) || null,
  deliveryAddresses: (details.deliveryAddresses as Array<IDeliveryAddress>) || null,
  rutrPassedSkillsTestTimestamp: details.rutrPassedSkillsTestTimestamp || '',
  rutrFailedSkillsTestTimestamp: details.rutrFailedSkillsTestTimestamp || '',
  showThLoyaltyOnboarding: details.showThLoyaltyOnboarding as boolean,
});

const getPreloadedUserDetails = () => LocalStorage.getItem(StorageKeys.USER);

export const useCurrentUser = ({ openErrorDialog, hasSignedIn, userSession }: IUseCurrentUser) => {
  const { formatMessage } = useIntl();
  const { updateUserAttributes: launchDarklyUpdateUserAttributes } = useLDContext();
  const { decorateLogger, logger } = useLoggerContext();
  const { updateUserAttributes: mParticleUpdateUserAttributes, logEvent } = useMParticleContext();
  const { hasNetworkError, setHasNotAuthenticatedError } = useNetworkContext();
  const { logUserInToThirdPartyServices } = useThirdPartyAuthentication();
  const [currentUserSession, setCurrentUserSession] = useState<CognitoUserSession | null>(
    userSession
  );
  const shouldUniqueByModifiers = useFlag(LaunchDarklyFlag.ENABLE_RECENT_ITEMS_WITH_MODIFIERS);
  const getMeVariables = {
    numUniquePurchasedItems: NUM_RECENT_PURCHASED_ITEMS,
    customInput: { shouldUniqueByModifiers },
  };
  const { data: userData, loading, refetch, called } = useGetMeQuery({
    skip: !currentUserSession,
    variables: getMeVariables,
    // Apollo client loading state gets stuck: https://github.com/apollographql/react-apollo/issues/3425
    // Temporary fix while we wait for a stable 3.0.0 version
    fetchPolicy: 'cache-and-network',
  });

  // NOTE: The updateMeMutation will attempt to refetch and update userData from useGetMeQuery
  // The refetchQueries query needs to match the useGetMeQuery exactly, including the variables
  const [updateMeMutation, { loading: useUpdateMeMutationLoading }] = useUpdateMeMutation({
    refetchQueries: [{ query: GetMeDocument, variables: getMeVariables }],
    awaitRefetchQueries: true,
  });

  const prevUserData = usePrevious(userData);

  const userInSession = getPreloadedUserDetails();
  const user = currentUserSession ? userData?.me ?? userInSession : null;
  const cognitoId = user?.cognitoId;

  const setCurrentUser = useCallback(
    (session: null | CognitoUserSession, numUniquePurchasedItems?: number) => {
      if (session && called) {
        const refetchVariables = numUniquePurchasedItems
          ? { numUniquePurchasedItems, customInput: { shouldUniqueByModifiers } }
          : undefined;
        refetch(refetchVariables);
      }
      setCurrentUserSession(session);
    },
    [called, shouldUniqueByModifiers, refetch]
  );

  const refreshCurrentUser = useCallback(
    async (numUniquePurchasedItems?: number) => {
      try {
        setCurrentUser(currentUserSession, numUniquePurchasedItems);
      } catch (error) {
        logger.error({ error, message: 'An error occurred while refreshing the current user.' });
        openErrorDialog({
          error,
          message: formatMessage({ id: 'authRetryError' }),
          modalAppearanceEventMessage: 'Error: Setting Current User Error',
        });
      }
    },
    [currentUserSession, formatMessage, logger, openErrorDialog, setCurrentUser]
  );

  const refreshCurrentUserWithNewSession = useCallback(
    async (numUniquePurchasedItems?: number) => {
      try {
        const session = await getCurrentSession();
        setCurrentUserSession(session);
        setCurrentUser(currentUserSession, numUniquePurchasedItems);
      } catch (error) {
        logger.error({
          error,
          message: 'An error occurred while refreshing the current user with new session.',
        });
        openErrorDialog({
          error,
          message: formatMessage({ id: 'authRetryError' }),
          modalAppearanceEventMessage: 'Error: Setting Current User Error With New Session',
        });
      }
    },
    [currentUserSession, formatMessage, logger, openErrorDialog, setCurrentUser]
  );

  const updateMParticleAttributes = useCallback(
    (updatedAttributes: IUserFormData | UserDetails['details']) => {
      if (!user) {
        return;
      }

      const userAttributes = {
        customerid: user.thLegacyCognitoId ? `us-east-1:${user.thLegacyCognitoId}` : user.cognitoId,
        rbiCognitoId: user.cognitoId,
        ...user.details,
        ...updatedAttributes,
      };

      mParticleUpdateUserAttributes(userAttributes);
    },
    [mParticleUpdateUserAttributes, user]
  );

  const updateUserInfo = useCallback(
    async (form: IUserFormData, shouldMuteUserInfoErrors = false) => {
      try {
        // QUESTION - why do we await the current session here if we don't do anything with it?
        await getCurrentSession();
        cognitoUpdateUserAttributes(pick(form, 'phoneNumber', 'dob', 'name'));
        updateMParticleAttributes(form);
        launchDarklyUpdateUserAttributes({
          key: user.cognitoId,
          ...form,
        });

        const updateMeParams = omit(
          form,
          'agreesToTermsOfService',
          'defaultCheckoutPaymentMethodId',
          'defaultReloadPaymentMethodId',
          'email',
          'emailVerified',
          'deliveryAddresses'
        );

        const input = {
          ...updateMeParams,
          defaultAccountIdentifier: form.defaultCheckoutPaymentMethodId,
          defaultPaymentAccountId: form.defaultReloadPaymentMethodId,
        };

        await updateMeMutation({ variables: { input } });
      } catch (error) {
        if (!shouldMuteUserInfoErrors) {
          logger.error({ error, message: 'Error: Update User Info Failure' });
          toast.error(formatMessage({ id: 'updateInfoError' }));
        } else {
          logger.error({
            error,
            message: 'Error: Update User Info Failure - Muted',
          });
        }
      }
    },
    [
      updateMParticleAttributes,
      launchDarklyUpdateUserAttributes,
      user,
      updateMeMutation,
      logger,
      formatMessage,
    ]
  );

  const updateUserCommPrefs = useCallback(
    async (communicationPreferences: Array<ICommunicationPreference>) => {
      try {
        const promotionalEmailsInput =
          (communicationPreferences.length && {
            promotionalEmails: communicationPreferences.some(({ value }) => value === 'true'),
          }) ||
          {};
        const input = {
          communicationPreferences,
          ...promotionalEmailsInput,
        };
        const { data } = await updateMeMutation({ variables: { input } });

        if (!data) {
          return logger.error({ message: 'An error occurred updating communication preference' });
        }

        const details = configUserDetails(data.updateMe.details);
        updateMParticleAttributes(details);
      } catch (error) {
        logger.error({ error, message: 'Error: Update User Communication Preferences Failure' });
        toast.error(formatMessage({ id: 'updateInfoError' }));
      }
    },
    [updateMeMutation, updateMParticleAttributes, logger, formatMessage]
  );

  const updateUserFavStores = useCallback(
    async (favoriteStores: Array<IFavoriteStore>) => {
      try {
        const input = { favoriteStores };
        const { data } = await updateMeMutation({ variables: { input } });
        if (!data) {
          logger.error({ message: 'An error occurred updating favorite store' });
          toast.error(formatMessage({ id: 'updateInfoError' }));
        }
      } catch (error) {
        logger.error({ message: `An error occurred updating favorite store: ${error}` });
        toast.error(formatMessage({ id: 'updateInfoError' }));
      }
    },
    [formatMessage, logger, updateMeMutation]
  );

  const updateUserPhoneNumber = useCallback(
    async (phoneNumber: string) => {
      try {
        const input = { phoneNumber };
        cognitoUpdateUserAttributes({ phoneNumber });
        const { data } = await updateMeMutation({ variables: { input } });
        if (!data) {
          logger.error({ message: 'An error occurred updating phone number' });
          toast.error(formatMessage({ id: 'updateInfoError' }));
        }
        toast.success(formatMessage({ id: 'phoneNumberUpdatedSuccess' }));
      } catch (error) {
        logger.error({ message: `An error occurred updating phone number: ${error}` });
        toast.error(formatMessage({ id: 'updateInfoError' }));
      }
    },
    [formatMessage, logger, updateMeMutation]
  );

  const updateUserSkillsTestStatus = useCallback(
    async ({ hasPassed }: { hasPassed: boolean }) => {
      try {
        const timeStamp = (+new Date()).toString();
        const input = hasPassed
          ? { rutrPassedSkillsTestTimestamp: timeStamp }
          : { rutrFailedSkillsTestTimestamp: timeStamp };

        const { data } = await updateMeMutation({ variables: { input } });
        if (!data) {
          logger.error({ message: "An error occurred updating user's skills test status" });
          toast.error(formatMessage({ id: 'updateInfoError' }));
        }
      } catch (error) {
        logger.error({ message: `An error occurred updating user's skills test status: ${error}` });
        toast.error(formatMessage({ id: 'updateInfoError' }));
      }
    },
    [formatMessage, logger, updateMeMutation]
  );

  const checkIfUnexpectedSignOut = useCallback(async () => {
    const errorUnexpectedSignOut = await checkForUnexpectedSignOut();
    if (errorUnexpectedSignOut) {
      logger.error({
        message: 'Unexpected Sign out',
        error: errorUnexpectedSignOut,
      });
      // sent mParticle event telling that there has been an unexpected sign out
      logEvent(CustomEventNames.UNEXPECTED_SIGN_OUTS, EventTypes.Other, {
        cognitoId: prevUserData?.me?.cognitoId,
        error: errorUnexpectedSignOut,
      });
    }
  }, [logEvent, logger, prevUserData]);

  useEffect(() => {
    // decorate logger with cognito id
    decorateLogger({ userId: cognitoId });
  }, [decorateLogger, cognitoId]);

  useEffect(() => {
    if (!user) {
      checkIfUnexpectedSignOut();
    }
    LocalStorage.setItem(StorageKeys.USER, user);
  }, [checkIfUnexpectedSignOut, prevUserData, user]);

  useEffectOnce(() => {
    const refreshUserIfHasSignedIn = async () => {
      if (!hasSignedIn) {
        setHasNotAuthenticatedError(true);
      }
    };
    refreshUserIfHasSignedIn();
  });

  useEffectOnUpdates(() => {
    // any update fetch + set current user
    if (!hasNetworkError) {
      refreshCurrentUser();
    }
  }, [hasNetworkError]);

  // when user data populates for the first time, it means the user got signed in, therefore we should sign them into all third party services as well
  useEffect(() => {
    if (userData && !prevUserData) {
      logUserInToThirdPartyServices((userData.me as unknown) as UserDetails);
    }
  }, [userData, prevUserData, logUserInToThirdPartyServices]);

  return {
    refreshCurrentUser,
    refreshCurrentUserWithNewSession,
    setCurrentUser,
    updateUserCommPrefs,
    updateUserFavStores,
    updateUserPhoneNumber,
    updateUserSkillsTestStatus,
    updateUserInfo,
    user,
    currentUserSession,
    setCurrentUserSession,
    userLoading: loading,
    useUpdateMeMutationLoading,
  };
};
