import React, { useMemo } from 'react';

import { Box, RadioButton } from '@rbilabs/components-library';
import { useIntl } from 'react-intl';

import ActionButton from 'components/action-button';
import { SupportedReadableLanguages, SupportedReadableRegions, useLocale } from 'state/intl';
import { useFlag } from 'state/launchdarkly';
import { LaunchDarklyFlag } from 'utils/launchdarkly';
import { formatCurrencyCodeForRegion } from 'utils/payment/format-currency-code-for-region';

import { SHOW_LANGUAGE_BEFORE_COUNTRY } from '../show-language-before-country';

import {
  Disclaimer,
  LanguageSelectionCell,
  LanguageSelectionContainer,
  ModalAdditionalContentWrapper,
} from './styled';

interface ILanguageAndCountryLabelProps {
  labelHeader: boolean;
  showLanguageBeforeCountry: boolean;
  singleRegionLanguageSelector: boolean;
  readableLanguages: SupportedReadableLanguages;
  readableRegions: SupportedReadableRegions;
  mappedLanguage: string;
  mappedRegion: string;
}

const LabelContent: React.FC<ILanguageAndCountryLabelProps> = ({
  labelHeader,
  showLanguageBeforeCountry,
  singleRegionLanguageSelector,
  readableLanguages,
  readableRegions,
  mappedLanguage,
  mappedRegion,
}) => {
  const languageLabel = (
    <span lang={mappedLanguage.toLowerCase()}>{readableLanguages[mappedLanguage]}</span>
  );

  const regionLabel = !singleRegionLanguageSelector ? (
    <span data-testid={`dialog-code-${mappedLanguage.toLowerCase()}-${mappedRegion.toLowerCase()}`}>
      {formatCurrencyCodeForRegion(readableRegions[mappedRegion])}
    </span>
  ) : null;

  if (showLanguageBeforeCountry) {
    return labelHeader ? languageLabel : regionLabel;
  }
  return labelHeader ? regionLabel : languageLabel;
};

interface ILanguageModalContentsProps {
  regionFilter: (region: string) => boolean;
  disclaimer?: string;
  applyButtonText: string;
  setLanguage: () => void;
  handleSelection: (
    selectedLanguage: string,
    mappedRegion: string,
    isOneStep: boolean
  ) => () => void;
  regionKey: string;
  langKey: string;
  /**
   * Pass the id of the heading that describes the radio group.
   */
  ariaRadioGroupLabeledBy: string;
}

const LanguageSelectorModalContents: React.FC<ILanguageModalContentsProps> = ({
  regionFilter,
  regionKey,
  langKey,
  disclaimer,
  applyButtonText,
  setLanguage,
  handleSelection,
  ariaRadioGroupLabeledBy = '',
}) => {
  const { formatMessage } = useIntl();
  const { readableRegions, readableLanguages, supportedLocales, region } = useLocale();
  const singleRegionLanguageSelector = useFlag(LaunchDarklyFlag.SINGLE_REGION_LANGUAGE_SELECTOR);

  const locales = useMemo(
    () =>
      singleRegionLanguageSelector
        ? supportedLocales.filter(l => l.region === region)
        : supportedLocales,
    [region, singleRegionLanguageSelector, supportedLocales]
  );

  const regionLanguageSelections = locales
    .filter(({ region: filteredRegion }) => regionFilter(filteredRegion))
    .map(({ region: mappedRegion, language: mappedLanguage }) => {
      const commonLabelProps = {
        showLanguageBeforeCountry: SHOW_LANGUAGE_BEFORE_COUNTRY,
        singleRegionLanguageSelector,
        readableLanguages,
        readableRegions,
        mappedLanguage,
        mappedRegion,
      };
      return (
        <LanguageSelectionCell key={`dialog-button-${mappedLanguage}-${mappedRegion}`}>
          <RadioButton
            value={`${mappedLanguage.toLowerCase()}-${mappedRegion}`}
            data-testid={`dialog-button-${mappedLanguage.toLowerCase()}-${mappedRegion.toLowerCase()}`}
            onChange={handleSelection(mappedLanguage, mappedRegion, false)}
            checked={regionKey === mappedRegion && langKey === mappedLanguage}
            aria-label={`${formatMessage({ id: 'select' })} ${
              readableLanguages[mappedLanguage]
            } / ${mappedRegion}`}
            labelHeader={<LabelContent labelHeader {...commonLabelProps} />}
            label={<LabelContent labelHeader={false} {...commonLabelProps} />}
          />
        </LanguageSelectionCell>
      );
    });

  return (
    <>
      <LanguageSelectionContainer role="radiogroup" aria-labelledby={ariaRadioGroupLabeledBy}>
        {regionLanguageSelections}
      </LanguageSelectionContainer>
      {disclaimer && (
        <ModalAdditionalContentWrapper>
          <Disclaimer>{disclaimer}</Disclaimer>
        </ModalAdditionalContentWrapper>
      )}
      <Box center width="100%" margin="0 0 2rem">
        <ActionButton onClick={setLanguage} data-testid="action-button-apply-language-selection">
          {applyButtonText}
        </ActionButton>
      </Box>
    </>
  );
};

export default LanguageSelectorModalContents;
