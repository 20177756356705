import { useCallback, useEffect, useState } from 'react';

import { unionBy } from 'lodash-es';

import { ICartEntry, IServerOrder } from '@rbi-ctg/menu';
import { IUnavailableCartEntry } from 'generated/rbi-graphql';
import useInterval from 'hooks/use-interval';
import useIsMenuDataAvailableByDayPart from 'hooks/use-is-menu-data-available-by-day-part';
import { CartEntryType } from 'utils/cart';

import { useCartEntriesMenuObjects } from './use-cart-entries-menu-objects';

interface IUseUnavailableCartEntries {
  serverOrder: IServerOrder | null;
  cartEntries: ICartEntry[];
  interval?: number;
}

type EntryOrChildrenMatchId = (entry: ICartEntry) => boolean;

export const useGetUnavailableItemsFromMenu = () => {
  const isAvailableForActiveDayParts = useIsMenuDataAvailableByDayPart();
  const { getCartEntriesMenuObjects } = useCartEntriesMenuObjects();
  return useCallback(
    async (cartEntries: ICartEntry[], unavailableMenuCartEntries: ICartEntry[] = []) => {
      let newUnavailableItems = false;
      const unavailableMenuCartEntriesIds = new Set(
        unavailableMenuCartEntries.map(({ _id }) => _id)
      );

      // Transform offer cart entries to evaluate availability
      const cartEntriesAndOffers = cartEntries.map(cartEntry => ({
        ...cartEntry,
        type: cartEntry.type.replace(/^offer/i, '') as CartEntryType,
      }));

      const cartEntriesMenuObjects = await getCartEntriesMenuObjects(cartEntriesAndOffers);

      // Wait until menu object finished loading and check if data has been found
      // When a menuObject's data is null, that indicates it is unavailable at that time

      const items = cartEntriesMenuObjects.reduce((acc: Array<ICartEntry>, { data, entry }) => {
        if (!data || !isAvailableForActiveDayParts(data)) {
          acc.push(entry);
          // If the unavailable entry does not exist in unavailableMenuCartEntriesIds
          // then we need to update state
          newUnavailableItems =
            newUnavailableItems || !unavailableMenuCartEntriesIds.has(entry._id);
        }
        return acc;
      }, [] as Array<ICartEntry>);

      return {
        items,
        newUnavailableItems,
      };
    },
    [getCartEntriesMenuObjects, isAvailableForActiveDayParts]
  );
};

const cartEntryContainsUnavailableId = (id: string): EntryOrChildrenMatchId => {
  function entryOrChildrenMatchId(entry: ICartEntry) {
    return entry.cartId === id || (entry.children || []).some(entryOrChildrenMatchId);
  }
  return entryOrChildrenMatchId;
};

const getUnavailableCartEntries = ({
  cartEntries,
  serverOrder,
}: IUseUnavailableCartEntries): ICartEntry[] => {
  if (!serverOrder || !serverOrder.cart || !serverOrder.cart.unavailableCartEntries) {
    return [];
  }

  const serverOrderUnavailableCartEntries: IUnavailableCartEntry[] =
    serverOrder.cart.unavailableCartEntries;
  const unavailableLineIds = serverOrderUnavailableCartEntries.map(
    (item: IUnavailableCartEntry) => item.lineId
  );

  // get all unavailable cart entries from cartEntries
  // if a child of the combo/item is unavailable, return the most parent cart entry
  return unavailableLineIds.reduce((acc: ICartEntry[], lineId: string) => {
    const filterCartEntries = cartEntryContainsUnavailableId(lineId);

    return acc.concat(cartEntries.filter(filterCartEntries));
  }, []);
};

export const useUnavailableCartEntries = ({
  cartEntries,
  serverOrder,
  interval = 1000,
}: IUseUnavailableCartEntries) => {
  const getUnavailableItemsFromMenu = useGetUnavailableItemsFromMenu();
  const [unavailableMenuCartEntries, setUnavailableMenuCartEntries] = useState<ICartEntry[]>([]);
  const [unavailableCartEntries, setUnavailableCartEntries] = useState<ICartEntry[]>(
    getUnavailableCartEntries({ cartEntries, serverOrder })
  );

  // set unavailable cart entries based on serverOrder
  useEffect(() => {
    setUnavailableCartEntries(currentUnavailableEntries =>
      unionBy(
        getUnavailableCartEntries({ cartEntries, serverOrder }),
        currentUnavailableEntries,
        'cartId'
      )
    );
  }, [serverOrder, cartEntries, setUnavailableCartEntries]);

  // Check menu for unavailable cartEntries
  // This can occur when a cart entry is tied to a specific day part
  // e.g breakfast
  const intervalFunction = useCallback(async () => {
    // NOTE: cypress-v2 test suite requirement
    // Skip the interval for checking for unavailable items.
    // Avoids race condition in cart with recorded tests
    if (window.Cypress && window._skipUnavailableItemsIntervalCheck) {
      return;
    }
    const { items, newUnavailableItems } = await getUnavailableItemsFromMenu(
      cartEntries,
      unavailableMenuCartEntries
    );

    // If the items arrays length and unavailableMenuCartEntries length aren't equal
    // This indicates an unavailable item may have been removed
    const lengthChanged = items.length !== unavailableMenuCartEntries.length;
    if (newUnavailableItems || lengthChanged) {
      setUnavailableMenuCartEntries(items);
    }
  }, [cartEntries, getUnavailableItemsFromMenu, unavailableMenuCartEntries]);

  useInterval(intervalFunction, interval);

  return {
    unavailableCartEntries: unionBy(unavailableCartEntries, unavailableMenuCartEntries, '_id'),
    setUnavailableCartEntries,
  };
};
