import React from 'react';

import { isNil } from 'lodash-es';

import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';

import { ServiceModeAddress } from './service-mode-address';
import { SubheaderContainer } from './styled';
import { SubheaderLanguageSelector } from './subheader-language-selector';
import { IServiceModeAddressProps } from './types';

const SubHeader: React.FC<IServiceModeAddressProps> = ({
  serviceMode,
  userAddress,
  cateringPickupDateTime,
}) => {
  const hideServiceModeFlag = useFlag(LaunchDarklyFlag.HIDE_SERVICE_MODE);
  const hideServiceMode = isNil(hideServiceModeFlag) ? true : hideServiceModeFlag;

  const enableBottomServiceFlag = useFlag(LaunchDarklyFlag.ENABLE_BOTTOM_SERVICE_MODE);
  const enableBottomService = isNil(enableBottomServiceFlag) ? false : enableBottomServiceFlag;

  const enableTopServiceModeFlag = useFlag(LaunchDarklyFlag.ENABLE_TOP_SERVICE_MODE);
  const enableTopServiceMode = isNil(enableTopServiceModeFlag) ? false : enableTopServiceModeFlag;

  const disableLocalizationFlag = useFlag(LaunchDarklyFlag.DISABLE_LOCALIZATION);
  const disableLocalization = isNil(disableLocalizationFlag) ? true : disableLocalizationFlag;

  const isEmpty = hideServiceMode && disableLocalization;

  if (enableBottomService || enableTopServiceMode || isEmpty) {
    return null;
  }

  return (
    <SubheaderContainer data-testid="app-subheader-container">
      {!hideServiceMode && (
        <ServiceModeAddress
          serviceMode={serviceMode}
          userAddress={userAddress}
          cateringPickupDateTime={cateringPickupDateTime}
        />
      )}
      <SubheaderLanguageSelector />
    </SubheaderContainer>
  );
};

export default SubHeader;
