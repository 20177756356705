import styled from 'styled-components';

import UnstyledButton from 'components/unstyled-button';

export const CartItemButton = styled(UnstyledButton)`
  font-family: ${Styles.fontFamily.body};
  font-size: 1rem;
  color: ${Styles.color.primary};
  padding: 0.75rem;
  margin-left: -0.75rem;
  cursor: pointer;
`;
