import logger from 'utils/logger';

import { importMap } from './generated/language-to-translation-map';

export async function getMessagesForLanguage(language: string, region: string) {
  let regionLocale;
  let baseLocale;

  try {
    regionLocale = await importMap[`${language}_${region}`]();
  } catch (e) {
    logger.warn(e);
  }

  try {
    baseLocale = await importMap[language]();
  } catch (e) {
    logger.warn(e);
  }

  return { ...(baseLocale || {}), ...(regionLocale || {}) };
}
