/**
 * AUTOGENERATED - DO NOT EDIT DIRECTLY
 *
 * Please see "scripts/regionalization/README.md" for how to modify
 */

export enum REGIONS {
  AQ = 'AQ',
  AT = 'AT',
  CA = 'CA',
  CH = 'CH',
  CZ = 'CZ',
  DE = 'DE',
  ES = 'ES',
  FR = 'FR',
  GB = 'GB',
  KR = 'KR',
  NL = 'NL',
  NZ = 'NZ',
  PL = 'PL',
  PT = 'PT',
  RO = 'RO',
  US = 'US',
  ZA = 'ZA',
}

export enum LANGUAGES {
  ca = 'ca',
  cs = 'cs',
  de = 'de',
  en = 'en',
  es = 'es',
  eu = 'eu',
  fr = 'fr',
  gl = 'gl',
  it = 'it',
  ko = 'ko',
  nl = 'nl',
  pl = 'pl',
  pt = 'pt',
  ro = 'ro',
}

export enum LOCALES {
  ca_es = 'ca-ES',
  cs_cz = 'cs-CZ',
  de_at = 'de-AT',
  de_ch = 'de-CH',
  de_de = 'de-DE',
  en_aq = 'en-AQ',
  en_at = 'en-AT',
  en_ca = 'en-CA',
  en_ch = 'en-CH',
  en_cz = 'en-CZ',
  en_de = 'en-DE',
  en_es = 'en-ES',
  en_gb = 'en-GB',
  en_kr = 'en-KR',
  en_nl = 'en-NL',
  en_nz = 'en-NZ',
  en_pt = 'en-PT',
  en_pl = 'en-PL',
  en_ro = 'en-RO',
  en_us = 'en-US',
  en_za = 'en-ZA',
  es_es = 'es-ES',
  eu_es = 'eu-ES',
  fr_ca = 'fr-CA',
  fr_ch = 'fr-CH',
  fr_fr = 'fr-FR',
  gl_es = 'gl-ES',
  it_ch = 'it-CH',
  ko_kr = 'ko-KR',
  nl_nl = 'nl-NL',
  pl_pl = 'pl-PL',
  pt_pt = 'pt-PT',
  ro_ro = 'ro-RO',
}

export enum DOMAINS {
  aq = '.aq',
  at = '.at',
  ca = '.ca',
  ch = '.ch',
  cz = '.cz',
  de = '.de',
  es = '.es',
  fr = '.fr',
  uk = '.co.uk',
  kr = '.kr',
  nl = '.nl',
  nz = '.co.nz',
  pl = '.pl',
  pt = '.pt',
  ro = '.ro',
  com = '.com',
  za = '.co.za',
  io = '.io',
}

export enum CURRENCY_CODE_FORMAT {
  ca_es = 'EUR',
  cs_cz = 'CZK',
  de_at = 'EUR',
  de_ch = 'CHF',
  de_de = 'EUR',
  en_aq = 'USD',
  en_at = 'EUR',
  en_ca = 'CAD',
  en_ch = 'CHF',
  en_cz = 'CZK',
  en_de = 'EUR',
  en_es = 'EUR',
  en_gb = 'GBP',
  en_nl = 'EUR',
  en_nz = 'NZD',
  en_pl = 'PLN',
  en_ro = 'RON',
  en_us = 'USD',
  en_kr = 'KRW',
  en_pt = 'EUR',
  en_za = 'ZAR',
  es_es = 'EUR',
  eu_es = 'EUR',
  fr_ca = 'CAD',
  fr_ch = 'CHF',
  fr_fr = 'EUR',
  gl_es = 'EUR',
  it_ch = 'CHF',
  ko_kr = 'KRW',
  nl_nl = 'EUR',
  pl_pl = 'PLN',
  pt_pt = 'EUR',
  ro_ro = 'RON',
}

export enum MONEY_FORMAT {
  ca_es = '€EU',
  cs_cz = 'CZK',
  de_at = '€EU',
  de_ch = 'CHF',
  de_de = '€EU',
  en_aq = '$US',
  en_at = '€EU',
  en_ca = '$CA',
  en_ch = 'CHF',
  en_cz = 'CZK',
  en_de = '€EU',
  en_es = '€EU',
  en_gb = 'GB£',
  en_nl = '€EU',
  en_nz = 'NZ$',
  en_pl = 'PLN',
  en_ro = 'RON',
  en_us = '$US',
  en_kr = 'KRW',
  en_pt = '€EU',
  en_za = 'ZAR',
  es_es = '€EU',
  eu_es = '€EU',
  fr_ca = 'CA$',
  fr_ch = 'CHF',
  fr_fr = '€EU',
  gl_es = '€EU',
  it_ch = 'CHF',
  ko_kr = 'KRW',
  nl_nl = '€EU',
  pl_pl = 'PLN',
  pt_pt = '€EU',
  ro_ro = 'RON',
}

export const CURRENCY_PREFIX = {
  [LOCALES.ca_es]: '€',
  [LOCALES.cs_cz]: 'Kč',
  [LOCALES.de_at]: '€',
  [LOCALES.de_ch]: 'CHF',
  [LOCALES.de_de]: '€',
  [LOCALES.en_aq]: '$',
  [LOCALES.en_at]: '€',
  [LOCALES.en_ca]: '$',
  [LOCALES.en_ch]: 'CHF',
  [LOCALES.en_cz]: 'Kč',
  [LOCALES.en_de]: '€',
  [LOCALES.en_es]: '€',
  [LOCALES.en_gb]: '£',
  [LOCALES.en_nl]: '€',
  [LOCALES.en_nz]: 'NZD',
  [LOCALES.en_pl]: 'zł',
  [LOCALES.en_ro]: 'lei',
  [LOCALES.en_us]: '$',
  [LOCALES.en_kr]: 'KRW',
  [LOCALES.en_pt]: '€',
  [LOCALES.en_za]: 'ZAR',
  [LOCALES.es_es]: '€',
  [LOCALES.eu_es]: '€',
  [LOCALES.fr_ca]: '$',
  [LOCALES.fr_ch]: 'CHF',
  [LOCALES.fr_fr]: '€',
  [LOCALES.gl_es]: '€',
  [LOCALES.it_ch]: 'CHF',
  [LOCALES.ko_kr]: 'KRW',
  [LOCALES.nl_nl]: '€',
  [LOCALES.pl_pl]: 'zł',
  [LOCALES.pt_pt]: '€',
  [LOCALES.ro_ro]: 'lei',
};

export type LocaleData = {
  code: LOCALES;
  region: REGIONS;
  language: LANGUAGES;
  moneyFormat?: MONEY_FORMAT;
  currencyCodeFormat?: CURRENCY_CODE_FORMAT;
};

export type SupportedReadableRegions = {
  AQ: string;
  AT: string;
  CA: string;
  CH: string;
  CZ: string;
  DE: string;
  ES: string;
  FR: string;
  GB: string;
  KR: string;
  NL: string;
  NZ: string;
  PL: string;
  PT: string;
  RO: string;
  US: string;
  ZA: string;
};

export type SupportedReadableLanguages = {
  br?: string;
  ca?: string;
  co?: string;
  cs?: string;
  de?: string;
  en?: string;
  es?: string;
  eu?: string;
  fr?: string;
  frp?: string;
  gl?: string;
  it?: string;
  ko?: string;
  mwl?: string;
  nl?: string;
  oc?: string;
  pl?: string;
  pt?: string;
  ro?: string;
};
