import gql from 'graphql-tag';

import NutritionFragment from './nutrition';

export default gql`
  fragment ItemOptionFragment on ItemOption {
    name {
      locale: _LANG_
    }
    displayGroup {
      name {
        locale: _LANG_
      }
    }
    componentStyle
    upsellModifier
    allowMultipleSelections
    injectDefaultSelection
    displayModifierMultiplierName
    minAmount
    maxAmount
    _key
    type: _type
    options {
      default
      _key
      type: _type
      name {
        locale: _LANG_
      }
      vendorConfigs {
        ...VendorConfigsFragment
      }
      modifierMultiplier {
        multiplier
        prefix {
          locale: _LANG_
        }
        vendorConfigs {
          ...VendorConfigsFragment
        }
        modifier {
          name {
            locale: _LANG_
          }
          image {
            ...MenuImageFragment
          }
          imageDescription {
            locale: _LANG_
          }
          vendorConfigs {
            ...VendorConfigsFragment
          }
        }
      }
      nutrition {
        ...NutritionFragment
      }
    }
  }

  ${NutritionFragment}
`;
