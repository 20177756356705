import React, { createContext, useContext } from 'react';

import { IBaseProps } from '@rbi-ctg/frontend';

import useBranch, { IBranchContext } from './hooks/use-branch';

export const BranchContext = createContext<IBranchContext>({} as IBranchContext);

export const useBranchContext = () => useContext(BranchContext);

export const BranchProvider = ({ children }: IBaseProps) => {
  const ctxValue = useBranch();

  return <BranchContext.Provider value={ctxValue}>{children}</BranchContext.Provider>;
};
