import * as React from 'react';
import { FC } from 'react';

import { capitalize } from 'lodash-es';
import { useIntl } from 'react-intl';

import LoyaltyPointsIcon from 'components/icons/loyalty-points-icon';
import { usePrevious } from 'hooks/use-previous';
import { selectors, useAppSelector } from 'state/global-state';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useIsLoyaltyEnabled } from 'state/loyalty/hooks/use-is-loyalty-enabled';
import { getItemLoyaltyRewardDiscount } from 'utils/cart/helper';
import { delayUnmountHOC } from 'utils/delay-unmount-hoc';
import priceForCartEntry from 'utils/menu/price-for-cart-entry';

import { IPrice } from '../types';

import {
  CartItemPrice,
  CurrencyContainer,
  FreeMessageContainer,
  StrikeThrough,
  StyledCurrency,
  StyledDiscountPrice,
} from './styled';

const DelayedUnmountPrice = delayUnmountHOC(StyledDiscountPrice);

const PriceFree = () => {
  const { formatMessage } = useIntl();

  return (
    <FreeMessageContainer>
      <LoyaltyPointsIcon />
      {capitalize(formatMessage({ id: 'free' }))}
    </FreeMessageContainer>
  );
};

const RewardDiscountPrice = ({
  rewardApplied,
  showPriceInsteadFreeInCart,
  discountPrice,
}: {
  rewardApplied: boolean;
  showPriceInsteadFreeInCart: boolean;
  discountPrice: number;
}) => {
  const prevDiscountPrice = usePrevious(discountPrice);
  const discountDisplay = rewardApplied ? discountPrice : prevDiscountPrice;

  if (showPriceInsteadFreeInCart) {
    return null;
  }

  if (rewardApplied && discountPrice === 0) {
    return <PriceFree />;
  }

  return <DelayedUnmountPrice delayTime={300} isVisible={rewardApplied} amount={discountDisplay} />;
};

const Price: FC<IPrice> = ({ item, isOffer, className, rewardApplied }) => {
  const loyaltyEnabled = useIsLoyaltyEnabled();
  const showPriceInsteadFreeInCart = useFlag(LaunchDarklyFlag.SHOW_PRICE_INSTEAD_FREE_IN_CART);
  const appliedLoyaltyRewards = useAppSelector(selectors.loyalty.selectAppliedLoyaltyRewards);
  const price = priceForCartEntry(item);
  const isRewardApplied = Boolean(rewardApplied && !isOffer);
  const showStrike = isRewardApplied && !showPriceInsteadFreeInCart;

  const discountPrice =
    price - getItemLoyaltyRewardDiscount({ cartEntry: item, appliedLoyaltyRewards });

  return (
    <CartItemPrice className={className} isOffer={isOffer}>
      <CurrencyContainer isRewardApplied={isRewardApplied}>
        {showStrike && <StrikeThrough />}
        <StyledCurrency
          amount={showPriceInsteadFreeInCart || discountPrice === 0 ? discountPrice : price}
        />
      </CurrencyContainer>
      {loyaltyEnabled && (
        <RewardDiscountPrice
          discountPrice={discountPrice}
          rewardApplied={isRewardApplied}
          showPriceInsteadFreeInCart={showPriceInsteadFreeInCart}
        />
      )}
    </CartItemPrice>
  );
};

export default Price;
