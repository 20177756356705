import { HandlerType, StatusType, datadogLogs } from '@datadog/browser-logs';

import { Loggable } from '@rbi-ctg/frontend';
import LocalStorage from 'utils/cognito/storage';
import { StorageKeys } from 'utils/local-storage';

import {
  RBIEnv,
  appVersionCode,
  brand,
  checkRbiEnv,
  env,
  getConfigValue,
  isLocalDev,
  platform,
} from '../environment';

import { makeBeforeSendCallback } from './make-before-send-callback';

export { StatusType } from '@datadog/browser-logs';

interface Context {
  [x: string]: Loggable | Loggable[];
}

interface DataDogLogger {
  context?: Context;
  message: string | Error;
  status: StatusType;
}

export function init(configuredSampleRate?: number): void {
  let handler: HandlerType = HandlerType.http;

  if (checkRbiEnv(RBIEnv.TEST)) {
    handler = HandlerType.silent;
  }

  const defaultRate = isLocalDev ? 100 : 1;
  const sampleRate = typeof configuredSampleRate === 'number' ? configuredSampleRate : defaultRate;
  const datacenter = getConfigValue('datadog').logs.dataCenter ?? 'us';
  const clientToken = getConfigValue('datadog').logs.clientToken;

  datadogLogs.init({
    clientToken,
    datacenter,
    forwardErrorsToLogs: true,
    env: env(),
    service: `${brand()}_${platform()}`,
    version: appVersionCode(),
    beforeSend: makeBeforeSendCallback(sampleRate),
  });

  datadogLogs.logger.setHandler(handler);
}

const UNKNOWN_ERROR_MESSAGE: string = 'Unknown application error. Unable to parse error message.';

export function dataDogLogger({ message, context = {}, status }: DataDogLogger): void {
  const ctx = {
    appVersionCode: appVersionCode(),
    brand: brand(),
    platform: platform(),
    stage: env(),
    userId: LocalStorage?.getItem(StorageKeys.USER)?.cognitoId,
    ...context,
  };

  const strMessage: string =
    typeof message === 'string' ? message : message?.message || UNKNOWN_ERROR_MESSAGE;

  datadogLogs.logger.log(strMessage, ctx, status);
}

export function addContext(key: string, value: string) {
  datadogLogs.logger.addContext(key, value);
}
