import { LOCALES } from './types';

export const SORTED_PROD_SUPPORTED_LOCALES = [
  LOCALES.ca_es,
  LOCALES.cs_cz,
  LOCALES.de_at,
  LOCALES.de_ch,
  LOCALES.de_de,
  LOCALES.en_aq,
  LOCALES.en_at,
  LOCALES.en_ca,
  LOCALES.en_ch,
  LOCALES.en_cz,
  LOCALES.en_es,
  LOCALES.en_gb,
  LOCALES.en_nl,
  LOCALES.en_nz,
  LOCALES.en_pl,
  LOCALES.en_pt,
  LOCALES.en_ro,
  LOCALES.en_us,
  LOCALES.en_za,
  LOCALES.es_es,
  LOCALES.eu_es,
  LOCALES.fr_ca,
  LOCALES.fr_ch,
  LOCALES.fr_fr,
  LOCALES.gl_es,
  LOCALES.it_ch,
  LOCALES.nl_nl,
  LOCALES.pl_pl,
  LOCALES.pt_pt,
  LOCALES.ro_ro,
];

export * from './generated/supported-locales.bk';
