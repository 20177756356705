import { ApolloLink, NextLink, Operation } from 'apollo-link';
import { merge } from 'lodash-es';

import { SupportedLanguages, SupportedRegions } from '@rbi-ctg/frontend';

export class I18nLink extends ApolloLink {
  public language?: SupportedLanguages;
  public region?: SupportedRegions;

  public request(operation: Operation, forward: NextLink) {
    const context = operation.getContext();

    const locale: { language?: SupportedLanguages; region?: SupportedRegions } = {};

    if (this.language) {
      locale.language = this.language;
    }

    if (this.region) {
      locale.region = this.region;
    }

    operation.setContext(merge(locale, context));

    return forward(operation);
  }

  public setLocale(language: SupportedLanguages, region: SupportedRegions) {
    this.language = language;
    this.region = region;
  }
}

/**
 * use a singleton instance to share i18n state
 */
export const withI18nContext = new I18nLink();
