/* globals google */
import { ILocation } from '@rbi-ctg/frontend';

import noop from '../noop';

import { GeolocationPermissionStates, PermissionState } from './types';
import { GET_CURRENT_POSITION_TIMEOUT, getGoogleApiSrcPath } from './utils';

export { parsePlaceAddress } from './parse-place-address';
export * from './types';

/**
 * ====================================================
 *
 *            GOOGLE GEOLOCATION LIBRARY
 *
 * ====================================================
 */
declare global {
  interface Window {
    google: typeof google;
  }
}

let scriptLoadingListeners: Array<(value?: unknown) => void> = [];
let scriptLoadingListenersReject: Array<(value?: unknown) => void> = [];

export function geolocationLibraryIsLoaded() {
  const loadedLibraryScriptElement = document.querySelector('script#google.loaded');
  return !!loadedLibraryScriptElement;
}

function clearListenerArrays() {
  scriptLoadingListeners = [];
  scriptLoadingListenersReject = [];
}

export function loadGeolocationLibrary(): Promise<void> {
  return new Promise((resolve, reject) => {
    // only load the library once
    // if it is already loaded, we can resolve immediately
    if (geolocationLibraryIsLoaded()) {
      resolve();
    }

    // if library is not yet loaded, push promise
    //  resolve / reject fns to arrays so we can
    //  notifiy caller when it is ready
    scriptLoadingListeners.push(resolve);
    scriptLoadingListenersReject.push(reject);

    // if this is the first request for the library
    // we won't have a script for it in the head yet
    if (!document.querySelector('script#google')) {
      const googleApiSrcPath = getGoogleApiSrcPath();
      // kick off async loading of library
      const script = document.createElement('script');
      script.src = googleApiSrcPath;
      script.id = 'google';
      script.async = true;
      // add script tag to document head
      document.head.appendChild(script);
      // create listeners to notify callers when
      //  it is ready (or if it failed to load)
      script.addEventListener('load', () => {
        // once loaded, add 'loaded' class to script element
        // to enable geolocationLibraryIsLoaded fn to be true
        const scriptElement = document.querySelector('script#google');
        if (scriptElement) {
          scriptElement.setAttribute('class', 'loaded');
        }
        scriptLoadingListeners.forEach(resolver => resolver());
        // once we've invoked all the resolvers clear out the listener arrays
        clearListenerArrays();
      });
      script.addEventListener('error', () => {
        // remove library script element from head
        // something failed so let's enable retry
        const scriptElement = document.querySelector('script#google');
        if (scriptElement?.parentElement) {
          scriptElement.parentElement.removeChild(scriptElement);
        }
        scriptLoadingListenersReject.forEach(rejecter => rejecter());
        // once we've invoked all the rejecters clear out the listener arrays
        clearListenerArrays();
      });
    }
  });
}

export function getUsersGeocodeInformation(userLocation: ILocation) {
  return loadGeolocationLibrary().then(() => {
    const geocoder = new google.maps.Geocoder();

    return new Promise<google.maps.GeocoderResult>((resolve, reject) => {
      geocoder.geocode({ location: userLocation }, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          const result = results[0]!;
          return resolve(result);
        }

        reject();
      });
    });
  });
}

/**
 * ====================================================
 *
 *                USER'S GEOLOCATION
 *
 * ====================================================
 */
export function getUsersCurrentPosition(): Promise<ILocation> {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      position =>
        resolve({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
          accuracy: position.coords.accuracy,
        }),
      positionError => reject(positionError),
      { timeout: GET_CURRENT_POSITION_TIMEOUT }
    );
  });
}

/**
 * ====================================================
 *
 *            USER'S GEOLOCATION PERMISSIONS
 *
 * ====================================================
 */

export const isKnownPermissionState = (permission: GeolocationPermissionStates) =>
  permission === GeolocationPermissionStates.GRANTED ||
  permission === GeolocationPermissionStates.DENIED;

export const determinePermissionsFromGetCurrentPositionErrorCode = (
  errorCode?: PermissionState
): GeolocationPermissionStates => {
  switch (errorCode) {
    // timeout defaults to denied because otherwise
    //  we can get caught in a "checking" loop
    case PermissionState.TIMEOUT:
    case PermissionState.DENIED:
    case PermissionState.UNAVAILABLE:
    default:
      return GeolocationPermissionStates.DENIED;
  }
};

/**
 * navigator.permissions has a status of 'Working Draft' on MDN:
 * https://developer.mozilla.org/en-US/docs/Web/API/Permissions/query
 *
 * It is still not available on many mobile browsers. This handles it's fallback.
 *
 * determines if user has GRANTED or DENIED location permissions
 *  by attempting to get user's coords which, if permissions are
 *  unknown, will trigger the browser/native geolocation prompt.
 * if permissions are granted, we should get back a location in
 *  which case we can confidently say we have permissions granted
 * if permissions are denied, we will get back an empty location
 *  in which case we can confidently say we have permissions denied
 * if an error is caught, howeveer, we may need to do more processing
 *  and perhaps reprompt (if we couldn't get coords due to timeout)
 * @NOTE: DOES NOT return user's coords, even if we get them. it might
 *  seem like a helpful way to combine steps, but this has shown to cause
 *  logistical issues and so we are doing our best here to decouple permission
 *  from actually getting location and don't want this function being used
 *  for purposes it is not meant to be used for.
 */
export function promptUserForLocationPermission() {
  return getUsersCurrentPosition()
    .then(location => {
      if (!location || !location.lat || !location.lng) {
        return GeolocationPermissionStates.DENIED;
      }
      return GeolocationPermissionStates.GRANTED;
    })
    .catch(positionError => {
      // TODO: add log noting that promptUserForLocationPermission failed
      if (positionError?.code) {
        return Promise.resolve(
          determinePermissionsFromGetCurrentPositionErrorCode(positionError.code)
        );
      }
      return Promise.resolve(GeolocationPermissionStates.DENIED);
    });
}

/**
 * checks if we already have permissions granted / denied by user
 *  but DOES NOT prompt them to share location if it is not GRANTED
 *  unless we catch an error beucase navigator.permissions is still
 *  not available on many mobile browsers so this soft check doesn't
 *  work
 *    @NOTE: navigator.permissions has a status of 'Working Draft' on MDN:
 *      https://developer.mozilla.org/en-US/docs/Web/API/Permissions/query
 *      It is still not available on many mobile browsers.
 *    TODO: as a fallback, this func can call promptUserForLocationPermission
 *          which WILL prompt the user if they haven't already been
 *          prompted. This has been the default behaviour, but do we
 *          actually want this? i don't think so.
 *          Maybe we just deal with waiting til we're more confident
 *          in the prompt on mobile browsers (i.e. on store locator)
 * @example we only want to pull location on app load for user's
 *  we KNOW have granted location permission, but we DO NOT want
 *  to trigger prompting users for their location if it's not.
 *  pre-approved. This function allows us to check those permissions
 *  without triggering a CTA for the user
 *
 * @NOTE: DOES NOT trigger getting the user's coords if permissions
 *  are GRANTED in order to keep permissions decoupled from coords at
 *  this stage
 */
export async function checkIfLocationPermissionIsPreapproved(
  usePromptAsFallback = true
): Promise<GeolocationPermissionStates> {
  try {
    // if we have navigator.permissions
    if (navigator.permissions) {
      // run query on permissions
      const { state } = await navigator.permissions.query({ name: 'geolocation' });
      switch (state) {
        case GeolocationPermissionStates.GRANTED:
          return GeolocationPermissionStates.GRANTED;
        case GeolocationPermissionStates.DENIED:
          return GeolocationPermissionStates.DENIED;
        // DEFAULT: something went wrong so let's re-prompt
        default:
          return GeolocationPermissionStates.PROMPT;
      }
    }
    // otherwise, throw error to trigger fallback
    else {
      throw new Error('No navigator permissions, maybe try fallback');
    }
  } catch (e) {
    if (usePromptAsFallback) {
      // run promptUserForLocationPermission to acutally
      // check for geolocation (see separate flow)
      return await promptUserForLocationPermission();
    }
    // otherwise, resolve with PROMPT because we don't actually know
    return Promise.resolve(GeolocationPermissionStates.PROMPT);
  }
}

/**
 * ====================================================
 *
 *                MISC GEOLOCATION UTILS
 *
 * ====================================================
 */

const isNotNilFloat = (n?: number | null) => typeof n === 'number' && Number.isFinite(n);

export const isValidPosition = (
  position: {
    lat?: number | null;
    lng?: number | null;
  } | null
): position is ILocation => {
  return (
    !!position &&
    'lat' in position &&
    isNotNilFloat(position.lat) &&
    'lng' in position &&
    isNotNilFloat(position.lng)
  );
};

// stubs for native-only functionality
export const initializeRadar = noop as any;
export const setRadarUser = noop as any;
export const getNativeLocationPermissions = noop as () => Promise<string | undefined>;
