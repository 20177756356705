import { useCallback, useMemo, useState } from 'react';

import {
  GetNutritionPageQuery,
  GetStaticPageQuery,
  IGetStaticPageQuery,
  IGetStaticPageVariables,
  IStaticPage,
} from 'remote/queries/static-page';
import { useErrorContext } from 'state/errors';
import { useLazySanityQuery } from 'state/graphql';

export const useStaticPage = () => {
  const { setSanityDataRef } = useErrorContext();
  const [
    isNutritionExplorerMostRecentlyFetchedPage,
    setIsNutritionExplorerMostRecentlyFetchedPage,
  ] = useState(false);

  const [
    getPage,
    {
      data: normalPageData,
      error: normalPageError,
      loading: normalPageLoading,
      refetch: normalPageRefetch,
    },
  ] = useLazySanityQuery<IGetStaticPageQuery, IGetStaticPageVariables>(GetStaticPageQuery);

  const [
    getNutritionPage,
    {
      data: nutritionPageData,
      error: nutritionPageError,
      loading: nutritionPageLoading,
      refetch: nutritionPageRefetch,
    },
  ] = useLazySanityQuery<IGetStaticPageQuery, IGetStaticPageVariables>(GetNutritionPageQuery);

  const retrieveStaticPageById = useCallback(
    (id: string, isNutritionPage = false): void => {
      setIsNutritionExplorerMostRecentlyFetchedPage(isNutritionPage);
      const pageGetter = isNutritionPage ? getNutritionPage : getPage;
      pageGetter({ variables: { id } });
    },
    [getNutritionPage, getPage]
  );

  const currentPage = useMemo<IStaticPage | undefined>(() => {
    const staticPage = isNutritionExplorerMostRecentlyFetchedPage
      ? nutritionPageData?.StaticPage
      : normalPageData?.StaticPage;
    setSanityDataRef(staticPage);
    return staticPage;
  }, [
    isNutritionExplorerMostRecentlyFetchedPage,
    nutritionPageData,
    normalPageData,
    setSanityDataRef,
  ]);

  const error = isNutritionExplorerMostRecentlyFetchedPage ? nutritionPageError : normalPageError;
  const loading = isNutritionExplorerMostRecentlyFetchedPage
    ? nutritionPageLoading
    : normalPageLoading;
  const refetch = isNutritionExplorerMostRecentlyFetchedPage
    ? nutritionPageRefetch
    : normalPageRefetch;

  return { currentPage, error, loading, refetch, retrieveStaticPageById };
};
