import styled from 'styled-components';

const StyledContainer = styled.div`
  height: 60px;
  width: 55px;
  object-fit: contain;
  display: block;
  /*
    We want Reach Router's Link component to handle the onClick event.
    Setting 'pointer-events' to 'none' ensures that this component,
    or its children, don't intercept the clicks.
   */
  pointer-events: none;
`;

export default StyledContainer;
