import { useEffect } from 'react';

import { WatchQueryFetchPolicy } from 'apollo-client';

import { ICartEntry } from '@rbi-ctg/menu';
import { LoyaltyServiceMode, useLoyaltyUserRewardsQuery } from 'generated/graphql-gateway';
import { actions, useAppDispatch } from 'state/global-state';
import { useLoyaltyContext } from 'state/loyalty';
import { useServiceModeContext } from 'state/service-mode';
import { useStoreContext } from 'state/store';

import { IUseLoyaltyRewardsFeedback } from './types';
import { useIsLoyaltyEnabled } from './use-is-loyalty-enabled';
import { useLoyaltyUser } from './use-loyalty-user';

export const useLoyaltyRewardsFeedback = (
  cartEntries: ICartEntry[],
  fetchPolicy: WatchQueryFetchPolicy = 'cache-and-network'
): IUseLoyaltyRewardsFeedback => {
  const { serviceMode } = useServiceModeContext();
  const { store } = useStoreContext();
  const loyaltyEnabled = useIsLoyaltyEnabled();
  const { loyaltyUser } = useLoyaltyUser();
  const { getAvailableRewardFromCartEntry } = useLoyaltyContext();
  const dispatch = useAppDispatch();

  const rewardsMapIncludesAllCartEntries = cartEntries.every(
    entry => !!getAvailableRewardFromCartEntry(entry)
  );

  const shouldSkipQuery =
    !loyaltyUser?.id || !loyaltyEnabled || !cartEntries?.length || rewardsMapIncludesAllCartEntries;

  // get the all available rewards with its feedback to create the available rewards map
  const { data, loading, error } = useLoyaltyUserRewardsQuery({
    skip: shouldSkipQuery,
    variables: {
      loyaltyId: loyaltyUser?.id || '',
      where: {
        serviceMode: LoyaltyServiceMode[serviceMode || ''] || undefined,
        storeId: store?.number,
      },
    },
    fetchPolicy,
  });

  const availableRewardsFeedback = data?.loyaltyUserV2?.rewards;

  useEffect(() => {
    if (!shouldSkipQuery && !loading && availableRewardsFeedback) {
      dispatch(actions.loyalty.createAvailableLoyaltyRewardsMap(availableRewardsFeedback));
    }
  }, [availableRewardsFeedback, dispatch, loading, shouldSkipQuery]);

  return { loading: !data?.loyaltyUserV2 && loading, error };
};
