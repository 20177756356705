import { maybeLocaleImage, maybeLocaleString } from 'utils/graphql';

import { HasKeys } from './types';

const typeParser = (data: { [key: string]: any }) => {
  const parsingFunction = {
    LocaleString: maybeLocaleString,
    LocaleImage: maybeLocaleImage,
  }[data?.__typename];

  return parsingFunction ? parsingFunction(data) : null;
};

const isValidData = <T, U>(data: HasKeys<T>, keysToValidate: string[]): data is U => {
  return keysToValidate.every(key => !!data[key]);
};

export const parseData = <T, U>(data: T, keysToValidate: string[]) => {
  if (!data) {
    return null;
  }
  const parsedData = Object.entries(data).reduce((acc: HasKeys<T>, [key, val]) => {
    const parsedField = typeParser(val);
    if (parsedField) {
      acc[key] = parsedField;
    }
    return acc;
  }, {});

  return isValidData<T, U>(parsedData, keysToValidate) ? parsedData : null;
};

export const validationKeys = {
  modal: ['continue', 'body', 'header', 'image', 'selectNew', 'itemNotAvailableForDelivery'],
};
