import * as React from 'react';

import { ActionButtonSizes, IActionButtonProps } from 'components/action-button';

import { FabContainer, StyledActionButton } from './styled';

export interface FabProps extends IActionButtonProps {
  caption?: string;
  badge?: string | number | undefined;
}

export const Fab: React.FC<FabProps> = ({
  size = ActionButtonSizes.LARGE,
  caption,
  badge,
  onClick,
  ...rest
}) => (
  <FabContainer $badgeContent={badge}>
    <StyledActionButton size={size} onClick={onClick} {...rest}>
      {caption}
    </StyledActionButton>
  </FabContainer>
);
