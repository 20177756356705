import { SupportedLanguages } from '@rbi-ctg/frontend';
import LocalStorage, { StorageKeys } from 'utils/local-storage';

import { getNavigatorLanguage } from './get-navigator-language';
import { loadRegion } from './load-region';
import {
  PROD_DEFAULT_LANGUAGE,
  PROD_SUPPORTED_LANGUAGES as SUPPORTED_LANGUAGES,
} from './supported-languages';

export function loadLanguage(): SupportedLanguages {
  const navigatorLanguage = getNavigatorLanguage();
  const inferredUrlParamLanguage = inferLanguageFromUrlParams();

  const lang =
    inferredUrlParamLanguage ||
    LocalStorage.getItem<SupportedLanguages>(StorageKeys.LANGUAGE) ||
    navigatorLanguage.split(/-.*/)[0];

  return findSupportedLanguage(lang);
}

function inferLanguageFromUrlParams(): string | null {
  const params = new URL(window.location.href).searchParams;

  const uriLang = params.get('lang');

  return uriLang;
}

function findSupportedLanguage(requestedLanguage: string): SupportedLanguages {
  return SUPPORTED_LANGUAGES[requestedLanguage] || PROD_DEFAULT_LANGUAGE[loadRegion()];
}
