import React from 'react';

import { useIntl } from 'react-intl';
import styled from 'styled-components';

import ActionButton from 'components/action-button';
import { brandFont } from 'components/layout/brand-font';
import Link from 'components/link';
import LoyaltyRewardsLogo from 'pages/loyalty/loyalty-rewards-logo';
import { useIsLoyaltyEnabled } from 'state/loyalty/hooks/use-is-loyalty-enabled';
import { CustomEventNames } from 'state/mParticle';
import { primitive } from 'styles/constants/primitives';
import { routes } from 'utils/routing';

const Container = styled.div`
  margin: 0 ${primitive.$spacing3} ${primitive.$spacing10};
`;

const HaveAccount = styled.div`
  text-align: center;
  margin-top: ${primitive.$spacing2};
`;

const StyledRewardsLogo = styled(LoyaltyRewardsLogo)`
  margin: 0 auto ${primitive.$spacing7};
  padding: 0 ${primitive.$spacing6};
  width: 100%;
  display: block;
  height: 4rem;
`;

const Headline = styled.h1`
  font: ${brandFont.headerOne};
  text-align: center;
  margin-bottom: ${primitive.$spacing5};
  text-transform: ${Styles.textTransform.headlines};
`;

const StyledLink = styled(Link)`
  color: ${p => p.theme.token('text-link-default')};
  display: inline;
  font: ${brandFont.headerThree};
  text-decoration: none;
  text-transform: ${Styles.textTransform.headlines};
  margin-left: ${primitive.$spacing1};

  :hover {
    color: ${p => p.theme.token('text-link-hover')};
  }
`;

const CreateAccount = () => {
  const { formatMessage } = useIntl();
  const loyaltyEnabled = useIsLoyaltyEnabled();

  return (
    <Container>
      <Headline data-testid="account-sign-up-label">
        {formatMessage({
          id: loyaltyEnabled ? 'loyaltyCreateAccountHeadline' : 'createAccountHeadline',
        })}
      </Headline>
      {loyaltyEnabled && <StyledRewardsLogo data-testid="account-sign-up-loyalty-logo" />}

      <ActionButton
        fullWidth
        data-testid="account-sign-up-link"
        eventName={CustomEventNames.BUTTON_CLICK_SIGN_UP}
        to={routes.signUp}
      >
        {formatMessage({ id: 'signUpNow' })}
      </ActionButton>

      <HaveAccount data-testid="account-have-account">
        {formatMessage({ id: 'alreadyHaveAnAccount' })}

        <StyledLink to={formatMessage({ id: 'routes.signIn' })}>
          {formatMessage({ id: 'signIn' })}
        </StyledLink>
      </HaveAccount>
    </Container>
  );
};

export default CreateAccount;
