import React from 'react';

import { useIntl } from 'react-intl';

import { ExternalLink } from './styled';

export const CCPA_COMPLIANCE = 'ccpa-compliance';

export const CCPAButtonLink: React.FC = () => {
  const { formatMessage } = useIntl();

  return (
    <ExternalLink onClick={() => window.OneTrust.ToggleInfoDisplay()}>
      {formatMessage({ id: 'CCPADontSellButton' })}
    </ExternalLink>
  );
};
