import { useCallback, useEffect, useRef, useState } from 'react';

import { IRestaurantNode } from 'generated/rbi-graphql';
import { serviceModeList } from 'pages/cart/service-mode-details/service-mode-list';
import useStoreLocator from 'pages/store-locator/hooks/use-store-locator';
import { useGeolocation } from 'state/geolocation';
import { useLocale } from 'state/intl';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useServiceModeContext } from 'state/service-mode';
import { SelectedStore, useStoreContext } from 'state/store';
import { isMobile } from 'utils/environment';

/**
 *
 * Auto selects closest restaurant based on navigator geolocation
 *
 **/
export const useAutoSelectRestaurant = () => {
  const [restaurantToConfirm, setRestaurantToConfirm] = useState<IRestaurantNode | null>(null);
  const isManualRestaurantSelectionRef = useRef<boolean>();
  const checkedClosestStore = useRef(false);
  const {
    isRestaurantAvailable,
    setStore,
    noStoreSelected,
    serviceModeStatus,
    store,
    resetStore,
  } = useStoreContext();
  const { setServiceMode } = useServiceModeContext();
  const { isPermissionPrompt, setPermissionChecking } = useGeolocation();
  const { determiningLocaleComplete } = useLocale();
  const enableAutoLocale = useFlag(LaunchDarklyFlag.ENABLE_AUTO_LOCALE);
  const noStoreSelectedOnMount = useRef(noStoreSelected);
  // This ensures that autoSelectRestaurant hook does not run prior to correct domain / locale being selected
  const autoLocaleComplete = !enableAutoLocale || determiningLocaleComplete;
  const notMobileSizedDevice = !isMobile();
  // The storeLocater hook fetches user geolocation and queries closest stores on mount if
  // permission.isGranted and listens for changes in permission status.
  // loadPositionOnMount is set to true so we can determine if closest store has changed since last session.
  const { storesNearby } = useStoreLocator({
    loadPositionOnMount: autoLocaleComplete,
    onStoreLocator: false,
  });

  const getFirstAvailableStore = useCallback(
    async (stores: IRestaurantNode[]) => {
      for (const storeToCheck of stores) {
        const isAvailable = await isRestaurantAvailable(storeToCheck);
        if (isAvailable && storeToCheck?.hasMobileOrdering) {
          return storeToCheck;
        }
      }

      return undefined;
    },
    [isRestaurantAvailable]
  );

  // Only use native prompt on mount if not mobile sized device (including mobile running web app)
  // and permission for geolocation unknown
  const confirmRestaurant = (confirmed: boolean) => {
    if (restaurantToConfirm && confirmed) {
      setStore(({ ...restaurantToConfirm, hasSelection: true } as unknown) as SelectedStore);
      const firstAvailableServiceMode =
        serviceModeList.find(serviceMode => {
          return (
            serviceModeStatus[serviceMode].available && !serviceModeStatus[serviceMode].disabled
          );
        }) || serviceModeList[0];
      setServiceMode(firstAvailableServiceMode);
    } else {
      isManualRestaurantSelectionRef.current = true;
      resetStore();
    }
    setRestaurantToConfirm(null);
  };

  useEffect(() => {
    if (
      isPermissionPrompt &&
      notMobileSizedDevice &&
      noStoreSelectedOnMount.current &&
      autoLocaleComplete
    ) {
      setPermissionChecking();
    }
  }, [autoLocaleComplete, isPermissionPrompt, notMobileSizedDevice, setPermissionChecking]);

  // If nearby stores available from useStoreLocater hook, select first available
  // And if closest store has changed since last session, select first available from new nearby stores
  // (results for nearby stores limited to only open)
  useEffect(() => {
    const hasClosestStoreChanged = () => {
      // This function should only run once
      if (checkedClosestStore.current) {
        return false;
      }
      checkedClosestStore.current = true;
      return storesNearby && store._id !== storesNearby[0]?._id;
    };

    if (storesNearby?.length && (noStoreSelected || hasClosestStoreChanged())) {
      getFirstAvailableStore(storesNearby).then(firstAvailableStore => {
        if (firstAvailableStore) {
          setRestaurantToConfirm(firstAvailableStore);
        }
      });
    }
  }, [storesNearby, noStoreSelected, store, getFirstAvailableStore]);

  return {
    confirmRestaurant,
    restaurantToConfirm,
    isManualRestaurantSelection: isManualRestaurantSelectionRef.current,
  };
};
