import destinationMarkerSvg from './destination-marker';
import driverMarkerSvg from './driver-marker';
import storeMarkerSvg from './icons/store-marker';
import storeMarkerActiveSvg from './icons/store-marker-active';
import storeMarkerDisabledSvg from './icons/store-marker-disabled';
import storeMarkerFavOpenSvg from './icons/store-marker-fav';
import storeMarkerFavClosedSvg from './icons/store-marker-fav-closed';
import {
  activeMarkerX,
  activeMarkerY,
  markerX,
  markerY,
  regularMarkerX,
  regularMarkerY,
} from './marker-dimensions';

const iconBase = 'data:image/svg+xml;charset=UTF-8;base64,';
export const storeIconUrl = iconBase + btoa(storeMarkerSvg);
export const storeIconActiveUrl = iconBase + btoa(storeMarkerActiveSvg);
export const storeIconDisabledUrl = iconBase + btoa(storeMarkerDisabledSvg);
export const storeIconFavOpenUrl = iconBase + btoa(storeMarkerFavOpenSvg);
export const storeIconFavClosedUrl = iconBase + btoa(storeMarkerFavClosedSvg);
export const destinationIconUrl = iconBase + btoa(destinationMarkerSvg);
export const driverIconUrl = iconBase + btoa(driverMarkerSvg);

export const createMarker = markerOptions => {
  const marker = new window.google.maps.Marker(markerOptions);

  if (markerOptions.onClick) {
    window.google.maps.event.addListener(marker, 'click', markerOptions.onClick);
  }

  return {
    marker,
    inactive: () => {
      marker.setIcon({
        url: storeIconUrl,
        scaledSize: new window.google.maps.Size(regularMarkerX, regularMarkerY),
      });
      marker.setZIndex(null);
    },
    active: () => {
      marker.setIcon({
        url: storeIconActiveUrl,
        scaledSize: new window.google.maps.Size(activeMarkerX, activeMarkerY),
      });
      marker.setZIndex(1000);
    },
    disabled: () => {
      marker.setIcon({
        url: storeIconDisabledUrl,
        scaledSize: new window.google.maps.Size(regularMarkerX, regularMarkerY),
      });
      marker.setZIndex(null);
    },
    favOpen: () => {
      marker.setIcon({
        url: storeIconFavOpenUrl,
        scaledSize: new window.google.maps.Size(regularMarkerX, regularMarkerY),
      });
      marker.setZIndex(null);
    },
    favClosed: () => {
      marker.setIcon({
        url: storeIconFavClosedUrl,
        scaledSize: new window.google.maps.Size(regularMarkerX, regularMarkerY),
      });
      marker.setZIndex(null);
    },
  };
};

export const createUserLocationMarker = (latLng, map) => {
  const marker = createMarker({
    position: latLng,
    map,
    clickable: false,
    icon: {
      path: window.google.maps.SymbolPath.CIRCLE,
      fillColor: '#3780D6',
      fillOpacity: 1,
      strokeColor: 'white',
      strokeWeight: 2,
      scale: 10,
    },
    zIndex: 999,
  });

  return marker;
};

export const createStoreLocationMarker = (latLng, map, onClick) => {
  const marker = createMarker({
    map,
    position: latLng,
    icon: {
      url: storeIconUrl,
      scaledSize: new window.google.maps.Size(regularMarkerX, regularMarkerY),
    },
    onClick,
  });

  return marker;
};

export const createDestinationMarker = (latLng, map) => {
  const marker = createMarker({
    map,
    position: latLng,
    icon: {
      url: destinationIconUrl,
      scaledSize: new window.google.maps.Size(activeMarkerX, activeMarkerY),
    },
  });

  return marker;
};

export const createDriverLocationMarker = (latLng, map, driverIconUrlOverride) => {
  const driverIcon = driverIconUrlOverride || driverIconUrl;
  const iconSize = driverIconUrlOverride ? null : new window.google.maps.Size(markerX, markerY);

  const marker = createMarker({
    position: latLng,
    map,
    icon: {
      url: driverIcon,
      scaledSize: iconSize,
    },
  });

  return marker;
};

let mapListeners = {};
export const addListener = (eventName, map, cb, id) => {
  if (!mapListeners[id]) {
    mapListeners[id] = [];
  }

  mapListeners[id] = mapListeners[id].concat(map.addListener(eventName, cb));
};

export const clearListenerCache = id => {
  const handlers = mapListeners[id] || [];
  handlers.forEach(handler => {
    if (typeof handler.remove === 'function') {
      handler.remove();
    }
  });
  mapListeners[id] = [];
};
