import React, { FC } from 'react';

import { Box } from '@rbilabs/components-library';

import { portalToFooter } from 'state/mobile-footer';
import { designTokens } from 'styles/constants/design-tokens';

import { FIXED_STICKY_FOOTER_HEIGHT } from './constants';
import { StickyBoxChild } from './sticky-footer.styled';
import { IStickyFooter } from './types';

const Footer: FC<IStickyFooter> = ({ boxProps, elements, padding }) => (
  <Box
    data-testid="sticky-footer-banner"
    zIndex={`${Styles.zIndex.overlay + 300}`}
    minHeight={FIXED_STICKY_FOOTER_HEIGHT}
    padding={padding}
    boxShadow={designTokens['drop-shadow-bottom-nav']}
    background={designTokens['background-pattern']}
    flexWrap={'wrap'}
    {...boxProps}
  >
    {elements.map(({ element, spaces }, idx) => {
      // TODO: remove condition when all consumers move to TS
      if (!element) {
        return null;
      }
      return (
        <StickyBoxChild key={idx} lastChild={idx === elements.length - 1} spaces={spaces}>
          {element}
        </StickyBoxChild>
      );
    })}
  </Box>
);

/**
 * StickyFooter is a fixed bottom banner component by using a PortalSlot to
 * ultimately render within the LayoutBottomContainer.
 *
 * IT takes a set of element(s) and renders them using flex.
 * It will do this depending on the number of spaces specified for the element.
 * Else, it'll default to take up the whole width (i.e. 'flex: 1').
 *
 * It can also take in additional Box props if any style needs overriding.
 * This makes the component more flexible for various use cases.
 */
export const StickyFooter: FC<IStickyFooter> = portalToFooter(Footer);

// Adding this extra export to integrate with footers in the app that currently use the StickyFooter to hug the bottom of the page.
// Those situations need to be refactored to use flexbox appropriately. But exporting this as the legacy implementation to keep
// the app working for now.
export const DialogFooter = Footer;

export const HiddenFooter = portalToFooter(() => null);
