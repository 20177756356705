import { IPaymentMethod } from './types';
// @TODO remove after merging cba-797

// In favor of RELOAD_AMOUNTS label/value pair
export const PREPAID_RELOAD_AMOUNTS = [500, 1000, 2500, 5000];

export const DEFAULT_RELOAD_AMOUNT = 500;

export const RELOAD_AMOUNTS = [
  {
    label: '$5',
    value: 500,
  },
  {
    label: '$10',
    value: 1000,
  },
  {
    label: '$25',
    value: 2500,
  },
  {
    label: '$50',
    value: 5000,
  },
];

export const CASH_ACCOUNT_IDENTIFIER = 'CASH';
export const PAYPAL_PAYMENT_METHOD_PLACEHOLDER: IPaymentMethod = {
  paypal: true,
  fdAccountId: 'PAYPAL',
  accountIdentifier: 'PAYPAL',
  orbitalIdentifier: null,
  chaseProfileId: null,
  credit: null,
  prepaid: null,
  paypalIdentifier: null,
};
