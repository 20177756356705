import { SupportedRegions } from '@rbi-ctg/frontend';
import { PROD_DEFAULT_LANGUAGE, PROD_SUPPORTED_REGIONS } from 'state/intl/hook';
import { LANGUAGES, REGIONS } from 'state/intl/types';

import { regionSupportedLanguages } from './regionLanguages';

export const determineValidLang = (region: REGIONS | string, browserLang: LANGUAGES | string) => {
  // Pull supported Languages for region / brand.
  const regionSupportsBrowserLang = regionSupportedLanguages[region]?.has(browserLang);
  // Use user language if supported by region otherwise use default language
  return regionSupportsBrowserLang ? browserLang : PROD_DEFAULT_LANGUAGE[region];
};

export const isSupportedRegion = (
  region: SupportedRegions | string
): region is SupportedRegions => {
  return PROD_SUPPORTED_REGIONS[region];
};

export const isNewLocaleSameAsCurrent = (
  currentLocale: string,
  region: SupportedRegions,
  lang: string
) => {
  const determinedLocale = `${lang}-${region}`;
  return determinedLocale === currentLocale;
};
