import { isAfter, parseISO, startOfDay, startOfMonth, startOfWeek, startOfYear } from 'date-fns';
import { isNil } from 'lodash-es';

import { ILimit, Rule } from '@rbi-ctg/offers';

import { findFirstRuleOfTypeThatApplies } from './rule-of-type-will-apply';
import { RuleType } from './types';

const startOfForInterval = {
  day: startOfDay,
  month: startOfMonth,
  week: startOfWeek,
  year: startOfYear,
};

function startOfInterval(interval: keyof typeof startOfForInterval): Date {
  const now = new Date();

  const startingDate = startOfForInterval[interval](now);

  return startingDate;
}

export const isRedeemableWithLimit = (
  ruleSet: Rule[] | null,
  redemptionHistory: string[] = []
): boolean => {
  const limitRule = findFirstRuleOfTypeThatApplies<ILimit>(RuleType.Limit, ruleSet);

  if (!limitRule) {
    // if no limit rule is found the assumption is that the coupon is redeemable
    // once
    return redemptionHistory.length === 0;
  }

  const { interval, maximumRedemptions } = limitRule;

  // if maximum redemptions is null, this coupon
  // can be infinitely redeemed
  if (isNil(maximumRedemptions)) {
    return true;
  }

  // if an interval is specified, we need to determine
  // how many redemptions the user has in the current period
  if (!isNil(interval)) {
    const periodStartDate = startOfInterval(interval);

    const totalRedemptionCount = redemptionHistory.reduce(
      (redemptionCount, redemptionIsoString) =>
        isAfter(parseISO(redemptionIsoString), periodStartDate)
          ? redemptionCount + 1
          : redemptionCount,
      0
    );

    return totalRedemptionCount < maximumRedemptions;
  }

  // otherwise, we just need to check if the user has exceeded the number
  // of maximum redemptions
  return redemptionHistory.length < maximumRedemptions;
};

export default isRedeemableWithLimit;
