import { SupportedLanguages, SupportedRegions } from '@rbi-ctg/frontend';

import { SORTED_PROD_SUPPORTED_LOCALES } from './supported-locales';

export function inferSupportedLocale(
  newLanguage: SupportedLanguages,
  newRegion: SupportedRegions,
  useDefault = false
): string {
  const regionSupportedLocales = SORTED_PROD_SUPPORTED_LOCALES.filter(locale =>
    locale.includes(newRegion)
  );

  if (useDefault) {
    return regionSupportedLocales[0];
  }

  // Find the first item on the list with the langauge that we care about
  return (
    regionSupportedLocales.find(sortedLocale => sortedLocale.startsWith(`${newLanguage}`)) ||
    regionSupportedLocales[0]
  );
}
