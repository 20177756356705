import { useCallback, useMemo } from 'react';

import {
  GetStaticPageRoutesQuery,
  IGetStaticPageRoutesQuery,
  IStaticPageRoute,
} from 'remote/queries/static-page';
import { useLazySanityQuery } from 'state/graphql';

export const useStaticPageRoutes = () => {
  const [
    _loadRoutes,
    { data, error, loading, refetch, called: loadRoutesHasBeenCalled },
  ] = useLazySanityQuery<IGetStaticPageRoutesQuery>(GetStaticPageRoutesQuery, {}, { isV2: true });

  const routes = useMemo<IStaticPageRoute[]>(
    () => (data && data.allStaticPage ? data.allStaticPage : []),
    [data]
  );

  const loadRoutes = useCallback(() => {
    // Don't refetch the static routes if _loadRoutes already has been called.
    if (!loadRoutesHasBeenCalled) {
      _loadRoutes();
    }
  }, [_loadRoutes, loadRoutesHasBeenCalled]);

  return { loadRoutes, error, loading, refetch, routes, loadRoutesHasBeenCalled };
};
