import { addDays, addHours, addSeconds, differenceInHours, differenceInMinutes } from 'date-fns';

import { IEvaluationFeedback } from '@rbi-ctg/offers';
import { IOfferFeedbackEntryFragment } from 'generated/rbi-graphql';

export interface IDays {
  days: number;
}

export interface IHours {
  hours: number;
  minutes?: number;
}

type OfferEvaluationFeedbackEntries = IOfferFeedbackEntryFragment['redemptionEligibility']['evaluationFeedback'];

// given a list feedback entries, return the feedback entry where
// condition is true and redeemableForSeconds is longest
// and not null
// returns null if neither entry fulfills criteria
function feedbackWithRedeemableForLongestDuration(feedback: OfferEvaluationFeedbackEntries) {
  return (feedback || []).reduce(
    (
      leftFeedback: IEvaluationFeedback | null,
      rightFeedback: IEvaluationFeedback
    ): IEvaluationFeedback | null => {
      if (!rightFeedback.condition || rightFeedback.redeemableForSeconds === null) {
        return leftFeedback;
      }

      if (!leftFeedback || !leftFeedback.condition || leftFeedback.redeemableForSeconds === null) {
        return rightFeedback;
      }

      return leftFeedback.redeemableForSeconds > rightFeedback.redeemableForSeconds
        ? leftFeedback
        : rightFeedback;
    },
    null
  );
}

export function timeLeftToRedeem(
  offerFeedback: IOfferFeedbackEntryFragment | undefined
): IDays | IHours | null {
  const { redemptionEligibility } = offerFeedback || {};
  if (!redemptionEligibility || !redemptionEligibility.isRedeemable) {
    return null;
  }

  const applicableFeedbackEntry: IEvaluationFeedback | null = feedbackWithRedeemableForLongestDuration(
    redemptionEligibility.evaluationFeedback
  );

  if (!applicableFeedbackEntry) {
    return null;
  }

  const { redeemableForSeconds } = applicableFeedbackEntry;

  const now = new Date();

  const endOfRedemption: Date = addSeconds(now, redeemableForSeconds!);

  // https://date-fns.org/v2.15.0/docs/differenceInDays
  // To ignore DST and only measure exact 24-hour periods,
  // use this instead: Math.floor(differenceInHours(dateLeft, dateRight)/24)|0.
  const diffInHours = differenceInHours(endOfRedemption, now);
  const diffInMinutes = differenceInMinutes(endOfRedemption, now);
  const days = Math.floor(diffInHours / 24) | 0;

  return {
    days,
    hours: diffInHours % 24,
    minutes: diffInMinutes % 60,
  };
}

// derive the date a coupon is no longer redeemable from the time left to redeem
export function getEndingDate(endingIn: IDays | IHours | null): Date | null {
  if (!endingIn) {
    return null;
  }

  if ('days' in endingIn) {
    return addDays(new Date(), endingIn.days);
  }

  return addHours(new Date(), endingIn.hours);
}
