import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const IconWrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;

  & > svg,
  & > img {
    position: relative;
    height: 100%;
  }
`;

export const NavLinkText = styled.p`
  margin: 0.75rem auto 0.5rem;
  font-size: 0.75rem;
`;

export const iconSize = 25;

export const FloatingLink = styled(Link)<{ 'data-active'?: boolean }>`
  align-content: center;
  background-color: ${Styles.color.primary};
  border: 0;
  border-radius: 9999rem;
  position: absolute;
  bottom: 66px;
  box-shadow: ${Styles.boxShadow};
  color: ${Styles.color.white};
  display: flex;
  font-family: ${Styles.fontFamily.body};
  font-size: 0.9375rem;
  justify-content: center;
  min-width: 6.813rem;
  max-height: 3.125rem;
  padding: 0.8rem 1rem;
  position: absolute;
  right: 0.688rem;
  text-decoration: none;
  text-transform: uppercase;
  user-select: none;

  &:active,
  &:visited,
  &:link {
    color: ${Styles.color.white};
  }

  svg {
    fill: ${Styles.color.white};
  }
`;
