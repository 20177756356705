import React from 'react';

import { routes } from 'utils/routing';

import { NAV_LINK_ACCOUNT_ICON_COMPONENT } from './constants';
import { NavLink } from './helpers';
import More from './more';
import { IconWrap, NavLinkText } from './styled';

interface IRenderNavLinkProps {
  route: string;
  id: string;
  icon: React.ReactNode;
  text: string;
  navBarText: string;
}

interface IGetLinkProps {
  formatMessage: ({ id }: { id: string }) => string;
}

export const getNavLinkItem = ({ route, id, icon, text, navBarText }: IRenderNavLinkProps) => ({
  selectedHrefs: [route],
  Component: () => (
    <NavLink data-qa-id={id} data-testid={id} to={route} requiresExactMatch navBarText={navBarText}>
      <IconWrap>{icon}</IconWrap>
      <NavLinkText>{text}</NavLinkText>
    </NavLink>
  ),
});

const IconProfile = NAV_LINK_ACCOUNT_ICON_COMPONENT;

const accountTextKey = 'account';
const aboutTextKey = 'more';

export const getAccountLink = ({ formatMessage }: IGetLinkProps) =>
  getNavLinkItem({
    route: routes.account,
    id: 'account-mobile',
    icon: <IconProfile />,
    text: formatMessage({ id: 'account' }),
    navBarText: accountTextKey,
  });

export const getAboutLink = ({ formatMessage }: IGetLinkProps) =>
  getNavLinkItem({
    route: routes.about,
    id: 'about-mobile',
    icon: <More />,
    text: formatMessage({ id: 'more' }),
    navBarText: aboutTextKey,
  });
