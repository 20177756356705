import React, { useCallback, useState } from 'react';

import { useGeolocation } from 'state/geolocation';
import { useLocale } from 'state/intl';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { isMobile } from 'utils/environment';

import { GeolocationModalView } from './geolocation-modal-view';

export const AutoGeolocationModal = () => {
  const {
    isPermissionPrompt,
    setPermissionChecking,
    setPermissionPrompt,
    isPermissionKnown,
  } = useGeolocation();
  const { determiningLocaleComplete } = useLocale();
  const enableAutoLocale = useFlag(LaunchDarklyFlag.ENABLE_AUTO_LOCALE);
  // This ensures that autoSelectRestaurant hook does not run prior to correct domain / locale being selected
  const autoLocaleComplete = !enableAutoLocale || determiningLocaleComplete;
  // Auto geolocation modal should only show on mobile sized devices (including mobile on web app)
  const openModalOnMount = isMobile() && isPermissionPrompt;

  const [modalOpen, setModalOpen] = useState(openModalOnMount);

  const handleGeolocationPrompt = useCallback(() => {
    setModalOpen(false);
    setPermissionChecking();
  }, [setPermissionChecking]);

  const handleDismissModal = useCallback(() => {
    setModalOpen(false);
    setPermissionPrompt();
  }, [setPermissionPrompt]);

  const showModal = modalOpen && autoLocaleComplete && !isPermissionKnown;

  return showModal ? (
    <GeolocationModalView
      handleDismissModal={handleDismissModal}
      handleGeolocationPrompt={handleGeolocationPrompt}
    />
  ) : null;
};
