import React from 'react';

export const Background = () => (
  <svg
    width="375"
    height="812"
    viewBox="0 0 375 812"
    fill="none"
    preserveAspectRatio="xMinYMin slice"
  >
    <rect width="375" height="812" fill="#502314" />
  </svg>
);
