import { useEffect } from 'react';

import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';

import { hasAcceptedCookies } from 'utils/cookies';
import { getApiKey } from 'utils/environment';

export default function useGoogleAnalytics() {
  const location = useLocation();
  const googleAnalytics: string = getApiKey('googleAnalytics');
  const hasAcceptedCookiesValue = hasAcceptedCookies();

  useEffect(() => {
    if (googleAnalytics && hasAcceptedCookiesValue) {
      ReactGA.initialize(googleAnalytics);
    }
  }, [hasAcceptedCookiesValue, googleAnalytics]);

  useEffect(() => {
    if (googleAnalytics && hasAcceptedCookiesValue) {
      const currentPath = location.pathname + location.search;
      ReactGA.set({ page: currentPath });
      ReactGA.pageview(currentPath);
    }
  }, [location, googleAnalytics, hasAcceptedCookiesValue]);
}
