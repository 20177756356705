import styled from 'styled-components';
import rnstyled from 'styled-components/native';

import Link from 'components/link';

import { RIGHT_NAV_ALIGN_ITEMS } from './constants';

export const DesktopHeaderContainer = rnstyled.View`
  ${p => (p['aria-hidden'] ? 'display: none;' : '')}
  transform: translateY(0) scale(1);
  width: 100%;
  z-index: ${Styles.zIndex.overlay + 100};
`;

// This causes "extra" nav elements to be hidden (per product req), as opposed to
// wrapping and looking broken on small desktop screens when there are > 3 nav elements.
export const LeftNavWrapper = styled.div`
  max-height: 3.125rem;
  max-width: 40%;
  overflow: hidden;
  padding: 0.313rem;
  display: flex;
  align-items: center;
`;

export const RightNavContainer = styled.div`
  display: flex;
  align-items: ${RIGHT_NAV_ALIGN_ITEMS};
  padding: 0.313rem;
`;

export const FlexContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
  position: relative;
`;

export const StyledLink = styled(Link)`
  position: static;
  margin-top: 6px;
  color: ${Styles.color.white};

  &:hover {
    color: ${Styles.color.white};
  }

  ::before {
    color: transparent;
  }
`;

export const Center = styled.div`
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  z-index: ${Styles.zIndex.max - 300};
`;
