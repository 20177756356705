import { MutationHookOptions, useMutation } from '@apollo/react-hooks';

import { activateLoyaltyOfferMutation } from 'remote/queries/offers';

export interface IActivateOfferMutationVariables {
  tokenId: string;
}

export type IActivateOfferMutationResults = {
  activateLoyaltyOffer?: boolean;
};

export const useActivateOfferMutation = (
  options?: MutationHookOptions<IActivateOfferMutationResults, IActivateOfferMutationVariables>
) =>
  useMutation<IActivateOfferMutationResults, IActivateOfferMutationVariables>(
    activateLoyaltyOfferMutation,
    options
  );
