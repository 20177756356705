import * as LDClient from 'launchdarkly-js-client-sdk';
import { LDFlagChangeset } from 'launchdarkly-js-client-sdk';
import { isEqual, merge } from 'lodash-es';

import { loadLanguage } from 'state/intl/load-language';
import { loadRegion } from 'state/intl/load-region';
import { getCurrentVersion } from 'utils/appflow';
import { hasAcceptedCookies, isRecentCookieTimestamp } from 'utils/cookies';
import { init as initDataDogLogs } from 'utils/datadog';
import { appVersionCode, getApiKey, platform, sanityDataset } from 'utils/environment';
import { getMobileAppVersion } from 'utils/get-mobile-app-version';
import { getMobileOS } from 'utils/get-mobile-os';
import LocalStorage from 'utils/local-storage';
import { Keys } from 'utils/local-storage/constants';
import logger from 'utils/logger';
import LogRocketHelper from 'utils/logrocket';

import { FlagType, LaunchDarklyFlag } from './flags';

export * from './flags';

export type LDUser = LDClient.LDUser;
export type LDFlagSet = LDClient.LDFlagSet;

const createAnonymousUserAttributes = async (deviceId?: string) => {
  const version = await getCurrentVersion();
  const mobileOS = await getMobileOS();
  const appShellVersion = await getMobileAppVersion();
  const anon = {
    anonymous: true,
    custom: {
      host: window.location.host,
      platform: platform(),
      mobileOS,
      device_id: deviceId || '',
      userClient: window.navigator.userAgent || '',
      appVersion: version ? version.binaryVersionCode : appVersionCode(),
      appShellVersion,
      appFlowBuildId: version ? version.buildId : '',
      language: loadLanguage(),
      sanityDataset: `${sanityDataset}_${loadRegion().toLowerCase()}`,
    },
    country: loadRegion(),
  };
  return normalizeUserAttributes(anon);
};

export const initLaunchDarkly = async (): Promise<Record<string, unknown>> => {
  const ldClient = await LaunchDarklyHelper.init();

  return new Promise<Record<string, unknown>>((resolve, reject) => {
    ldClient.on('initialized', () => {
      const allFlags = ldClient.allFlags();
      const flattenedFlags = LaunchDarklyHelper.flattenFlags(allFlags);
      const ddSampleRate = flattenedFlags[LaunchDarklyFlag.DATADOG_LOG_SAMPLE_RATE];
      const logRocketFlag = flattenedFlags[LaunchDarklyFlag.ENABLE_LOGROCKET];
      const cookieBannerFlag = flattenedFlags[LaunchDarklyFlag.ENABLE_COOKIE_BANNER];
      const isCookieVersioningEnabled = flattenedFlags[LaunchDarklyFlag.ENABLE_COOKIE_VERSIONING];
      const cookiesAccepted = isCookieVersioningEnabled
        ? hasAcceptedCookies()
        : isRecentCookieTimestamp();

      initDataDogLogs(ddSampleRate);

      if (logRocketFlag && (!cookieBannerFlag || cookiesAccepted)) {
        LogRocketHelper.init();
      }

      resolve(flattenedFlags);
    });

    ldClient.on('failed', () => reject(new Error('LD Init failed')));
    ldClient.on('error', error => reject(error));
  }).catch(error => {
    logger.error(error);
    return {};
  });
};

export type LaunchDarklyFlagsObject = { [F in LaunchDarklyFlag]?: FlagType<F> };

export type UserAttributeUpdates = LDClient.LDUser;

export type AnonymousUserAttributes = LDClient.LDUser & { anonymous: true };

export type UserAttributes = AnonymousUserAttributes & UserAttributeUpdates;

export default class LaunchDarklyHelper {
  private static instance: LaunchDarklyHelper;
  public launchDarkly: LDClient.LDClient;
  private _userAttributes: LDClient.LDUser;

  public get userAttributes() {
    return this._userAttributes;
  }

  public async initUserAttributes(hydratedUserAttributes: LDClient.LDUser = {}) {
    const anonUserAttributes = await createAnonymousUserAttributes();
    this._userAttributes = merge({}, anonUserAttributes, hydratedUserAttributes);
    return this.userAttributes;
  }

  public async updateCurrentUser(changes: UserAttributeUpdates) {
    const normalized = normalizeUserAttributes(changes);
    const originalAttributes = this._userAttributes;
    const mergedAttributes = merge({}, originalAttributes, normalized);

    // Only update LD user if attributes are different via a deep comparison check
    let newFlags: LDClient.LDFlagSet | undefined;
    if (!isEqual(originalAttributes, mergedAttributes)) {
      this._userAttributes = mergedAttributes;
      const flags = await this.launchDarkly.identify(mergedAttributes);
      newFlags = LaunchDarklyHelper.flattenFlags(flags);
      LocalStorage.setItem(Keys.LAUNCH_DARKLY_USER_ATTRIBUTES, this.launchDarkly.getUser());
    }

    return { newFlags, userAttributes: mergedAttributes };
  }

  public clearCurrentUser = async () => {
    const originalAttributes = this._userAttributes;

    // Persist device id across user sessions
    const deviceId = originalAttributes?.custom?.device_id as string | undefined;
    const newAttributes = await createAnonymousUserAttributes(deviceId);

    const flags = await this.launchDarkly.identify(newAttributes);
    const newFlags = LaunchDarklyHelper.flattenFlags(flags);
    this._userAttributes = newAttributes;
    LocalStorage.setItem(Keys.LAUNCH_DARKLY_USER_ATTRIBUTES, this.launchDarkly.getUser());
    return { newFlags, userAttributes: newAttributes };
  };

  public static getInstance(): LaunchDarklyHelper {
    if (!LaunchDarklyHelper.instance) {
      LaunchDarklyHelper.instance = new LaunchDarklyHelper();
    }
    return LaunchDarklyHelper.instance;
  }

  public static flattenFlags(allFlags: LDFlagSet): LaunchDarklyFlagsObject {
    const flattened: LaunchDarklyFlagsObject = {};
    for (const key in allFlags) {
      flattened[key] = allFlags[key];
    }

    return flattened;
  }

  // Making this async because the rn varation is also async
  public evaluateFlagVariants = async () => {
    await this.launchDarkly.waitUntilReady();
    let updatedFlags = { ...this.launchDarkly.allFlags() };

    for (const flagName in updatedFlags) {
      if (typeof updatedFlags[flagName] === 'object') {
        const variationDetail = this.launchDarkly.variationDetail(flagName);

        if (variationDetail && typeof variationDetail.variationIndex === 'number') {
          updatedFlags[flagName] = `Variation ${variationDetail.variationIndex + 1}`;
        }
      }
    }

    return updatedFlags;
  };

  public addChangeListener = (callback: (flags: LaunchDarklyFlagsObject) => void) => {
    const changeCallback = (changes: LDFlagChangeset) => {
      const flattened = {};
      for (const key in changes) {
        flattened[key] = changes[key].current;
      }

      callback(flattened);
    };
    this.launchDarkly.on('change', changeCallback);

    return () => {
      this.launchDarkly.off('change', changeCallback);
    };
  };
  public static async init() {
    const helperInstance = this.getInstance();
    let config: LDClient.LDOptions = {
      privateAttributeNames: [
        'email',
        'firstName',
        'lastName',
        'name',
        'dateOfBirth',
        'phoneNumber',
      ],
      evaluationReasons: true,
    };

    // NOTE: cypress-v2 test suite requirement
    if (window.Cypress) {
      // Allow us to set initial values for launchDarkly.
      config.bootstrap = window._initial_cypress_feature_flags;
      // Avoid launchDarkly flag stream updates via window.EventSource.
      config.streaming = false;
      // Avoid launchDarkly event requests.
      config.sendEvents = false;
      // Opt out of diagnostic data
      config.diagnosticOptOut = true;
    }
    const cachedUser = LocalStorage.getItem(Keys.LAUNCH_DARKLY_USER_ATTRIBUTES) || {};
    const initialUserAttributes = await helperInstance.initUserAttributes(cachedUser);

    const apiKey = getApiKey('launchDarkly');
    const ldClient = LDClient.initialize(apiKey, initialUserAttributes, config);
    helperInstance.launchDarkly = ldClient;
    return ldClient;
  }
}

const trimStringKeys = <T extends {}>(attributes: T): T =>
  Object.entries(attributes).reduce((acc, [key, value]) => {
    if (typeof value === 'object') {
      return { ...acc, [key]: value && trimStringKeys(value) };
    }

    if (typeof value === 'string') {
      return { ...acc, [key]: value.trim() };
    }

    return { ...acc, [key]: value };
  }, {} as T);

const emailAttributeLowerCase = ({ email, ...attributes }: UserAttributeUpdates) => ({
  ...attributes,
  ...(email ? { email: email.toLowerCase() } : null),
});

export const normalizeUserAttributes = (
  userAttributeUpdates: UserAttributeUpdates
): LDClient.LDUser => emailAttributeLowerCase(trimStringKeys(userAttributeUpdates));
