import { IAppliedRewards, IParseAppliedRewards } from 'state/loyalty/hooks/types';
import LocalStorage, { StorageKeys } from 'utils/local-storage';
import { parseStringifiedJSON } from 'utils/parse-string';

import { IRewardsState } from './rewards.types';

export const getAppliedRewardsFromStorage = () => {
  const storedAppliedRewards = LocalStorage.getItem(StorageKeys.APPLIED_LOYALTY_REWARDS);
  return parseStringifiedJSON({ value: storedAppliedRewards, defaultValue: {} }) || {};
};

export const updateAppliedRewardsInStorage = (appliedReward: IAppliedRewards) => {
  LocalStorage.setItem(StorageKeys.APPLIED_LOYALTY_REWARDS, JSON.stringify(appliedReward));
};

export const removeAppliedRewardsInStorage = () => {
  LocalStorage.removeItem(StorageKeys.APPLIED_LOYALTY_REWARDS);
};

export const getAppliedRewardsArrayAndTimesApplied = (appliedRewards: IAppliedRewards | {}) => {
  const { appliedRewardsArray, timesRewardApplied } = Object.entries(appliedRewards).reduce(
    (acc: IParseAppliedRewards, [cartId, { timesApplied, rewardId, sanityId }]) => {
      if (!timesApplied) {
        return acc;
      }
      // create array to send to BE for cart pricing
      acc.appliedRewardsArray.push({ rewardId, timesApplied, cartId, sanityId });
      // create map of total number of times a reward is applied within the cart
      acc.timesRewardApplied[rewardId] = ++acc.timesRewardApplied[rewardId] || timesApplied || 1;
      return acc;
    },
    { appliedRewardsArray: [], timesRewardApplied: {} }
  );
  const rewardsArray = appliedRewardsArray.length ? appliedRewardsArray : null;
  return { appliedRewardsArray: rewardsArray, timesRewardApplied };
};

export const setStateFromAppliedRewards = (
  state: IRewardsState,
  appliedRewards: IAppliedRewards
) => {
  updateAppliedRewardsInStorage(appliedRewards);
  const { appliedRewardsArray, timesRewardApplied } = getAppliedRewardsArrayAndTimesApplied(
    appliedRewards
  );
  state.appliedLoyaltyRewardsArray = appliedRewardsArray;
  state.totalTimesRewardApplied = timesRewardApplied;
  state.appliedLoyaltyRewards = appliedRewards;
};

export const getAppliedPointsInCart = (state: IRewardsState) => {
  const { appliedLoyaltyRewards = [], availableLoyaltyRewardsMap = {} } = state;
  return Object.values(appliedLoyaltyRewards).reduce((acc, { rewardBenefitId, timesApplied }) => {
    const { pointCost } = availableLoyaltyRewardsMap[rewardBenefitId] || {};
    return acc + (pointCost || 0) * timesApplied;
  }, 0);
};

export const getStagedCartPoints = (state: IRewardsState, basePoints: number) => {
  const appliedPointsInCart = getAppliedPointsInCart(state);
  const availablePointsToUse = basePoints - appliedPointsInCart;
  const isValid = 0 <= availablePointsToUse && availablePointsToUse <= basePoints;
  if (isValid) {
    return availablePointsToUse;
  } else {
    // TODO: on the else case, analyze if we need to reset some props if we find a discrepancy
    return null;
  }
};
