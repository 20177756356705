import { Auth } from '@aws-amplify/auth';
import Amplify from '@aws-amplify/core';
import * as CognitoLibrary from 'amazon-cognito-identity-js';
import { CognitoUserPool, CognitoUserSession, ICognitoStorage } from 'amazon-cognito-identity-js';

import { getCurrentSession } from 'remote/auth';

import { getConfigValue } from '../environment';

import storageSync from './storage';

export const initCognitoSession = async (): Promise<CognitoUserSession | null> => {
  Cognito.configure();

  // When initializing the Cognito session, we call a callback which,
  // in case the current user was already migrated, will set the new Cognito tokens
  // so that the app will continue with the new migrated user
  if (
    typeof window !== 'undefined' && // some tests run this from nodejs
    // @ts-ignore
    typeof window?.__rbiMigration?.initCognitoSessionCallback === 'function'
  ) {
    try {
      // @ts-ignore
      window.__rbiMigration.initCognitoSessionCallback({
        CognitoLibrary,
        Pool: Cognito.userPool(),
        Storage: storageSync,
      });
    } catch (e) {
      if (window.console && typeof window.console.error === 'function') {
        window.console.error(e);
      }
    }
  }

  return getCurrentSession();
};

/**
 * @class
 * The cognito class provides helper methods for configuring and interacting
 * with cognito services
 */
export class Cognito {
  public static storage: ICognitoStorage = storageSync as ICognitoStorage;

  private static userPoolEndpoint() {
    const region = getConfigValue('aws').region;
    return `https://cognito-idp.${region}.amazonaws.com/`;
  }

  public static userPool() {
    const { userPoolClientId, userPoolId } = getConfigValue('aws');

    return new CognitoUserPool({
      UserPoolId: userPoolId,
      ClientId: userPoolClientId,
      endpoint: Cognito.userPoolEndpoint(),
      Storage: storageSync as ICognitoStorage,
    });
  }

  public static configure() {
    const { userPoolClientId, userPoolId } = getConfigValue('aws');
    Auth.configure({
      userPoolId,
      userPoolWebClientId: userPoolClientId,
      endpoint: Cognito.userPoolEndpoint(),
      storage: storageSync,
    });

    Amplify.configure({ disabled: true });
  }
}

export default Cognito;
