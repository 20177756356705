import { useCallback, useEffect, useState } from 'react';

import { IBackendCartEntries, IHistoricalOrder, ServiceMode } from '@rbi-ctg/menu';
import { selectors, useAppSelector } from 'state/global-state';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useLocationContext } from 'state/location';
import { OrderStatus, useOrderContext } from 'state/order';
import { useStoreContext } from 'state/store';
import { routes } from 'utils/routing';
import { isCatering } from 'utils/service-mode';

import { isOfferType } from '../utils';

export interface IFavoriteOrder {
  cart: {
    cartEntries: IBackendCartEntries[];
    /* reorderCartEntries are needed to handleReorder */
    reorderCartEntries: IBackendCartEntries[];
    serviceMode: ServiceMode | null;
    cartVersion: number;
  };
  status?: OrderStatus;
}

export const useReorder = (order?: IHistoricalOrder | IFavoriteOrder | null) => {
  const [hasOffer, setHasOffer] = useState(false);
  const {
    reorder: { handleReorder, setPendingReorder },
  } = useOrderContext();
  const { isStoreOpenAndAvailable, noStoreSelected } = useStoreContext();
  const { navigate, location, setStoreLocatorCallbackUrl } = useLocationContext();
  const enableReorder = useFlag(LaunchDarklyFlag.ENABLE_REORDER);
  const incentivesIdsSet = useAppSelector(selectors.loyalty.selectIncentivesIds);

  /**
   * TODO: Add support for offers with reorder.
   * - right now just disabling reorder if there's an offer.
   */
  useEffect(() => {
    const hasOfferEntry =
      order && order.cart
        ? (order.cart.cartEntries || []).some(
            (entry: IBackendCartEntries) =>
              isOfferType(entry.type) || incentivesIdsSet.has(entry.sanityId)
          )
        : false;

    setHasOffer(hasOfferEntry);
  }, [incentivesIdsSet, order]);

  const handleReorderClick = useCallback(async () => {
    if (!order) {
      return;
    }

    if (isStoreOpenAndAvailable) {
      handleReorder(order);
    } else {
      const route = isCatering(order.cart.serviceMode)
        ? routes.serviceModeCatering
        : routes.storeLocator;
      setPendingReorder(order);
      setStoreLocatorCallbackUrl(location.pathname);
      navigate(route, { replace: true });
    }
  }, [
    order,
    isStoreOpenAndAvailable,
    handleReorder,
    setPendingReorder,
    setStoreLocatorCallbackUrl,
    location.pathname,
    navigate,
  ]);

  const serviceMode = order?.cart.serviceMode ?? null;
  const isCateringWithWrongStatus =
    isCatering(serviceMode) && order?.status !== OrderStatus.CATERING_SUCCESSFUL;

  const hasDonation = (order?.cart.cartEntries || []).some(
    (entry: IBackendCartEntries) => entry.isDonation
  );
  const disabled =
    !enableReorder ||
    hasOffer ||
    hasDonation ||
    isCateringWithWrongStatus ||
    isCatering(serviceMode) ||
    (!isStoreOpenAndAvailable && !noStoreSelected);

  return { handleReorderClick, disabled };
};
