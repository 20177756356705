import { useEffect, useMemo } from 'react';

import useLocation from 'hooks/location/use-location';
import { IFeatureHomePageQuery, useFeatureHomePageQuery } from 'hooks/use-feature-home-page-query';
import { useAuthContext } from 'state/auth';
import { useErrorContext } from 'state/errors';
import { useFeaturesContext } from 'state/features';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { routes } from 'utils/routing';

import { FeatureHomePage } from '../types';

import { useFeatureHomePageComponents } from './feature-home-page-components';

export const processFeatureHomePage = (
  data: IFeatureHomePageQuery | undefined,
  isAuthenticated: boolean
) => {
  const featureHomePage = data?.FeatureHomePage;
  if (!featureHomePage) {
    return null;
  }

  // Keep it verbose to make things clear and to keep type safety
  if (isAuthenticated) {
    return featureHomePage?.authenticated;
  }
  return featureHomePage?.unauthenticated;
};

export const useFeatureHomePage = () => {
  const { featureHomePageId = '', featureIdsLoading } = useFeaturesContext();
  const { isAuthenticated } = useAuthContext();
  const { setSanityDataRef } = useErrorContext();
  const {
    location: { pathname },
  } = useLocation();

  const isUserAuthenticated = isAuthenticated();

  // This Flag determines which variation of homepage is shown to the user.
  // The ID that we pass is the sanity ID for the homepage document
  const abTestingHomepageValue = useFlag(LaunchDarklyFlag.AB_TESTING_HOME_PAGE);
  const homePageId = { featureHomePageId: abTestingHomepageValue ?? featureHomePageId };

  const { data, loading: featureHomePageComponentIdsLoading } = useFeatureHomePageQuery({
    variables: homePageId,
    skip: !featureHomePageId && !abTestingHomepageValue,
  });

  const featureHomePage = useMemo(() => processFeatureHomePage(data, isUserAuthenticated), [
    data,
    isUserAuthenticated,
  ]);

  // Now we go get the data for each component on the home page
  // NOTE: this was purposely split into separate queries to always
  // ensure we're hitting the Sanity CDN instead of the API directly
  // which was caused by the combined feature home page query hitting
  // the URL character count limit and falling back to a POST on API
  const {
    featureHomePageComponents,
    featureHomePageComponentsLoading,
  } = useFeatureHomePageComponents(featureHomePage || undefined);

  // Now we'll stitch together the home page data structure and component data
  const featureHomePageWithPopulatedComponents = useMemo(() => {
    if (!featureHomePage || !featureHomePageComponents) {
      return null;
    }
    if (featureHomePageComponents) {
      return { ...featureHomePage, components: featureHomePageComponents };
    }
    return featureHomePage;
  }, [featureHomePage, featureHomePageComponents]);

  const featureHomePageLoading =
    featureIdsLoading || featureHomePageComponentIdsLoading || featureHomePageComponentsLoading;

  useEffect(() => {
    if (pathname === routes.base && data) {
      setSanityDataRef(featureHomePage);
    }
  }, [featureHomePage, data, pathname, setSanityDataRef]);

  return {
    featureHomePageLoading,
    featureHomePage: featureHomePageWithPopulatedComponents as FeatureHomePage,
    homePageId,
  };
};
