import gql from 'graphql-tag';

export default gql`
  fragment VideoFileFragment on File {
    asset {
      assetId
      path
      url
    }
  }
`;
