import React from 'react';

import { VisuallyHidden } from '@rbilabs/components-library';
import { useIntl } from 'react-intl';

/**
 * Opens the link in a new tab.
 */
export const ExternalLink: React.FC<React.AnchorHTMLAttributes<{}>> = ({ children, ...props }) => {
  const { formatMessage } = useIntl();

  return (
    <ExternalLinkView {...props} accessibilityText={formatMessage({ id: 'linkOpensInNewTab' })}>
      {children}
    </ExternalLinkView>
  );
};

interface ExternalLinkViewProps extends React.AnchorHTMLAttributes<{}> {
  accessibilityText?: string;
}

export const ExternalLinkView: React.FC<ExternalLinkViewProps> = ({
  children,
  accessibilityText,
  ...props
}) => (
  <a {...props} target="_blank" rel="noopener noreferrer">
    {children}
    <VisuallyHidden>{accessibilityText}</VisuallyHidden>
  </a>
);

export default ExternalLink;
