import React, { useMemo } from 'react';

import { Footer } from '@rbilabs/components-library';
import { useIntl } from 'react-intl';

import { LanguageSelectorV2 } from 'components/language-selector-v2';
import useLocation from 'hooks/location/use-location';
import { useFeatureFooterV2 } from 'hooks/use-feature-footer-v2';
import useMediaQuery from 'hooks/use-media-query';
import { useLocale } from 'state/intl';
import { LocaleData } from 'state/intl/types';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { routes } from 'utils/routing';

export const FooterV2: React.FC = () => {
  const isMobile = useMediaQuery('mobile');
  const { featureFooterV2Loading, featureFooterV2 } = useFeatureFooterV2();
  const singleRegionLanguageSelector = useFlag(LaunchDarklyFlag.SINGLE_REGION_LANGUAGE_SELECTOR);
  const { region, supportedLocales, readableLanguages, readableRegions } = useLocale();
  const { formatMessage } = useIntl();
  const {
    location: { pathname },
  } = useLocation();

  /** All valid locales for given brand/region */
  const locales: LocaleData[] = useMemo(
    () =>
      singleRegionLanguageSelector
        ? supportedLocales.filter(l => l.region === region)
        : supportedLocales,
    [region, singleRegionLanguageSelector, supportedLocales]
  );

  // Hide footer on sign up & login flow
  if (
    pathname === formatMessage({ id: 'routes.signUp' }) ||
    pathname === formatMessage({ id: 'routes.signIn' }) ||
    pathname === routes.confirmOtp
  ) {
    return null;
  }

  if (isMobile) {
    return null;
  }

  if (!featureFooterV2 || featureFooterV2Loading) {
    return null;
  }
  const { mainLinks, miscellaneousLinks, copyrightText, socialMediaData } = featureFooterV2;
  if (!mainLinks?.length || !miscellaneousLinks?.length || !copyrightText || !socialMediaData) {
    return null;
  }

  const localeOptions = locales.map(locale => {
    return {
      value: locale.code,
      /** label reads as English (USA) for example */
      label: `${readableLanguages[locale.language]} (${readableRegions[region]})`,
    };
  });

  return (
    <Footer
      mainLinks={mainLinks}
      miscellaneousLinks={miscellaneousLinks}
      copyrightText={copyrightText}
      socialMediaData={socialMediaData}
      localeSelector={
        locales.length > 1 ? (
          <LanguageSelectorV2 localeOptions={localeOptions} redirectToCurrentLocation={true} />
        ) : null
      }
      id="footer-component"
      onClick={() => null}
    />
  );
};
