import {
  GraphQLEnv,
  brand,
  getConfigValue,
  graphqlEnv,
  graphqlGatewayEnv,
} from 'utils/environment';

export const getGraphqlApiUrl = () => {
  const awsConfig = getConfigValue('aws');
  return graphqlEnv() === GraphQLEnv.TEST || graphqlEnv() === GraphQLEnv.LOCAL
    ? 'http://localhost:3001/graphql'
    : awsConfig.gqlApiUrl || `https://use1-${graphqlEnv()}-${brand()}.rbictg.com/graphql`;
};

export enum QUERY_TYPE {
  LambdaGraphqlQuery,
  SanityGroqQuery,
  SanityGraphqlQuery,
  SanityGraphqlV2Query,
}

export const getGraphqlGatewayApiUrl = () => {
  const awsConfig = getConfigValue('aws');
  switch (graphqlGatewayEnv()) {
    case GraphQLEnv.TEST:
      return 'https://use1-dev-bk-gateway.rbictg.com/graphql';
    case GraphQLEnv.LOCAL:
      return 'http://localhost:3002/graphql';
    default:
      return (
        awsConfig.gqlGatewayApiUrl ||
        `https://use1-${graphqlEnv()}-${brand()}-gateway.rbictg.com/graphql`
      );
  }
};

export enum HttpErrorCodes {
  BadRequest = 400,
  InternalServerError = 500,
  TooManyRequests = 429,
  Unauthorized = 401,
}
