import { ApolloLink, NextLink, Operation } from 'apollo-link';
import { merge } from 'lodash-es';

import { IMParticleCtx } from 'state/mParticle';

export class WithMParticleLink extends ApolloLink {
  public mParticle: IMParticleCtx;

  public request(operation: Operation, forward: NextLink) {
    const context = operation.getContext();

    operation.setContext(merge({ mParticle: this.mParticle }, context));

    return forward(operation);
  }

  public setMParticle(mParticle: IMParticleCtx): IMParticleCtx {
    this.mParticle = mParticle;
    return mParticle;
  }
}

/**
 * use a singleton instance to share mParticle across requests
 */
export const withMParticle = new WithMParticleLink();
