import React from 'react';

import { Icon, VisuallyHidden } from '@rbilabs/components-library';
import { useIntl } from 'react-intl';

import { ToastTypeLogo } from './toast-type-logo';
import { Close, Container, Message, MessageContainer, Wrapper } from './toast.styled';
import { IToastViewProps } from './types';

export const ToastView: React.FC<IToastViewProps> = ({
  message: label,
  type,
  closeToast,
  closeIcon: CloseIcon,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Wrapper type={type}>
      <Container>
        <MessageContainer>
          <ToastTypeLogo type={type} />
          <Message data-testid="toast-message">{label}</Message>
        </MessageContainer>
        {CloseIcon ? (
          <CloseIcon closeToast={closeToast} />
        ) : (
          <Close data-testid="closeToast" onClick={closeToast}>
            <Icon color="white" icon="close" height="2.25rem" width="2.25rem" aria-hidden />
            <VisuallyHidden>{formatMessage({ id: 'close' })}</VisuallyHidden>
          </Close>
        )}
      </Container>
    </Wrapper>
  );
};
