import styled from 'styled-components';

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.875rem;
  margin: 0 0 -0.5rem 0;
  border-top: 1px solid ${Styles.color.background};
`;
