/**
 * AUTOGENERATED - DO NOT EDIT DIRECTLY
 *
 * Please see "scripts/regionalization/README.md" for how to modify
 */

export enum ISOs {
  ATA = 'ATA',
  AUT = 'AUT',
  CAN = 'CAN',
  CHE = 'CHE',
  CZE = 'CZE',
  DEU = 'DEU',
  ESP = 'ESP',
  FRA = 'FRA',
  GBR = 'GBR',
  KOR = 'KOR',
  NLD = 'NLD',
  NZL = 'NZL',
  POL = 'POL',
  PRT = 'PRT',
  ROU = 'ROU',
  USA = 'USA',
  ZAF = 'ZAF',
  AQ = 'ATA',
  AT = 'AUT',
  CA = 'CAN',
  CH = 'CHE',
  CZ = 'CZE',
  DE = 'DEU',
  ES = 'ESP',
  FR = 'FRA',
  GB = 'GBR',
  KR = 'KOR',
  NL = 'NLD',
  NZ = 'NZL',
  PL = 'POL',
  PT = 'PRT',
  RO = 'ROU',
  US = 'USA',
  ZA = 'ZAF',
}

export enum RegionToISOs {
  AQ = ISOs.ATA,
  AT = ISOs.AUT,
  CA = ISOs.CAN,
  CH = ISOs.CHE,
  CZ = ISOs.CZE,
  DE = ISOs.DEU,
  ES = ISOs.ESP,
  FR = ISOs.FRA,
  GB = ISOs.GBR,
  KR = ISOs.KOR,
  NL = ISOs.NLD,
  NZ = ISOs.NZL,
  PL = ISOs.POL,
  PT = ISOs.PRT,
  RO = ISOs.ROU,
  US = ISOs.USA,
  ZA = ISOs.ZAF,
}

export enum ISOsToRegions {
  ATA = 'AQ',
  AUT = 'AT',
  CAN = 'CA',
  CHE = 'CH',
  CZE = 'CZ',
  DEU = 'DE',
  ESP = 'ES',
  FRA = 'FR',
  GBR = 'GB',
  KOR = 'KR',
  NLD = 'NL',
  NZL = 'NZ',
  POL = 'PL',
  PRT = 'PT',
  ROU = 'RO',
  USA = 'US',
  ZAF = 'ZA',
}

export enum PaymentISOs {
  AQ = 'AQ',
  AT = 'AT',
  CA = 'CA',
  CH = 'CH',
  CZ = 'CZ',
  DE = 'DE',
  ES = 'ES',
  FR = 'FR',
  GB = 'GB',
  KR = 'KR',
  NL = 'NL',
  NZ = 'NZ',
  PL = 'PL',
  PT = 'PT',
  RO = 'RO',
  US = 'US',
  ZA = 'ZA',
}

export enum Currencies {
  CAD = 'CAD',
  CHF = 'CHF',
  CZK = 'CZK',
  EUR = 'EUR',
  GBP = 'GBP',
  KRW = 'KRW',
  NZD = 'NZD',
  PLN = 'PLN',
  RON = 'RON',
  USD = 'USD',
  ZAR = 'ZAR',
}

export const BILLING_COUNTRIES = {
  [ISOs.ATA]: { countryCode: PaymentISOs.AQ, currencyCode: Currencies.USD },
  [ISOs.AUT]: { countryCode: PaymentISOs.AT, currencyCode: Currencies.EUR },
  [ISOs.CAN]: { countryCode: PaymentISOs.CA, currencyCode: Currencies.CAD },
  [ISOs.CHE]: { countryCode: PaymentISOs.CH, currencyCode: Currencies.CHF },
  [ISOs.CZE]: { countryCode: PaymentISOs.CZ, currencyCode: Currencies.CZK },
  [ISOs.DEU]: { countryCode: PaymentISOs.DE, currencyCode: Currencies.EUR },
  [ISOs.ESP]: { countryCode: PaymentISOs.ES, currencyCode: Currencies.EUR },
  [ISOs.FRA]: { countryCode: PaymentISOs.FR, currencyCode: Currencies.EUR },
  [ISOs.GBR]: { countryCode: PaymentISOs.GB, currencyCode: Currencies.GBP },
  [ISOs.KOR]: { countryCode: PaymentISOs.KR, currencyCode: Currencies.KRW },
  [ISOs.NLD]: { countryCode: PaymentISOs.NL, currencyCode: Currencies.EUR },
  [ISOs.NZL]: { countryCode: PaymentISOs.NZ, currencyCode: Currencies.NZD },
  [ISOs.POL]: { countryCode: PaymentISOs.PL, currencyCode: Currencies.PLN },
  [ISOs.PRT]: { countryCode: PaymentISOs.PT, currencyCode: Currencies.EUR },
  [ISOs.ROU]: { countryCode: PaymentISOs.RO, currencyCode: Currencies.RON },
  [ISOs.USA]: { countryCode: PaymentISOs.US, currencyCode: Currencies.USD },
  [ISOs.ZAF]: { countryCode: PaymentISOs.ZA, currencyCode: Currencies.ZAR },
};
