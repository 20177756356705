import gql from 'graphql-tag';

import { NutritionExplorerItemFragment } from './nutrition-explorer-menu';

export const ConditionAllergenFragment = gql`
  fragment ConditionAllergenFragment on ConditionAllergen {
    _type
    allergenIdentifier
    comparisonOperator
    comparisonValue
  }
`;
export const ConditionNutritionFragment = gql`
  fragment ConditionNutritionFragment on ConditionNutrition {
    _type
    nutritionIdentifier
    comparisonOperator
    comparisonValue
  }
`;
export const ConditionParentCategoryFragment = gql`
  fragment ConditionParentCategoryFragment on ConditionParentCategory {
    _type
    parentCategory {
      ... on Section {
        _id
      }
      ... on Picker {
        _id
      }
    }
  }
`;
export const ConditionItemOneOfFragment = gql`
  fragment ConditionItemOneOfFragment on ConditionItemOneOf {
    _type
    items {
      ...NutritionExplorerItemFragment
    }
  }
  ${NutritionExplorerItemFragment}
`;

export const FilterFragment = gql`
  fragment FilterFragment on Filter {
    _type
    description {
      locale: _LANG_
    }
    conditions {
      ...ConditionAllergenFragment
      ...ConditionNutritionFragment
      ...ConditionParentCategoryFragment
      ...ConditionItemOneOfFragment
    }
  }
  ${ConditionAllergenFragment}
  ${ConditionNutritionFragment}
  ${ConditionParentCategoryFragment}
  ${ConditionItemOneOfFragment}
`;

export const FilterGroupFragment = gql`
  fragment FilterGroupFragment on FilterGroup {
    _type
    description {
      locale: _LANG_
    }
    filters {
      ...FilterFragment
    }
  }
  ${FilterFragment}
`;
