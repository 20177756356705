import { OTPAuthDeliveryMethod } from 'utils/otp';

/**
 * The following enum and utility types give us strongly-typed
 * LaunchDarkly flag values. When adding a new flag, two steps
 * must be taken to ensure the correct type is associated with the flag
 */

/**
 * First update this enum to include the flag name
 * Note: the flag name should appear exactly as is it is in the
 * Launch Darkly dashboard
 */
export enum LaunchDarklyFlag {
  AB_TESTING_HOME_PAGE = 'ab-testing-home-page',
  AUTO_FILL_TEST_CARD = 'autoFillTestCard',
  CHEF_CART_UPSELL_ITEM_COUNT = 'chefUpsellItemCount',
  CHEF_RECOMMENDATION_ENGINE_2 = 'chef-recommendation-engine-2',
  CURBSIDE_VEHICLE_DESCRIPTION_MAX_LENGTH = 'curbsideVehicleDescriptionMaxLength',
  DATADOG_LOG_SAMPLE_RATE = 'datadogLogSampleRate',
  DATADOG_RUM_SAMPLE_RATE = 'datadogRumSampleRate',
  DEFAULT_SERVICE_MODE_IN_STORE = 'defaultServiceModeInStore',
  DELIVERY_BANNER_POLLING = 'deliveryBannerPolling',
  DELIVERY_RADIUS_IN_MILES = 'deliveryRadiusInMiles',
  DISABLE_DINE_IN = 'disableDineIn',
  DISABLE_DRIVE_THRU = 'disableDriveThru',
  DISABLE_LOCALIZATION = 'disableLocalization',
  DISABLE_TAKE_OUT = 'disableTakeOut',
  DISCOUNTED_DELIVERY_THRESHOLD_CENTS = 'discounted-delivery-threshold-cents',
  /** temporary flag: once menu redesign and menu service get integrated, we should remove all usages of this flag */
  DISPLAY_NUTRITION_WITH_MODIFIERS_FROM_SANITY = 'displayNutritionWithModifiersFromSanity',
  DEPRECATE_CHEF = 'deprecate-chef',
  /** temporary flag: once store selection 2.0 is integrated, this can be removed. */
  DISPLAY_SELECTED_RESTAURANT_INFORMATION_WHEN_RESTAURANT_UNAVAILABLE = 'display-selected-restaurant-information-when-restaurant-unavailable',
  ENABLE_ACCOUNT_DELETE_PAGE = 'enableAccountDeletePage',
  ENABLE_ACCOUNT_DELETE_ONE_TRUST = 'enableAccountDeleteOneTrust',
  ENABLE_ACCOUNT_IN_FOOTER = 'enable-account-in-footer',
  ENABLE_ADD_APPLE_WALLET_BUTTON = 'enableAddToAppleWallet',
  ENABLE_ADD_EXTRAS = 'enable-add-extras',
  ENABLE_ADD_GOOGLE_PAY_BUTTON = 'enableAddToGooglePay',
  ENABLE_ALLERGENS_DISCLAIMER = 'enableAllergensDisclaimer',
  ENABLE_ALPHA_BETA_STORE_ORDERING = 'enable-alpha-beta-store-ordering',
  ENABLE_AMENITIES_SECTION = 'enable-amenities-section',
  ENABLE_APP_FLOW_UPDATE = 'enable-app-flow-update',
  ENABLE_APPLE_PAY = 'enableApplePay',
  ENABLE_AUTO_LOCALE = 'enableAutoLocale',
  ENABLE_AUTO_LINK_REWARDS_CARD = 'enable-auto-link-rewards-card',
  ENABLE_AUTO_SELECT_RESTAURANT = 'enableAutoSelectRestaurant',
  ENABLE_AUTO_SIGN_IN_ON_SIGN_UP = 'enableAutoSignInOnSignUp',
  ENABLE_BEEPER_MODAL = 'enable-beeper-modal',
  ENABLE_SIGNOUT_ON_INVALID_TOKEN = 'enable-signout-on-invalid-token',
  ENABLE_SMART_DEFAULT = 'enable-smart-default',
  ENABLE_CLOSING_TIME_DIALOG = 'enable-closing-time-dialog',
  ENABLE_BONUS_POINTS_REWARDS_LOGO = 'enableBonusPointsRewardsLogo',
  ENABLE_BOTTOM_HERO_BUTTON = 'enable-bottom-hero-button',
  ENABLE_BOTTOM_SERVICE_MODE = 'enableBottomServiceMode',
  ENABLE_CALORIES_CALCULATOR = 'enable-calories-calculator',
  ENABLE_CASH_PAYMENT = 'enable-cash-payment',
  ENABLE_CATERING = 'enableCatering',
  ENABLE_CATERING_DELIVERY = 'enableCateringDelivery',
  ENABLE_CHECKOUT_UPSELL_ITEMS_2 = 'enable-checkout-upsell-items-2',
  ENABLE_CLAIM_LOYALTY_POINTS = 'enable-claim-loyalty-points',
  ENABLE_CLEAR_STORE_AFTER_ORDER = 'enable-clear-store-after-order',
  ENABLE_COGNITO_SIGNUP_IN_BE = 'enableCognitoSignupInBe',
  ENABLE_COMMUNICATION_PREFERENCES = 'enableCommunicationPreferences',
  ENABLE_CONSOLIDATED_DELIVERY_RESTAURANT_BACKEND_LOGIC = 'enable-consolidated-delivery-restaurant-backend-logic',
  ENABLE_COOKIE_BANNER = 'enableCookieBanner',
  ENABLE_COOKIE_VERSIONING = 'enableCookieVersioning',
  ENABLE_CURBSIDE = 'enableCurbside',
  ENABLE_CUSTOM_BIRTHDAY_INPUT = 'enableCustomBirthdayInput',
  ENABLE_CUSTOMER_CAN_EDIT_OWN_PHONE_NUMBER = 'enable-customer-can-edit-own-phone-number',
  ENABLE_DELIVERY = 'delivery',
  ENABLE_DELIVERY_CANCELLATION_POLICY = 'enableDeliveryCancellationPolicy',
  ENABLE_DELIVERY_ONLY = 'enable-delivery-only',
  ENABLE_DELIVERY_ORDER_TIMED_FIRE = 'enable-delivery-order-timed-fire',
  ENABLE_DELIVERY_WATCH_DRIVER_LOCATION = 'enableDeliveryWatchDriverLocation',
  /**
   * Enable checkout outside opening hours, only for delivery
   */
  ENABLE_DELIVERY_CHECKOUT_OUTSIDE_OPENING_HOURS = 'enable-delivery-checkout-outside-opening-hours',
  /**
   * Whether or not to require address line 2 in the delivery details form
   * - Some markets, like BK DE, require this to identify the customer
   */
  ENABLE_DELIVERY_DETAILS_FORM_REQUIRE_ADDRESS_2 = 'enable-delivery-details-form-require-address-2',
  ENABLE_DESKTOP_SIDE_NAVIGATION = 'enable-desktop-side-navigation',
  ENABLE_DIAGNOSTIC_TOOLS = 'enableDiagnosticTools',
  ENABLE_DISCOUNTS_ON_ORDER_RECEIPT = 'enable-discounts-on-order-receipt',
  ENABLE_DOUBLE_VAULTING_FROM_PAYMENT_METHODS = 'enableDoubleVaultingFromPaymentMethods',
  ENABLE_DRIVE_THRU_TIMED_FIRE = 'enable-timed-fire-for-drive-thru-web',
  ENABLE_EDIT_CART = 'enable-edit-cart',
  ENABLE_EU_NUTRITION_DISPLAY = 'nutritionEuDisplay',
  ENABLE_FAVORITE_STORES = 'enableFavoriteStores',
  ENABLE_FAVORITES = 'enable-favorites',
  ENABLE_FAVORITES_DISPLAY_CALORIES = 'enable-favorites-display-calories',
  ENABLE_FEATURE_HOME_PAGE = 'enableFeatureHomePage',
  ENABLE_FEATURE_NUTRITION_INTEGRATION = 'enable-feature-nutrition-integration',
  ENABLE_FEATURE_ONBOARDING = 'enable-feature-onboarding',
  ENABLE_FEES_ON_ORDER_RECEIPT = 'enable-fees-on-order-receipt',
  ENABLE_FIND_RESTAURANT_LINK = 'enable-find-restaurant-link',
  ENABLE_FIXED_ADD_TO_ORDER_AND_QUANTITY_BUTTON = 'enable-fixed-add-to-order-and-quantity-button',
  ENABLE_FOOTER_CART_PAGE = 'enable-footer-cart-page',
  ENABLE_FORCED_NATIVE_APP_UPDATE = 'enable-forced-native-app-update',
  ENABLE_FORMATTED_CALORIES_FOR_DEFAULT = 'enableFormattedCaloriesForDefault',
  ENABLE_FORTER_FRAUD = 'enable-forter-fraud',
  ENABLE_FREE_DELIVERY_CHECKOUT_PROGRESS_BAR = 'enableFreeDeliveryCheckoutProgressBar',
  ENABLE_GIFT_CARD = 'gift-card-payment-method',
  ENABLE_GLOBAL_OFFERS_COOLDOWN = 'enable-global-offers-cooldown',
  ENABLE_GOOGLE_PAY = 'enableGooglePay',
  ENABLE_GUEST_AND_MULTI_STEP_SUPPORT_PAGE = 'enableGuestAndMultiStepSupportForm',
  ENABLE_HAMBURGER_MENU_ON_MOBILE = 'enable-hamburger-menu-on-mobile',
  ENABLE_HIDE_MODIFIER_MULTIPLIERS = 'enable-hide-modifier-multipliers',
  ENABLE_HIDE_OFFERS_FOR_SERVICE_MODE = 'enableHideOffersForServiceMode',
  ENABLE_HIDE_LOYALTY_BANNER_WELCOME_MESSAGE = 'enableHideLoyaltyBannerWelcomeMessage',
  /**
   * If true, hide the "Order Here" button in the store info modal
   * - Used with store card buttons to effectively disable pickup service mode
   */
  ENABLE_HIDE_STORE_MODAL_ORDER_HERE_BUTTON = 'enable-hide-store-modal-order-here-button',
  /**
   * If true, hide the tax line in the cost breakdown wherever shown (checkout, receipt, etc)
   * Applicable in any market where we use VAT instead of sales tax, like BK DE
   */
  ENABLE_HIDE_TAX_LINE = 'enable-hide-tax-line',
  /**
   * If true, hide the subtotal line in the cost breakdown (checkout, recent orders)
   */
  ENABLE_HIDE_SUBTOTAL_LINE = 'enable-hide-subtotal-line',
  /**
   * If true, move the street number after the street name
   */
  ENABLE_STREET_NUMBER_AFTER_STREET_NAME = 'enable-street-number-after-street-name',
  ENABLE_HOME_PAGE_RECENT_ITEMS = 'enableHomePageRecentItems',
  ENABLE_HOMEPAGE_ALERT = 'enableHomepageAlert',
  ENABLE_HTML_IN_APP_MESSAGES_IN_BRAZE_SDK = 'enable-html-in-app-messages-in-braze-sdk',
  ENABLE_IM_CLOSE_DISPLAY = 'enableImCloseDisplay',
  ENABLE_IN_APP_QUIZ_GAME = 'enableInAppQuizGame',
  ENABLE_IN_RESTAURANT_LOYALTY = 'enable-in-restaurant-loyalty',
  ENABLE_IN_RESTAURANT_VARIATIONS = 'enable-in-restaurant-variations',
  ENABLE_STATIC_IN_STORE_IDENTIFICATION = 'enable-static-in-store-identification',
  ENABLE_INTERNAL_DIAGNOSTIC_TOOLS = 'enableInternalDiagnosticTools',
  ENABLE_LINK_PHYSICAL_CARD = 'enable-link-physical-card',
  ENABLE_LOCATOR_CLEAR_STORE_AND_SM_BUTTON = 'enable-locator-clear-store-and-sm-button',
  ENABLE_LOCATOR_UI_REFRESH = 'enable-locator-ui-refresh',
  ENABLE_LOCATOR_UI_REFRESH_DELIVERY = 'enable-locator-ui-refresh-delivery',
  ENABLE_LOCK_ON_REWARD_IMAGE = 'enable-lock-on-reward-image',
  ENABLE_LOCKED_OFFERS = 'enableLockedOffers',
  ENABLE_LOGROCKET = 'log-rocket',
  ENABLE_LOGROCKET_TARGETED_RECORDING = 'enable-log-rocket-targeted-recording',
  ENABLE_LOYALTY = 'enable-loyalty-fe',
  ENABLE_LOYALTY_OPT_IN_MODAL = 'enable-loyalty-opt-in-modal',
  ENABLE_LOYALTY_OFFERS = 'enable-loyalty-offers',
  ENABLE_LOYALTY_POINTS_IN_HEADER = 'enable-loyalty-points-in-header',
  ENABLE_LOYALTY_QR_AND_SHORT_CODE = 'enable-loyalty-qr-and-short-code',
  ENABLE_LOYALTY_QR_CODE_IN_MY_CODE = 'enable-qr-code-in-my-code-page',
  ENABLE_LOYALTY_QR_SNAP = 'enable-qr-snap',
  ENABLE_LOYALTY_STANDARD_OFFERS = 'enable-loyalty-standard-offers',
  ENABLE_LOYALTY_SURPRISE_OFFERS = 'enable-loyalty-surprise-offers',
  ENABLE_MENU_LIST_UI = 'enable-menu-list-ui',
  ENABLE_MENU_UPSELL_ITEMS = 'enable-menu-upsell-items',
  ENABLE_METER_ON_REWARDS = 'enable-meter-on-rewards',
  ENABLE_MOBILE_FOOTER = 'enable-mobile-footer',
  ENABLE_MORE_INFO_OPTIONAL_OFFER_DISCLAIMER = 'enableMoreInfoOptionalOfferDisclaimer',
  ENABLE_MOVE_SIGNUP_AFTER_CART = 'enable-move-signup-after-cart',
  ENABLE_MPARTICLE_QR_CODE = 'enable-mparticle-qr-code',
  ENABLE_COGNITOID_QR_CODE = 'enable-cognitoid-qr-code',
  ENABLE_MY_CODE_NEW_UI = 'enable-my-code-new-ui',
  ENABLE_MY_CODE_PAYMENT_METHOD = 'enable-my-code-payment-method',
  ENABLE_NATIVE_MOBILE_ACCOUNT = 'enable-native-mobile-account',
  ENABLE_NEW_MENU_CUSTOMIZATION_DESIGN = 'enable-new-menu-customization-design',
  ENABLE_NEW_WIZARD_ISSUE_COLLECTOR = 'new-wizard-issue-collector',
  ENABLE_NEW_PHONE_NUMBER_FIELD = 'enableNewPhoneNumberField',
  ENABLE_NEW_SERVICE_MODE_FLOW = 'enableNewServiceModeFlow',
  /** temporary flag: once proper phone validation is implemented for all regions, this can be removed. */
  ENABLE_NUMERIC_PHONE_VALIDATION = 'enable-numeric-phone-validation',
  ENABLE_NUTRITION_DECIMALS = 'enableNutritionDecimals',
  ENABLE_NUTRITION_EXPLORER = 'enableNutritionExplorer',
  ENABLE_NUTRITION_EXPLORER_ALLERGENS = 'enable-nutrition-explorer-allergens',
  ENABLE_OFFER_BADGES = 'enableOfferBadges',
  ENABLE_OFFER_GIFTING = 'enable-offer-gifting',
  ENABLE_OFFER_QR_CODE = 'enable-offer-qr-code',
  ENABLE_OFFER_REDEMPTION_METHODS = 'enable-offer-redemption-methods',
  ENABLE_OFFERS = 'enableOffers',
  ENABLE_OFFERS_ACTIVATION = 'enableOffersActivation',
  ENABLE_OFFERS_EDIT_CART = 'enable-offers-edit-cart',
  ENABLE_OFFERS_MOBILE = 'enableOffersMobile',
  ENABLE_OFFERS_REFRESH = 'enableOffersRefresh',
  ENABLE_OFFERS_SELECT_RESTAURANT_CTA = 'enableOffersSelectRestaurantCta',
  ENABLE_OFFERS_UNDER_LOYALTY_TAB = 'offers-under-loyalty-tab',
  ENABLE_OLD_OFFERS_HOMEPAGE_SECTION = 'enableOldOffersHomepageSection',
  ENABLE_ONE_TIME_PASSWORD = 'enable-one-time-password',
  ENABLE_ONE_TRUST_COOKIE_CONSENT_BANNER = 'enable-one-trust-cookie-consent-banner',
  ENABLE_ORDER_LINK_NAV = 'enableOrderLinkNav',
  ENABLE_ORDER_NUMBER_GENERATION = 'enable-order-number-generation',
  ENABLE_ORDER_SUPPORT = 'enableOrderSupport',
  ENABLE_ORDER_TIMED_FIRE = 'enableOrderTimedFire',
  ENABLE_ORDER_TIMED_FIRE_CARROLS = 'enableOrderTimedFireCarrols',
  ENABLE_ORDER_TIMED_FIRE_NCR = 'enableOrderTimedFireNCR',
  ENABLE_ORDER_TIMED_FIRE_SICOM = 'enableOrderTimedFireSICOM',
  ENABLE_ORDER_TIMED_FIRE_QST = 'enableOrderTimedFireQST',
  ENABLE_ORDER_TIMED_FIRE_RPOS = 'enable-rpos-order-timed-fire',
  ENABLE_ORDERING = 'enableOrdering',
  ENABLE_OTP_EMAIL = 'enableOtpEmail',
  ENABLE_PAYBACK = 'enable-payback',
  ENABLE_PAYMENT_ERROR_CONTINUE = 'enable-payment-error-continue',
  ENABLE_PAYPAL_PAYMENTS = 'enable-paypal-payments',
  ENABLE_PERSONALIZED_OFFERS_TEMPLATE = 'fe-personalized-offers-template',
  ENABLE_PLU_OFFER_QR_CODE = 'enable-plu-offer-qr-code',
  ENABLE_PLK_TRANSPARENT_BACKGROUND_MENU_TILE = 'enable-plk-transparent-background-menu-tile',
  ENABLE_PYPESTREAM = 'enable-pypestream',
  ENABLE_PREMIUM_COMBO_SLOTS = 'enable-premium-combo-slots',
  ENABLE_PRICE_AND_CALORIES_IN_MENU = 'enable-price-and-calories-in-menu',
  ENABLE_PRODUCT_IMAGE_DISCLAIMER = 'enable-product-image-disclaimer',
  ENABLE_PROMO_CODE_AT_CHECKOUT = 'enablePromoCodeAtCheckout',
  ENABLE_PROMO_CODE_OFFERS = 'enablePromoCodeOffers',
  ENABLE_LOYALTY_PROMO_CODES = 'enable-loyalty-promo-codes',
  ENABLE_RADAR_TRACKING = 'enableRadarTrackingOnNewSessions',
  ENABLE_RECENT_ITEMS_ADD_TO_CART = 'enable-recent-items-add-to-cart',
  ENABLE_RECENT_ITEMS_IN_MENU = 'enable-recent-items-in-menu',
  ENABLE_RECENT_ITEMS_WITH_MODIFIERS = 'enableRecentItemsWithModifiers',
  ENABLE_RECENTLY_ORDERED_ITEMS = 'enableRecentlyOrderedItems',
  ENABLE_REORDER = 'enable-reorder',
  ENABLE_REQUEST_MY_INFORMATION = 'enableRequestMyInformation',
  ENABLE_RETURN_ALL_REWARDS = 'enable-return-all-rewards',
  ENABLE_REWARD_CARD_DEACTIVATION = 'enable-reward-card-deactivation',
  ENABLE_REWARD_PAGE_STAND_ALONE = 'enable-reward-page-stand-alone',
  ENABLE_RUTR = 'enableRutr',
  ENABLE_RUTR_HOME_PAGE_BANNER = 'enableRutrHomePageBanner',
  ENABLE_RUTW_COUNTDOWN = 'enable-rutw-countdown',
  ENABLE_SANITY_CDN = 'enableSanityCdn',
  ENABLE_SANITY_CONTROLLED_MOBILE_NAV = 'enableSanityControlledMobileNav',
  ENABLE_SANITY_FOOTER = 'enableSanityFooter',
  ENABLE_SANITY_WEB_NAVIGATION = 'enableSanityWebNavigation',
  ENABLE_SEND_SAME_OTP_CODE_ONCE = 'send-same-otp-code-once',
  ENABLE_SERVICE_MODE_CART_SELECTION = 'enableServiceModeCartSelection',
  ENABLE_SHOW_ADDRESS2_BEFORE_CITY = 'enable-show-address2-before-city',
  ENABLE_SIDE_BY_SIDE_HERO_BUTTONS = 'enable-side-by-side-hero-buttons',
  ENABLE_SIGN_UP = 'enableSignUp',
  ENABLE_SIGNUP_CART_BUTTON_TEXT = 'enable-signup-cart-button-text',
  ENABLE_SIGNUP_COMMUNICATIONS_PRESELECTION = 'enable-signup-communications-preselection',
  ENABLE_SORT_OFFERS_FOR_SERVICE_MODE = 'enable-sort-offers-for-service-mode',
  ENABLE_STATIC_MENU = 'enableStaticMenu',
  ENABLE_STATIC_MENU_ALLERGENS = 'enable-static-menu-allergens',
  ENABLE_STORE_CONFIRMATION_MODAL = 'enableStoreConfirmationModal',
  ENABLE_STORE_SELECTION_2_0 = 'enable-store-selection-2',
  ENABLE_TABBED_MENU_BAR = 'enable-tabbed-menu-bar',
  ENABLE_TABBED_MENU_BAR_FAVORITES = 'enable-tabbed-menu-bar-fav',
  ENABLE_TABBED_MENU_BAR_RECENTS = 'enable-tabbed-menu-bar-recents',
  ENABLE_TERMS_LINK_ON_CHECKOUT = 'enable-terms-link-on-checkout',
  ENABLE_TH_DIRECT_PAY = 'th-direct-pay',
  ENABLE_TH_DIRECT_PAY_APPLE_PAY = 'enableThDirectPayApplePay',
  ENABLE_TH_DIRECT_PAY_GOOGLE_PAY = 'enableThDirectPayGooglePay',
  ENABLE_TH_HOCKEY_CHALLENGE = 'enable-th-hockey-challenge',
  ENABLE_TH_LOYALTY_BARCODE_COPY_TO_CLIPBOARD = 'enable-th-loyalty-barcode-copy-to-clipboard',
  ENABLE_TH_LOYALTY_ONBOARDING_REFRESH = 'enable-th-loyalty-onboarding-refresh',
  ENABLE_TH_LOYALTY_REDIRECT = 'enable-th-loyalty-redirect',
  ENABLE_TH_MEDIA_SERVICES = 'enable-th-media-services',
  ENABLE_TH_OFFER_TAGS = 'enable-th-offer-tags',
  ENABLE_TH_OFFERS_PAGE = 'enable-th-offers-page',
  ENABLE_TH_REWARD_TIER_TOGGLE_UI = 'enable-th-reward-tier-toggle-ui',
  ENABLE_TH_ROUND_UP = 'enable-th-round-up',
  ENABLE_TH_SCAN_AND_PAY = 'enable-th-scan-and-pay',
  ENABLE_TH_TIMBITS_LTO = 'enable-th-timbits-lto',
  ENABLE_TH_VOUCHER_CODES = 'enableThVoucherCodes',
  ENABLE_TOP_SERVICE_MODE = 'enable-top-service-mode',
  ENABLE_TWO_STEP_SIGN_UP = 'enable-two-step-sign-up',
  ENABLE_TRENDING = 'enable-trending',
  ENABLE_UNVERIFIED_LOYALTY_REGISTRATION = 'enable-unverified-loyalty-registration',
  ENABLE_UNVERIFIED_SAVED_PAYMENTS = 'enable-unverified-saved-payments',
  /** Shows modal with user preference questions, BK-DE initially */
  ENABLE_USER_PREFERENCE_QUESTIONNAIRE = 'enable-user-preference-questionnaire',
  ENABLE_USER_SAVED_DELIVERY_ADDRESS_PHONE = 'enableUserSavedDeliveryAddressPhone',
  /**
   * when set to true, execute the standalone addPaymentMethod mutation
   * that occurs as part of the checkout process when the 'save payment method' checkbox is selected
   */
  ENABLE_VAULT_AFTER_MAKING_ONE_TIME_PAYMENT = 'enable-vault-after-making-one-time-payment',
  ENABLE_ZERO_BOUNCE_ERROR_MODAL = 'enable-zero-bounce-error-modal',
  FIRE_ORDER_AHEAD = 'fireOrderAhead',
  FORCE_HOMEPAGE_AUTH = 'force-homepage-auth',
  FORCE_RESTAURANT_SELECTION_FOR_REWARDS = 'force-restaurant-selection-for-rewards',
  GEOLOCATION_TIMEOUT = 'geolocation-timeout',
  GOOGLE_PAY_ALLOWED_PAYMENT_METHODS = 'google-pay-allowed-payment-methods',
  HEARTBEAT_INTERVAL_MAX_MINUTES = 'heartbeat-interval-max-minutes',
  HIDE_ITEMS_FROM_NUTRITION_INFO = 'hide-items-from-nutrition-info',
  HIDE_SERVICE_MODE = 'hideServiceMode',
  HIDE_TIP_AMOUNT_CHECKOUT = 'hide-tip-amount-checkout',
  /** Hide all calories outside of the nutrition explorer */
  HIDE_UI_CALORIES = 'hide-ui-calories',
  LOYALTY_IN_RESTAURANT_STATUS_POLL_INTERVAL_MS = 'loyalty-in-restaurant-status-poll-interval-ms',
  MINIMUM_APPFLOW_BUILD_ID = 'minimumAppflowBuildId',
  MOBILE_CART_UPSELL_LOCATION = 'mobileCartUpsellLocation',
  MY_ACCOUNT_FIELDS_VARIATIONS = 'my-account-fields-variations',
  ORDER_LOCATOR_TABS = 'order-locator-tabs',
  ORDER_PRICE_REFRESH_INTERVAL_MS = 'orderPriceRefreshIntervalMs',
  OVERRIDE_CHECKOUT_CATERING_LIMIT = 'CheckoutCateringLimitOverrideCents',
  OVERRIDE_CHECKOUT_CATERING_MINIMUM = 'checkoutCateringMinimumOverrideCents',
  OVERRIDE_CHECKOUT_DELIVERY_MINIMUM = 'checkoutDeliveryMinimumOverrideCents',
  OVERRIDE_CHECKOUT_LIMIT = 'CheckoutLimitOverrideCents',
  PAYMENT_FIELD_VARIATIONS = 'payment-fields-variations',
  ADYEN_SUPPORTED_CARD_BRANDS_VARIATIONS = 'adyen-supported-card-brands-variations',
  SUPPORTED_CARD_BRANDS_VARIATIONS = 'supported-card-brands-variations',
  RADIUS_FOR_STORE_LOCATOR = 'radius-for-store-locator',
  RESET_CART_RESET_STORE_TIMEOUT = 'resetStoreResetCartTimeout',
  RESET_CART_TIMEOUT_HRS = 'reset-cart-timeout-hrs',
  RUTW_ROUND_START_TIMESTAMP = 'rutw-round-start-timestamp',
  SEND_POSTAL_CODE_ONLY_FOR_FIRST_DATA_PAYMENT = 'send-postal-code-only-for-first-data-payment',
  SHOW_INCREASED_DELIVERY_FEES_MODAL = 'show-increased-delivery-fees-modal',
  SHOW_LOYALTY_BANNER_V1 = 'show-loyalty-banner-v1',
  SHOW_LOYALTY_BANNER_V2 = 'show-loyalty-banner-v2',
  SHOW_LOYALTY_BANNER_V2_IN_HOME = 'show-loyalty-banner-v2-in-home',
  SHOW_ORDERING_ISSUE = 'show-ordering-issue-info',
  SHOW_STORE_SERVICE_MODES_SLIM_CARDS = 'show-store-service-modes',
  SIGN_UP_FIELDS_VARIATIONS = 'sign-up-fields-variations',
  SINGLE_REGION_LANGUAGE_SELECTOR = 'singleRegionLanguageSelector',
  SKIP_LOYALTY_STORE_VALIDATION = 'skip-loyalty-store-validation',
  STORE_CARD_BUTTON_OPTIONS = 'store-card-button-options',
  SUPPORT_BACKLOG_MSG = 'supportBacklogMsg',
  TEMP_ENABLE_CAROUSEL_COMPONENT_UPDATE = 'temp-enable-carousel-component-update',
  TEMP_ENABLE_FOOTER_COMPONENT_UPDATE = 'temp-enable-footer-component-update',
  TH_WORTH_THE_WAIT = 'th-worth-the-wait-lto',
  THREE_DS_FIRST_DATA_SUBMIT_TIMEOUT = 'first-data-3ds-submit-timeout',
  TIERED_CATERING_DELIVERY_FEES = 'tiered-catering-delivery-fees',
  TIERED_DELIVERY_FEES = 'tiered-delivery-fees',
  TIMED_FIRE_OPTIONS = 'timed-fire-options',
  TIP_PERCENT_THRESHOLD_CENTS = 'tipPercentThresholdCents',
  TWO_STEP_CHECKOUT = 'two-step-checkout',
  USE_HIGH_VOLUME_SUPPORT_MSG = 'use-high-volume-support-msg',
  ENABLE_MENU_SERVICE_DATA = 'enable-menu-service-data',
  ENABLE_MENU_SERVICE_PLUS = 'enable-menu-service-plus-fe',
  ENABLE_PREPAID_PAYMENT_METHODS = 'enable-prepaid-payment-methods',
  ENABLE_PREPAID_DIGITAL_CARD_CREATION = 'enable-prepaid-digital-card-creation',
  ENABLE_SHOW_PICKER_ASPECTS_IN_STATIC_MENU = 'enable-show-picker-aspects-in-static-menu',
  ENABLE_SWITCH_SERVICE_MODE_MODAL = 'enable-switch-service-mode-modal',

  // ****** TEST FLAGS ******
  // These flags may not exist in LD but allows us to enable their functionality
  // by using ?rbiFeature.$FLAG=true on the url such as rbiFeature.enable-test-google-pay-web=true
  ENABLE_COOLDOWN_TOAST_INFO = 'enable-cooldown-toast-info',
  ENABLE_MAIN_FAB_BUTTON = 'enable-main-fab-button',
  ENABLE_TEST_APPLE_PAY_WEB = 'enable-test-apple-pay-web',
  ENABLE_TEST_GOOGLE_PAY_WEB = 'enable-test-google-pay-web',
  ENABLE_TEST_RUTW_SPECIFIC_PRIZE = 'enable-test-rutw-specific-prize',
  ORDER_CART_VERSION = 'order-cart-version',

  // Iframe Campaign
  ENABLE_IFRAME_CAMPAIGN = 'enable-iframe-campaign',
  ENABLE_IFRAME_CAMPAIGN_URL = 'enable-iframe-campaign-url',
  ENABLE_IFRAME_CAMPAIGN_IN_WEB = 'enable-iframe-campaign-in-web',
  ENABLE_IFRAME_OFFER_URL = 'enable-iframe-offer-url',
  ENABLE_IFRAME_OFFER = 'enable-iframe-offer',
  ENABLE_IFRAME_OFFER_NOT_ON_SUCCESS = 'enalbe-iframe-offer-not-on-success',
  ENABLE_IFRAME_OFFER_URL_NOT_SUCCESS = 'enable-iframe-offer-url-not-success',

  /*
  Load static Sanity page instead of default loyalty component
  */
  ENABLE_REWARDS_STATIC_PAGE = 'enable-rewards-static-page',
  ENABLE_OFFERS_STATIC_PAGE = 'enable-offers-static-page',

  /*
  Determines what sections are displayed in the /account page
  */
  ENABLE_ACCOUNT_SECTIONS_VARIATIONS = 'enable-account-sections-variations',

  /*
  Validates the user's region is equal to the current region
  */
  ENABLE_ACCOUNT_REGION_VERIFICATION = 'enable-account-region-verification',

  /*
  Enable detailed error list in the cart modal error
  */
  ENABLE_CART_MODAL_ERROR_DETAILED_ERROR_LIST = 'enable-cart-modal-error-detailed-error-list',

  /*
  Enable third party URL redirect IF unavailable delivery modal for BKDE
  */
  ENABLE_THIRD_PARTY_REDIRECT_UNAVAILABLE_DELIVERY_MODAL = 'enable-third-party-redirect-unavailable-delivery-modal',
  LIEFERANDO_BUTTON_URL = 'lieferando-button-url',
  UBEREATS_BUTTON_URL = 'ubereats-button-url',

  /*
  Enable additional store details. Only needed for BK DE
  */
  ENABLE_CHECKOUT_MODAL_FZ_INFORMATION = 'enable-checkout-modal-fz-information',

  /*
  Enable routing for getOrder query
  */
  ENABLE_FULFILLMENT_SERVICE_GET_ORDER = 'enable-fulfillment-service-get-order',

  /*
  Enable routing for priceOrder mutation
  */
  ENABLE_FULFILLMENT_SERVICE_PRICE_ORDER = 'enable-fulfillment-service-price-order',

  /*
  Enable routing for commitOrder mutation
  */
  ENABLE_FULFILLMENT_SERVICE_COMMIT_ORDER = 'enable-fulfillment-service-commit-order',

  /*
  Enable routing for getUserOrders query
  */
  ENABLE_FULFILLMENT_SERVICE_GET_USER_ORDERS = 'enable-fulfillment-service-get-user-orders',

  /*
  Enable restaurant querying for vrPayment merchantId configuration. Only needed for BK DE
  */
  ENABLE_QUERY_VR_PAYMENT_MERCHANT_ID = 'enable-query-vr-payment-merchant-id',

  SHOW_PRICE_INSTEAD_FREE_IN_CART = 'show-price-instead-free-in-cart',
  /**
  Boolean value that controls whether or not Table service mode is disabled globally.
  */
  ENABLE_TABLE_SERVICE = 'enable-table-service',
  /**
  Boolean value that controls whether or not set fixed delivery time (CH request).
  */
  ENABLE_OVERRIDE_DELIVERY_TIME = 'enable-override-delivery-time',
  /**
  Boolean value that forces the new loyalty modal for all stores
  */
  ENABLE_LOYALTY_REDEEM_IN_RESTURANT_SCREEN_IN_ALL_STORES = 'enable-loyalty-redeem-in-restaurant-screen-in-all-stores',
  /**
  Boolean value that forces to show the redeem in restaurannt button even not having loyalty enabled and not store selected
  */
  ENABLE_ALWAYS_SHOW_REDEEM_INSTORE_BUTTON_FOR_REWARDS = 'enable-always-show-redeem-instore-button-for-rewards',
  /**
   * Number that controls the time to show/hide the Loyalty Link Card Reward Modal
   */
  LOYALTY_LINK_CARD_REWARD_TIMEOUT = 'loyalty-link-card-reward-timeout',
  /*
   * Used for rPOS stores that have Locker Pickup enabled
   */
  ENABLE_RPOS_LOCKERS_CODE = 'enable-rpos-lockers-code',
  /*
   * This flag controls if the Locker Pickup modal will poll every N seconds and display
   * an order locker code when ready.
   */
  ENABLE_ORDER_READY_LOCKER_CODE_MODAL = 'enable-order-ready-locker-code-modal',
  /*
   * Used for overwriting used country in the store location
   */
  ENABLE_OVERRIDE_COUNTRY_STORE_RESTRICTION = 'enable-override-country-store-restriction',

  /**
   * Whether to include the taxes in the total of the order (used mainly in EMEA where VAT is included in price)
   */
  ENABLE_CALCULATE_ORDER_TOTAL_WITH_TAXES = 'enable-calculate-order-total-with-taxes',

  /**
   * Skip refetching recent orders just after an order is committed.
   */
  ENABLE_SKIP_REFETCH_RECENT_ORDERS_ON_COMMIT = 'enable-skip-refetch-recent-orders-on-commit',

  /**
   * Whether to enable the app rating modal
   */
  ENABLE_APP_RATING_ON_ORDER_COMPLETED = 'enable-app-rating-on-order-completed',

  /**
   * Flag for toggling the loyalty tags on the Store Locator page
   */
  ENABLE_LOYALTY_TAGS_STORE_LOCATOR = 'enable-loyalty-tags-store-locator',

  /**
   * IAP22 related
   */
  ENABLE_IAP_PREFETCHING = 'enable-iap-prefetching',

  /**
   * Enable digital wallet migration from one merchant identifier to another
   */
  ENABLE_DIGITAL_WALLET_MIGRATION = 'enable-digital-wallet-migration',

  /**
   * Flag for toggling the new My Code v3 page
   */
  ENABLE_MY_CODE_UI_V3 = 'enable-my-code-ui-v3',

  /**
   *  Enabled use the One Trust method to display a modal with the information a user want to share.
   */
  ENABLE_CCPA_DO_NOT_SELL_BUTTON = 'enable-ccpa-do-not-sell-button',

  /**
   * Enable to avoid sending detailed error information about auth user when sign in
   */
  ENABLE_USER_NOT_FOUND_MASK_ON_AUTH_FLOW = 'enable-user-not-found-mask-on-auth-flows',
}

/**
 * Secondly, update the appropriate union based on the type
 * your new flag should return
 */
export type NumericFlags =
  | LaunchDarklyFlag.CURBSIDE_VEHICLE_DESCRIPTION_MAX_LENGTH
  | LaunchDarklyFlag.FIRE_ORDER_AHEAD
  | LaunchDarklyFlag.OVERRIDE_CHECKOUT_LIMIT
  | LaunchDarklyFlag.OVERRIDE_CHECKOUT_DELIVERY_MINIMUM
  | LaunchDarklyFlag.OVERRIDE_CHECKOUT_CATERING_LIMIT
  | LaunchDarklyFlag.OVERRIDE_CHECKOUT_CATERING_MINIMUM
  | LaunchDarklyFlag.MINIMUM_APPFLOW_BUILD_ID
  | LaunchDarklyFlag.DELIVERY_RADIUS_IN_MILES
  | LaunchDarklyFlag.DELIVERY_BANNER_POLLING
  | LaunchDarklyFlag.DATADOG_RUM_SAMPLE_RATE
  | LaunchDarklyFlag.RESET_CART_RESET_STORE_TIMEOUT
  | LaunchDarklyFlag.TIP_PERCENT_THRESHOLD_CENTS
  | LaunchDarklyFlag.CHEF_CART_UPSELL_ITEM_COUNT
  | LaunchDarklyFlag.DISCOUNTED_DELIVERY_THRESHOLD_CENTS
  | LaunchDarklyFlag.RADIUS_FOR_STORE_LOCATOR
  | LaunchDarklyFlag.GEOLOCATION_TIMEOUT
  | LaunchDarklyFlag.DATADOG_LOG_SAMPLE_RATE
  | LaunchDarklyFlag.LOYALTY_IN_RESTAURANT_STATUS_POLL_INTERVAL_MS
  | LaunchDarklyFlag.THREE_DS_FIRST_DATA_SUBMIT_TIMEOUT
  | LaunchDarklyFlag.ORDER_CART_VERSION
  | LaunchDarklyFlag.RESET_CART_TIMEOUT_HRS
  | LaunchDarklyFlag.LOYALTY_LINK_CARD_REWARD_TIMEOUT;

export type StringFlags =
  | LaunchDarklyFlag.AB_TESTING_HOME_PAGE
  | LaunchDarklyFlag.DEFAULT_SERVICE_MODE_IN_STORE
  | LaunchDarklyFlag.ENABLE_OFFER_REDEMPTION_METHODS
  | LaunchDarklyFlag.ENABLE_TEST_RUTW_SPECIFIC_PRIZE
  | LaunchDarklyFlag.MOBILE_CART_UPSELL_LOCATION
  | LaunchDarklyFlag.CHEF_RECOMMENDATION_ENGINE_2
  | LaunchDarklyFlag.RUTW_ROUND_START_TIMESTAMP
  | LaunchDarklyFlag.ENABLE_IFRAME_CAMPAIGN_URL
  | LaunchDarklyFlag.ENABLE_IFRAME_OFFER_URL
  | LaunchDarklyFlag.ENABLE_IFRAME_OFFER_URL_NOT_SUCCESS
  | LaunchDarklyFlag.ENABLE_FORCED_NATIVE_APP_UPDATE
  | LaunchDarklyFlag.LIEFERANDO_BUTTON_URL
  | LaunchDarklyFlag.UBEREATS_BUTTON_URL
  | LaunchDarklyFlag.TH_WORTH_THE_WAIT
  | LaunchDarklyFlag.GOOGLE_PAY_ALLOWED_PAYMENT_METHODS;

export type VariationFlags =
  | LaunchDarklyFlag.ENABLE_ACCOUNT_SECTIONS_VARIATIONS
  | LaunchDarklyFlag.ENABLE_IN_RESTAURANT_VARIATIONS
  | LaunchDarklyFlag.ENABLE_PREMIUM_COMBO_SLOTS
  | LaunchDarklyFlag.ENABLE_PREPAID_PAYMENT_METHODS
  | LaunchDarklyFlag.ENABLE_RUTW_COUNTDOWN
  | LaunchDarklyFlag.HIDE_ITEMS_FROM_NUTRITION_INFO
  | LaunchDarklyFlag.MY_ACCOUNT_FIELDS_VARIATIONS
  | LaunchDarklyFlag.ORDER_LOCATOR_TABS
  | LaunchDarklyFlag.PAYMENT_FIELD_VARIATIONS
  | LaunchDarklyFlag.ADYEN_SUPPORTED_CARD_BRANDS_VARIATIONS
  | LaunchDarklyFlag.SUPPORTED_CARD_BRANDS_VARIATIONS
  | LaunchDarklyFlag.SIGN_UP_FIELDS_VARIATIONS
  | LaunchDarklyFlag.STORE_CARD_BUTTON_OPTIONS
  | LaunchDarklyFlag.TIMED_FIRE_OPTIONS
  | LaunchDarklyFlag.ENABLE_OVERRIDE_COUNTRY_STORE_RESTRICTION;

export type EnumFlags = LaunchDarklyFlag.ENABLE_ONE_TIME_PASSWORD;

/**
 * Special case - for enum flag types, update this conditional type to
 * associate a flag name with a specific enum
 */
export type EnumFlagTypes<F extends EnumFlags> = F extends LaunchDarklyFlag.ENABLE_ONE_TIME_PASSWORD
  ? OTPAuthDeliveryMethod
  : never;

export type BooleanFlags = Exclude<
  LaunchDarklyFlag,
  NumericFlags | StringFlags | EnumFlags | VariationFlags
>;

export type FlagType<F extends LaunchDarklyFlag> = F extends NumericFlags
  ? number
  : F extends StringFlags
  ? string
  : F extends BooleanFlags
  ? boolean
  : F extends EnumFlags
  ? EnumFlagTypes<F>
  : F extends VariationFlags
  ? unknown
  : never;
