import { useCallback, useState } from 'react';

import { CustomEventNames, useMParticleContext } from 'state/mParticle';

import { StoreLocatorViews } from '../types';

export const MPARTICLE_VIEW_EVENT_MAP = {
  [StoreLocatorViews.LIST_VIEW]: 'Selected list view',
  [StoreLocatorViews.MAP_VIEW]: 'Selected map view',
};

export default function useStoreLocatorView() {
  const { logNavigationClick } = useMParticleContext();
  const [currentView, setCurrentView] = useState(StoreLocatorViews.LIST_VIEW);

  const updateCurrentView = useCallback(
    (newView: StoreLocatorViews) => {
      // log view switch event
      logNavigationClick(CustomEventNames.BUTTON_CLICK_STORE_LOCATOR_VIEW, {
        'Selected View': MPARTICLE_VIEW_EVENT_MAP[newView],
      });
      // switch view state
      setCurrentView(newView);
    },
    [logNavigationClick]
  );

  return {
    currentView,
    currentViewIsList: currentView === StoreLocatorViews.LIST_VIEW,
    currentViewIsMap: currentView === StoreLocatorViews.MAP_VIEW,
    updateStoreLocatorView: updateCurrentView,
  };
}
