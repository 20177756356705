import React from 'react';

const IconRefreshBK: React.FC<{ width?: string | number }> = props => (
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>Refresh</title>
    <path d="M20.1926 13.4497C20.0644 12.1672 18.7819 11.5259 18.0124 12.2954C17.4994 12.8084 17.4994 13.9627 17.2429 14.7321C16.9864 15.6299 16.2169 16.3994 15.5757 16.9124C14.0367 18.0666 11.7282 18.0666 9.80442 17.6819C8.90668 17.4254 8.13717 16.9124 7.49593 16.2711C5.82868 14.6039 5.82868 11.7824 6.85468 9.85866C7.75243 8.44792 9.29143 7.42191 10.9587 7.42191C10.9587 7.80666 10.9587 8.06316 10.9587 8.44791C10.9587 8.83266 11.2152 9.34565 11.8564 9.34565C12.6259 9.34565 14.9344 7.80666 15.9604 7.16541C16.2169 7.03716 16.4734 6.65243 16.3452 6.39593C16.3452 6.26768 15.9604 5.88293 15.8321 5.75468C14.6779 4.85693 14.0367 4.34393 12.8824 3.44618C12.4977 3.18968 11.7282 2.80494 11.3434 3.18969C10.9587 3.57444 11.0869 3.95917 11.2152 4.60042C9.29142 4.72867 7.36768 5.49817 5.95694 6.90892C2.4942 10.2434 2.62245 16.0146 6.34169 19.0926C10.4457 22.5554 18.2689 21.2729 20.0644 16.0146C20.0644 15.1169 20.1926 14.2192 20.1926 13.4497Z" />
  </svg>
);

export default IconRefreshBK;
