import styled, { css } from 'styled-components';

import { ActionButtonVariants } from 'components/action-button';

import * as Base from './base';

const linkStyles = css<{ reversed: boolean }>`
  color: ${p => (p.reversed ? p.theme.token('text-reversed') : p.theme.token('text-default'))};

  &.selected {
    color: ${Styles.color.navLinkColor.active};
  }

  &.selected:hover {
    color: ${Styles.color.navLinkColor.active};
  }
`;

const StyledLink = styled(Base.StyledLink)`
  ${linkStyles}
`;

const StyledLinkExternal = styled(Base.StyledLinkExternal)`
  ${linkStyles}
`;

export default {
  buttonVariant: ActionButtonVariants.PRIMARY,
  linkVariant: ActionButtonVariants.INVERSE,
  linkVariantReversed: ActionButtonVariants.PRIMARY,
};

export * from './base';
export { StyledLink, StyledLinkExternal };
