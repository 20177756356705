import React from 'react';

import { VisuallyHidden } from '@rbilabs/components-library';
import { useIntl } from 'react-intl';

import { ICartEntry } from '@rbi-ctg/menu';
import { useRedeemReward } from 'components/cart-item/redeem-reward/use-redeem-reward/use-redeem-reward';
import Incrementor, { IncrementorActions } from 'components/incrementor';
import { useCartItemEditingTools } from 'hooks/use-cart-item-editing-tools';
import { useIsEditCartEnabled } from 'hooks/use-is-edit-cart-enabled';
import { useOrderContext } from 'state/order';
import { MIN_CART_QUANTITY, getMenuObjectCartQuantity } from 'utils/cart';

import { ActionsContainer } from '../styled/actions-container';
import { CartEntryTitle } from '../styled/cart-entry-title';
import { CartItemButton } from '../styled/cart-item-button';
import { ICartPreviewActions, ICartPreviewIncrementor, ICartPreviewTitle } from '../types';

import { ICartPreviewActionsProps } from './types';

type State = {
  isOffer: boolean;
  isEditCartEnabled: boolean;
  handleEditItem: () => void;
  handleRemoveItem: () => void;
  handleQuantityChange: (value: number, incrementorAction: IncrementorActions) => void;
  item?: ICartEntry;
};

const CartPreviewContext = React.createContext<State>({
  isOffer: false,
  isEditCartEnabled: false,
  handleEditItem: () => {},
  handleRemoveItem: () => {},
  handleQuantityChange: () => {},
});

const CartPreviewActions: React.FC<ICartPreviewActions> = ({
  children,
  isOffer,
  isReward,
  item,
}) => {
  const isEditCartEnabled = useIsEditCartEnabled({ cartHasIncentive: isOffer || isReward });
  const { handleEditItem, handleRemoveItem, handleQuantityChange } = useCartItemEditingTools({
    item,
    isOffer,
  });
  return (
    <CartPreviewContext.Provider
      value={{
        item,
        isOffer,
        isEditCartEnabled,
        handleEditItem,
        handleRemoveItem,
        handleQuantityChange,
      }}
    >
      <ActionsContainer>{children}</ActionsContainer>
    </CartPreviewContext.Provider>
  );
};

export const CartPreviewTitle: React.FC<ICartPreviewTitle> = ({ item }) => (
  <CartEntryTitle data-testid="cart-preview-name">{item.name}</CartEntryTitle>
);

export const CartPreviewEditButton: React.VFC<ICartPreviewActionsProps> = ({
  closeModalCallback,
}) => {
  const { formatMessage } = useIntl();
  const { item, isEditCartEnabled, handleEditItem } = React.useContext(CartPreviewContext);

  const onClickCallback = () => {
    closeModalCallback();
    handleEditItem();
  };

  return isEditCartEnabled ? (
    <CartItemButton
      onClick={onClickCallback}
      aria-label={`${formatMessage({ id: 'edit' })} ${item?.name ?? ''} `}
      data-testid="cart-preview-edit-button"
    >
      {formatMessage({
        id: 'edit',
      })}
    </CartItemButton>
  ) : null;
};

export const CartPreviewRemoveButton: React.VFC<ICartPreviewActionsProps> = ({
  closeModalCallback = () => {},
}) => {
  const { formatMessage } = useIntl();
  const { item, handleRemoveItem } = React.useContext(CartPreviewContext);

  const onClickCallback = () => {
    closeModalCallback();
    handleRemoveItem();
  };

  return (
    <CartItemButton onClick={onClickCallback} aria-label={`Remove ${item?.name ?? ''}`}>
      {formatMessage({
        id: 'remove',
      })}
    </CartItemButton>
  );
};

export const CartPreviewIncrementor: React.FC<ICartPreviewIncrementor> = ({ children, border }) => {
  const { item, isOffer, handleQuantityChange } = React.useContext(CartPreviewContext);
  const { hasSufficientBalance, incentiveNotInMenu, limitPerOrderMet } = useRedeemReward(item);
  const { cartEntries } = useOrderContext() ?? {};
  const { availableCartQuantity, maxCartQuantityMet } = getMenuObjectCartQuantity({
    menuObject: item,
    cartEntries,
  });

  const insufficientBalanceForRewardRedemption = incentiveNotInMenu && !hasSufficientBalance;

  const isIncrementDisabled =
    limitPerOrderMet || insufficientBalanceForRewardRedemption || maxCartQuantityMet;

  const itemQuantity = item?.quantity ?? 0;

  return !isOffer ? (
    <Incrementor
      name={item?.name ?? ''}
      aria-label={`Quantity ${itemQuantity} of ${item?.name ?? ''}`}
      data-testId="cart-preview-quantity"
      value={itemQuantity}
      onChange={handleQuantityChange}
      min={MIN_CART_QUANTITY}
      max={itemQuantity + availableCartQuantity}
      border={border}
      disabled={item?.isExtra}
      disableIncrement={isIncrementDisabled}
    >
      {children}
    </Incrementor>
  ) : null;
};

export const CartPreviewVisuallyHidden: React.FC = () => {
  const { formatMessage } = useIntl();
  const { item } = React.useContext(CartPreviewContext);

  return (
    <VisuallyHidden role="alert">
      {formatMessage(
        { id: 'quantityUpdatedAlert' },
        {
          quantity: item?.quantity ?? 0,
          itemName: item?.name ?? '',
        }
      )}
    </VisuallyHidden>
  );
};

export default CartPreviewActions;
