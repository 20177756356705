import * as React from 'react';

import { useIntl } from 'react-intl';

import { useIsLoyaltyEnabled } from 'state/loyalty/hooks/use-is-loyalty-enabled';
import { useLoyaltyUser } from 'state/loyalty/hooks/use-loyalty-user';
import { CustomEventNames, useMParticleContext } from 'state/mParticle';

import {
  LinkContainer,
  Points,
  PointsContainer,
  StyledArrowLink,
  StyledPointsIcon,
  StyledRewardsLogo,
} from './rewards-link.styled';

interface IRewardsLinkProps {
  onClickEventName: CustomEventNames;
  showPointsLabel?: boolean;
  textLabel?: string;
}

const RewardsLink = ({ onClickEventName, showPointsLabel, textLabel }: IRewardsLinkProps) => {
  const mParticle = useMParticleContext();
  const { formatMessage } = useIntl();
  const handleClick = () => mParticle.logNavigationClick(onClickEventName);
  const loyaltyEnabled = useIsLoyaltyEnabled();
  const { loyaltyUser } = useLoyaltyUser();

  return loyaltyEnabled && loyaltyUser ? (
    <StyledArrowLink
      data-testid="loyalty-link"
      to={formatMessage({ id: 'routes.rewardsHistory' })}
      onClick={handleClick}
    >
      <LinkContainer>
        {textLabel || <StyledRewardsLogo />}
        <PointsContainer $hasLabel={showPointsLabel}>
          {showPointsLabel && `${formatMessage({ id: 'points' })}`}
          <StyledPointsIcon />
          <Points>{loyaltyUser.points}</Points>
        </PointsContainer>
      </LinkContainer>
    </StyledArrowLink>
  ) : null;
};

export default RewardsLink;
