import React from 'react';

import { useIntl } from 'react-intl';

import { ArrowLink } from 'components/arrow-link';
import useRoutes from 'hooks/use-routes';
import { routes } from 'utils/routing';

import { SUBMIT_FEEDBACK_INTL_KEY } from './constants';

const SubmitFeedbackLink = () => {
  const { formatMessage } = useIntl();
  const { getLocalizedRouteForPath, doesPageExist } = useRoutes();
  const feedbackPath = getLocalizedRouteForPath(routes.contactUs);
  const doesFeedbackPageExist = doesPageExist(routes.contactUs);
  if (!doesFeedbackPageExist) {
    return null;
  }
  return <ArrowLink to={feedbackPath}>{formatMessage({ id: SUBMIT_FEEDBACK_INTL_KEY })}</ArrowLink>;
};

export default SubmitFeedbackLink;
