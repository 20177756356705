import React from 'react';

import { format, parse } from 'date-fns';
import { defaultTo } from 'lodash-es';
import { useIntl } from 'react-intl';

import useMediaQuery from 'hooks/use-media-query';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useMenuContext } from 'state/menu';
import { useStoreContext } from 'state/store';
import {
  getAddressMessage,
  getOrderingDisabledStoreSelectionMessage,
  isCatering,
} from 'utils/service-mode';

import ChangeServiceModeLink from '../change-service-mode-link';
import { AdditionalTextContainer } from '../styled';
import { IServiceModeAddressProps } from '../types';

const ServiceModeAddress: React.FC<IServiceModeAddressProps> = ({
  serviceMode,
  userAddress,
  cateringPickupDateTime,
}) => {
  const { formatMessage } = useIntl();
  const {
    isStoreOpenAndAvailable,
    isStoreOpenAndUnavailable,
    noStoreSelected,
    store,
  } = useStoreContext();
  const isMobile = useMediaQuery('mobile');
  const enableOrdering = defaultTo(useFlag(LaunchDarklyFlag.ENABLE_ORDERING), true);
  const { showStaticMenu } = useMenuContext();

  const addressMessage = getAddressMessage({
    store,
    noStoreSelected,
    isStoreOpenAndUnavailable,
    serviceMode,
    userAddress,
    formatMessage,
    isMobile,
  });

  const orderingDisabledStoreSelectionMessage = getOrderingDisabledStoreSelectionMessage({
    store,
    formatMessage,
    isStoreOpenAndAvailable,
  });

  // show time for catering pickup
  const additionalText =
    isCatering(serviceMode) &&
    cateringPickupDateTime &&
    format(parse(cateringPickupDateTime, 'yyyy-MM-dd h:mm aaaa', new Date()), 'MM/dd/yy h:mm aaaa');

  let primaryText = orderingDisabledStoreSelectionMessage;

  if (enableOrdering) {
    primaryText = showStaticMenu ? formatMessage({ id: 'forItemAvailability' }) : addressMessage;
  }

  return (
    <div data-testid="service-mode-address">
      <h3>{primaryText}</h3>
      {additionalText && <AdditionalTextContainer>{additionalText}</AdditionalTextContainer>}
      <ChangeServiceModeLink
        storeSelected={isStoreOpenAndAvailable}
        orderingEnabled={enableOrdering}
      />
    </div>
  );
};

export default ServiceModeAddress;
