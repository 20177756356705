import { useCallback, useEffect, useMemo, useState } from 'react';

import { IOffer } from '@rbi-ctg/menu';
import { IOfferRedemptions } from '@rbi-ctg/offers';
import {
  IEvaluateAllUserOffersQuery,
  IOfferFeedbackEntryFragment,
  Locale,
  useEvaluateAllUserOffersQuery,
} from 'generated/rbi-graphql';
import { UserDetails } from 'state/auth/hooks/use-current-user';
import { useLocale } from 'state/intl';
import { ServiceMode } from 'state/service-mode';
import { useStoreContext } from 'state/store';
import { platform } from 'utils/environment';
import LocalStorage, { StorageKeys } from 'utils/local-storage';
import { redeemedOnTime, reduceOffersFeedback } from 'utils/offers';

import { mapOfferDataToNewOffer, shouldDisplayOffer } from '../util';

export interface IUseOfferListProps {
  serviceMode: null | ServiceMode;
  user: null | UserDetails;
  skip?: boolean;
}

const getAllRedeemedOffersNoAuth = (): IOfferRedemptions =>
  LocalStorage.getItem(StorageKeys.OFFERS_REDEEMED_NO_AUTH) || {};

// @todo if we have stored offers and a user signs up afterward,
// should we attribute those redemptions to that user, or allow
// them to redeem the offers again?
const persistUnauthenticatedRedemptions = (offerRedemptions: IOfferRedemptions) => {
  const allRedeemed = getAllRedeemedOffersNoAuth();
  LocalStorage.setItem(StorageKeys.OFFERS_REDEEMED_NO_AUTH, {
    ...allRedeemed,
    ...offerRedemptions,
  });
  return allRedeemed;
};

/**
 * returns a list of currently usable offers for the given user
 * (if no user is logged in returns all offers)
 */

function useOffersList({ serviceMode, user, skip = false }: IUseOfferListProps) {
  const { language } = useLocale();
  const preloadedOfferRedemptions = useMemo(getAllRedeemedOffersNoAuth, []);
  const [offers, setOffers] = useState<IOffer[]>([]);
  const [standardOffers, setStandardOffers] = useState<IOffer[]>([]);
  const { store } = useStoreContext();
  const [redeemedOn, setRedeemedOn] = useState<string>(redeemedOnTime());
  const [offersFeedback, setOffersFeedback] = useState<
    Record<string, IOfferFeedbackEntryFragment> | undefined
  >();
  const [unauthenticatedRedemptions, setUnauthenticatedRedemptions] = useState(
    preloadedOfferRedemptions
  );

  // make the first EvaluateUserOffers query, and then trigger the rest in the onCompleted callback
  const { data, loading, refetch } = useEvaluateAllUserOffersQuery({
    skip,
    context: { shouldNotBatch: true },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      locale: language as Locale,
      platform: platform(),
      serviceMode,
      redeemedOn,
      storeId: store.number,
    },
    partialRefetch: true,
  });

  const cognitoId = user?.cognitoId ?? null;
  const queryRedeemableOffers = useCallback(() => {
    if (cognitoId) {
      setRedeemedOn(redeemedOnTime());
    }
  }, [cognitoId]);

  const redeemUnauthenticatedOffer = useCallback((couponId: string) => {
    setUnauthenticatedRedemptions(redemptions => {
      const redemptionDateTime = redeemedOnTime();
      if (redemptions[couponId] && Array.isArray(redemptions[couponId])) {
        return {
          ...redemptions,
          [couponId]: redemptions[couponId].concat(redemptionDateTime),
        };
      }

      return {
        ...redemptions,
        [couponId]: [redemptionDateTime],
      };
    });
  }, []);

  const updateFilteredOffersList = useCallback(
    (queryData: IEvaluateAllUserOffersQuery | undefined, currentUserCognitoId: string | null) => {
      if (!queryData) {
        setOffersFeedback(undefined);
        setOffers([]);
        return;
      }

      // Get all redeemable user offers in the same order as sortedOffers
      const { offersFeedback: offerFeedbackResult } = queryData.evaluateAllUserOffers;
      const normalOffers: IOffer[] = [];

      const sortedOffersFeedback: IOfferFeedbackEntryFragment[] = offerFeedbackResult.filter(
        (maybeFeedback): maybeFeedback is IOfferFeedbackEntryFragment => !!maybeFeedback
      );

      const filteredOffers = sortedOffersFeedback.reduce<IOffer[]>(
        (currFilteredOffers, offerFeedback) => {
          if (!offerFeedback.offerDetails) {
            return currFilteredOffers;
          }
          const newOffers = mapOfferDataToNewOffer({ offerFeedback, language });
          if (
            // Add only if not logged in, or if it is redeemable
            (!currentUserCognitoId || newOffers.isRedeemable) &&
            shouldDisplayOffer(newOffers, unauthenticatedRedemptions[newOffers._id])
          ) {
            normalOffers.push(newOffers);
          }

          return currFilteredOffers.concat(newOffers);
        },
        []
      );

      const feedback = reduceOffersFeedback(sortedOffersFeedback);
      setOffersFeedback(feedback);
      setOffers(filteredOffers);
      setStandardOffers(normalOffers);
    },
    [language, unauthenticatedRedemptions]
  );

  /**
   * When all EvaluateUserOffer queries have run, call updateFilteredOffersList to update the offers list.
   * If updateFilteredOffersList runs before all offers have been evaluated,
   * the user may be kicked off of the redemption screen if their offer isn't in the list yet.
   **/
  useEffect(() => {
    // do not update filteredOffersList if more EvaluateUserOffers queries are still running
    if (!loading) {
      updateFilteredOffersList(data, cognitoId);
    }
  }, [data, updateFilteredOffersList, cognitoId, loading]);

  useEffect(() => {
    persistUnauthenticatedRedemptions(unauthenticatedRedemptions);
    setOffers(currentOffers =>
      currentOffers.filter(offer =>
        shouldDisplayOffer(offer, unauthenticatedRedemptions[offer._id])
      )
    );
  }, [unauthenticatedRedemptions]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    evaluateUserOffersLoading: loading,
    offers,
    offersFeedback,
    queryRedeemableOffers,
    redeemUnauthenticatedOffer,
    refetch,
    standardOffers,
  };
}

export default useOffersList;
