import styled from 'styled-components';

export const CartPreviewEntryContainer = styled.div`
  background-color: #ffffff;
  width: 100%;
  color: ${Styles.color.black};
  display: flex;
  flex-direction: column;
  padding: 1rem;

  border: 1px solid ${Styles.color.grey.five};
  border-radius: ${Styles.borderRadius};
  box-shadow: ${Styles.boxShadow};

  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }
`;
