import React, { FC, createContext, useCallback, useContext, useEffect, useState } from 'react';

import isEmpty from 'lodash/isEmpty';
import { useIntl } from 'react-intl';

import { IStore } from '@rbi-ctg/store';
import useDialogModal from 'hooks/use-dialog-modal';
import useEffectOnUpdates from 'hooks/use-effect-on-updates';
import { useLoyaltyRewardsList } from 'hooks/use-loyalty-rewards-list';
import useMediaQuery, { MediaQuery } from 'hooks/use-media-query';
import { useSetResetCartTimeout } from 'hooks/use-set-reset-cart-timeout';
import { actions, useAppDispatch } from 'state/global-state';
import { removeAppliedRewardsInStorage } from 'state/global-state/models/loyalty/rewards/rewards.utils';
import { useLocale } from 'state/intl';
import { REGIONS } from 'state/intl/types';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useLocationContext } from 'state/location';
import { useLoyaltyContext } from 'state/loyalty';
import { useEvaluateLoyaltyAtRestaurant } from 'state/loyalty/hooks/use-evaluate-loyalty-at-restaurant';
import { ServiceMode, useServiceModeContext } from 'state/service-mode';
import { useStoreContext } from 'state/store';
import { StorageKeys } from 'utils/local-storage';
import { toast } from 'utils/toast';

import { useLoyaltyUser } from '../hooks/use-loyalty-user';

import { ICartEntryType, useInRestaurantCart } from './hooks/use-in-restaurant-redemption-cart';
import type { ICartEntry, ICreateCartEntryParams } from './hooks/use-in-restaurant-redemption-cart';
import { ShortCodeState, useShortCode } from './hooks/use-short-code';
import { ShortCodePollingStatus, useShortCodePollStatus } from './hooks/use-short-code-poll-status';
import useStaticIdentifier from './hooks/use-static-identifier';
import { isMediaAllowed } from './is-media-allowed';
import { isServiceModeValid } from './is-service-mode-valid';
import { ShortCodeConfirmationModal, ShortCodeOverrideModal } from './modals';
import {
  ICart,
  IInRestaurantFlagVariations,
  IInRestaurantRedemptionContext,
  InRestaurantRedemptionFlagsState,
} from './types';
import {
  generateOrderLineItems,
  getAppliedOffersFromInRestaurantCart,
  mapRewardsToCartEntries,
} from './utils';

interface IOverrideModalConfirmation {
  overrideShortCodeCallback: () => void;
}

export const InRestaurantRedemptionContext = createContext<IInRestaurantRedemptionContext>(
  {} as IInRestaurantRedemptionContext
);
export const useInRestaurantRedemptionContext = () => useContext(InRestaurantRedemptionContext);

export const InRestaurantRedemptionProvider: FC = ({ children }) => {
  const { region } = useLocale();
  const { loyaltyUser } = useLoyaltyUser();
  const { refetchLoyaltyUser } = useLoyaltyContext();
  const isMobile = useMediaQuery(MediaQuery.Mobile);
  const { formatMessage } = useIntl();
  const { navigate } = useLocationContext();
  const { store } = useStoreContext();
  const { evaluateLoyaltyAtRestaurant } = useEvaluateLoyaltyAtRestaurant();
  const { serviceMode } = useServiceModeContext();
  const loyaltyUserId = loyaltyUser?.id;
  const [ShortCodeOverrideModalConfirmation, openOverrideModalConfirmation] = useDialogModal<
    IOverrideModalConfirmation
  >({
    Component: ShortCodeOverrideModal,
    onConfirm: ({ overrideShortCodeCallback }: IOverrideModalConfirmation) => {
      overrideShortCodeCallback();
    },
  });
  const shouldForceRestaurantSelection = Boolean(
    useFlag(LaunchDarklyFlag.FORCE_RESTAURANT_SELECTION_FOR_REWARDS)
  );

  const enableInRestaurantVariations = useFlag<IInRestaurantFlagVariations>(
    LaunchDarklyFlag.ENABLE_IN_RESTAURANT_VARIATIONS
  );
  const enableReturnAllRewards = useFlag(LaunchDarklyFlag.ENABLE_RETURN_ALL_REWARDS);
  const enableLoyaltyRedeemAllStores = useFlag(
    LaunchDarklyFlag.ENABLE_LOYALTY_REDEEM_IN_RESTURANT_SCREEN_IN_ALL_STORES
  );
  const enableInRestaurantRedemption = !isEmpty(enableInRestaurantVariations);
  const inRestaurantRedemptionEnabled = Boolean(
    enableInRestaurantRedemption &&
      isServiceModeValid({ serviceMode }) &&
      isMediaAllowed({ isMobile })
  );

  const [
    { inRestaurantLoyaltyEnabledAtRestaurant, inRestaurantLoyaltyEnabledAtRestaurantLoading },
    setFlagsConfig,
  ] = useState<InRestaurantRedemptionFlagsState>({
    inRestaurantLoyaltyEnabledAtRestaurantLoading: true,
    inRestaurantLoyaltyEnabledAtRestaurant: inRestaurantRedemptionEnabled,
  });

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [scanSucceeded, setScanSucceeded] = useState<boolean>(false);

  const setScanSuccess = (success: boolean) => {
    setScanSucceeded(success);
  };

  /** Evaluates InRestaurantRedemption feature availability against a store different from the one set in context */
  const isInRestaurantRedemptionEnabledAtStore = useCallback(
    async (storeToCheck: IStore, serviceModeToCheck: ServiceMode | null) => {
      if (!inRestaurantRedemptionEnabled) {
        return false;
      }

      /**
       * Temporary solution
       * 👇 Refactor task and problem explained 👇
       * https://rbictg.atlassian.net/browse/CIA-2586
       */
      return shouldForceRestaurantSelection || region === REGIONS.GB
        ? await evaluateLoyaltyAtRestaurant(storeToCheck, serviceModeToCheck)
        : true;
    },
    [
      evaluateLoyaltyAtRestaurant,
      inRestaurantRedemptionEnabled,
      region,
      shouldForceRestaurantSelection,
    ]
  );

  const { engineRewardsMap, sanityRewardsMap } = useLoyaltyRewardsList({
    skip: !enableReturnAllRewards,
  });

  /** Evaluates restaurant loyalty ability against the current Store in the context */
  useEffect(() => {
    let mounted = true;

    async function setEnabledAtRestaurant() {
      setFlagsConfig(prev =>
        prev.inRestaurantLoyaltyEnabledAtRestaurantLoading
          ? prev
          : { ...prev, inRestaurantLoyaltyEnabledAtRestaurantLoading: true }
      );
      let isEnabledAtRestaurant = false;

      if (store) {
        isEnabledAtRestaurant = await isInRestaurantRedemptionEnabledAtStore(
          store as IStore,
          serviceMode
        );
      }

      if (mounted) {
        setFlagsConfig({
          inRestaurantLoyaltyEnabledAtRestaurant: isEnabledAtRestaurant,
          inRestaurantLoyaltyEnabledAtRestaurantLoading: false,
        });
      }
    }

    setEnabledAtRestaurant();

    return () => {
      mounted = false;
    };
  }, [isInRestaurantRedemptionEnabledAtStore, serviceMode, store]);

  const inRestaurantRedemptionStatusPollIntervalMs = useFlag(
    LaunchDarklyFlag.LOYALTY_IN_RESTAURANT_STATUS_POLL_INTERVAL_MS
  );
  const dispatch = useAppDispatch();

  const {
    inRestaurantCart,
    addInRestaurantCartEntry,
    removeInRestaurantCartEntry: removeInRestaurantRedemption,
    updateInRestaurantCartEntryQuantity: updateInRestaurantRedemptionQuantity,
    resetInRestaurantCart,
    isInRestaurantCartEmpty,
    existEntryTypeInRestaurantCart,
    clearInRestaurantCartAllRewards,
    lastModificationDate,
    updateLastModificationDate,
    removeTypeFromCart,
  } = useInRestaurantCart();
  const {
    resetShortCode,
    getNewShortCode,
    shortCodeLoading,
    shortCodeState,
    setShortCodeClaimed,
    setShortCodeExpired,
    shortCode,
  } = useShortCode();

  const clearInRestaurantCartAllOffers = useCallback(() => {
    removeTypeFromCart(ICartEntryType.OFFER);
    removeTypeFromCart(ICartEntryType.LEGACY_OFFER);
  }, [removeTypeFromCart]);
  const { staticIdentifier, isStaticIdentifierEnabled } = useStaticIdentifier();

  const skipPolling: boolean =
    !inRestaurantRedemptionStatusPollIntervalMs ||
    shortCodeLoading ||
    shortCodeState !== ShortCodeState.Pending;

  const { pollingShortCodeStatus, resetPollingStatus } = useShortCodePollStatus({
    loyaltyId: loyaltyUserId,
    skipPolling,
    shortCode,
    pollInterval: inRestaurantRedemptionStatusPollIntervalMs,
  });

  const storeNumber = store?.number;

  const hasLegacyOffer =
    enableLoyaltyRedeemAllStores &&
    inRestaurantCart?.some((o: ICartEntry) => o.type === ICartEntryType.LEGACY_OFFER);

  const resetInRestaurantRedemption = useCallback(() => {
    // reset all the in-restaurant state
    resetPollingStatus();
    resetShortCode();
    resetInRestaurantCart();

    // reset loyalty state
    removeAppliedRewardsInStorage();
    dispatch(actions.loyalty.resetLoyaltyRewardsState(loyaltyUser?.points ?? 0));
  }, [dispatch, loyaltyUser, resetInRestaurantCart, resetPollingStatus, resetShortCode]);

  const generateInRestaurantOrder = useCallback(() => {
    let cart: ICart = [];

    if (inRestaurantCart.length) {
      cart = inRestaurantCart;
    } else if (sanityRewardsMap && enableReturnAllRewards) {
      cart = mapRewardsToCartEntries({ engineRewardsMap, sanityRewardsMap });
    }

    return generateOrderLineItems(cart);
  }, [enableReturnAllRewards, engineRewardsMap, inRestaurantCart, sanityRewardsMap]);

  const generateShortCode = useCallback(() => {
    if (loyaltyUserId) {
      getNewShortCode({
        appliedOffers: getAppliedOffersFromInRestaurantCart(inRestaurantCart),
        inRestaurantOrder: generateInRestaurantOrder(),
        loyaltyId: loyaltyUserId,
        restaurantId: storeNumber,
        serviceMode,
        store,
      });
    }
  }, [
    generateInRestaurantOrder,
    getNewShortCode,
    inRestaurantCart,
    loyaltyUserId,
    serviceMode,
    store,
    storeNumber,
  ]);

  const enabeQrAndShortCode =
    useFlag(LaunchDarklyFlag.ENABLE_LOYALTY_QR_AND_SHORT_CODE) && !inRestaurantCart.length;

  useEffectOnUpdates(() => {
    resetShortCode();
    // generate a new short code on every cart update
    generateShortCode();
  }, [inRestaurantCart]);

  useEffect(() => {
    if (pollingShortCodeStatus === ShortCodePollingStatus.Confirmed) {
      setShortCodeClaimed();
    } else if (pollingShortCodeStatus === ShortCodePollingStatus.NotFound) {
      // expiring the code if it's not found by the polling request
      setShortCodeExpired();
    }
  }, [pollingShortCodeStatus, setShortCodeClaimed, setShortCodeExpired]);

  useEffect(() => {
    // Reset in restaurant redemption if the feature isn't enabled and in restaurant cart is not empty
    if (
      !isInRestaurantCartEmpty &&
      (!inRestaurantRedemptionEnabled ||
        (!inRestaurantLoyaltyEnabledAtRestaurant && !inRestaurantLoyaltyEnabledAtRestaurantLoading))
    ) {
      resetInRestaurantRedemption();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    inRestaurantLoyaltyEnabledAtRestaurantLoading,
    loyaltyUserId,
    inRestaurantRedemptionEnabled,
    inRestaurantLoyaltyEnabledAtRestaurant,
    isInRestaurantCartEmpty,
    resetInRestaurantRedemption,
  ]);

  useEffect(() => {
    if (shortCodeState === ShortCodeState.Claimed && !enabeQrAndShortCode) {
      setShowConfirmationModal(true);
    } else if (shortCodeState === ShortCodeState.OfferValidationError) {
      toast.default(formatMessage({ id: 'invalidOffersRemoved' }));
      // TODO: clear only invalid offers
      clearInRestaurantCartAllOffers();
    }
  }, [clearInRestaurantCartAllOffers, enabeQrAndShortCode, formatMessage, shortCodeState]);

  const { enableOfferRedemption = false, enableRewardRedemption = false } =
    enableInRestaurantVariations || {};
  useEffect(() => {
    if (!enableOfferRedemption) {
      clearInRestaurantCartAllOffers();
    }
    if (!enableRewardRedemption) {
      clearInRestaurantCartAllRewards();
    }
  }, [
    clearInRestaurantCartAllOffers,
    clearInRestaurantCartAllRewards,
    enableOfferRedemption,
    enableRewardRedemption,
    removeTypeFromCart,
  ]);

  /**
   * Adds the redemption object to the cart and applies the reward if necessary,
   * and displays a toast if the action was successful, and redirects the user
   * to the in-restaurant redemption page.
   */
  const addInRestaurantRedemptionEntry = useCallback(
    (item: ICreateCartEntryParams) => {
      const cartEntry = addInRestaurantCartEntry(item);

      if (cartEntry) {
        let entityType = 'offer';

        if (cartEntry.type === ICartEntryType.REWARD) {
          entityType = 'reward';
          // apply the reward
          const { engineReward } = cartEntry.details;
          dispatch(
            actions.loyalty.applyReward({
              cartId: engineReward.id,
              rewardBenefitId: engineReward.rewardBenefitId,
            })
          );
        }

        toast.success(
          formatMessage(
            { id: 'addToInRestaurantRedemptionCartSuccess' },
            {
              type: formatMessage({
                id: entityType,
              }),
            }
          )
        );
      }
      // TODO: move this to the consumer
      navigate(formatMessage({ id: 'routes.redemptionInRestaurant' }));
    },
    [addInRestaurantCartEntry, dispatch, formatMessage, navigate]
  );

  /**
   * Removes the cart entry from the cart and remove the reward from `applied rewards` if necessary.
   */
  const removeInRestaurantRedemptionEntry = useCallback(
    (cartEntry: ICartEntry) => {
      removeInRestaurantRedemption(cartEntry);
      updateLastModificationDate(new Date());
      if (cartEntry.type === ICartEntryType.REWARD) {
        const { engineReward } = cartEntry.details;

        // remove the applied reward
        dispatch(
          actions.loyalty.removeAppliedReward({
            cartId: engineReward.id,
            rewardBenefitId: engineReward.rewardBenefitId,
          })
        );
      }
    },
    [dispatch, removeInRestaurantRedemption, updateLastModificationDate]
  );

  /**
   * Updates the cart entry quantity and apply or unapply the reward depending on the given and previous quantity.
   */
  const updateInRestaurantRedemptionEntryQuantity = useCallback(
    (cartEntry: ICartEntry, quantity: number) => {
      updateInRestaurantRedemptionQuantity(cartEntry, quantity);

      // apply or un-apply the reward as many times as necessary
      if (cartEntry.type === ICartEntryType.REWARD) {
        const {
          details: { engineReward },
          quantity: beforeQuantity,
        } = cartEntry;
        const { applyReward, unApplyReward } = actions.loyalty;
        const isDecrementAction = quantity - beforeQuantity < 0;
        const handleAction = isDecrementAction ? unApplyReward : applyReward;

        const { id: cartId, rewardBenefitId } = engineReward;
        for (let i = 0; i < Math.abs(quantity - beforeQuantity); i++) {
          dispatch(
            handleAction({
              rewardBenefitId,
              cartId,
              loyaltyUser,
            })
          );
        }
      }
    },
    [dispatch, loyaltyUser, updateInRestaurantRedemptionQuantity]
  );

  /**
   * The actions that have the ability to override the short code, should be wrapper by
   * this function in order to display a confirmation modal before the action is called.
   */
  const overrideShortCodeActionWrapper = useCallback(
    <T extends (...args: any) => void>(callback: T): ((...args: Parameters<T>) => void) => (
      ...args: Parameters<T>
    ) => {
      if (pollingShortCodeStatus === ShortCodePollingStatus.Processing) {
        // call to the callback to override the state
        openOverrideModalConfirmation({
          overrideShortCodeCallback: () => callback(...args),
        });
      } else {
        callback(...args);
      }
    },
    [openOverrideModalConfirmation, pollingShortCodeStatus]
  );

  const onConfirmationModalAction = useCallback(() => {
    resetInRestaurantRedemption();

    // re-fetching user points
    refetchLoyaltyUser();
    setShowConfirmationModal(false);
  }, [refetchLoyaltyUser, resetInRestaurantRedemption]);

  const onDismissConfirmationModalAction = useCallback(() => {
    setShowConfirmationModal(false);
  }, []);

  useSetResetCartTimeout({
    storageKey: StorageKeys.IN_RESTAURANT_CART_LAST_UPDATE,
    cart: inRestaurantCart,
    resetCartCallback: resetInRestaurantRedemption,
  });

  return (
    <InRestaurantRedemptionContext.Provider
      value={{
        enableOfferRedemption,
        enableRewardRedemption,
        enableLoyaltyRedeemAllStores,
        inRestaurantLoyaltyEnabledAtRestaurant,
        inRestaurantLoyaltyEnabledAtRestaurantLoading,
        inRestaurantRedemptionEnabled,
        generateShortCode,
        inRestaurantRedemptionCart: inRestaurantCart,
        // applying the override short code wrapper to catch up when the cart is changing
        // and display a confirmation modal
        addInRestaurantRedemptionEntry: overrideShortCodeActionWrapper<
          typeof addInRestaurantRedemptionEntry
        >(addInRestaurantRedemptionEntry),
        removeInRestaurantRedemptionEntry: overrideShortCodeActionWrapper<
          typeof removeInRestaurantRedemptionEntry
        >(removeInRestaurantRedemptionEntry),
        updateInRestaurantRedemptionEntryQuantity: overrideShortCodeActionWrapper<
          typeof updateInRestaurantRedemptionEntryQuantity
        >(updateInRestaurantRedemptionEntryQuantity),
        isInRestaurantRedemptionCartEmpty: isInRestaurantCartEmpty,
        resetInRestaurantRedemption,
        shortCodeLoading,
        shortCodeState,
        shortCode,
        existEntryTypeInRestaurantRedemptionCart: existEntryTypeInRestaurantCart,
        clearInRestaurantRedemptionAllRewards: clearInRestaurantCartAllRewards,
        isInRestaurantRedemptionEnabledAtStore,
        inRestaurantRedemptionLastModificationDate: lastModificationDate,
        staticIdentifier,
        isStaticIdentifierEnabled,
        scanSucceeded,
        setScanSuccess,
        shouldForceRestaurantSelection,
        hasLegacyOffer,
      }}
    >
      {showConfirmationModal && (
        <ShortCodeConfirmationModal
          onAction={onConfirmationModalAction}
          onDismiss={onDismissConfirmationModalAction}
        />
      )}
      <ShortCodeOverrideModalConfirmation />

      {children}
    </InRestaurantRedemptionContext.Provider>
  );
};
