import { loadLanguage } from 'state/intl/load-language';
import { loadRegion } from 'state/intl/load-region';
import * as DatadogLogger from 'utils/datadog';

import { brand, env, getApiKey, isLocalDev, platform } from '../environment';

const privateFieldNames = [
  'email',
  'address',
  'name',
  'phoneNumber',
  'billingCity',
  'billingState',
  'billingZip',
  'billingApt',
  'billingAddress',
  'credit',
  'dateOfBirth',
];

type LogRocketEvent = 'initialized' | 'session';
type Callback = (payload?: any) => void;

export default class LogRocketHelper {
  private static instance: LogRocketHelper;

  private _sessionURL?: string;
  private _isInitialized: boolean = false;
  private _logrocket: typeof import('logrocket') | null = null;
  private _runOnInit?: Function;

  private _callbacks: { [key: string]: Callback[] } = {};

  private constructor() {}

  get runOnInit() {
    return this._runOnInit;
  }

  set runOnInit(runOnInit) {
    this._runOnInit = runOnInit;
  }

  get logrocket() {
    return this._logrocket;
  }

  set logrocket(lrInstance) {
    this._logrocket = lrInstance;
  }

  /**
   * Register callbacks for LogRocket events
   */
  public on(event: LogRocketEvent, callback: Callback): void {
    this._callbacks[event] = this._callbacks[event] || [];
    this._callbacks[event].push(callback);
  }

  /**
   * Emit LogRocket events
   */
  public emit(event: LogRocketEvent, payload?: any): void {
    if (!this._callbacks[event]) {
      return;
    }

    this._callbacks[event].forEach(callback => callback(payload));
  }

  /**
   * Remove the given callback as a listener for the event, if already set
   */
  public removeListener(event: LogRocketEvent, callback: Callback): void {
    this._callbacks[event] = (this._callbacks[event] || []).filter(cb => cb !== callback);
  }

  /**
   * Current session URL. Set after initialization (this may take several seconds)
   */
  public sessionURL(): string | undefined {
    return this._sessionURL;
  }

  /**
   * Whether or not we've initialized LogRocket.
   */
  public isInitialized(): boolean {
    return this._isInitialized;
  }

  public static getInstance(): LogRocketHelper {
    if (!LogRocketHelper.instance) {
      LogRocketHelper.instance = new LogRocketHelper();
    }

    return LogRocketHelper.instance;
  }

  public static getSessionUrl(): Promise<string | null> {
    return new Promise(resolve => {
      const logRocketInstance = LogRocketHelper.getInstance();
      if (logRocketInstance.logrocket) {
        logRocketInstance.logrocket.getSessionURL((sessionUrl: string) => resolve(sessionUrl));
      } else {
        resolve(null);
      }
    });
  }

  public static track(trackEvent: string) {
    const logRocketInstance = LogRocketHelper.getInstance();
    if (logRocketInstance.logrocket) {
      logRocketInstance.logrocket.track(trackEvent);
    }
  }

  static async init() {
    if (!this.isEnabled()) {
      return;
    }

    const { default: LogRocket } = await import('logrocket');
    const { default: LogrocketFuzzySanitizer } = await import('logrocket-fuzzy-search-sanitizer');

    const { requestSanitizer, responseSanitizer } = LogrocketFuzzySanitizer.setup([
      ...privateFieldNames,
    ]);

    const logRocketInstance = this.getInstance();

    logRocketInstance.logrocket = LogRocket;

    if (logRocketInstance.logrocket) {
      // Fallback to old api key config value if need be
      logRocketInstance.logrocket.init(getApiKey('logRocket'), {
        network: {
          requestSanitizer,
          responseSanitizer,
        },
      });
      logRocketInstance._isInitialized = true;
      logRocketInstance.emit('initialized');

      LogRocket.getSessionURL((sessionURL: string) => {
        logRocketInstance._sessionURL = sessionURL;
        logRocketInstance.emit('session', { sessionURL });

        DatadogLogger.addContext('logrocketSessionUrl', sessionURL);
      });

      logRocketInstance._runOnInit?.();
    }

    logRocketInstance.logrocket.track(`Env: ${env()}`);
    logRocketInstance.logrocket.track(`Brand: ${brand()}`);
    logRocketInstance.logrocket.track(`Platform: ${platform()}`);
    logRocketInstance.logrocket.track(`Country: ${String(loadRegion())}`);
    logRocketInstance.logrocket.track(`Lang: ${String(loadLanguage())}`);
  }

  public static isEnabled() {
    return !(isLocalDev || env() === 'test');
  }
}
