import React from 'react';

import { Box } from '@rbilabs/components-library';
import { useIntl } from 'react-intl';

import { ICartEntry } from '@rbi-ctg/menu';
import ActionButton from 'components/action-button';
import Modal, { ModalSize } from 'components/modal';
import Picture from 'components/picture';

import theme from './theme';

import {
  Container,
  Content,
  Header,
  Item,
  ItemContainer,
  ItemName,
  ModalMessage,
  TileImageWrapper,
} from '.';

export interface IRemoveUnavailableItemsModalProps {
  unavailableItems: ICartEntry[];
  onConfirm: () => void;
  onDismiss: () => void;
}

export const RemoveUnavailableItemsModal: React.FC<IRemoveUnavailableItemsModalProps> = ({
  onConfirm,
  onDismiss,
  unavailableItems,
}: IRemoveUnavailableItemsModalProps) => {
  const { formatMessage } = useIntl();

  const message = formatMessage({ id: 'someItemsAreUnavailableInSelectedStore' });

  const buttons = [
    { label: formatMessage({ id: 'removeItems' }), onClick: onConfirm },
    {
      label: formatMessage({ id: 'cancel' }),
      onClick: onDismiss,
      variant: theme.emptyCartBtnVariant,
    },
  ];

  return (
    <Modal
      allowsDismiss={false}
      mParticleEventData={{
        modalAppearanceEventMessage: 'Items in order unavailable at selected store',
        modalMessage: message,
      }}
      onDismiss={onDismiss}
      size={ModalSize.REGULAR}
    >
      <Content>
        <Container>
          <Header id="modal-heading" data-testid="modal-heading">
            {formatMessage({ id: 'itemsUnavailable' })}
          </Header>
          <ModalMessage data-testid="modal-message">{message}</ModalMessage>
          <ItemContainer>
            {unavailableItems.map((item, index) => (
              <>
                <Item key={`${item.cartId}-${index}`}>
                  {item.image && (
                    <TileImageWrapper>
                      <Picture alt="" image={item.image} />
                    </TileImageWrapper>
                  )}
                  <ItemName>{item.name}</ItemName>
                </Item>
              </>
            ))}
          </ItemContainer>
          <Box
            flexDirection="column"
            margin={theme.buttonContainerMargin}
            minWidth="auto"
            width="100%"
          >
            {buttons.map(button => (
              <Box margin="0.5rem 0" width="100%" minWidth="auto" key={button.label}>
                <ActionButton onlyIcon fullWidth onClick={button.onClick} variant={button.variant}>
                  {button.label}
                </ActionButton>
              </Box>
            ))}
          </Box>
        </Container>
      </Content>
    </Modal>
  );
};
