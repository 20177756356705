import { ISanityVendorConfigs } from '@rbi-ctg/menu';
import {
  ILoyaltyInRestaurantIncentiveInput,
  ILoyaltyInRestaurantOrderLineItem,
  ILoyaltyVendorConfigsInput,
  LoyaltyCartDiscountType,
} from 'generated/graphql-gateway';
import { CartEntryType } from 'generated/rbi-graphql';
import { IOfferDiscount } from 'generated/sanity-graphql';
import {
  IEngineRewardsMap,
  ISanityRewardsMap,
} from 'hooks/use-loyalty-rewards-list/use-loyalty-rewards-list';
import { isCombo, isItem, isPicker } from 'utils/menu/is-menu-type';
import { blockContentToPlainText } from 'utils/sanity';

import { LoyaltyAppliedOffer } from '../types';

import { ICartEntryType } from './hooks/use-in-restaurant-redemption-cart';
import type {
  ICart,
  ICartEntry,
  ICartEntryLegacyOffer,
  ICartEntryOffer,
  ICartEntryReward,
} from './hooks/use-in-restaurant-redemption-cart';
import { createCartEntry } from './hooks/use-in-restaurant-redemption-cart/incentive-cart';

// the accepted rbi gateway vendors
// @todo Should we add SimplyDelivery here?
const lineItemVendors = [
  'carrols',
  'carrolsDelivery',
  'hdx',
  'ncr',
  'ncrDelivery',
  'productNumber',
  'productNumberDelivery',
  'qdi',
  'qdiDelivery',
  'qst',
  'rpos',
  'rposDelivery',
  'sicom',
  'sicomDelivery',
  'tablet',
  'tabletDelivery',
];

// filter invalid vendors
export const filterVendorConfigs = (vendorConfig: ISanityVendorConfigs) =>
  Object.keys(vendorConfig).reduce((accum, vendorKey) => {
    if (lineItemVendors.indexOf(vendorKey) !== -1) {
      accum[vendorKey] = vendorConfig[vendorKey];
    }

    return accum;
  }, {});

const getFilterBenefitsVendorConfigs = <IncentiveObject extends readonly (object | null)[]>(
  incentiveBenefits: IncentiveObject | null
) => {
  return incentiveBenefits?.reduce<Array<ILoyaltyVendorConfigsInput>>((acc, incentive) => {
    if (incentive && (isItem(incentive) || isCombo(incentive)) && incentive?.vendorConfigs) {
      acc.push(filterVendorConfigs(incentive.vendorConfigs));
    } else if (isPicker(incentive)) {
      // if picker collect all options' vendor configs
      const pickerBenefitVendorConfigs = getFilterBenefitsVendorConfigs(
        incentive.options.map(({ option }) => option)
      );

      if (pickerBenefitVendorConfigs?.length) {
        acc.push(...pickerBenefitVendorConfigs);
      }
    }

    return acc;
  }, []);
};

const getIncentiveVendorConfigs = (
  cartEntry: ICartEntry
): { incentiveVendorConfigs: Array<ILoyaltyVendorConfigsInput> } | null => {
  let incentiveVendorConfigs: Array<ILoyaltyVendorConfigsInput> | undefined;

  if (cartEntry.type === ICartEntryType.LEGACY_OFFER) {
    incentiveVendorConfigs = getOptionVendorConfigs(cartEntry);
  } else {
    const incentives =
      cartEntry.type === ICartEntryType.OFFER
        ? cartEntry.details.offer.incentives
        : cartEntry.details.reward.incentives;

    incentiveVendorConfigs = getFilterBenefitsVendorConfigs(incentives);
  }

  return incentiveVendorConfigs?.length ? { incentiveVendorConfigs } : null;
};

const getOptionVendorConfigs = (
  cartEntryLegacyOffer: ICartEntryLegacyOffer
): ILoyaltyVendorConfigsInput[] => {
  const { offer } = cartEntryLegacyOffer.details;
  const vendorConfigs: ILoyaltyVendorConfigsInput[] = [];

  if ((isItem(offer.option) || isCombo(offer.option)) && offer.option?.vendorConfigs) {
    vendorConfigs.push(filterVendorConfigs(offer.option.vendorConfigs));
  }

  return vendorConfigs;
};

const getNameFromCartEntry = (offerCartEntry: ICartEntryOffer | ICartEntryLegacyOffer): string => {
  const { offer } = offerCartEntry.details;
  const stringOfferName = blockContentToPlainText(offer?.name?.localeRaw || []);

  return stringOfferName;
};

const getLocalizedPropFromCartEntry = (
  cartEntry: ICartEntryOffer | ICartEntryLegacyOffer,
  propName: 'name' | 'description'
): Pick<ILoyaltyInRestaurantIncentiveInput, 'name' | 'description'> | null => {
  if (!propName) {
    return null;
  }
  const offer = cartEntry.details.offer;
  const stringOffer = blockContentToPlainText(offer[propName]?.localeRaw || []);

  return { [propName]: stringOffer };
};

export const mapRewardsToCartEntries = ({
  sanityRewardsMap,
  engineRewardsMap,
}: {
  sanityRewardsMap: ISanityRewardsMap;
  engineRewardsMap: IEngineRewardsMap;
}): ICart =>
  Object.values(engineRewardsMap).reduce((cartEntries: ICart, reward) => {
    if (
      reward.remainingPointsNeededForRedemption !== 0 ||
      !reward.sanityId ||
      !sanityRewardsMap[reward.sanityId]
    ) {
      return cartEntries;
    }

    return cartEntries.concat(
      createCartEntry({ engineReward: reward, reward: sanityRewardsMap[reward.sanityId] })
    );
  }, []);

const rewardOrderLineItem = (cartEntry: ICartEntryReward): ILoyaltyInRestaurantOrderLineItem => {
  const { quantity, referenceId, type } = cartEntry;
  const { engineReward, reward } = cartEntry.details;

  return {
    incentive: {
      id: engineReward.id,
      ...getIncentiveVendorConfigs(cartEntry),
      type,
    },
    productId: reward._id,
    quantity,
    referenceId,
    vendorConfigs: filterVendorConfigs(reward.vendorConfigs as ISanityVendorConfigs),
  };
};

const offerOrderLineItem = (
  cartEntry: ICartEntryOffer | ICartEntryLegacyOffer
): ILoyaltyInRestaurantOrderLineItem => {
  const { quantity, referenceId, type } = cartEntry;
  const { offer } = cartEntry.details;
  const { shortCode: offerCode } = offer;
  let id: string;

  if (cartEntry.type === ICartEntryType.OFFER) {
    id = cartEntry.details.offer.loyaltyEngineId!;
  } else {
    id = cartEntry.details.offer._id;
  }

  const name = getNameFromCartEntry(cartEntry);

  const offerDiscountIncentive =
    'incentives' in offer &&
    (offer.incentives?.find(
      incentive => incentive?.__typename === CartEntryType.OFFERDISCOUNT
    ) as IOfferDiscount);

  return {
    incentive: {
      id,
      ...getLocalizedPropFromCartEntry(cartEntry, 'name'),
      ...getLocalizedPropFromCartEntry(cartEntry, 'description'),
      ...getIncentiveVendorConfigs(cartEntry),
      name,
      type,
      ...(offerCode && { offerCode }),
      ...(offerDiscountIncentive && {
        discount: {
          type:
            offerDiscountIncentive.discountType?.toUpperCase() === LoyaltyCartDiscountType.AMOUNT
              ? LoyaltyCartDiscountType.AMOUNT
              : LoyaltyCartDiscountType.PERCENTAGE,
          value: offerDiscountIncentive.discountValue || 0,
        },
      }),
    },
    productId: offer._id,
    quantity,
    referenceId,
    vendorConfigs: filterVendorConfigs(offer.vendorConfigs as ISanityVendorConfigs),
  };
};

const generateOrderLineItemsMap: {
  [key in ICartEntryType]: (ce: ICartEntry) => ILoyaltyInRestaurantOrderLineItem;
} = {
  [ICartEntryType.REWARD]: rewardOrderLineItem,
  [ICartEntryType.OFFER]: offerOrderLineItem,
  [ICartEntryType.LEGACY_OFFER]: offerOrderLineItem,
};

export const generateOrderLineItems = (cart: ICart): Array<ILoyaltyInRestaurantOrderLineItem> =>
  cart.map((cartEntry: ICartEntry) => generateOrderLineItemsMap[cartEntry.type](cartEntry));

export const getAppliedOffersFromInRestaurantCart = (
  inRestaurantCart: ICart
): LoyaltyAppliedOffer[] => {
  return inRestaurantCart.reduce<LoyaltyAppliedOffer[]>((acc: LoyaltyAppliedOffer[], cartEntry) => {
    if (cartEntry.type === ICartEntryType.OFFER) {
      const { offer } = cartEntry.details;
      acc.push({
        id: offer.loyaltyEngineId,
        cartId: offer._id,
        isStackable: offer.isStackable,
        cmsId: offer._id,
      });
    } else if (cartEntry.type === ICartEntryType.LEGACY_OFFER) {
      const { offer } = cartEntry.details;
      acc.push({
        id: offer._id,
        cartId: offer._id,
        isStackable: false,
        cmsId: offer._id,
      });
    }
    return acc;
  }, []);
};
