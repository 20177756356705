import { useFeatureLoyaltyLinkPhysicalCardContentQuery } from 'generated/sanity-graphql';
import { useFeaturesContext } from 'state/features';

export const useLoyaltyLinkCardUI = () => {
  const { featureLinkPhysicalCardUIId = '', featureIdsLoading } = useFeaturesContext();
  const { data, loading } = useFeatureLoyaltyLinkPhysicalCardContentQuery({
    variables: { featureLinkPhysicalCardUIId },
    skip: !featureLinkPhysicalCardUIId,
  });

  return {
    data: data?.LinkPhysicalCardUi,
    loading: featureIdsLoading || loading,
  };
};
