export enum ServiceMode {
  CATERING_PICKUP = 'CATERING_PICKUP',
  CATERING_DELIVERY = 'CATERING_DELIVERY',
  CURBSIDE = 'CURBSIDE',
  DELIVERY = 'DELIVERY',
  DRIVE_THRU = 'DRIVE_THRU',
  EAT_IN = 'EAT_IN',
  TABLE_SERVICE = 'TABLE_SERVICE',
  TAKEOUT = 'TAKEOUT',
}
