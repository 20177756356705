import { useCallback, useState } from 'react';

import { UseThreeDS } from 'hooks/use-threeDS/types';
import { ThreeDSType } from 'state/payment/hooks/types';

const useThreeDS = (): UseThreeDS => {
  // ThreeDS data
  const [threeDSActiveFlow, setThreeDSActiveFlow] = useState<ThreeDSType | null>(null);
  const [threeDSTransactionId, setThreeDSTransactionId] = useState<string | null>(null);
  // Method flow states
  const [threeDSIframeContent, setThreeDSIframeContent] = useState<string | null>(null); // html
  // Challenge flow states
  const [threeDSAcsUrl, setThreeDSAcsUrl] = useState<string | null>(null);
  const [threeDSChallengeRequest, setThreeDSChallengeRequest] = useState<string | null>(null);
  const [threeDSChallengeTokenResponse, setThreeDSChallengeTokenResponse] = useState<string | null>(
    null
  );

  const isThreeDSMethodFlow = useCallback((): boolean => {
    return !!(
      threeDSActiveFlow === ThreeDSType.METHOD &&
      threeDSTransactionId &&
      threeDSIframeContent
    );
  }, [threeDSActiveFlow, threeDSTransactionId, threeDSIframeContent]);

  const isChallengeRequest = useCallback((): boolean => {
    return !!(
      threeDSActiveFlow === ThreeDSType.CHALLENGE &&
      threeDSAcsUrl &&
      threeDSChallengeRequest
    );
  }, [threeDSActiveFlow, threeDSAcsUrl, threeDSChallengeRequest]);

  const cleanThreeDSMethodDSFlow = () => {
    setThreeDSActiveFlow(null);
    setThreeDSIframeContent(null);
  };

  const cleanThreeDSFlow = () => {
    setThreeDSActiveFlow(null);
    setThreeDSTransactionId(null);
    setThreeDSIframeContent(null);
    setThreeDSAcsUrl(null);
    setThreeDSChallengeRequest(null);
  };

  return {
    threeDSActiveFlow,
    setThreeDSActiveFlow,
    threeDSTransactionId,
    setThreeDSTransactionId,
    threeDSIframeContent,
    setThreeDSIframeContent,
    threeDSAcsUrl,
    setThreeDSAcsUrl,
    threeDSChallengeRequest,
    setThreeDSChallengeRequest,
    threeDSChallengeTokenResponse,
    setThreeDSChallengeTokenResponse,
    isChallengeRequest,
    isThreeDSMethodFlow,
    cleanThreeDSFlow,
    cleanThreeDSMethodDSFlow,
  };
};

export default useThreeDS;
