import { IFee, IServerOrder } from '@rbi-ctg/menu';
import { DONATIONS } from 'hooks/use-donations/constants';

import { FEES } from './constants';
import { IGetStandardizedName } from './types';

export const getRoundUpDonations = (serverOrder: IServerOrder | null) =>
  serverOrder?.cart?.donations?.find(({ name }) => name === DONATIONS.ROUND_UP);

export const getStandardizedName = ({ name }: IGetStandardizedName) => FEES[name] ?? name;

export const getFeesByGroup = (fees: IFee[] | null) =>
  (fees ?? []).reduce((acc: { [key: string]: number }, val: IFee) => {
    const lowerCaseName = val.name.toLowerCase();
    if (!acc[lowerCaseName]) {
      acc[lowerCaseName] = 0;
    }

    acc[lowerCaseName] += val.totalCents;

    return acc;
  }, {});
