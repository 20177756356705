import React from 'react';

import { Meta } from 'react-meta-elements';

import { allowBotIndexFollowList, shouldUseAllowBotIndexFollowList } from 'utils/routing/botRoutes';

/*
 * This hook adds noindex/nofollow meta when "required":
 *
 * Paths not in the brands "allow" list will have the noindex/nofollow
 * meta tag applied to the <Head> so bots do not index and do not follow links.
 *
 * NOTE: has to be an exact match to allow bots to index/follow
 * NO TRAILING '/' !!!
 */
const useRobotMetaTags = (pathname: string) => {
  // check if the brand subscribes to this functionality
  if (shouldUseAllowBotIndexFollowList) {
    // if path is in list allow bot to index/follow links
    if (allowBotIndexFollowList.includes(pathname)) {
      return null;
    } else {
      // if path not in the allowed list we want to disable all bot indexing/following
      return <Meta name="robots" content="none" />;
    }
  }

  return null;
};

export default useRobotMetaTags;
