import gql from 'graphql-tag';

import ItemFragment from './item';
import MenuImageFragment from './menu-image';
import VendorConfigsFragment from './vendor-configs';

export default gql`
  fragment ComboFragment on Combo {
    _id
    _type
    name {
      locale: _LANG_
    }
    image {
      ...MenuImageFragment
    }
    mainItem {
      ...ItemFragment
    }
    uiPattern
    hideMainItemDescription
    forceModifiersToBottom
    vendorConfigs {
      ...VendorConfigsFragment
    }
    options {
      name {
        locale: _LANG_
      }
      _type
      _id
      vendorConfigs {
        ...VendorConfigsFragment
      }
      uiPattern
      minAmount
      maxAmount
      respectMaximum
      optionVisibilitySettings {
        visibleOptions
        toggleButtonTextClosed {
          locale: _LANG_
        }
        toggleButtonTextOpen {
          locale: _LANG_
        }
      }
      options {
        minAmount
        maxAmount
        defaultAmount
        isPremium
        option {
          ... on Item {
            ...ItemFragment
          }
          ... on Picker {
            _type
            uiPattern
            pickerAspects {
              name {
                locale: _LANG_
              }
              pickerAspectOptions {
                name {
                  locale: _LANG_
                }
                identifier
              }
            }
          }
        }
      }
    }
  }
  ${ItemFragment}
  ${MenuImageFragment}
  ${VendorConfigsFragment}
`;
