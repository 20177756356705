import * as React from 'react';
import { FC } from 'react';

import { VisuallyHidden } from '@rbilabs/components-library';

import RedeemRewardActionButton from './redeem-reward-action';
import {
  BottomContainer,
  ButtonContainer,
  FloatingPointsIcon,
  InfoContainer,
  PointsApplied,
  PointsIcon,
  Wrapper,
} from './redeem-reward.styled';
import { IRedeemRewardViewProps, RewardActions } from './types';

export const RedeemRewardView: FC<IRedeemRewardViewProps> = ({
  handleRewardClick,
  points,
  rewardTitle,
  isRewardApplied,
  disableApplyButton,
  pointsApplied,
  itemQuantity,
  floatDirection,
  incentiveNotInMenu,
  redeemRewardLoading,
}) => {
  const displayApplyButton = itemQuantity > 1 || !isRewardApplied;
  const displayActionButtons = !redeemRewardLoading && !incentiveNotInMenu;

  const renderRewardActionButtons = () => (
    <>
      {displayApplyButton && (
        <RedeemRewardActionButton
          disabled={disableApplyButton}
          action={RewardActions.ADD}
          handleRewardClick={handleRewardClick}
          points={points}
        />
      )}
      {isRewardApplied && (
        <RedeemRewardActionButton
          disabled={!isRewardApplied}
          action={RewardActions.REMOVE}
          handleRewardClick={handleRewardClick}
          points={points}
        />
      )}
    </>
  );

  return (
    <Wrapper>
      <InfoContainer>
        <VisuallyHidden>{rewardTitle}</VisuallyHidden>
        <BottomContainer>
          <ButtonContainer>{displayActionButtons && renderRewardActionButtons()}</ButtonContainer>
          {!!pointsApplied && (
            <PointsApplied data-testid="points-applied" arePointsApplied={!!pointsApplied}>
              <PointsIcon /> -{pointsApplied}
              {floatDirection && (
                <FloatingPointsIcon key={pointsApplied} $floatDirection={floatDirection} />
              )}
            </PointsApplied>
          )}
        </BottomContainer>
      </InfoContainer>
    </Wrapper>
  );
};
