import { useContext, useEffect } from 'react';

import { compact } from 'lodash-es';

import { LocationContext } from 'state/location';
import {
  CustomEventNames,
  EventTypes,
  MParticleContext,
  useMParticleContext,
} from 'state/mParticle';
import { useStoreContext } from 'state/store';
import { routes } from 'utils/routing';

import { useFeatureHomePage } from '../features/home-page/use-feature-home-page';

export default function useLogPageView() {
  const locationContext = useContext(LocationContext);
  const { pathname } = locationContext.location;
  const mParticle = useContext(MParticleContext);
  const { homePageId } = useFeatureHomePage();
  const { logRBIEvent } = useMParticleContext();
  const { store } = useStoreContext();

  useEffect(() => {
    // determine attributes to send to the events below
    const attrs = {
      pathname,
      sanityId: pathname === routes.base ? homePageId.featureHomePageId : '',
      referrer: document.referrer,
    };
    const addr = store.physicalAddress;
    const restaurantAddress = {
      restaurantId: store._id || '',
      restaurantAddress: compact([addr?.address1, addr?.address2]).join(', ') || '',
      restaurantZip: addr?.postalCode || '',
      restaurantCity: addr?.city || '',
      restaurantState: addr?.stateProvince || '',
      restaurantCountry: addr?.country || '',
    };
    // Flags for google analytics integration
    const flags = {
      'Google.Page': pathname,
      'Google.DocumentReferrer': document.referrer,
    };
    // mParticle page view event - uses mParticle logPageView API to track page view
    mParticle.logPageView(pathname, attrs, flags);
    // mParticle custom event for page view - uses mParticle logEvent API to track page view
    logRBIEvent({
      name: CustomEventNames.PAGE_VIEW,
      type: EventTypes.Other,
      attributes: {
        ...attrs,
        ...flags,
        path: pathname,
        ...restaurantAddress,
      },
    });
  }, [pathname]); // eslint-disable-line react-hooks/exhaustive-deps
}
