import styled from 'styled-components';

import {
  StyledButtonsContainer as StyledButtonsContainerBase,
  StyledDialog as StyledDialogBase,
  StyledHeader as StyledHeaderBase,
  StyledTextOnlyButton as StyledTextOnlyButtonBase,
} from './actions-modal.styled.default';

export * from './actions-modal.styled.default';

export const StyledDialog = styled(StyledDialogBase)`
  padding-top: 1.5rem;
  text-align: left;
`;

export const StyledBody = styled.div``;

export const StyledHeader = styled(StyledHeaderBase)`
  font-family: ${Styles.fontFamily.base};
  margin-bottom: 0.5rem;
`;

export const StyledButtonsContainer = styled(StyledButtonsContainerBase)`
  width: 100%;
`;

export const StyledTextOnlyButton = styled(StyledTextOnlyButtonBase)`
  color: ${Styles.color.tertiary};
  &:focus {
    border: none;
    color: ${Styles.color.tertiary};
  }
`;
