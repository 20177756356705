import React from 'react';

import { useIntl } from 'react-intl';
import styled from 'styled-components';

import Currency from 'components/currency';
import { selectors, useAppSelector } from 'state/global-state';
import { useLoyaltyContext } from 'state/loyalty';
import { useIsLoyaltyEnabled } from 'state/loyalty/hooks/use-is-loyalty-enabled';
import { useOffersContext } from 'state/offers';
import { useOrderContext } from 'state/order';
import { priceForCartEntry } from 'utils/menu/price';

import { PriceAndQuantity } from '../cart-item/price-and-quantity';
import { RedeemReward } from '../cart-item/redeem-reward';

import CartPreviewActions, {
  CartPreviewEditButton,
  CartPreviewIncrementor,
  CartPreviewRemoveButton,
  CartPreviewTitle,
  CartPreviewVisuallyHidden,
} from './cart-preview-actions';
import { CartEntryQuantityText } from './styled/cart-entry-quantity-text';
import { CartPreviewEditContainer } from './styled/cart-preview-edit-container';
import { CartPreviewEntryContainer } from './styled/cart-preview-entry-container';
import { CartPreviewItem } from './styled/cart-preview-item';
import { ItemPrice } from './styled/item-price';
import theme from './theme';
import { ICartPreviewEntry } from './types';
import { getIsOffer } from './utils';

const PeopleLabel = styled.span`
  color: ${theme.peopleLabelColor};
  margin-right: 1rem;
`;

const RedeemRewardWrapper = styled.div`
  > div {
    margin: 1.25rem 0;
  }
`;

const CartPreviewEntry: React.FC<ICartPreviewEntry> = ({
  closeModalCallback,
  item,
  rewardApplied = false,
}) => {
  const { formatMessage } = useIntl();
  const { isCartEntryInSwaps } = useOrderContext();
  const { selectedOfferPrice, selectedOfferCartEntry } = useOffersContext();
  const appliedOffers = useAppSelector(selectors.loyalty.selectAppliedOffers);
  const loyaltyEnabled = useIsLoyaltyEnabled();
  const { getAvailableRewardFromCartEntry } = useLoyaltyContext();
  const isStandardOffer = selectedOfferCartEntry
    ? getIsOffer({ selectedOfferCartEntry, item })
    : false;
  const isSwapOffer = isCartEntryInSwaps(item);
  const isLoyaltyOffer =
    isSwapOffer || appliedOffers.some(lylOffer => lylOffer.cartId === item.cartId);
  const isExtra = item?.isExtra;
  const itemPrice = isStandardOffer ? selectedOfferPrice : priceForCartEntry(item);
  const loyaltyRewardAvailable = getAvailableRewardFromCartEntry(item);
  const isItemEditableInline = !isStandardOffer && !isLoyaltyOffer && loyaltyEnabled && !isExtra;

  return (
    <CartPreviewEntryContainer>
      <CartPreviewItem>
        <CartPreviewTitle item={item} />
        {isItemEditableInline ? (
          <PriceAndQuantity item={item} rewardApplied={rewardApplied} />
        ) : (
          <ItemPrice>
            <Currency amount={isLoyaltyOffer ? item.price : itemPrice} />
          </ItemPrice>
        )}
      </CartPreviewItem>

      {isItemEditableInline && loyaltyRewardAvailable && (
        <RedeemRewardWrapper>
          <RedeemReward
            isRewardApplied={rewardApplied}
            item={item}
            reward={loyaltyRewardAvailable}
          />
        </RedeemRewardWrapper>
      )}
      <CartPreviewActions isOffer={isStandardOffer} isReward={rewardApplied} item={item}>
        <CartPreviewEditContainer>
          {!isLoyaltyOffer && <CartPreviewEditButton closeModalCallback={closeModalCallback} />}
          <CartPreviewRemoveButton closeModalCallback={closeModalCallback} />
        </CartPreviewEditContainer>
        {isExtra && <CartEntryQuantityText>{`${item.quantity}x`}</CartEntryQuantityText>}
        {!isLoyaltyOffer && !isExtra && (
          <CartPreviewIncrementor>
            {item.labelAsPerPerson && (
              <PeopleLabel>
                {formatMessage({
                  id: 'people',
                })}
                :
              </PeopleLabel>
            )}
          </CartPreviewIncrementor>
        )}
        <CartPreviewVisuallyHidden />
      </CartPreviewActions>
    </CartPreviewEntryContainer>
  );
};

export default CartPreviewEntry;
