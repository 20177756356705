import React, { FC } from 'react';

import styled from 'styled-components';

import { DesktopRaggedEdge } from 'components/icons/ragged-edge';
import useMediaQuery from 'hooks/use-media-query';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';

import { Row } from './shared';
import { IFooterProps } from './types';
import { useFeatureFooter } from './use-feature-footer';

const FooterContainer = styled.footer`
  display: flex;
  flex-direction: column;
  font-family: ${Styles.fontFamily.base};
  font-weight: ${Styles.fontWeight.normal};
  letter-spacing: normal;

  ${Styles.desktop`
    display: flex;
    width: 100%;
    margin-top: -13px;
    z-index: ${Styles.zIndex.top};
  `}
`;

const EdgeContainer = styled.div`
  height: 13px;
  width: 100%;
`;

const ContentContainer = styled.div`
  background-color: ${Styles.color.white};
  color: ${Styles.color.grey.one};
  width: 100%;
  padding: 1rem 1rem 2rem;
  ${Styles.desktop`
    color: ${Styles.color.white};
    background-color: ${Styles.color.grey.one};
    padding: 48px 64px 40px;
  `}
  ${Styles.desktopLarge`
    padding: 90px 120px 50px;
  `}
`;

const FooterContent: FC<IFooterProps> = props => {
  const { featureFooter, featureFooterLoading } = useFeatureFooter();
  const isMobile = useMediaQuery('mobile');
  const enableMobileFooter = useFlag(LaunchDarklyFlag.ENABLE_MOBILE_FOOTER);

  return featureFooterLoading || (isMobile && !enableMobileFooter) ? null : (
    <FooterContainer {...props} role="contentinfo">
      {!isMobile && (
        <EdgeContainer>
          <DesktopRaggedEdge />
        </EdgeContainer>
      )}
      <ContentContainer>
        {featureFooter?.rows?.map((row, index) => row && <Row key={row._key ?? index} {...row} />)}
      </ContentContainer>
    </FooterContainer>
  );
};

export default FooterContent;
