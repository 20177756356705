import { regionSupportedLanguages } from 'hooks/use-set-user-locale/regionLanguages';
import { loadLanguage } from 'state/intl/load-language';
import { loadRegion } from 'state/intl/load-region';
import { PROD_DEFAULT_LANGUAGE } from 'state/intl/supported-languages';
import { PROD_DEFAULT_REGION, PROD_SUPPORTED_REGIONS } from 'state/intl/supported-regions';
import { country } from 'utils/environment';

import { REGIONAL_APP } from './regional-app';

export const loadLocale = () => {
  if (REGIONAL_APP) {
    const region = country?.toUpperCase();

    const hasMultipleLanguages = region && regionSupportedLanguages[region].size > 1;
    // If region has multiple languages, access language from selection history (or default if not available)
    // otherwise lock in default language
    const language = hasMultipleLanguages ? loadLanguage() : PROD_DEFAULT_LANGUAGE[region];
    const supportedRegion = PROD_SUPPORTED_REGIONS[region] || PROD_DEFAULT_REGION;

    return { language, region: supportedRegion };
  }

  return { language: loadLanguage(), region: loadRegion() };
};
