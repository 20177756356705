import React, { useCallback, useEffect, useState } from 'react';

import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { SupportedLanguages, SupportedRegions } from '@rbi-ctg/frontend';
import Modal, { ModalContent, ModalHeading } from 'components/modal';
import useEffectOnce from 'hooks/use-effect-once';
import { useLocale } from 'state/intl';
import { LOCALE_SELECTED_QUERY_PARAM } from 'state/intl/hook';
import { inferSupportedLocale } from 'state/intl/infer-supported-locale';
import { REGIONS } from 'state/intl/types';
import { useFlag } from 'state/launchdarkly';
import { NEXT_URL_QUERY_PARAM } from 'state/location/constants';
import { CustomEventNames, EventTypes, useMParticleContext } from 'state/mParticle';
import { isNative } from 'utils/environment';
import { LaunchDarklyFlag } from 'utils/launchdarkly';

import { modalHeadingStyles } from './constants';
import { domainChangeByRegion } from './domainChangeByRegion';
import LanguageSelectorModalContents from './language-selector-modal-contents';
import { ModalAdditionalContentWrapper } from './language-selector-modal-contents/styled';

const ContentContainer = styled(ModalContent)`
  padding-bottom: 2rem;
  & div.modal-inner {
    margin: 0;
    width: 100%;
    max-width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    grid-auto-rows: auto;
    text-align: justify;
  }
`;

const StyledModalHeading = styled(ModalHeading)`
  justify-self: center;

  ${modalHeadingStyles}

  ${Styles.desktop`
    max-width: none;
  `}
`;

const PrimaryText = styled.div`
  margin: 2rem auto;
  text-align: center;
`;

const SecondaryText = styled.div`
  text-align: center;
`;

interface ILanguageModalProps {
  onModalDismiss: () => void;
  heading?: string;
  regionFilter?: (region: string) => boolean;
  redirectToCurrentLocation?: boolean;
  primaryText?: string;
  secondaryText?: string;
  disclaimer?: string;
}

const LanguageSelectorModal: React.FC<ILanguageModalProps> = ({
  onModalDismiss,
  heading,
  primaryText,
  secondaryText,
  disclaimer,
  regionFilter = (regionString: string) => !!regionString,
  redirectToCurrentLocation = false,
}) => {
  const { formatMessage } = useIntl();
  const { logEvent, updateUserAttributes } = useMParticleContext();
  const {
    region,
    regionDomainValues,
    clearRegionSpecificStorage,
    setHasShownLocaleSelector,
    locale,
    language,
  } = useLocale();
  const [{ langKey, regionKey }, setSelectedLocale] = useState({
    langKey: language,
    regionKey: region,
  });

  const [isOneStepSelection, setIsOneStepSelection] = useState(false);

  const singleRegionLanguageSelector = useFlag(LaunchDarklyFlag.SINGLE_REGION_LANGUAGE_SELECTOR);

  const handleSelection = (
    selectedLanguage: string,
    mappedRegion: string,
    isOneStep: boolean = false
  ) => () => {
    setIsOneStepSelection(isOneStep);

    setSelectedLocale({
      langKey: selectedLanguage as SupportedLanguages,
      regionKey: mappedRegion as SupportedRegions,
    });
  };

  const isNewLocaleSelectionSameAsCurrent = useCallback(() => {
    const inferredLocaleFromArgs = inferSupportedLocale(
      langKey as SupportedLanguages,
      regionKey as SupportedRegions
    );

    return inferredLocaleFromArgs === locale;
  }, [langKey, regionKey, locale]);

  const setLanguage = useCallback(() => {
    if (isNewLocaleSelectionSameAsCurrent()) {
      onModalDismiss();
      return;
    }

    const isDeployPreview = window.location.href.includes('preview-');

    const encodedCurrentUrl = encodeURIComponent(window.location.pathname);
    let strParams = `?lang=${langKey}&${LOCALE_SELECTED_QUERY_PARAM}=1`;

    if (redirectToCurrentLocation) {
      strParams += `&${NEXT_URL_QUERY_PARAM}=${encodedCurrentUrl}`;
    }

    const params = new URLSearchParams(strParams);

    let url = window.location.origin.replace(
      regionDomainValues[region],
      regionDomainValues[regionKey]
    );

    url = domainChangeByRegion(url, regionKey as REGIONS);

    // Don't change the URL on native
    // Changing the url will cause Android to open the web browser
    if (isNative || isDeployPreview) {
      url = window.location.origin;
    }

    // Add the region query param and clear localstorage
    // if we are not going to do a full url change
    if (url === window.location.origin) {
      params.append('region', regionKey);

      clearRegionSpecificStorage();
    }

    setHasShownLocaleSelector();
    window.location.href = `${url}?${params}`;

    onModalDismiss();
  }, [
    isNewLocaleSelectionSameAsCurrent,
    onModalDismiss,
    langKey,
    regionKey,
    redirectToCurrentLocation,
    regionDomainValues,
    region,
    setHasShownLocaleSelector,
    clearRegionSpecificStorage,
  ]);

  useEffectOnce(() => {
    logEvent(CustomEventNames.LOCALE_SELECTOR_MODAL_SHOWN, EventTypes.Navigation);
  });

  useEffect(() => {
    updateUserAttributes({ language });
    if (!isOneStepSelection) {
      return;
    }
    if (isNewLocaleSelectionSameAsCurrent()) {
      onModalDismiss();
    } else {
      setLanguage();
    }
  }, [
    langKey,
    regionKey,
    isNewLocaleSelectionSameAsCurrent,
    setLanguage,
    isOneStepSelection,
    onModalDismiss,
    updateUserAttributes,
    language,
  ]);

  const applyButtonText = formatMessage({ id: 'apply' });
  const modalHeading =
    heading ||
    (singleRegionLanguageSelector
      ? formatMessage({ id: 'selectLanguage' })
      : formatMessage({ id: 'changeLanguage' }));

  return (
    <Modal
      data-testid="select-language-modal"
      onDismiss={onModalDismiss}
      mParticleEventData={{
        modalAppearanceEventMessage: 'Language and region selector',
      }}
    >
      <ContentContainer>
        <StyledModalHeading id="language-select-modal-heading">{modalHeading}</StyledModalHeading>
        {(primaryText || secondaryText) && (
          <ModalAdditionalContentWrapper>
            {primaryText && <PrimaryText>{primaryText}</PrimaryText>}
            {secondaryText && <SecondaryText>{secondaryText}</SecondaryText>}
          </ModalAdditionalContentWrapper>
        )}
        <LanguageSelectorModalContents
          ariaRadioGroupLabeledBy="language-select-modal-heading"
          regionFilter={regionFilter}
          regionKey={regionKey}
          langKey={langKey}
          disclaimer={disclaimer}
          applyButtonText={applyButtonText}
          setLanguage={setLanguage}
          handleSelection={handleSelection}
        />
      </ContentContainer>
    </Modal>
  );
};

export default LanguageSelectorModal;
