import { useEffect, useState } from 'react';

import { getConfigValue } from 'utils/environment';

declare global {
  interface Window {
    OneTrust: any;
  }
}

type ErrorState = ErrorEvent | null;

const IS_BROWSER = typeof window !== 'undefined' && typeof window.document !== 'undefined';
const ONE_TRUST_SDK_SCRIPT_ID = 'one-trust-cookie-consent-sdk';
const getAutoBlockScript = (scriptId: string) => {
  return `https://cdn.cookielaw.org/consent/${scriptId}/OtAutoBlock.js`;
};

/**
 * Loads one trust sdk cookie consent script
 */
export default function useOneTrustSdk() {
  const [loading, setLoading] = useState(!!window.OneTrust ? false : true);
  const [loaded, setLoaded] = useState(!!window.OneTrust ? true : false);
  const [error, setError] = useState<ErrorState>(null);

  useEffect(() => {
    const { scriptUrl, scriptId } = getConfigValue('oneTrust').cookieCompliance;
    if (!IS_BROWSER || !scriptUrl || !scriptId) {
      return;
    }

    // OneTrust for plk/bk US is configured to be added in the <head> as part of the public/index.html
    // payload to ensure it runs before any other scripting.
    // In this case, we can just check if the OneTrust object has been added to the global scope or not
    const existing = !!window.OneTrust;

    if (existing) {
      setLoading(false);
      setLoaded(true);
      return;
    }

    const handleLoad = () => {
      setLoading(false);
      setLoaded(true);

      // This code block is required by One Trust script which is called after the SDK is loaded
      function OptanonWrapper() {} // eslint-disable-line @typescript-eslint/no-unused-vars
    };

    const handleError = (err: ErrorState) => {
      setLoading(false);
      setLoaded(false);
      setError(err);
    };

    // Add autoblock script
    const blockScript = document.createElement('script');
    blockScript.setAttribute('type', 'text/javascript');
    blockScript.setAttribute('src', getAutoBlockScript(scriptId));
    document.head.appendChild(blockScript);

    const otSdkScript = document.createElement('script');
    // TODO: This will be updated to include live endpoints based on region.
    otSdkScript.setAttribute('src', scriptUrl);
    otSdkScript.setAttribute('data-domain-script', scriptId);
    otSdkScript.setAttribute('id', ONE_TRUST_SDK_SCRIPT_ID);
    otSdkScript.setAttribute('type', 'text/javascript');
    otSdkScript.setAttribute('charset', 'UTF-8');
    otSdkScript.addEventListener('load', handleLoad);
    otSdkScript.addEventListener('error', handleError);

    document.head.appendChild(otSdkScript);

    return () => {
      document.head.removeChild(blockScript);
      document.head.removeChild(otSdkScript);
      otSdkScript.removeEventListener('load', handleLoad);
      otSdkScript.removeEventListener('error', handleError);
    };
  }, []);

  return { loaded, loading, error };
}
