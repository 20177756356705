import React, { Suspense } from 'react';

import { lazyWithNullFallback } from 'components/layout/util';
import { LaunchDarklyFlag, useFlag, useLDContext } from 'state/launchdarkly';

const LoyaltyOptInModalContent = lazyWithNullFallback(() =>
  import('pages/loyalty/loyalty-opt-in-modal/loyalty-opt-in-modal-content')
);

export default () => {
  const { ldUserIsAuthenticated } = useLDContext();
  const isLoyaltyOptInModalEnabled = useFlag(LaunchDarklyFlag.ENABLE_LOYALTY_OPT_IN_MODAL);

  if (!isLoyaltyOptInModalEnabled || !ldUserIsAuthenticated) {
    return null;
  }

  return (
    <Suspense fallback={null}>
      <LoyaltyOptInModalContent data-testid="loyalty-opt-in-modal" />
    </Suspense>
  );
};
