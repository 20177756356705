import { useMemo, useState } from 'react';

import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import useAuth from 'hooks/auth/use-auth';
import useDialogModal from 'hooks/use-dialog-modal';
import { useMParticleContext } from 'state/mParticle';
import { routes } from 'utils/routing';

export function useFavoriteStores() {
  const { user } = useAuth();
  const existingFavoriteStores = useMemo(() => {
    const userFavoriteStores = user?.details?.favoriteStores || [];
    const trimmedFavoriteStores = userFavoriteStores.map(store => ({
      storeId: store.storeId,
      storeNumber: store.storeNumber || '',
    }));
    // We need to remove falsy ids that were previously saved from bad sanity data
    return trimmedFavoriteStores.filter(store => !!store.storeId);
  }, [user]);

  return {
    validFavStores: existingFavoriteStores,
  };
}
export const useFavoriteStore = (storeId: string, storeNumber: string) => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { updateUserFavStores, isAuthenticated } = useAuth();
  const { validFavStores } = useFavoriteStores();
  const { updateUserAttributes } = useMParticleContext();

  const [favStoreLoading, setFavStoreLoading] = useState(false);

  const [ErrorFavoriteStoreDialog, openErrorDialog] = useDialogModal({
    showCancel: true,
    onConfirm: () => navigate(routes.signUp),
    modalAppearanceEventMessage: 'Error: Sign In to Set Favorite Stores',
  });

  const isFavorite = useMemo(() => !!validFavStores.find(store => store.storeId === storeId), [
    storeId,
    validFavStores,
  ]);

  const toggleFavorite = async () => {
    if (!isAuthenticated()) {
      openErrorDialog({
        title: formatMessage({ id: 'errorDialogFavoriteStoreTitle' }),
        message: formatMessage({ id: 'errorDialogFavoriteStoreMessage' }),
      });
      return;
    }

    const allFavoriteStores = isFavorite
      ? validFavStores.filter(store => store.storeId !== storeId)
      : [{ storeId, storeNumber }, ...validFavStores];
    // We need to remove falsy ids before we save to clean up bad sanity data
    const allValidFavoriteStores = allFavoriteStores.filter(store => !!store.storeId);
    setFavStoreLoading(true);
    await updateUserFavStores(allValidFavoriteStores);
    updateUserAttributes({
      favoriteStores: JSON.stringify(allValidFavoriteStores.map(store => store?.storeNumber)),
    });
    setFavStoreLoading(false);
  };

  return {
    toggleFavorite,
    isFavorite,
    ErrorFavoriteStoreDialog,
    favStoreLoading,
  };
};
