// This mParticleAdapter is used as the adapter layer to talk to the mParticle web SDK
// Usage of window.mParticle should be strongly discouraged outside of this file

import { EventTypes, ProductActionTypes } from './constants';
import {
  IMParticleAdapter,
  IMParticleCallback,
  IMParticleIdentityAdapter,
  IMParticleProduct,
  IMparticleIdentityApiData,
} from './types';

class MParticleIdentityAdapterWeb implements IMParticleIdentityAdapter {
  static modify(identityApiData: IMparticleIdentityApiData, callback: IMParticleCallback) {
    return window.mParticle.Identity.modify(identityApiData, callback);
  }
}

class MParticleAdapterWeb implements IMParticleAdapter {
  createProduct(name: string, sku: string, price: number, quantity: number) {
    return window.mParticle.eCommerce.createProduct(name, sku, price, quantity);
  }

  logEvent(name: string, eventTypes: EventTypes, attributes?: object, customFlags?: object) {
    if (customFlags) {
      window.mParticle.logEvent(name, eventTypes, attributes, customFlags);
    } else {
      window.mParticle.logEvent(name, eventTypes, attributes);
    }
  }

  logProductAction(
    type: ProductActionTypes,
    products: IMParticleProduct[],
    attrs?: object | null,
    flags?: object | null,
    transactionalAttrs?: object
  ) {
    window.mParticle.eCommerce.logProductAction(type, products, attrs, flags, transactionalAttrs);
  }

  getDeviceId() {
    return window.mParticle.getDeviceId();
  }

  getSession(): Promise<{ sessionId: string }> {
    const sessionId = window.mParticle.sessionManager.getSession();
    return Promise.resolve({ sessionId });
  }

  setOptOut(optOut: boolean) {
    window.mParticle.setOptOut(optOut);
  }

  Identity = MParticleIdentityAdapterWeb;
}

const MParticleAdapter = new MParticleAdapterWeb();

export { MParticleAdapter };
