import { useCallback, useEffect, useMemo, useState } from 'react';

import { SupportedLanguages, SupportedRegions } from '@rbi-ctg/frontend';
import useEffectOnUpdates from 'hooks/use-effect-on-updates';
import useEffectOnce from 'hooks/use-effect-once';
import { languageSelectorValidForRegion } from 'pages/about/languageSelectorValidForRegion';
import { LaunchDarklyFlag, useFlag, useLDContext } from 'state/launchdarkly';
import en from 'state/translations/en.json';
import { ENABLE_AUTO_SHOW_REGION_SELECTOR } from 'utils/constants';
import * as DatadogLogger from 'utils/datadog';
import { ISOs } from 'utils/form/constants';
import LocalStorage, { StorageKeys } from 'utils/local-storage';
import { routes } from 'utils/routing';

import {
  LOCALE_SELECTED_QUERY_PARAM,
  READABLE_LANGUAGES,
  READABLE_REGIONS,
  REGION_DOMAIN_VALUES,
  regionSpecificLocalStorageKeys,
} from './constants';
import { getMessagesForLanguage } from './get-messages-for-language';
import { inferRegionFromUrlParams } from './infer-region-from-url-params';
import { inferSupportedLocale } from './infer-supported-locale';
import { inferLanguage } from './inferLanguage';
import { loadLanguage } from './load-language';
import { loadLocale } from './load-locale';
import { loadRegion } from './load-region';
import { LOCALE_DATA } from './locale-data';
import {
  PROD_DEFAULT_LANGUAGE,
  PROD_SUPPORTED_LANGUAGES as SUPPORTED_LANGUAGES,
} from './supported-languages';
import { SORTED_PROD_SUPPORTED_LOCALES } from './supported-locales';
import {
  PROD_DEFAULT_REGION as DEFAULT_REGION,
  PROD_SUPPORTED_REGIONS as SUPPORTED_REGIONS,
} from './supported-regions';
import { LocaleData } from './types';

export * from './supported-regions';
export * from './supported-languages';
export * from './constants';

export { SUPPORTED_REGIONS };
export { SUPPORTED_LANGUAGES };

const LOCALE_SEPARATOR = '-';

const reloadWindow = () => document.location.reload();

function findSupportedRegion(requestedRegion: string): SupportedRegions {
  return SUPPORTED_REGIONS[requestedRegion] || DEFAULT_REGION;
}

function findSupportedLanguage(requestedLanguage: string): SupportedLanguages {
  return SUPPORTED_LANGUAGES[requestedLanguage] || PROD_DEFAULT_LANGUAGE[loadRegion()];
}

function inferLanguageFromUrlParams(): string | null {
  const params = new URL(window.location.href).searchParams;

  const uriLang = params.get('lang');

  return uriLang;
}

function hydrateInitialLocale(useDefault = false): string {
  // if native, uses loadLocale.app.tsx to hydrate based on env variable
  const { language, region } = loadLocale();

  const newLocale = inferSupportedLocale(
    language as SupportedLanguages,
    region as SupportedRegions,
    useDefault
  );

  const [newLocaleLanguage, newLocaleRegion] = newLocale.split(LOCALE_SEPARATOR);

  if (!LocalStorage.getItem(StorageKeys.LANGUAGE) || inferLanguageFromUrlParams()) {
    LocalStorage.setItem(StorageKeys.LANGUAGE, newLocaleLanguage);
  }

  if (!LocalStorage.getItem(StorageKeys.REGION) || inferRegionFromUrlParams()) {
    LocalStorage.setItem(StorageKeys.REGION, newLocaleRegion);
  }

  return newLocale;
}

function inferHasShownLanguageSelectorFromUrlParams(): boolean {
  return Boolean(new URL(window.location.href).searchParams.get(LOCALE_SELECTED_QUERY_PARAM));
}

export function inferHasShownLanguageSelector() {
  const hasSelectedLocale = inferHasShownLanguageSelectorFromUrlParams();
  const hasSingleSupportedLocale = SORTED_PROD_SUPPORTED_LOCALES.length < 2;

  return (
    hasSelectedLocale ||
    hasSingleSupportedLocale ||
    !!LocalStorage.getItem<boolean>(StorageKeys.HAS_SHOWN_LOCALE_SELECTOR)
  );
}

/*
 * This Region hook has a few specs to it:
 *
 * 1. It is preloaded with the users inferred region from domain or url params
 * 2. It allows the UI to update the region manually
 * 3. When the users intentionally changes the region, we store that in local storage.
 *    so that we can preload with that selected region in the future.
 * 4. The preloaded locale (language + region) must exist in our list of `SORTED_PROD_SUPPORTED_LOCALES` or fallback to a value on that list.
 * 5. If the user updates their browser region, we should update too.
 */
const useIntl = () => {
  const [messages, setMessages] = useState(en);
  const { updateUserLocale } = useLDContext();
  const isLocalizationDisabled = useFlag(LaunchDarklyFlag.DISABLE_LOCALIZATION);
  const enableAutoLocale = useFlag(LaunchDarklyFlag.ENABLE_AUTO_LOCALE);
  const [locale, setLocale] = useState<string>(hydrateInitialLocale(isLocalizationDisabled));
  const [language, region] = useMemo(() => locale.split(LOCALE_SEPARATOR), [locale]) as [
    SupportedLanguages,
    SupportedRegions
  ];

  const [hasShownLocaleSelector, setInternalHasShownLocaleSelector] = useState<boolean>(
    inferHasShownLanguageSelector()
  );

  const setHasShownLocaleSelector = useCallback(() => {
    LocalStorage.setItem(StorageKeys.HAS_SHOWN_LOCALE_SELECTOR, true);

    setInternalHasShownLocaleSelector(true);
  }, [setInternalHasShownLocaleSelector]);

  const clearRegionSpecificStorage = useCallback(() => {
    // Wipe out region specific keys
    regionSpecificLocalStorageKeys.forEach(LocalStorage.removeItem);
  }, []);

  const setCurrentLocale = useCallback(
    (newLanguage: SupportedLanguages, newRegion: SupportedRegions, reloadPage: boolean = true) => {
      const newLocale = inferSupportedLocale(newLanguage, newRegion, isLocalizationDisabled);
      const [newLocaleLanguage, newLocaleRegion] = newLocale.split(LOCALE_SEPARATOR);

      LocalStorage.setItem(StorageKeys.LANGUAGE, newLocaleLanguage);
      LocalStorage.setItem(StorageKeys.REGION, newLocaleRegion);

      if (newRegion !== region) {
        // Wipe out region specific keys
        clearRegionSpecificStorage();
      }

      // todo: make sure the locale is valid so we dont end up with fr-US
      setLocale(newLocale);
      getMessagesForLanguage(newLocaleLanguage, newLocaleRegion).then(setMessages);
      updateUserLocale({ region: newRegion, language: newLanguage });
      // reload to refresh content that is pulled from Sanity - edge case
      // eg if user's language is english, but they click an external link to french static page
      if (reloadPage) {
        reloadWindow();
      }
    },
    [isLocalizationDisabled, region, clearRegionSpecificStorage, updateUserLocale]
  );

  useEffectOnce(() => {
    window.addEventListener('languagechange', () => {
      setCurrentLocale(loadLanguage(), loadRegion());
    });
  });

  // We track the user's locale (region + language) in logger errors
  // some errors might occur in specific langauges, so its crucial
  // to know all info on how to reproduce.
  useEffect(() => {
    DatadogLogger.addContext('page_locale', locale);
  }, [locale]);

  useEffectOnce(() => {
    // Make sure we never show the user the dialog again if the params we passed
    if (inferHasShownLanguageSelectorFromUrlParams()) {
      setHasShownLocaleSelector();
    }
    getMessagesForLanguage(language, region).then(setMessages);
  });

  // Make sure locale will update for logged-in users who have localization enabled
  // and also any time the isLocalization flag changes - including when it kicks in initially
  useEffectOnUpdates(() => {
    // remove the region from localStorage so that it doesn't override the domain's region
    localStorage.removeItem(StorageKeys.REGION);
    setCurrentLocale(loadLanguage(), loadRegion(), false);
  }, [isLocalizationDisabled]);

  const { dateFormat } = LOCALE_DATA[locale];

  const blacklistedLangModalRoute = [routes.confirmJwt, routes.store].some(route =>
    window.location.pathname.includes(route)
  );

  const shouldAutoShowSelector =
    ENABLE_AUTO_SHOW_REGION_SELECTOR &&
    !enableAutoLocale &&
    !isLocalizationDisabled &&
    !blacklistedLangModalRoute &&
    !hasShownLocaleSelector &&
    languageSelectorValidForRegion;

  const [showLanguageSelectorModal, setInternalShowLanguageSelectorModal] = useState(
    shouldAutoShowSelector
  );

  const localeDetermined =
    !!LocalStorage.getItem(StorageKeys.DETERMINING_LOCALE_COMPLETE) ||
    inferHasShownLanguageSelector();

  const [determiningLocaleComplete, setDeterminingLocaleCompleteInternal] = useState(
    localeDetermined
  );

  const setDeterminingLocaleComplete = useCallback(() => {
    setDeterminingLocaleCompleteInternal(true);
    LocalStorage.setItem(StorageKeys.DETERMINING_LOCALE_COMPLETE, true);
  }, []);

  const setShowLanguageSelectorModal = useCallback(
    (bool: boolean) => {
      setInternalShowLanguageSelectorModal(bool);
    },
    [setInternalShowLanguageSelectorModal]
  );

  return {
    region,
    feCountryCode: ISOs[region] || ISOs[DEFAULT_REGION],
    language,
    locale,
    inferLanguage,
    findSupportedRegion,
    readableRegions: READABLE_REGIONS[language],
    regionDomainValues: REGION_DOMAIN_VALUES,
    clearRegionSpecificStorage,
    findSupportedLanguage,
    readableLanguages: READABLE_LANGUAGES[language],
    setCurrentLocale,
    hasShownLocaleSelector,
    setHasShownLocaleSelector,
    showLanguageSelectorModal,
    setShowLanguageSelectorModal,
    messages,
    dateFormat,
    determiningLocaleComplete,
    setDeterminingLocaleComplete,
    supportedLocales: SORTED_PROD_SUPPORTED_LOCALES.map(
      supportedLocale => LOCALE_DATA[supportedLocale] as LocaleData
    ),
  };
};

export default useIntl;
