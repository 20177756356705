import React, { FC, useState } from 'react';

import { useIntl } from 'react-intl';

import { RewardCategories } from 'components/app-header/loyalty/rewards-management/reward-tier-selection-modal/components/reward-categories';
import { HeaderLogo } from 'components/app-header/mobile-header/header-logo';
import Modal from 'components/modal';
import { DialogFooter } from 'components/sticky-footer';
import useDialogModal from 'hooks/use-dialog-modal';
import useMediaQuery from 'hooks/use-media-query';
import { ILoyaltyCategory } from 'state/loyalty/types';

import { useLoyaltyCategories } from '../hooks';

import { RewardTierSelectionButton } from './components/reward-tier-selection-button';
import { getRewardCategory, getSelectedTierName } from './components/utils';
import {
  AllModalContent,
  Background,
  ModalCloseButton,
  PositionedLogoContainer,
  RewardCategoriesContainer,
} from './reward-tier-selection-modal.styled';
import { IRewardCategoriesModalProps } from './types';

export const RewardTierSelectionModal: FC<IRewardCategoriesModalProps> = ({
  onDismiss,
  radioButtonName,
  onChangeRewardTier,
  selectedRewardTierId,
  selectedRewardCategoryId,
}) => {
  const { formatMessage } = useIntl();
  const isMobile = useMediaQuery('mobile');
  const loyaltyCategories = useLoyaltyCategories();

  const [currentRewardTierId, setCurrentRewardTierId] = useState<string>(
    selectedRewardTierId ?? ''
  );
  const [currentRewardCategoryId, setCurrentRewardCategoryId] = useState(selectedRewardCategoryId);

  const updateRewardTier = () => {
    onChangeRewardTier(currentRewardTierId);
    onDismiss();
  };

  const [UpdateNotSavedModal, openUpdateNotSavedModal] = useDialogModal({
    onConfirm: () => updateRewardTier,
    onCancel: onDismiss,
    showCancel: true,
    modalAppearanceEventMessage: 'Remove Favorite',
    allowDismiss: false,
  });

  const updateCurrentRewardTier = ({
    updatedRewardCategoryId,
    updatedRewardTierId,
  }: {
    updatedRewardCategoryId?: string;
    updatedRewardTierId: string;
  }) => {
    setCurrentRewardTierId(updatedRewardTierId);
    setCurrentRewardCategoryId(updatedRewardCategoryId);
  };

  const currentCategory: ILoyaltyCategory | undefined = getRewardCategory(
    currentRewardCategoryId || '',
    loyaltyCategories
  );

  const selectedTierName = getSelectedTierName({
    selectedCategory: true,
    tiers: currentCategory?.options,
    selectedRewardTierId: currentRewardTierId,
  });

  const ensureTierUpdated = () => {
    if (currentRewardTierId === selectedRewardTierId) {
      return onDismiss();
    }

    return openUpdateNotSavedModal();
  };

  return (
    <Modal
      closeButton={ModalCloseButton}
      onDismiss={ensureTierUpdated}
      mParticleEventData={{ modalAppearanceEventMessage: 'Reward Categories' }}
    >
      <PositionedLogoContainer>
        <HeaderLogo />
      </PositionedLogoContainer>
      <AllModalContent>
        <Background>
          <RewardCategoriesContainer>
            <RewardCategories
              radioButtonName={radioButtonName}
              setCurrentRewardTierId={updateCurrentRewardTier}
              selectedRewardCategoryId={currentRewardCategoryId}
              selectedRewardTierId={currentRewardTierId}
              loyaltyCategories={loyaltyCategories}
            />
          </RewardCategoriesContainer>
        </Background>
        {!isMobile && (
          <RewardTierSelectionButton
            onClick={updateRewardTier}
            buttonText={formatMessage({ id: 'updateRewardLevel' })}
          />
        )}
      </AllModalContent>
      {isMobile && (
        <DialogFooter
          padding={''}
          elements={[
            {
              element: (
                <RewardTierSelectionButton
                  onClick={updateRewardTier}
                  buttonText={formatMessage({ id: 'updateRewardLevel' })}
                />
              ),
            },
          ]}
        />
      )}

      <UpdateNotSavedModal
        onConfirm={updateRewardTier}
        buttonLabel={formatMessage({ id: 'updateRewardLevel' })}
        heading={formatMessage({ id: 'yourUpdateIsntSavedYetHeading' })}
        body={formatMessage({ id: 'yourUpdateIsntSavedYetBody' }, { selectedTierName })}
        cancelLabel={formatMessage({ id: 'discard' })}
      />
    </Modal>
  );
};
