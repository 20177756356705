import React from 'react';

import { Icon } from '@rbilabs/components-library';

import theme from './theme';

export const CloseIcon = () => (
  <Icon icon="close" color={theme.iconContainer.color} width="24px" aria-hidden />
);
export const NextIcon = () => (
  <Icon icon="next" color={theme.iconContainer.color} width="16px" aria-hidden />
);
