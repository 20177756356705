import { useCallback } from 'react';

import { ApolloQueryResult } from 'apollo-client';

import { ServiceMode } from '@rbi-ctg/menu';
import { EvaluateUserOffersDocument, IEvaluateUserOffersQuery } from 'generated/rbi-graphql';
import { useApolloClient } from 'state/graphql/hooks/use-sanity-query';
import { LoyaltyAppliedOffer } from 'state/loyalty/types';
import { StoreProxy } from 'state/store';
import { buildCartInput } from 'utils/cart';
import logger from 'utils/logger';
import { redeemedOnTime } from 'utils/offers';

export const useCbaOffersEvaluation = () => {
  const client = useApolloClient();

  const evaluateCbaOffers = useCallback(
    async ({
      appliedOffers = [],
      serviceMode,
      store,
    }: {
      appliedOffers: LoyaltyAppliedOffer[];
      serviceMode: ServiceMode | null;
      store?: StoreProxy | null;
    }): Promise<boolean> => {
      let hasValidationErrors = false;

      try {
        const variables = {
          couponIds: appliedOffers.map(appliedOffer => appliedOffer.id),
          redeemedOn: redeemedOnTime(),
          ...(serviceMode &&
            store && { cart: buildCartInput({ cartEntries: [], serviceMode, store }) }),
        };

        const { data }: ApolloQueryResult<IEvaluateUserOffersQuery> = await client.query({
          query: EvaluateUserOffersDocument,
          fetchPolicy: 'network-only',
          variables,
        });

        const offersFeedback = data?.evaluateUserOffers?.offersFeedback || [];
        if (!offersFeedback.length) {
          hasValidationErrors = false;
        } else {
          const offersFeedbackMap = offersFeedback.reduce((acc, feedback) => {
            if (feedback?.couponId) {
              acc[feedback?.couponId] = Boolean(feedback.redemptionEligibility?.isRedeemable);
            }
            return acc;
          }, {});

          hasValidationErrors = appliedOffers.some(({ id }) => {
            const isRedeemable = offersFeedbackMap[id || ''] ?? true;
            return !isRedeemable;
          });
        }
      } catch (e) {
        logger.error(`CBA Offers evaluation error: ${e}`);

        throw e;
      }

      return hasValidationErrors;
    },
    [client]
  );

  return { evaluateCbaOffers };
};
