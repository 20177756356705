import styled from 'styled-components';

import { brandFont } from 'components/layout/brand-font';

export const Container = styled.div`
  padding: 16px;
  margin-top: 16px;
  display: flex;
  background: ${Styles.color.cardBackground};
  box-shadow: 0px 2px 2px rgb(0 0 0 / 25%);
  border-radius: 12px;
`;

export const CardContainer = styled.div`
  width: 65%;
`;

export const Title = styled.h2`
  font: ${brandFont.headerTwo};
  font-size: 1.25rem;
  margin: 0;
`;

export const CardNumber = styled.p`
  font-size: 1.125rem;
  margin: 8px 0;
`;

export const PictureContainer = styled.div`
  width: 35%;
`;
