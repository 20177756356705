import { useCallback } from 'react';

import { useBrazeContext } from 'state/braze';
import { useLDContext } from 'state/launchdarkly';
import { useMParticleContext } from 'state/mParticle';
import LogRocketHelper from 'utils/logrocket';

import { UserDetails } from './types';

export const useThirdPartyAuthentication = () => {
  const { setUserId: brazeSetUserId } = useBrazeContext();
  const { login: launchDarklyLogin, logout: launchDarklyLogout } = useLDContext();
  const { login: mParticleLogin, logout: mParticleLogout } = useMParticleContext();

  const logUserInToThirdPartyServices = useCallback(
    (currentUser: UserDetails) => {
      launchDarklyLogin(currentUser);

      mParticleLogin({
        customerid: currentUser.thLegacyCognitoId
          ? `us-east-1:${currentUser.thLegacyCognitoId}`
          : currentUser.cognitoId,
        rbiCognitoId: currentUser.cognitoId,
        ...currentUser.details,
      });

      brazeSetUserId(
        currentUser.thLegacyCognitoId
          ? `us-east-1:${currentUser.thLegacyCognitoId}`
          : currentUser.cognitoId
      );

      const lrIdentify = () => {
        const logRocketInstance = LogRocketHelper.getInstance();
        logRocketInstance.logrocket?.identify(currentUser.cognitoId);
      };

      // Run logrocket.identify on init in case we are already logged in
      LogRocketHelper.getInstance().runOnInit = lrIdentify;

      // Run logrocket.identify in case a LR session was initialized before we were logged in
      lrIdentify();
    },
    [brazeSetUserId, launchDarklyLogin, mParticleLogin]
  );

  const logUserOutOfThirdPartyServices = useCallback(() => {
    launchDarklyLogout();
    mParticleLogout();
    brazeSetUserId(null);

    const logRocketInstance = LogRocketHelper.getInstance();
    if (logRocketInstance.logrocket) {
      logRocketInstance.logrocket.identify('');
    }
  }, [brazeSetUserId, launchDarklyLogout, mParticleLogout]);

  return { logUserInToThirdPartyServices, logUserOutOfThirdPartyServices };
};
