import useEffectOnce from 'hooks/use-effect-once';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import LogRocketHelper from 'utils/logrocket';

export default function useLogrocketTargetedRecording() {
  const isTargetedForLogrocketRecording = useFlag(
    LaunchDarklyFlag.ENABLE_LOGROCKET_TARGETED_RECORDING
  );

  // This hook calls console.log for users that are targeted via LD
  // so that Logrocket's conditional recording can effectively mark them
  // as targeted session and record them. This was not needed before because
  // we used to record every session when LR was enabled via LD
  //
  // There is an open question about how this works with RN which is tracked
  // via https://rbictg.atlassian.net/browse/FEC-613
  useEffectOnce(() => {
    const onInitialized = () => {
      if (isTargetedForLogrocketRecording) {
        // eslint-disable-next-line no-console
        console.log('LR');
      }
    };

    LogRocketHelper.getInstance().on('initialized', onInitialized);

    return () => {
      LogRocketHelper.getInstance().removeListener('initialized', onInitialized);
    };
  });
}
