import { eq, gt, gte, isNil, isNull, isUndefined, lt, lte } from 'lodash-es';

import { ICartProperty } from '@rbi-ctg/offers';

import { CartPropertyOperator } from './types';

export function valueSatisfiesCartPropertyRule<V extends ICartProperty['value']>(
  rule: ICartProperty
) {
  const evaluator = (value: V) => {
    switch (rule.operator) {
      case CartPropertyOperator.equalTo:
        return eq(value, rule.value);
      case CartPropertyOperator.greaterThan:
        return gt(value, rule.value);
      case CartPropertyOperator.greaterThanOrEqual:
        return gte(value, rule.value);
      case CartPropertyOperator.lessThan:
        return lt(value, rule.value);
      case CartPropertyOperator.lessThanOrEqual:
        return lte(value, rule.value);
      case CartPropertyOperator.not:
        return !eq(value, rule.value);
      case CartPropertyOperator.nil:
        return isNil(value);
      case CartPropertyOperator.null:
        return isNull(value);
      case CartPropertyOperator.undefined:
        return isUndefined(value);
      // undefined behavior
      default:
        return true;
    }
  };

  return (value: V) => (rule.negated ? !evaluator(value) : evaluator(value));
}
