import {
  IdGetterObj,
  InMemoryCache,
  IntrospectionFragmentMatcher,
  defaultDataIdFromObject,
} from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import uuid from 'uuid/v4';

import { SupportedLanguages, SupportedRegions } from '@rbi-ctg/frontend';

import { getConfiguredLink } from './links';
import introspectionQueryResultData from './sanity-fragments.json';

interface IDataIdFromObject extends IdGetterObj {
  [key: string]: any;
}

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

const getConfiguredCache = () =>
  new InMemoryCache({
    dataIdFromObject: (object: IDataIdFromObject) => {
      switch (object.__typename) {
        case 'CouponUserOffersFeedbackEntry':
          return object.tokenId || object._id;
        case 'CartEntries':
          // Generating uuid to avoid normalization for this type
          return uuid();
        default:
          return defaultDataIdFromObject(object);
      }
    },
    cacheRedirects: {
      Query: {
        order: (_, args, { getCacheKey }) =>
          getCacheKey({ __typename: 'Order', _id: args.rbiOrderId }),
      },
    },
    fragmentMatcher,
  });

export const getClient = (language: SupportedLanguages, region: SupportedRegions) =>
  new ApolloClient({
    cache: getConfiguredCache(),
    link: getConfiguredLink(language, region),
  });
