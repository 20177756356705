import React, { useContext, useState } from 'react';

// @ts-ignore
import createDOMProps from 'react-native-web/dist/modules/createDOMProps';
import { Link as RRLink, useLocation, useNavigate } from 'react-router-dom';

import { IBaseProps } from '@rbi-ctg/frontend';
import noop from 'utils/noop';

import { useNavigateBack } from './hooks/use-navigate-back';
import { ILocation } from './types';

export { useMatch } from 'react-router-dom';

export const Link = (props: any) => {
  const domProps = createDOMProps('a', props);

  return <RRLink {...domProps} />;
};

export const LocationContext = React.createContext<ILocation>({} as ILocation);
export const useLocationContext = () => useContext(LocationContext);

export function LocationProvider(props: IBaseProps) {
  const [storeLocatorCallbackUrl, setStoreLocatorCallbackUrl] = useState('');

  const { addNavigateBackListener, back } = useNavigateBack();
  const addHardwareBackButtonListener = noop;
  const removeHardwareBackButtonListener = noop;
  const [referrerLocation, setReferrerLocation] = useState<string | undefined>();
  const [signUpEmail, setSignUpEmail] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <LocationContext.Provider
      value={{
        addNavigateBackListener,
        addHardwareBackButtonListener,
        back,
        signUpEmail,
        location,
        navigate,
        referrerLocation,
        removeHardwareBackButtonListener,
        setSignUpEmail,
        setReferrerLocation,
        setStoreLocatorCallbackUrl,
        storeLocatorCallbackUrl,
      }}
    >
      {props.children}
    </LocationContext.Provider>
  );
}

export default LocationContext.Consumer;
