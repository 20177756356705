import React from 'react';

import { useIntl } from 'react-intl';
import styled from 'styled-components';

export const StyledCtaButton = styled.button`
  text-decoration: underline;
  font-size: 0.875rem;
  white-space: nowrap;
  ${Styles.desktop`
    font-size: 0.983rem;
  `}

  &:hover {
    text-decoration: none;
  }

  border: none;
  background: none;
  cursor: pointer;
  margin-top: -0.01rem;
  padding: 0;
  font-family: ${Styles.fontFamily.body};
`;

interface CtaButtonProps {
  text: string;
  cta: () => void;
}

const CtaButton = ({ text, cta }: CtaButtonProps) => {
  const { formatMessage } = useIntl();
  return (
    <StyledCtaButton
      aria-label={formatMessage({ id: 'confirmationDifferentLocation' })}
      onClick={cta}
    >
      {formatMessage({ id: text })}
    </StyledCtaButton>
  );
};

export default CtaButton;
