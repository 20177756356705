import { isError } from 'lodash-es';

export enum AppflowAction {
  download = 'downloadUpdate',
  extract = 'extractUpdate',
}

interface IAppflowErrorConstructor {
  readonly appflowAction: AppflowAction;
  readonly originalError?: Error;
  readonly originalSuccessValue?: boolean;
}

export class AppflowError extends Error {
  public readonly appflowAction: AppflowAction;
  // collect original return value/thrown error for logging
  public readonly originalError?: Error;
  public readonly originalSuccessValue?: boolean;

  constructor(options: IAppflowErrorConstructor) {
    const { appflowAction, originalError, originalSuccessValue } = options;
    super(`Appflow update error during ${appflowAction}`);
    this.appflowAction = appflowAction;
    this.originalError = originalError;
    this.originalSuccessValue = originalSuccessValue;
  }
}

export function isAppflowError(obj: any): obj is AppflowError {
  return isError(obj) && 'appflowAction' in obj;
}
