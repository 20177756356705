import React from 'react';

import { useIntl } from 'react-intl';

import NavLink from 'components/app-header/nav-link';
import { routes } from 'utils/routing';

import { ITrendingLink } from './types';

const TrendingLink: React.FC<ITrendingLink> = ({ onTrendingClick }) => {
  const { formatMessage } = useIntl();
  return (
    <NavLink data-testid="trending-desktop" to={routes.trending} onClick={onTrendingClick}>
      {formatMessage({ id: 'trending' })}
    </NavLink>
  );
};

export default TrendingLink;
