/**
 * AUTOGENERATED - DO NOT EDIT DIRECTLY
 *
 * Please see "scripts/regionalization/README.md" for how to modify
 */

import { LOCALES } from './types';

export enum PROD_SUPPORTED_LOCALES {
  ca_es = LOCALES.ca_es,
  cs_cz = LOCALES.cs_cz,
  de_at = LOCALES.de_at,
  de_ch = LOCALES.de_ch,
  de_de = LOCALES.de_de,
  en_aq = LOCALES.en_aq,
  en_at = LOCALES.en_at,
  en_ca = LOCALES.en_ca,
  en_ch = LOCALES.en_ch,
  en_cz = LOCALES.en_cz,
  en_de = LOCALES.en_de,
  en_es = LOCALES.en_es,
  en_gb = LOCALES.en_gb,
  en_nl = LOCALES.en_nl,
  en_nz = LOCALES.en_nz,
  en_pt = LOCALES.en_pt,
  en_pl = LOCALES.en_pl,
  en_ro = LOCALES.en_ro,
  en_us = LOCALES.en_us,
  en_za = LOCALES.en_za,
  es_es = LOCALES.es_es,
  eu_es = LOCALES.eu_es,
  fr_ca = LOCALES.fr_ca,
  fr_ch = LOCALES.fr_ch,
  gl_es = LOCALES.gl_es,
  it_ch = LOCALES.it_ch,
  nl_nl = LOCALES.nl_nl,
  pl_pl = LOCALES.pl_pl,
  pt_pt = LOCALES.pt_pt,
  ro_ro = LOCALES.ro_ro,
}
