import { useIntl } from 'react-intl';

import { IRestaurantNode } from 'generated/rbi-graphql';
import { useMemoWithCompare } from 'hooks/use-memo-with-compare';
import { ServiceModeStatus, useServiceModeStatus } from 'hooks/use-service-mode-status';
import { ServiceMode } from 'state/service-mode';

export const serviceModeToIntlId: Record<ServiceMode, string> = {
  [ServiceMode.CATERING_DELIVERY]: 'cateringDelivery',
  [ServiceMode.CATERING_PICKUP]: 'cateringPickUp',
  [ServiceMode.CURBSIDE]: 'curbside',
  [ServiceMode.DELIVERY]: 'delivery',
  [ServiceMode.DRIVE_THRU]: 'driveThru',
  [ServiceMode.EAT_IN]: 'eatIn',
  [ServiceMode.TAKEOUT]: 'takeOut',
  [ServiceMode.TABLE_SERVICE]: 'tableService',
};

// Store the order and which service modes will display in the text.
const serviceModeOrder: ServiceMode[] = [
  ServiceMode.DRIVE_THRU,
  ServiceMode.TAKEOUT,
  ServiceMode.EAT_IN,
  ServiceMode.CURBSIDE,
  ServiceMode.CATERING_PICKUP,
  ServiceMode.TABLE_SERVICE,
];

interface UseServiceModeTextOptions {
  restaurant: IRestaurantNode;
}

const isAvailable = (serviceModeStatus: ServiceModeStatus) => (
  serviceMode: ServiceMode
): boolean => {
  const status = serviceModeStatus[serviceMode];

  if (!status) {
    return false;
  }

  return status.available && !status.disabled;
};

export const useServiceModeText = ({
  restaurant,
}: UseServiceModeTextOptions): string | undefined => {
  const { formatMessage } = useIntl();
  const { serviceModeStatus } = useServiceModeStatus(restaurant);

  const serviceModes = serviceModeOrder.filter(isAvailable(serviceModeStatus));

  return useMemoWithCompare(() => {
    if (!serviceModes.length) {
      return;
    }

    return serviceModes
      .map(serviceMode => formatMessage({ id: serviceModeToIntlId[serviceMode] }))
      .join(', ');
  }, [serviceModes]);
};
