import { localeRoutes } from './intl';

export const relativeRoutes = {
  address: 'address',
  addCard: 'add-card',
  addExtra: 'extras',
  addGiftCard: 'add-gift-card',
  favorites: 'favorites',
  transferGiftCard: 'transfer-gift-card',
  locationService: 'location-service',
  noDeliveryStores: 'no-delivery-stores',
  deliveryStoresClosed: 'delivery-stores-closed',
  orderPayment: 'payment',
  pickupMode: 'pickup-mode',
  recentItems: 'recent-items',
  serviceMode: 'service-mode',
  serviceModeCatering: 'service-mode-catering',
  serviceModeCateringDelivery: 'service-mode-catering-delivery',
  serviceModeCateringPickup: 'service-mode-catering-pickup',
  store: 'store',
  sharedRolls: 'send',
  successGiftCard: 'success-gift-card',
  successTransferGiftCard: 'success-transfer-gift-card',
};

export const routes = {
  about: '/about',
  aboutApp: '/about-app',
  addCard: `/account/payment/${relativeRoutes.addCard}`,
  addGiftCard: `/account/payment/${relativeRoutes.addGiftCard}`,
  address: `/store-locator/${relativeRoutes.address}`,
  authChallengeJwt: '/auth-challenge-jwt',
  base: '/',
  browseMenu: '/browse-menu',
  bkRewards: '/bkrewards',
  cart: '/cart',
  cartAddCard: `/cart/${relativeRoutes.orderPayment}/${relativeRoutes.addCard}`,
  cartTransferGiftCard: `/cart/${relativeRoutes.transferGiftCard}`,
  cartTransferGiftCardPayment: `/cart/${relativeRoutes.orderPayment}/${relativeRoutes.transferGiftCard}`,
  cardAddGiftCard: `/cart/${relativeRoutes.addGiftCard}`,
  cardAddGiftCardPayment: `/cart/${relativeRoutes.orderPayment}/${relativeRoutes.addGiftCard}`,
  cartPaymentSuccessGiftCard: `/cart/${relativeRoutes.orderPayment}/${relativeRoutes.successGiftCard}`,
  cartPaymentTransferSuccessGiftCard: `/cart/${relativeRoutes.orderPayment}/${relativeRoutes.successTransferGiftCard}`,
  cartSuccessGiftCard: `/cart/${relativeRoutes.successGiftCard}`,
  cartTransferSuccessGiftCard: `/cart/${relativeRoutes.successTransferGiftCard}`,
  catering: `/catering`,
  communication: `/communication-preferences`,
  confirmJwt: '/confirm-jwt',
  confirmOtp: '/confirm-otp',
  delivery: '/delivery',
  deliveryStoresClosed: `/store-locator/${relativeRoutes.deliveryStoresClosed}`,
  diagnostics: '/diagnostics',
  favorites: `/menu/${relativeRoutes.favorites}`,
  favoriteEditor: `/menu/${relativeRoutes.favorites}/:favoriteId/edit`,
  info: '/account/info',
  languageSelector: '/language-selector',
  loading: '/loading',
  locationService: `/store-locator/${relativeRoutes.locationService}`,
  menu: '/menu',
  missingItems: '/missing-items',
  missingOrder: '/missing-order',
  noDeliveryStores: `/store-locator/${relativeRoutes.noDeliveryStores}`,
  notFound: '/not-found',
  notFound404: '/not-found/404',
  nutritionExplorer: '/nutrition-explorer',
  offers: '/offers',
  orderConfirmation: '/order-confirmation',
  storeOrderConfirmation: '/store-order-confirmation',
  orders: '/account/orders',
  payment: '/account/payment',
  accountPaymentSuccessGiftCard: `/account/${relativeRoutes.orderPayment}/${relativeRoutes.successGiftCard}`,
  accountPaymentTransferSuccessGiftCard: `/account/${relativeRoutes.orderPayment}/${relativeRoutes.successTransferGiftCard}`,
  payback: '/account/payback',
  pickupMode: `/store-locator/${relativeRoutes.pickupMode}`,
  redeem: '/redeem',
  reportOrderIssue: '/digitalorderhelp',
  rewardsOnboarding: '/rewards-onboarding',
  rewardsList: '/rewards/list',
  rewardsOffers: '/rewards/offers',
  rewardsHistory: '/rewards/history',
  scaryPlaces: '/scaryplaces',
  serviceMode: `/store-locator/${relativeRoutes.serviceMode}`,
  serviceModeCatering: `/store-locator/${relativeRoutes.serviceModeCatering}`,
  serviceModeCateringDelivery: `/store-locator/${relativeRoutes.serviceModeCateringDelivery}`,
  serviceModeCateringPickup: `/store-locator/${relativeRoutes.serviceModeCateringPickup}`,
  signOut: '/signout',
  store: `/store-locator/${relativeRoutes.store}`,
  support: '/support',
  supportCategories: '/support/categories',
  supportCategory: '/support/categories/:categoryId',
  supportOrders: '/support/orders',
  supportForm: '/support/form',
  timbitsLTO: '/timbiebs',
  timCard: '/account/tim-card',
  timsRewards: '/tims-rewards',
  transferGiftCard: `/account/${relativeRoutes.orderPayment}/${relativeRoutes.transferGiftCard}`,
  trending: '/trending',
  contactUs: 'contact-us',
  offerActivate: '/activate-offer',
  worthTheWait: '/worth-the-wait',

  //TODO: this route is a static page in sanity. Ensure it exists in data set

  // Default localizable routes
  account: localeRoutes.en.account,
  acceptGift: localeRoutes.en.acceptGift,
  sendGift: localeRoutes.en.sendGift,
  signUp: localeRoutes.en.signUp,
  signIn: localeRoutes.en.signIn,
  storeLocator: localeRoutes.en.storeLocator,
  quiz: localeRoutes.en.quiz,
  hockeyChallenge: localeRoutes.en.hockeyChallenge,
  hockeyChallengeFr: localeRoutes.fr.hockeyChallenge,
  timsForGood: localeRoutes.en.timsForGood,
  timsForGoodFr: localeRoutes.fr.timsForGood,
  userPreferencesModal: localeRoutes.de.userPreferencesModal,
  foundation: '/foundation',
};

// brand-specific routes used to determine whether to display service mode header
export const routesRelevantToServiceModeHeaderDisplay = {
  default: 'store-locator',
  plk: 'store-locator',
  bk: 'store-locator',
  th: 'menu',
  ltw: 'store-locator',
  fhs: 'store-locator',
};

// brand-specific routes used to determine whether to display service mode header
export const routesForBottomServiceModeDisplay = [
  { route: routes.base, exact: true },
  { route: `${routes.menu}/${relativeRoutes.recentItems}`, exact: true },
  { route: `${routes.browseMenu}/${relativeRoutes.recentItems}`, exact: true },
  { route: routes.favorites, exact: true },
  { route: routes.menu, exact: false },
  { route: routes.browseMenu, exact: false },
  { route: routes.orders, exact: false },
  { route: routes.offers, exact: false },
  { route: routes.rewardsOffers, exact: false },
];

// brand-specific routes used to determine whether to display top service mode header
export const routesForTopServiceModeDisplay = [
  { route: routes.base, exact: true },
  { route: `${routes.menu}/${relativeRoutes.recentItems}`, exact: true },
  { route: `${routes.browseMenu}/${relativeRoutes.recentItems}`, exact: true },
  { route: routes.favorites, exact: true },
  { route: routes.menu, exact: false },
  { route: routes.browseMenu, exact: false },
  { route: routes.orders, exact: false },
  { route: routes.offers, exact: false },
  { route: routes.redeem, exact: false },
  { route: routes.rewardsList, exact: false },
  { route: routes.rewardsOffers, exact: false },
];

export const isSectionRoute = pathname => pathname.startsWith(`${routes.menu}/section-`);

export const isRecentItemsRoute = pathname =>
  pathname.startsWith(`${routes.menu}/${relativeRoutes.recentItems}`);
export const isFavoritesRoute = pathname => pathname.startsWith(routes.favorites);

export const isItemPickerOrComboRoute = pathname =>
  new RegExp(`^${routes.menu}/(item|picker|combo)-`).test(pathname);

export function goBack({ navigate, location }) {
  const pathParts = location.pathname.split('/');
  pathParts.length = pathParts.length - 1;
  navigate(pathParts.join('/'));
}

export function goToMenu({ navigate }) {
  navigate(routes.menu);
}

export function goToBrowseMenu({ navigate }) {
  navigate(routes.browseMenu);
}

export function editFavorite({ navigate, favoriteId }) {
  navigate(`${routes.favorites}/${favoriteId}/edit`);
}

export function extractRefFromUrl({ '*': splat }, subtrahend = -1) {
  const paths = (splat || '').split('/');
  const ref = paths[paths.length + subtrahend];
  return ref;
}

export {
  localeRoutes,
  getAllLocalizedVersionsOfRoute,
  getInCodeLocalizedRouteForPath,
  getStaticPagesLocalizedRouteForPath,
} from './intl';

// These are routes that are safe for appUpdates

export const UPDATE_ALLOWED_ROUTES = [routes.menu, routes.about];
export const PERMISSION_TO_UPDATE_ROUTES = [routes.cart];
