import React from 'react';

/* A note when using this component - you will need to pass a fill color if you want anything other than black. */

const IconFacebookSquare = props => (
  <svg viewBox="0 0 22 22" {...props}>
    <path d="M20.786 0H1.214C.544 0 0 .543 0 1.214v19.572C0 21.456.543 22 1.214 22h10.537v-8.52H8.884v-3.32h2.867V7.712c0-2.842 1.735-4.39 4.27-4.39 1.215 0 2.258.091 2.562.132v2.97h-1.758c-1.378 0-1.645.655-1.645 1.616v2.12h3.287l-.428 3.32h-2.86V22h5.607c.67 0 1.214-.544 1.214-1.214V1.214C22 .544 21.456 0 20.786 0" />
  </svg>
);

export default IconFacebookSquare;
