import { SupportedRegions } from '@rbi-ctg/frontend';
import { country, isNative, isRunningLocally } from 'utils/environment';
import LocalStorage, { StorageKeys } from 'utils/local-storage';

import { getNavigatorLanguage } from './get-navigator-language';
import { inferRegionFromUrlParams } from './infer-region-from-url-params';
import {
  PROD_DEFAULT_REGION as DEFAULT_REGION,
  PROD_SUPPORTED_REGIONS as SUPPORTED_REGIONS,
} from './supported-regions';
import { DOMAINS, REGIONS } from './types';

export function loadRegion(): SupportedRegions {
  const inferredUrlParamRegion = inferRegionFromUrlParams();

  if (isRunningLocally()) {
    // If there is no region inferred by URL params, set it to the selected region or to the default region
    return ((inferredUrlParamRegion || country?.toUpperCase()) ?? DEFAULT_REGION) as REGIONS;
  }
  const inferredDomainRegion = inferRegionFromTLD();

  const inferredNavigatorRegion = inferRegionFromNavigator();
  // TODO: get the Region form native code.
  // Ideally we would retrieve the user's defined region from native code.
  // As a temporary solution we are checking the users language e.g. en-US or en-CA
  const inferredDomainOrNavigatorRegion = isNative ? inferredNavigatorRegion : inferredDomainRegion;

  const region =
    inferredUrlParamRegion ||
    LocalStorage.getItem<SupportedRegions>(StorageKeys.REGION) ||
    inferredDomainOrNavigatorRegion;

  return findSupportedRegion(region.toString());
}

function inferRegionFromNavigator(): string {
  const navigatorLanguage = getNavigatorLanguage();

  // If the language isn't set return default region
  return navigatorLanguage.split(/.*-/)[1] || String(DEFAULT_REGION);
}

// TODO: Make more robust by using a mapping library (no pun intended)
function inferRegionFromTLD(): string {
  const supportedDomainsRegex = new RegExp(
    ([] as string[])
      .concat(...Object.values(DOMAINS))
      .join('|')
      .replace(/\./g, '\\.')
  );
  const tldMatches = window.location.host.match(supportedDomainsRegex);
  const matchedTld = tldMatches && tldMatches.length && tldMatches[0];

  switch (matchedTld as DOMAINS) {
    case DOMAINS.ca:
      return REGIONS.CA;
    case DOMAINS.uk:
      return REGIONS.GB;
    case DOMAINS.com:
      return REGIONS.US;
    case DOMAINS.ch:
      return REGIONS.CH;
    case DOMAINS.za:
      return REGIONS.ZA;
    case DOMAINS.de:
      return REGIONS.DE;
    case DOMAINS.nz:
      return REGIONS.NZ;
    case DOMAINS.aq:
    case DOMAINS.io:
      return REGIONS.AQ;
    case DOMAINS.nl:
      return REGIONS.NL;
    case DOMAINS.kr:
      return REGIONS.KR;
    case DOMAINS.es:
      return REGIONS.ES;
    case DOMAINS.pt:
      return REGIONS.PT;
    case DOMAINS.fr:
      return REGIONS.FR;
    case DOMAINS.at:
      return REGIONS.AT;
    case DOMAINS.cz:
      return REGIONS.CZ;
    case DOMAINS.pl:
      return REGIONS.PL;
    case DOMAINS.ro:
      return REGIONS.RO;
    default:
      return String(DEFAULT_REGION);
  }
}

function findSupportedRegion(requestedRegion: string): SupportedRegions {
  return SUPPORTED_REGIONS[requestedRegion] || DEFAULT_REGION;
}
