import { differenceInYears, parse } from 'date-fns';
import delv from 'dlv';
import { isEqual, isNil, upperFirst } from 'lodash-es';

import { CommunicationPreferences } from 'state/auth';
import { UserDetails } from 'state/auth/hooks/use-current-user';
import { getName } from 'utils/attributes';
import { isNativeIOS } from 'utils/environment';
import { getNativeLocationPermissions } from 'utils/geolocation';

import { CustomEventNames, EventTypes } from './constants';
import { IMParticleAttributes, IMParticleUser } from './types';
import { normalizeBooleans, sanitizeValues } from './utils';

import { IMParticleCtx } from './index';

const getAge = ({ age, dob }: { age?: number; dob?: string }): number | undefined => {
  if (dob) {
    return differenceInYears(new Date(), parse(dob, 'yyyy-MM-dd', new Date()));
  }
  return age;
};

const flattenCommunicationPreferences = (userCommPreferences?: CommunicationPreferences | null) =>
  isNil(userCommPreferences)
    ? {}
    : userCommPreferences.reduce(
        (acc, commPreference) => ({
          ...acc,
          [commPreference.id]: upperFirst(commPreference.value),
        }),
        {}
      );

type UpdatedUserAttributes = Partial<UserDetails['details']> & {
  'Legacy User'?: string;
};

// safely merge existing and new attributes
export const mergeUserAttributes = (
  {
    $FirstName,
    $LastName,
    $Zip: zipcode,
    $Age: age,
    $Gender: gender,
    $City: city,
    $State: state,
    $Mobile: phoneNumber,
    'Email Opt In': promotionalEmails,
    'Location Services': locationServices,
    Locale: locale,
    Timezone: timezone,
    'Join Date': joinDate,
    'Legacy User': legacyUser,
    'Date of Birth': dob,
    'RBI Cognito ID': rbiCognitoId,
    favoriteStores,
    language,
    paybackUserId,
    ...rest
  }: IMParticleAttributes = {},
  updatedAttributes: UpdatedUserAttributes = {}
): Partial<IMParticleAttributes> => {
  const getAttr = (name: string, fallback = ''): string =>
    delv(updatedAttributes, name) || fallback;

  const flattenedCommunicationPreferences = flattenCommunicationPreferences(
    updatedAttributes.communicationPreferences
  );

  return sanitizeValues({
    ...rest,
    ...getName(updatedAttributes, { $FirstName, $LastName }),
    $Zip: getAttr('zipcode', zipcode),
    $Age: getAge({ age, dob: updatedAttributes.dob }),
    $Gender: getAttr('gender', gender),
    $City: getAttr('city', city),
    $State: getAttr('state', state),
    $Mobile: getAttr('phoneNumber', phoneNumber),
    'Email Opt In': getAttr('promotionalEmails', promotionalEmails),
    'Location Services': getAttr('locationServices', locationServices),
    Locale: getAttr('locale', locale),
    Timezone: getAttr('timezone', timezone),
    'Join Date': getAttr('joinDate', joinDate),
    'Legacy User': getAttr('Legacy User', legacyUser || ''),
    'RBI Cognito ID': getAttr('rbiCognitoId', rbiCognitoId),
    'Date of Birth': getAttr('dob', dob),
    favoriteStores: getAttr('favoriteStores', favoriteStores),
    language: getAttr('language', language),
    paybackUserId: getAttr('paybackUserId', paybackUserId),
    ...flattenedCommunicationPreferences,
  });
};

const setUserAttributes = (
  user: IMParticleUser,
  newAttributes: UpdatedUserAttributes,
  logEvent: IMParticleCtx['logEvent']
) => {
  const existingAttributes = user.getAllUserAttributes();
  const updated = mergeUserAttributes(existingAttributes, newAttributes);

  if (!isEqual(existingAttributes, updated)) {
    logEvent(CustomEventNames.UPDATE_USER_ATTRIBUTES, EventTypes.Other, {
      'Promotional Emails': newAttributes.promotionalEmails,
      ...updated,
    });
  }

  const normalizedUpdated = normalizeBooleans(updated);
  user.setUserAttributes(normalizedUpdated);
};

export const setUserUTMAttributes = (user: IMParticleUser | undefined, params: URLSearchParams) => {
  const utmAttrs = {
    'UTM Source': params.get('utm_source') || '',
    'UTM Medium': params.get('utm_medium') || '',
    'UTM Campaign': params.get('utm_campaign') || '',
    'UTM Term': params.get('utm_term') || '',
    'UTM Content': params.get('utm_content') || '',
  };
  if (user?.setUserAttributes) {
    user.setUserAttributes(utmAttrs);
  }
};

export const updateLocationPermissionStatus = async (user: IMParticleUser | undefined) => {
  if (!user) {
    return;
  }
  const status = await getNativeLocationPermissions();
  if (!status) {
    return;
  }
  if (isNativeIOS()) {
    user.setUserAttributes({
      'IOS Location Permissions': status,
    });
  } else {
    user.setUserAttributes({
      'Android Location Permissions': status,
    });
  }
};

export default setUserAttributes;
