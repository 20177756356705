import * as React from 'react';
import { FC, useMemo } from 'react';

import { useIntl } from 'react-intl';

import Currency from 'components/currency';
import { useDonations } from 'hooks/use-donations';
import { DONATIONS } from 'hooks/use-donations/constants';
import { getFeesByGroup, getStandardizedName } from 'pages/cart/your-cart/totals/utils';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useUIContext } from 'state/ui';
import { computeDeliveryFee, computeOtherDiscountAmount } from 'utils/cart/helper';

import getRewardsDiscount from './get-rewards-discount';
import { FeeDeliveryItem, ReceiptDetail, RewardsDiscountDetail } from './receipt-details.styled';
import { IOrderFooterProps } from './types';

const geographicalFeeLabelIdMap = { CO: 'geographicalFeeCO' };

const ReceiptDetails: FC<IOrderFooterProps> = ({ order }) => {
  const { formatCurrencyForLocale: currencyFormatter } = useUIContext();
  const { delivery } = order;
  const { subTotalCents, taxCents, totalCents, donations } = order.cart;
  const { formatMessage } = useIntl();

  const enableHideTaxLine = useFlag(LaunchDarklyFlag.ENABLE_HIDE_TAX_LINE);
  const enableHideSubTotalLine = useFlag(LaunchDarklyFlag.ENABLE_HIDE_SUBTOTAL_LINE);
  const enableTHRoundUpToggle = useFlag(LaunchDarklyFlag.ENABLE_TH_ROUND_UP);
  const enableDiscountsOnOrderReceipt = useFlag(LaunchDarklyFlag.ENABLE_DISCOUNTS_ON_ORDER_RECEIPT);

  const { donationRowContent } = useDonations({
    donations,
    donationType: DONATIONS.ROUND_UP,
  });

  const feeCents = delivery?.feeCents || 0;
  const feeDiscountCents = delivery?.feeDiscountCents || 0;
  const serviceFeeCents = delivery?.serviceFeeCents || 0;
  const smallCartFeeCents = delivery?.smallCartFeeCents || 0;
  const geographicalFeeCents = delivery?.geographicalFeeCents || 0;
  const discounts = order.cart.discounts ?? [];
  const dropoffState = delivery?.dropoff?.state;

  // Use a custom label for geographical delivery fees as required
  const geographicalFeeLabelId =
    dropoffState && geographicalFeeLabelIdMap.hasOwnProperty(dropoffState)
      ? geographicalFeeLabelIdMap[dropoffState]
      : 'geographicalFee';

  const deliveryFeeCents = computeDeliveryFee({
    feeCents,
    feeDiscountCents,
    serviceFeeCents,
    smallCartFeeCents,
    geographicalFeeCents,
  });
  const isFreeDelivery = deliveryFeeCents === 0;
  const rewardsDiscountCents = useMemo(
    () => getRewardsDiscount({ discounts, enableDiscountsOnOrderReceipt }),
    [discounts, enableDiscountsOnOrderReceipt]
  );

  const otherDiscountAmount = computeOtherDiscountAmount(discounts);

  const fees = order.cart.fees ?? [];
  const itemFees = getFeesByGroup(fees);

  return (
    <>
      {enableDiscountsOnOrderReceipt &&
        !!itemFees &&
        Object.entries(itemFees).map(([name, entryCents]: [string, number], index) => (
          <ReceiptDetail key={index}>
            <p>{formatMessage({ id: `${getStandardizedName({ name })}Fee` })}</p>

            <p>{currencyFormatter(entryCents)}</p>
          </ReceiptDetail>
        ))}

      {order.delivery && (
        <>
          <ReceiptDetail>
            <p>{formatMessage({ id: 'deliveryFee' })}:</p>
            {isFreeDelivery ? (
              <FeeDeliveryItem>
                {formatMessage({ id: 'free' })}
                &nbsp;
                <s>
                  <Currency amount={feeDiscountCents} />
                </s>
              </FeeDeliveryItem>
            ) : (
              <p>{currencyFormatter(deliveryFeeCents)}</p>
            )}
          </ReceiptDetail>
          {geographicalFeeCents > 0 && (
            <ReceiptDetail>
              <p>{formatMessage({ id: geographicalFeeLabelId })}:</p>
              <p>{currencyFormatter(geographicalFeeCents)}</p>
            </ReceiptDetail>
          )}
          {serviceFeeCents > 0 && (
            <ReceiptDetail>
              <p>{formatMessage({ id: 'serviceFee' })}:</p>
              <p>{currencyFormatter(serviceFeeCents)}</p>
            </ReceiptDetail>
          )}
          {smallCartFeeCents > 0 && (
            <ReceiptDetail>
              <p>{formatMessage({ id: 'smallCartFee' })}:</p>
              <p>{currencyFormatter(smallCartFeeCents)}</p>
            </ReceiptDetail>
          )}
          <ReceiptDetail>
            <p>{formatMessage({ id: 'tip' })}:</p>
            <p>{currencyFormatter(order.delivery.tipCents)}</p>
          </ReceiptDetail>
        </>
      )}

      {enableDiscountsOnOrderReceipt && !!otherDiscountAmount && (
        <RewardsDiscountDetail>
          <p>{formatMessage({ id: 'otherDiscounts' })}</p>
          <p>{` - ${currencyFormatter(otherDiscountAmount)}`}</p>
        </RewardsDiscountDetail>
      )}

      {!!rewardsDiscountCents && (
        <RewardsDiscountDetail>
          <p>{formatMessage({ id: 'rewardsDiscount' })}</p>
          <p>{` - ${currencyFormatter(rewardsDiscountCents)}`}</p>
        </RewardsDiscountDetail>
      )}

      {enableTHRoundUpToggle && !!donationRowContent && (
        <ReceiptDetail>
          <p>{formatMessage({ id: 'timsCampDonation' })}</p>
          <p>{currencyFormatter(donationRowContent.totalCents)}</p>
        </ReceiptDetail>
      )}

      {!enableHideSubTotalLine && (
        <ReceiptDetail>
          <p>{formatMessage({ id: 'subtotal' })}</p>
          <p>{currencyFormatter(subTotalCents)}</p>
        </ReceiptDetail>
      )}

      {!enableHideTaxLine && (
        <ReceiptDetail>
          <p>{formatMessage({ id: 'tax' })}</p>
          <p>{currencyFormatter(taxCents)}</p>
        </ReceiptDetail>
      )}
      {/* TODO Add bag fee here when available from backend. */}
      <ReceiptDetail $total>
        <p>{formatMessage({ id: 'total' })}</p>
        <p>{currencyFormatter(totalCents)}</p>
      </ReceiptDetail>
    </>
  );
};

export default ReceiptDetails;
