import React, { ComponentType } from 'react';

import { ILinkWidgetFragment } from 'generated/sanity-graphql';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { isExternalLink } from 'utils/is-external-link';
import { routes } from 'utils/routing';

import { CCPAButtonLink, CCPA_COMPLIANCE } from './ccpa-button-link';
import { ExternalLink as DefaultExternalLink, InternalLink as DefaultInternalLink } from './styled';

type ILinksProps = {
  links: ILinkWidgetFragment[];
  InternalLink?: typeof DefaultInternalLink;
  ExternalLink?: typeof DefaultExternalLink;
};

export const Links: React.FC<ILinksProps> = ({
  links,
  InternalLink = DefaultInternalLink,
  ExternalLink = DefaultExternalLink,
}) => {
  const ENABLE_CCPA_DO_NOT_SELL_BUTTON = useFlag(LaunchDarklyFlag.ENABLE_CCPA_DO_NOT_SELL_BUTTON);

  return (
    <>
      {links.map((widget, index) => {
        const isExternal = isExternalLink(widget?.link?.locale);
        const isCCPALink: boolean =
          !!widget?.link?.locale?.includes(CCPA_COMPLIANCE) && ENABLE_CCPA_DO_NOT_SELL_BUTTON;

        const Link: ComponentType = isCCPALink
          ? CCPAButtonLink
          : isExternal
          ? ExternalLink
          : InternalLink;

        const linkProps = {
          ...(isExternal
            ? { href: widget?.link?.locale ?? undefined }
            : { to: widget?.link?.locale ?? routes.base }),
          children: widget?.text?.locale,
        };

        return <Link key={widget?._key ?? index} {...linkProps} />;
      })}
    </>
  );
};
