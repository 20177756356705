import * as React from 'react';
import { FC, useCallback } from 'react';

import { usePrevious } from 'hooks/use-previous';
import { actions, selectors, useAppDispatch, useAppSelector } from 'state/global-state';
import { useLoyaltyContext } from 'state/loyalty';
import { HapticsNotificationType, hapticNotification } from 'utils/haptic';

import { RedeemRewardView } from './redeem-reward.view';
import { FloatDirection, IRedeemRewardProps, RewardActions } from './types';
import { useRedeemReward } from './use-redeem-reward/use-redeem-reward';

/**
 *
 * RedeemReward card displays below cart items that are eligible for reward redemptions
 * Users can redeem rewards using points from their loyalty balance in order to receive discounts on their order
 *
 */
const RedeemReward: FC<IRedeemRewardProps> = ({ isRewardApplied, item, reward }) => {
  const { getAvailableRewardFromCartEntry } = useLoyaltyContext();
  const { rewardRedeemable, incentiveNotInMenu, redeemRewardLoading } = useRedeemReward(item);
  const dispatch = useAppDispatch();
  const appliedLoyaltyRewards = useAppSelector(selectors.loyalty.selectAppliedLoyaltyRewards);
  const loyaltyUser = useAppSelector(selectors.loyalty.selectUser);
  const { pointCost, name } = reward;

  const handleRewardClick = useCallback(
    (action: RewardActions) => () => {
      const { applyReward, setIsPricingRewardApplication, unApplyReward } = actions.loyalty;
      dispatch(setIsPricingRewardApplication(true));

      const cartEntryReward = getAvailableRewardFromCartEntry(item);
      if (!cartEntryReward) {
        return;
      }

      const handleAction = {
        [RewardActions.ADD]: applyReward,
        [RewardActions.REMOVE]: unApplyReward,
      }[action];

      dispatch(
        handleAction({
          cartId: item.cartId,
          rewardBenefitId: cartEntryReward.rewardBenefitId,
          loyaltyUser,
        })
      );
      hapticNotification({ type: HapticsNotificationType.SUCCESS });
    },
    [dispatch, loyaltyUser, getAvailableRewardFromCartEntry, item]
  );

  const numberOfTimesRewardApplied = appliedLoyaltyRewards[item.cartId]?.timesApplied;

  const pointsApplied = (numberOfTimesRewardApplied || 0) * pointCost;
  const prevPointsApplied = usePrevious(pointsApplied) || 0;
  let floatDirection;

  if (pointsApplied < prevPointsApplied) {
    floatDirection = FloatDirection.UP;
  } else if (pointsApplied > prevPointsApplied) {
    floatDirection = FloatDirection.DOWN;
  }

  if (pointCost === 0) {
    return null;
  }

  return (
    <RedeemRewardView
      handleRewardClick={handleRewardClick}
      isRewardApplied={isRewardApplied}
      points={pointCost}
      rewardTitle={name}
      disableApplyButton={!rewardRedeemable}
      pointsApplied={pointsApplied}
      floatDirection={floatDirection}
      itemQuantity={item.quantity}
      incentiveNotInMenu={incentiveNotInMenu}
      redeemRewardLoading={redeemRewardLoading}
    />
  );
};

export default RedeemReward;
