import { getOperationName } from 'apollo-link';
import { onError } from 'apollo-link-error';

import {
  DeleteLoyaltyCardDocument,
  GetLoyaltyCardDetailsDocument,
  GetLoyaltyCardDetailsFromBarcodeDocument,
  GetLoyaltyCardTransactionsDocument,
  MergeLoyaltyCardsDocument,
} from 'generated/rbi-graphql';
import { ILogger } from 'utils/logger';

import { REQUEST_CANCELLED_KEY } from './cancel-request-link';

export const withErrorLogger = onError(({ operation, graphQLErrors }) => {
  const logger: ILogger = operation.getContext().logger;

  if (
    !graphQLErrors ||
    !logger ||
    graphQLErrors.some(err => err.extensions?.[REQUEST_CANCELLED_KEY])
  ) {
    return;
  }

  const logError = (props?: any) => {
    /*
     * Default a message string.
     * TODO: Identified these messages and provide more specific message?
     * - Could use the graphQLErrors[0]?.extensions?.code as the message?
     */
    return logger.error({
      message: 'Generic graphQL Error - withErrorLogger Link',
      ...props,
      errors: graphQLErrors,
      queryName: operation.operationName,
    });
  };

  /**
   * Custom error cases
   */
  switch (operation.operationName) {
    case getOperationName(GetLoyaltyCardTransactionsDocument):
    case getOperationName(DeleteLoyaltyCardDocument):
    case getOperationName(GetLoyaltyCardDetailsDocument):
      logError({ cardId: operation.variables.cardId });
      break;
    case getOperationName(GetLoyaltyCardDetailsFromBarcodeDocument):
      logError({ barcode: operation.variables.barcode });
      break;
    case getOperationName(MergeLoyaltyCardsDocument):
      logError({
        cardId: operation.variables.cardId,
        physicalCardId: operation.variables.existingCardId,
      });
      break;
    default:
      logError();
  }
});
