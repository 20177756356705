import { ILogger } from 'utils/logger';

export interface IUseAppflowProps {
  logger: ILogger;
  forceBlockingUpdate?: boolean;
}

export interface IUseAppflow {
  error: Error | null;
  extractingUpdate: boolean;
  needsBlockingUpdate: boolean | null;
  progress: number;
  permissionToUpdate: boolean;
  updateConnection: (permissionUpdate?: boolean) => void;
}

export interface IApplyUpdate {
  isBlockingUpdate: boolean;
  updateVersion: string | undefined;
}

export enum AppflowStorageKeys {
  UPDATE_IN_PROGRESS = 'RBI::APPFLOW_UPDATE_IN_PROGRESS',
  IS_BLOCKING_UPDATE = 'RBI::APPFLOW_IS_BLOCKING_UPDATE',
  // This is used to prevent multiple app updates due to issue retrieving the MINIMUM_APPFLOW_BUILD_ID LD flag
  UPDATE_WITHOUT_MIN_APPFLOW_BUILD_ID = 'RBI:UPDATE_WITHOUT_MIN_APPFLOW_BUILD_ID',
  // Need to persist extracted timestamp because we reload the page before calling the mParticle event
  UPDATE_EXTRACTED_AT = 'RBI::APPFLOW_UPDATE_EXTRACTED_AT',
}

export const APP_STATE_CHANGE = 'appStateChange';
