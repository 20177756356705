import { ILocation } from '@rbi-ctg/frontend';
import { Geography } from 'utils/geography';

import { squared } from '../calc';

export function metersPerPx(lat: number, zoom: number): number {
  // see: https://gis.stackexchange.com/questions/7430/what-ratio-scales-do-google-maps-zoom-levels-correspond-to
  return (156543.03392 * Math.cos((lat * Math.PI) / 180)) / Math.pow(2, zoom);
}

/**
 * Degrees on a circle for a bearing of due northeast
 */
export const NORTHEAST_DEGREES = 45;

/**
 * Degrees on a circle for a bearing of due southwest
 */
export const SOUTHWEST_DEGREES = 225;

interface ICorners {
  ne: ILocation;
  sw: ILocation;
}

export interface IBoundingBox {
  maxLat: number;
  maxLng: number;
  minLat: number;
  minLng: number;
}

interface IBoundsForPoint {
  lat: number;
  lng: number;
  radianDistance: number;
}

/**
 * derive the corners given the center of a google map, the zoom level
 * and the diagonal of the map in px
 * @param {number} lat the latitude of the center of the map
 * @param {number} lng the longitude of the center of the map
 * @param {number} zoom the zoomLevel of the map
 *                      - return value of map.getZoom()
 * @param {number} distanceInPx the diagonal length of the map
 *
 * @return {ICorners} the corners of the map
 */
export function cornersForPoint(
  lat: number,
  lng: number,
  zoom: number,
  distanceInPx: number
): ICorners {
  const centerToCorner = metersPerPx(lat, zoom) * (distanceInPx / 2);
  const ne = Geography.coordinateAtDistanceAndBearing({
    lat,
    lng,
    bearing: Geography.toRadians(NORTHEAST_DEGREES),
    distance: centerToCorner,
  });
  const sw = Geography.coordinateAtDistanceAndBearing({
    lat,
    lng,
    bearing: Geography.toRadians(SOUTHWEST_DEGREES),
    distance: centerToCorner,
  });
  return { ne, sw };
}

export function cornersForPointWithDiagonal(
  lat: number,
  lng: number,
  centerToCorner: number
): IBoundingBox {
  const { lat: maxLat, lng: maxLng } = Geography.coordinateAtDistanceAndBearing({
    lat,
    lng,
    bearing: Geography.toRadians(NORTHEAST_DEGREES),
    distance: centerToCorner,
  });
  const { lat: minLat, lng: minLng } = Geography.coordinateAtDistanceAndBearing({
    lat,
    lng,
    bearing: Geography.toRadians(SOUTHWEST_DEGREES),
    distance: centerToCorner,
  });

  return { maxLat, maxLng, minLat, minLng };
}

export function hypotenuse(a: number, b: number) {
  const cSquared = squared(a) + squared(b);
  return Math.pow(cSquared, 0.5);
}
