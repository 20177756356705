import gql from 'graphql-tag';

import ComboFragment from './combo';
import ItemFragment from './item';
import MenuImageFragment from './menu-image';

export default gql`
  fragment MenuFragment on Menu {
    _id
    _type
    headerSuperText {
      locale: _LANG_
    }
    headerText {
      locale: _LANG_
    }
    options {
      ... on Picker {
        _id
        _type
        name {
          locale: _LANG_
        }
        image {
          ...MenuImageFragment
        }
      }
      ... on Section {
        _id
        _type
        daypart
        headerSuperText {
          locale: _LANG_
        }
        headerText {
          locale: _LANG_
        }
        description {
          localeRaw: _LANG_Raw
        }
        name {
          locale: _LANG_
        }
        redirectLink {
          locale: _LANG_
        }
        carouselImage {
          ...MenuImageFragment
        }
        carouselImageDescription {
          locale: _LANG_
        }
        image {
          ...MenuImageFragment
        }
        showInStaticMenu
        isBurgersForBreakfastSection
        channelExclusions {
          delivery
          pickup
          kiosk
          googleFoodOrdering
        }
      }

      ... on Item {
        ...ItemFragment
      }

      ... on Combo {
        ...ComboFragment
      }
    }
  }
  ${MenuImageFragment}
  ${ItemFragment}
  ${ComboFragment}
`;
