import styled, { keyframes } from 'styled-components';

import CloseIcon from 'components/icons/close';

const floatUp = keyframes`
  from {
    transform: translateY(30px);
  } to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  height: 1.75rem;
  cursor: pointer;
`;

export const Wrapper = styled.section`
  display: flex;
  position: relative;
  align-items: center;
  padding: 0;
  flex-direction: column;
  z-index: ${Styles.zIndex.max};
  height: 11.25rem;
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: ${Styles.color.background};
  box-shadow: 0px -9px 43px -2px rgba(0, 0, 0, 0.18);
  opacity: 0;
  animation: ${floatUp} 0.5s 0.3s forwards;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  text-align: center;
  margin: 1rem 1.65rem 0;
`;

export const Header = styled.h2`
  margin: 0;
  font-family: ${Styles.fontFamily.brand};
  font-size: 1.25rem;
`;
export const Body = styled.p`
  margin: 0;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
  align-items: center;
  max-width: 20rem;
  margin: auto;
  padding: 0 0.1rem;
  ${Styles.desktop`
    margin: .75rem auto 0;
  `}
`;
