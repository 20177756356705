import gql from 'graphql-tag';

import MenuFragment from './fragments/menu';
import MenuImageFragment from './fragments/menu-image';

export const menu = gql`
  query menu($id: ID!) {
    Menu(id: $id) {
      pickerBackgroundImage {
        ...MenuImageFragment
      }
      ...MenuFragment
    }
  }
  ${MenuFragment}
  ${MenuImageFragment}
`;
