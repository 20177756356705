import { LazyRoute } from './util';

export interface IBrandRoutes {
  [key: string]: LazyRoute;
}

export interface IMain {
  isAccessibleActive?: boolean;
}

export interface IAccountLayout {
  children: React.ReactNode;
  header?: string | null;
  subHeader?: string;
  fullWidth?: boolean;
  mobileContainerPadding?: string;
  desktopContainerPadding?: string;
  className?: string;
}

export enum ForceAppUpdateVariations {
  NONE = 'none',
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
}
