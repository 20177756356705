import { isNil } from 'lodash-es';

import { NonNullableObject } from '@rbi-ctg/frontend';
import { ICartEntry } from '@rbi-ctg/menu';
import { ServiceMode } from 'state/service-mode/types';
import { RBIExpandedPlatform } from 'utils/environment/types';

import { CartPaymentType } from '../order/types';

import { FALSE, FALSE_VALS, TRUE, TRUE_VALS } from './constants';
import { IMParticleAttributes } from './types';

export function serializePaymentType(paymentType: CartPaymentType | null) {
  switch (paymentType) {
    case CartPaymentType.APPLE_PAY:
      return 'APPLE_PAY';
    case CartPaymentType.GOOGLE_PAY:
      return 'GOOGLE_PAY';
    case CartPaymentType.CREDIT_ANONYMOUS:
      return 'CREDIT_ANONYMOUS';
    default:
      return 'VAULTED_ACCOUNT';
  }
}

export function serializeServiceMode(serviceMode: ServiceMode | null) {
  switch (serviceMode) {
    case ServiceMode.DELIVERY:
    case ServiceMode.CATERING_DELIVERY:
      return 'Delivery';
    case ServiceMode.DRIVE_THRU:
    case ServiceMode.EAT_IN:
    case ServiceMode.TAKEOUT:
    case ServiceMode.CURBSIDE:
    case ServiceMode.TABLE_SERVICE:
    case ServiceMode.CATERING_PICKUP:
      return 'Pickup';
    default:
      return 'None';
  }
}

export function serializePickupMode(serviceMode: ServiceMode | null) {
  switch (serviceMode) {
    case ServiceMode.DELIVERY:
      return 'Delivery';
    case ServiceMode.DRIVE_THRU:
      return 'Drive Thru';
    case ServiceMode.EAT_IN:
      return 'Eat In';
    case ServiceMode.TAKEOUT:
      return 'Take Out';
    case ServiceMode.CURBSIDE:
      return 'Curbside';
    case ServiceMode.TABLE_SERVICE:
      return 'Table Service';
    case ServiceMode.CATERING_PICKUP:
      return 'Catering Pickup';
    default:
      return 'None';
  }
}

export function serializeNumberOfDriveThruWindows(driveThruLaneType: string | null) {
  switch (driveThruLaneType) {
    case 'single':
      return 1;
    case 'dual':
      return 2;
    default:
      return 0;
  }
}

// Removes null, undefined and empty string values
export function sanitizeValues<M extends object = IMParticleAttributes>(
  attributes: M
): NonNullableObject<Partial<M>> {
  return Object.entries(attributes).reduce((memo, [key, value]) => {
    const attrIsEmptyString = typeof value === 'string' && value.length === 0;
    if (!isNil(value) && !attrIsEmptyString) {
      memo[key] = value;
    }
    return memo;
  }, {} as NonNullableObject<Partial<M>>) as NonNullableObject<Partial<M>>;
}

export const booleanToString = (bool: boolean): typeof TRUE | typeof FALSE => (bool ? TRUE : FALSE);

const normalizeStringBoolean = (str: string): string => {
  if (TRUE_VALS.includes(str)) {
    return TRUE;
  }
  if (FALSE_VALS.includes(str)) {
    return FALSE;
  }
  return str;
};

export function normalizeBooleans(attributes: object): any {
  const copy = {};
  Object.keys(attributes).forEach(key => {
    const value = attributes[key];
    if (typeof value === 'boolean') {
      copy[key] = booleanToString(value);
    } else if (typeof value === 'string') {
      copy[key] = normalizeStringBoolean(value);
    } else {
      copy[key] = value;
    }
  });
  return copy;
}

export function flattenCartEntryItems(cartEntry: ICartEntry): ICartEntry[] {
  const children = cartEntry.children.reduce((accum, current) => {
    return [...accum, ...flattenCartEntryItems(current)];
  }, []);
  return [cartEntry, ...children];
}

export function determinePlatformFromNavigator(): RBIExpandedPlatform {
  const regexPatterns = {
    androidRegex: /.*?Android.*?/i,
    iOSRegex: /^iP.*$/i,
  };
  const androidMatch = regexPatterns.androidRegex.test(navigator.userAgent);
  const iOSMAtch = regexPatterns.iOSRegex.test(navigator.platform);

  if (androidMatch) {
    return RBIExpandedPlatform.ANDROID;
  } else if (iOSMAtch) {
    return RBIExpandedPlatform.IOS;
  }
  // default to Web if none of the above are matched
  return RBIExpandedPlatform.WEB;
}
