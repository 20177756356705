import gql from 'graphql-tag';

import ItemOptionFragment from './item-option';
import MenuImageFragment from './menu-image';
import NutritionFragment from './nutrition';
import VendorConfigsFragment from './vendor-configs';

export default gql`
  fragment ItemFragment on Item {
    _id
    _type
    name {
      locale: _LANG_
    }
    image {
      ...MenuImageFragment
    }
    rewardEligible
    isDummyItem
    labelAsPerPerson
    operationalItem {
      daypart
    }
    vendorConfigs {
      ...VendorConfigsFragment
    }
    nutrition {
      ...NutritionFragment
    }
    nutritionWithModifiers {
      ...NutritionFragment
    }
    options {
      ...ItemOptionFragment
    }
    productHierarchy {
      L1
      L2
      L3
      L4
      L5
    }
  }

  ${MenuImageFragment}
  ${ItemOptionFragment}
  ${VendorConfigsFragment}
  ${NutritionFragment}
`;
