import { Platform } from 'react-native';
import { UAParser } from 'ua-parser-js';

import { Stage } from 'generated/rbi-graphql';

import { CONFIGURATION } from './__generated-configuration';
import { apiKeyLogger } from './api-key-logger';
import {
  GraphQLEnv,
  RBIAppUriScheme,
  RBIBrand,
  RBIEnv,
  RBIExpandedPlatform,
  RBIFullBrandName,
  RBIPlatform,
} from './types';

// We call the init callback of the migration so that in case the user was already migrated
// we have the chance to update the config (from US to EU for example) as early as possible and login with the migrated user
if (
  typeof window !== 'undefined' && // some tests run this from nodejs
  // @ts-ignore
  typeof window?.__rbiMigration?.initCallback === 'function'
) {
  // if the migration helper was included in the page
  try {
    // @ts-ignore
    window.__rbiMigration.initCallback({
      Configuration: CONFIGURATION,
    });
  } catch (e) {
    if (window.console && typeof window.console.error === 'function') {
      window.console.error(e);
    }
  }
}

const brandToFullBrandName = {
  [RBIBrand.PLK]: RBIFullBrandName.Popeyes,
  [RBIBrand.TH]: RBIFullBrandName.TimHortons,
  [RBIBrand.BK]: RBIFullBrandName.BurgerKing,
  [RBIBrand.LTW]: RBIFullBrandName.LoveThatWing,
  [RBIBrand.FHS]: RBIFullBrandName.FirehouseSubs,
};

const brandToUriScheme = {
  [RBIBrand.PLK]: RBIAppUriScheme.Popeyes,
  [RBIBrand.TH]: RBIAppUriScheme.TimHortons,
  [RBIBrand.BK]: RBIAppUriScheme.BurgerKing,
  [RBIBrand.LTW]: RBIAppUriScheme.LoveThatWing,
  [RBIBrand.FHS]: RBIAppUriScheme.FirehouseSubs,
};

export const getCountry = () => CONFIGURATION.country;
export const brand = () => CONFIGURATION.brand as RBIBrand;
export const env = () => (CONFIGURATION.env || RBIEnv.DEV) as RBIEnv;
export const platform = () => CONFIGURATION.platform as RBIPlatform;
export const checkPlatform = (is: RBIPlatform) => is === platform();
export const checkRbiEnv = (is: RBIEnv) => is === env();
export const appVersionCode = () => CONFIGURATION.commitRef || 'dev';
export const graphqlEnv = () => (CONFIGURATION.graphqlEnv || env()) as GraphQLEnv;
export const graphqlGatewayEnv = () => (CONFIGURATION.graphqlGatewayEnv || env()) as GraphQLEnv;
export const sanityEnv = () => (CONFIGURATION.sanityEnv || env()) as RBIEnv;
export const welcomeEmailDomain = () =>
  (process.env.REACT_APP_RBI_WELCOME_EMAIL_DOMAIN || env()) as Stage;
export const fullBrandName = () => brandToFullBrandName[brand()];
export const appUriScheme = () => brandToUriScheme[brand()];

type RBIReleaseTimestamp = string;
export const releaseTimestamp = () => CONFIGURATION.releaseTimestamp as RBIReleaseTimestamp;

export const country = getCountry();
// Our Dataset is always of the form ENV_BRAND except in test ENV where we always use 'automation'
export const sanityDataset = CONFIGURATION.sanityDataset || `${sanityEnv()}_${brand()}`;

export { RBIBrand, RBIEnv, RBIPlatform, RBIExpandedPlatform, GraphQLEnv };

export const googleTagManagerScriptId = () =>
  `rbi-${brand()}-${country}-${env()}-google-tag-manager`;

export const isLocalDev = process.env.NODE_ENV !== 'production';
export const isStoryBook = process.env.STORYBOOK === 'true';
export const isTest = process.env.NODE_ENV === 'test';
export const isProduction = checkRbiEnv(RBIEnv.PROD);
export const isWeb = checkPlatform(RBIPlatform.WEB);
export const isNative = checkPlatform(RBIPlatform.APP);
export const isKiosk = checkPlatform(RBIPlatform.KIOSK);
export const isMobile = () =>
  ['mobile', 'tablet'].includes(UAParser(navigator.userAgent).device.type ?? '');

export const isIOS = () => {
  if (Platform.OS === 'ios') {
    return true;
  }
  if (Platform.OS === 'android') {
    return false;
  }

  return (
    new Set(['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod']).has(
      navigator.platform
    ) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
};
export const isNativeIOS = () => isNative && isIOS();

export const versionIsGreaterThanOrEqualTo = ({
  versionString,
  majorVersionGreaterThanOrEqualTo,
}: {
  versionString: string | null;
  majorVersionGreaterThanOrEqualTo: number;
}): boolean => {
  if (!versionString) {
    return false;
  }
  const majorVersion = Number(versionString.split('.')[0]);
  return majorVersion >= majorVersionGreaterThanOrEqualTo;
};

/** Simple function to see if we're running the app locally. */
export const isRunningLocally = () =>
  window.location.hostname === 'localhost' || !isNaN(Number(window.location?.hostname?.charAt(0)));

export const getConfigValue = <K extends keyof typeof CONFIGURATION>(key: K) => {
  return CONFIGURATION[key];
};

type ApiKeys = NonNullable<typeof CONFIGURATION['apiKeys']>;

// Initiate an empty dictionary to prevent duplicates DD logs of missing api key
let missingApiKeys = {};

export const getApiKey = <K extends keyof ApiKeys>(key: K, fallback = '') => {
  const keys = getConfigValue('apiKeys');
  // Make sure we haven't already log the missing API keys
  if ((!keys || !keys[key]) && !missingApiKeys[String(key)]) {
    missingApiKeys[String(key)] = true;
    const error = `Missing API key in the Sanity FE configs. API Key: ${key}, Fallback: ${
      fallback === '' ? 'None' : `"${fallback}"`
    }.`;
    const ctx = {
      appVersionCode: appVersionCode(),
      brand: brand(),
      platform: platform(),
      stage: env(),
      reason: 'front end configuration missing an API key',
    };

    apiKeyLogger(error, ctx);

    return fallback;
  }
  return (keys && keys[key]) ?? fallback;
};

// This is only for testing purpose.
export const resetMissingApiKeysForTestOnly = () => (missingApiKeys = {});
