/**
 * AUTOGENERATED - DO NOT EDIT DIRECTLY
 *
 * Please see "scripts/regionalization/README.md" for how to modify
 */

import { REGIONS } from './types';

export enum PROD_SUPPORTED_REGIONS {
  AQ = REGIONS.AQ,
  AT = REGIONS.AT,
  CA = REGIONS.CA,
  CH = REGIONS.CH,
  CZ = REGIONS.CZ,
  DE = REGIONS.DE,
  ES = REGIONS.ES,
  GB = REGIONS.GB,
  NL = REGIONS.NL,
  NZ = REGIONS.NZ,
  PL = REGIONS.PL,
  PT = REGIONS.PT,
  RO = REGIONS.RO,
  US = REGIONS.US,
  ZA = REGIONS.ZA,
}

export const PROD_DEFAULT_REGION = PROD_SUPPORTED_REGIONS.US;
