import { ApolloLink, Operation, from, split } from 'apollo-link';
import { BatchHttpLink } from 'apollo-link-batch-http';
import { createHttpLink } from 'apollo-link-http';
import { createPersistedQueryLink } from 'apollo-link-persisted-queries';
import { RetryLink } from 'apollo-link-retry';
import { isNil } from 'lodash-es';

import { SupportedLanguages, SupportedRegions } from '@rbi-ctg/frontend';
import { getGraphqlApiUrl, getGraphqlGatewayApiUrl } from 'remote/constants';

import {
  AUTH_REQUIRED_DIRECTIVE,
  isRequestAuthProtected,
  isUserLoggedIn,
  stripAuthDirective,
} from './auth-required-directive';
import { cancelRequestLink } from './cancel-request-link';
import { sanityFetch } from './sanity-fetch';
import { isGatewayRequest, stripGatewayDirective } from './strip-gateway-directive';
import { removeNestedTypenameFromMenuQueriesLink, stripTypenameLink } from './strip-typename';
import { isCacheRequest, stripUseCacheDirective } from './strip-useCache-directive';
import { withAuthToken } from './with-auth-token';
import { withClientInfo } from './with-client-info-headers';
import { withErrorLogger } from './with-error-logger';
import { withErrorMParticle } from './with-error-mparticle';
import { withForterHeaders } from './with-forter-headers';
import { withHeaders } from './with-headers';
import { withI18nContext } from './with-i18n-context';
import { withI18nHeaders } from './with-i18n-headers';
import { DEPRECATED_withLocalizedQueries, withLocalizedQueries } from './with-localized-queries';
import { withLogger } from './with-logger';
import { withLogrocketHeaders } from './with-logrocket-headers';
import { withMParticle } from './with-mparticle';
import { withSessionId } from './with-session-id';
import { withDateTimeHeaders } from './with-user-datetime-headers';

const isSanityRequest = (context?: Record<string, any>) => !isNil(context && context.uri);
const operationIsGatewayRequest = (operation: Operation) => isGatewayRequest(operation.query);
const operationIsSanityRequest = (operation: Operation) => isSanityRequest(operation.getContext());
const operationIsCacheRequest = (operation: Operation) => isCacheRequest(operation.query);

// Used to display network requests in LogRocket for our Gateway and RBI GQL servers
const LogRocketFetcher = (url: string, config: RequestInit) => fetch(url, config);

const fetchOptions = {
  referrerPolicy: 'no-referrer',
};

const sanityHttpLink = from([
  withLocalizedQueries(),
  // @todo remove
  DEPRECATED_withLocalizedQueries(),
  new RetryLink({ attempts: { max: 3 } }),
  // sanity requests have the uri set in context
  from([
    removeNestedTypenameFromMenuQueriesLink,
    createHttpLink({ credentials: 'omit', fetch: sanityFetch, fetchOptions }),
  ]),
]);

const withRBIGraphQLLinks = from([
  withAuthToken,
  withI18nHeaders,
  withLogrocketHeaders,
  withDateTimeHeaders,
  withForterHeaders,
  withSessionId,
  withClientInfo,
]);

const gatewayHttpLink = createHttpLink({
  uri: getGraphqlGatewayApiUrl(),
  credentials: 'omit',
  fetch: LogRocketFetcher,
});

const gatewaySplit = split(
  operationIsGatewayRequest,
  split(
    operationIsCacheRequest,
    from([
      stripGatewayDirective,
      stripUseCacheDirective,
      createPersistedQueryLink({ useGETForHashedQueries: true }),
      gatewayHttpLink,
    ]),
    from([stripGatewayDirective, gatewayHttpLink])
  ),
  from([
    stripGatewayDirective,
    split(
      /**
       * We avoid batching requests via BatchHttpLink when:
       * - context.shouldNotBatch = true
       * - app is run within Cypress. we want deterministic tests.
       */
      operation => operation.getContext().shouldNotBatch || !!window?.Cypress,
      createHttpLink({ uri: getGraphqlApiUrl(), credentials: 'omit', fetch: LogRocketFetcher }),
      new BatchHttpLink({ uri: getGraphqlApiUrl(), credentials: 'omit' }) as ApolloLink
    ),
  ])
);

/**
 * The lambdaHttpLink composes the link chain for requests going out to our
 * lambda backend. It can be difficult to visualize the chain with all the
 * splits, so here's a diagram:
 *
 *                         ...withRBIGraphQLLinks
 *                      is auth required on this request?
 *                         /                 \
 *                       yes                  no
 *                      /                      \
 *                 is user logged in?       gateway split
 *                  /           \
 *                yes           no
 *               /                \
 *          gateway split     invalid request link
 *
 * we split out the gatewaySplit into a variable so that it can be re-used,
 * because there are now two different places where we might want to use it.
 * after composing the "base" links (`withRBIGraphQLLinks`), we first ask if
 * we're dealing with a query that required auth. if not, we send the request
 * down the pre-existing chain, which just has the gateway split as the next
 * step. if the query does require auth, we send it to this new step, which
 * then asks if the user is logged in. if not, we cancel the request and
 * return an error. if they are logged in, then we treat it as a "normal"
 * request and send it down the rest of the link chain, where the first
 * step is the gateway split.
 */
const lambdaHttpLink = from([
  withRBIGraphQLLinks,
  split(
    isRequestAuthProtected,
    split(
      isUserLoggedIn,
      from([stripAuthDirective, gatewaySplit]),
      cancelRequestLink(
        `@${AUTH_REQUIRED_DIRECTIVE} directive present on query but user is not authorized.`
      )
    ),
    gatewaySplit
  ),
]);

const httpSplit = split(operationIsSanityRequest, sanityHttpLink, lambdaHttpLink);

export const link = from([
  withLogger,
  withMParticle,
  withErrorLogger,
  withErrorMParticle,
  withI18nContext,
  withHeaders({ 'content-type': 'application/json' }),
  stripTypenameLink,
  httpSplit,
]);

// ensure language and region are set before any queries are fired
export const getConfiguredLink = (language: SupportedLanguages, region: SupportedRegions) => {
  withI18nContext.setLocale(language, region);

  return link;
};
