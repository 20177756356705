import React from 'react';

import styled from 'styled-components';

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  ${Styles.desktop`
    border-bottom: 1px solid ${Styles.color.grey.four};
    padding: 20px 0 64px;

    :first-child {
      padding-top: 0;
    }

    :last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
  `}

  > * {
    flex-basis: ${props => 100 / React.Children.count(props.children)}%;
  }
`;
